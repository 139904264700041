import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import { REQUEST_GET_PLANS, GET_PLANS } from '../types/index';

const api = new Api();

function* getPlansWatcher() {
  yield takeEvery(REQUEST_GET_PLANS, getPlansWorker);
}

function* getPlansWorker({ payload: { onSuccessCallback, onErrorCallback, onFinallyCallback } }) {
  try {
    const data = yield call(api.getPlans);
    yield put({ type: GET_PLANS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* plans() {
  yield all([fork(getPlansWatcher)]);
}
