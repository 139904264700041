import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Spin } from 'antd';
import { updateSlot } from '../../../redux/actions/slots';
import { prevModal, showModal } from '../../../redux/actions/modal';
import Api from '../../../redux/middlewares/Api';

import { schema } from '../../../utils/validation_schemas/slot';

import { getFormattedPostSlotData, getFormattedGetSlotData } from '../../../utils/slots';
import { getCorrectErrorMessages } from '../../../utils/errors';
import { MODAL_TYPES } from '../../../constants';

import HookedField from '../../HookedField/HookedField';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import LocationPicker from '../../LocationPicker/LocationPicker';

import './SlotForm.scss';

const api = new Api();

const SlotEditForm = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // get Slot

  const [slotData, setSlotData] = useState({});
  const [slotLoading, setSlotLoading] = useState(true);
  const [onGetSlotErrors, setOnGetSlotErrors] = useState([]);

  useEffect(() => {
    api.getSlot(id).then(
      (slot) => {
        const formattedData = getFormattedGetSlotData(slot);
        setSlotData(formattedData);
        setSlotLoading(false);
      },
      (errors) => {
        setSlotLoading(false);
        setOnGetSlotErrors(getCorrectErrorMessages(errors));
      },
    );
  }, [id]);

  // update Slot

  const [updateSlotLoading, setUpdateSlotLoading] = useState(false);
  const [onUpdateSlotErrors, setOnUpdateSlotErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(prevModal());
  };

  const submitHandler = (data) => {
    setUpdateSlotLoading(true);
    const formattedData = getFormattedPostSlotData(data);
    dispatch(
      updateSlot({
        id,
        slotData: formattedData,
        onSuccessCallback: () => {
          setUpdateSlotLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Slot was updated!'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setOnUpdateSlotErrors(getCorrectErrorMessages(errors));
          setUpdateSlotLoading(false);
        },
      }),
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="slotForm">
        <h3 className="slotForm-heading">{t('Editing Featured Partner Slot')}</h3>
        {slotLoading ? (
          <Spin size="large" />
        ) : Object.keys(slotData).length ? (
          <form onSubmit={methods.handleSubmit(submitHandler)}>
            <LocationPicker
              label={t('Region')}
              name="region"
              defaultValue={slotData.region || {}}
              placeholder={t('Select Region')}
            />
            <HookedField
              name="period"
              label={t('Activity Period')}
              defaultValue={slotData.period || []}
              type="dateRange"
              fieldType="dateRange"
              disabledDate={(current) => current && current < moment()}
            />
            <HookedField
              name="price"
              placeholder={t('Enter Price')}
              label={t('Price')}
              defaultValue={slotData.price || 0}
              fieldType="numberField"
              type="price"
              min={0}
            />
            <ErrorMessages errorMessages={onUpdateSlotErrors} />
            <Spin spinning={updateSlotLoading}>
              <div className="modal-actions modal-actions--fullsize">
                <button
                  type="button"
                  className="btn btn--small btn--secondary"
                  onClick={cancelHandler}
                >
                  {t('Cancel')}
                </button>
                <button type="submit" className="btn btn--small btn--primary">
                  {t('Save')}
                </button>
              </div>
            </Spin>
          </form>
        ) : (
          <ErrorMessages errorMessages={onGetSlotErrors} />
        )}
      </div>
    </FormProvider>
  );
};

export default SlotEditForm;
