import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../constants';

import Tabs from '../../../components/Tabs/Tabs';
import ResetPasswordForm from '../../../components/ResetPasswordForm/ResetPasswordForm';
import Support from '../../../components/Support/Support';
import { resetPassword } from '../../../redux/actions/auth';
import BackBtn from '../../../components/BackBtn/BackBtn';
import ChangePassword from '../../../components/ChangePassword/ChangePassword';
import { getCorrectErrorMessages } from '../../../utils/errors';

import './AccountSettings.scss';

const AccountSettings = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const [isResetForm, setIsResetForm] = useState(false);

  // reset password

  const [onResetPasswordErrors, setOnResetPasswordErrors] = useState([]);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const handleResetPassword = (data) => {
    setResetPasswordLoading(true);
    dispatch(
      resetPassword({
        data,
        onSuccessCallback: () => {
          setResetPasswordLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Instructions Sent!'),
              description: t(
                'Please check your email and follow the instructions to reset your password',
              ),
              onClose: () => {
                history.push('/auth');
                dispatch(hideModal());
              },
            }),
          );
        },
        onErrorCallback: (errors) => {
          setResetPasswordLoading(false);
          setOnResetPasswordErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="accountSettings">
      <div className="accountSettings-top">
        <h2 className="pageTitle">{t('Change password')}</h2>
        <a
          href="https://loial.pro/support"
          target="_blank"
          className="btn btn--small btn--primary"
          rel="noreferrer"
        >
          {t('Support')}
        </a>
      </div>
      <div className="accountSettings-tab">
        {isResetForm ? (
          <>
            <BackBtn onClick={() => setIsResetForm(false)} className="accountSettings-backBtn" />
            <ResetPasswordForm
              resetPasswordLoading={resetPasswordLoading}
              handleResetPassword={handleResetPassword}
              onResetPasswordErrors={onResetPasswordErrors}
            />
          </>
        ) : (
          <ChangePassword goToResetPasswordForm={() => setIsResetForm(true)} />
        )}
      </div>
    </div>
  );
};

export default AccountSettings;
