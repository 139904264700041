import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  REQUEST_GET_CUSTOMER_STATISTICS,
  GET_CUSTOMER_STATISTICS,
  REQUEST_GET_DETAILS_STATISTICS,
  GET_DETAILS_STATISTICS,
  GET_GENDER_STATISTICS,
  REQUEST_GET_GENDER_STATISTICS,
  GET_AGE_STATISTICS,
  REQUEST_GET_AGE_STATISTICS,
  GET_LINES_STATISTICS,
  REQUEST_GET_LINES_STATISTICS,
  GET_ADMIN_STATISTICS,
  REQUEST_GET_ADMIN_STATISTICS,
} from '../types/index';
import { ADMIN_STATISTICS_OPTIONS } from '../../constants';

const api = new Api();

function* getCustomerStatisticsWatcher() {
  yield takeEvery(REQUEST_GET_CUSTOMER_STATISTICS, getCustomerStatisticsWorker);
}

function* getDetailsStatisticsWatcher() {
  yield takeEvery(REQUEST_GET_DETAILS_STATISTICS, getDetailsStatisticsWorker);
}

function* getGenderStatisticsWatcher() {
  yield takeEvery(REQUEST_GET_GENDER_STATISTICS, getGenderStatisticsWorker);
}

function* getAgeStatisticsWatcher() {
  yield takeEvery(REQUEST_GET_AGE_STATISTICS, getAgeStatisticsWorker);
}

function* getLinesStatisticsWatcher() {
  yield takeEvery(REQUEST_GET_LINES_STATISTICS, getLinesStatisticsWorker);
}

function* getAdminStatisticsWatcher() {
  yield takeEvery(REQUEST_GET_ADMIN_STATISTICS, getAdminStatisticsWorker);
}

function* getCustomerStatisticsWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getCustomerStatistics);
    yield put({ type: GET_CUSTOMER_STATISTICS, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getDetailsStatisticsWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getDetailsStatistics);
    yield put({ type: GET_DETAILS_STATISTICS, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getGenderStatisticsWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getGenderStatistics);
    yield put({ type: GET_GENDER_STATISTICS, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getAgeStatisticsWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getAgeStatistics);
    yield put({ type: GET_AGE_STATISTICS, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getLinesStatisticsWorker({
  payload: { period, status, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.getLinesStatistics(period, status));
    yield put({ type: GET_LINES_STATISTICS, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getAdminStatisticsWorker({
  payload: { period, option, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => {
      if (option === ADMIN_STATISTICS_OPTIONS.APP_OPENED) {
        return api.getOpenedApp(period);
      }
      return api.getAdminStatistics(period, option);
    });
    yield put({ type: GET_ADMIN_STATISTICS, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* statistics() {
  yield all([
    fork(getCustomerStatisticsWatcher),
    fork(getDetailsStatisticsWatcher),
    fork(getGenderStatisticsWatcher),
    fork(getAgeStatisticsWatcher),
    fork(getLinesStatisticsWatcher),
    fork(getAdminStatisticsWatcher),
  ]);
}
