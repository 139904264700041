import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { Spin } from 'antd';
import { hideModal, prevModal } from '../../redux/actions/modal';

import Icon from '../Icon';
import { ICONS, MODAL_TYPES } from '../../constants';
import ConfirmModal from '../modals/ConfirmModal/ConfirmModal';
import NotificationModal from '../modals/NotificationModal/NotificationModal';
import QrCodeModal from '../modals/QrCodeModal/QrCodeModal';
import SupportModal from '../modals/SupportModal/SupportModal';
import CalendarModal from '../modals/CalendarModal/CalendarModal';
import InvitationModal from '../modals/InvitationModal/InvitationModal';
import SlotView from '../Slot/SlotView/SlotView';
import NotificationView from '../Notification/NotificationView/NotificationView';
import ProductView from '../Product/ProductView/ProductView';
import SlotBuy from '../Slot/SlotBuy/SlotBuy';
import NewsletterAddForm from '../Newsletter/NewsletterForms/NewsletterAddForm';
import NewsletterDelete from '../Newsletter/NewsletterDelete/NewsletterDelete';
import NewsletterEditForm from '../Newsletter/NewsletterForms/NewsletterEditForm';
import NewsletterView from '../Newsletter/NewsletterView/NewsletterView';
import MessageView from '../Message/MessageView/MessageView';
import MessageEditForm from '../Message/MessageForms/MessageEditForm';
import MessageAddForm from '../Message/MessageForms/MessageAddForm';
import MessageDelete from '../Message/MessageDelete/MessageDelete';
import AccessModal from '../modals/AccessModal/AccessModal';
import ProductDelete from '../Product/ProductDelete/ProductDelete';
import BusinessDelete from '../Business/BusinessDelete/BusinessDelete';
import BusinessPartnerDelete from '../BusinessPartner/BusinessPartnerDelete/BusinessPartnerDelete';
import ProductAddForm from '../Product/ProductForms/ProductAddForm';
import ProductEditForm from '../Product/ProductForms/ProductEditForm';
import SlotDelete from '../Slot/SlotDelete/SlotDelete';
import SlotAddForm from '../Slot/SlotForms/SlotAddForm';
import SlotEditForm from '../Slot/SlotForms/SlotEditForm';

import './Modal.scss';
import MemberDelete from '../Member/MemberDelete/MemberDelete';
import RegularMessageAddForm from '../RegularMessage/RegularMessageForms/RegularMessageAddForm';

const Modal = () => {
  const dispatch = useDispatch();

  const isOpened = useSelector((state) => state.modal.isOpened);
  const data =
    useSelector((state) => state.modal.dataArray[state.modal.dataArray.length - 1]) || {};

  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    dispatch(hideModal());
  };

  const openPrevModal = () => {
    dispatch(prevModal());
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, [data]);

  const renderContent = () => {
    switch (data.type) {
      case MODAL_TYPES.PRODUCT_VIEW:
        return <ProductView id={data.id} />;
      case MODAL_TYPES.PRODUCT_ADD:
        return <ProductAddForm onSuccessCallback={data.onSuccessCallback} />;
      case MODAL_TYPES.PRODUCT_EDIT:
        return <ProductEditForm id={data.id} onSuccessCallback={data.onSuccessCallback} />;
      case MODAL_TYPES.PRODUCT_DELETE:
        return <ProductDelete id={data.id} />;
      case MODAL_TYPES.BUSINESS_DELETE:
        return <BusinessDelete id={data.id} />;
      case MODAL_TYPES.PARTNER_DELETE:
        return <BusinessPartnerDelete id={data.id} />;
      case MODAL_TYPES.MEMBER_DELETE:
        return <MemberDelete businessId={data.businessId} memberId={data.memberId} />;
      case MODAL_TYPES.MESSAGE_VIEW:
        return <MessageView id={data.id} />;
      case MODAL_TYPES.MESSAGE_EDIT:
        return <MessageEditForm id={data.id} />;
      case MODAL_TYPES.MESSAGE_ADD:
        return <MessageAddForm autopilotType={data.autopilotType} />;
      case MODAL_TYPES.MESSAGE_DELETE:
        return <MessageDelete id={data.id} />;
      case MODAL_TYPES.CONFIRM:
        return (
          <ConfirmModal
            question={data.question}
            cancelBtnText={data.cancelBtnText}
            okBtnText={data.okBtnText}
            cancelHandler={data.cancelHandler}
            okHandler={data.okHandler}
          />
        );
      case MODAL_TYPES.NOTIFICATION:
        return (
          <NotificationModal
            title={data.title}
            description={data.description}
            cancelBtnText={data.cancelBtnText}
            okBtnText={data.okBtnText}
            okHandler={data.okHandler}
            cancelHandler={data.cancelHandler}
          />
        );
      case MODAL_TYPES.QR_CODE:
        return (
          <QrCodeModal
            title={data.title}
            productType={data.productType}
            productId={data.productId}
            productTrackingID={data.productTrackingID}
          />
        );
      case MODAL_TYPES.SUPPORT:
        return <SupportModal />;
      case MODAL_TYPES.CALENDAR:
        return <CalendarModal cards={data.cards} />;
      case MODAL_TYPES.INVITATION:
        return (
          <InvitationModal
            onInvitationsSent={data.onInvitationsSent}
            businessId={data.businessId}
          />
        );
      case MODAL_TYPES.SLOT_VIEW:
        return <SlotView data={data.data} />;
      case MODAL_TYPES.SLOT_ADD:
        return <SlotAddForm />;
      case MODAL_TYPES.SLOT_EDIT:
        return <SlotEditForm id={data.id} />;
      case MODAL_TYPES.SLOT_BUY:
        return <SlotBuy slotId={data.slotId} onSuccessCallback={data.onSuccessCallback} />;
      case MODAL_TYPES.SLOT_DELETE:
        return <SlotDelete id={data.id} />;
      case MODAL_TYPES.NOTIFICATION_VIEW:
        return <NotificationView data={data.data} />;
      case MODAL_TYPES.NEWSLETTER_ADD:
        return <NewsletterAddForm />;
      case MODAL_TYPES.NEWSLETTER_EDIT:
        return <NewsletterEditForm id={data.id} />;
      case MODAL_TYPES.NEWSLETTER_DELETE:
        return <NewsletterDelete id={data.id} />;
      case MODAL_TYPES.NEWSLETTER_VIEW:
        return <NewsletterView id={data.id} />;
      case MODAL_TYPES.ACCESS:
        return <AccessModal title={data.title} description={data.description} />;
      case MODAL_TYPES.REGULAR_MESSAGE_ADD:
        return <RegularMessageAddForm />;
      default:
        return <div>Hello !</div>;
    }
  };

  return (
    <div className={`modal modal--${data.type}`}>
      {isOpened && <div className="modal-overlay" onClick={data.onClose || closeModal} />}
      <CSSTransition in={isOpened} classNames="fadeIn" timeout={200} unmountOnExit>
        <div className="modal-window">
          <button className="modal-close" onClick={data.onClose || closeModal}>
            <Icon icon={ICONS.CLOSE} size={24} />
          </button>
          {data && (
            <Spin spinning={loading} size="large">
              <div className="modal-content">{renderContent()}</div>
            </Spin>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};

export default Modal;
