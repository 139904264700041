import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './redux/reducers/rootReducer';
import rootSaga from './redux/saga/rootSaga';

const isNotProduction = process.env.NODE_ENV !== 'production';
export const history = createBrowserHistory();
const saga = createSagaMiddleware();
const reduxRouterMiddleware = routerMiddleware(history);
const middlewareItems = [reduxRouterMiddleware, saga];
let middlewares = applyMiddleware(...middlewareItems);

if (isNotProduction && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middlewares = compose(middlewares, window.__REDUX_DEVTOOLS_EXTENSION__());
}

const initialState = {};

const reducer = combineReducers({
  ...rootReducer,
  router: connectRouter(history),
});

export const store = createStore(reducer, initialState, middlewares);

saga.run(rootSaga);
