import React from 'react';
import { useSelector } from 'react-redux';

import { ROLES } from '../../constants';

import PartnerProfile from '../../components/Profile/PartnerProfile';
import AdminProfile from '../../components/Profile/AdminProfile';

const ProfilePage = () => {
  const { role } = useSelector((state) => state.profile.data);

  const renderProfile = () => {
    switch (role) {
      case ROLES.PARTNER:
        return <PartnerProfile />;
      case ROLES.ADMIN:
        return <AdminProfile />;
      default:
        return null;
    }
  };

  return renderProfile();
};

export default ProfilePage;
