import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { updateNewsletter } from '../../../redux/actions/newsletter';
import { hideModal, showModal } from '../../../redux/actions/modal';

import { schema } from '../../../utils/validation_schemas/newsletter';

import {
  getFormattedGetNewsletterData,
  getFormattedPostNewsletterData,
} from '../../../utils/newsletters';
import { MODAL_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';
import Api from '../../../redux/middlewares/Api';

import HookedField from '../../HookedField/HookedField';
import Images from '../../Images/Images';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';

import './NewsletterForm.scss';
import NewsletterSlot from '../NewsletterSlot/NewsletterSlot';
import { sortById } from '../../../utils/sorters';

const api = new Api();

const NewsletterEditForm = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let counter = 0;

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // get newsletter

  const [newsletterData, setNewsletterData] = useState({});
  const [newsletterLoading, setNewsletterLoading] = useState(true);
  const [onGetNewsletterErrors, setOnGetNewsletterErrors] = useState([]);

  useEffect(() => {
    api.getNewsletter(id).then(
      (newsletter) => {
        const formattedData = getFormattedGetNewsletterData(newsletter);
        setNewsletterData(formattedData);
        setNewsletterLoading(false);
      },
      (errors) => {
        setOnGetNewsletterErrors(getCorrectErrorMessages(errors));
        setNewsletterLoading(false);
      },
    );
  }, [id]);

  // update Newsletter

  const [updateNewsletterLoading, setUpdateNewsletterLoading] = useState(false);
  const [onUpdateNewsletterErrors, setOnUpdateNewsletterErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = (data) => {
    setUpdateNewsletterLoading(true);
    const formattedData = getFormattedPostNewsletterData(data);
    dispatch(
      updateNewsletter({
        id,
        newsletterData: formattedData,
        onSuccessCallback: () => {
          setUpdateNewsletterLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your newsletter was successfully updated'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setUpdateNewsletterLoading(false);
          setOnUpdateNewsletterErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="newsletterForm">
      <h3 className="newsletterForm-heading">{t('Editing Newsletter')}</h3>
      <FormProvider {...methods}>
        {newsletterLoading ? (
          <Spin size="large" />
        ) : Object.keys(newsletterData).length ? (
          <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
            <fieldset>
              <HookedField
                name="subject"
                placeholder={t('Main Subject')}
                label={t('Main Subject')}
                defaultValue={newsletterData.subject || ''}
                fieldType="textField"
                type="text"
              />
              <Images defaultCover={newsletterData.cover || null} />
              <HookedField
                name="description"
                placeholder={t('Message Text')}
                label={t('Message Text')}
                defaultValue={newsletterData.description || ''}
                fieldType="textField"
                type="textarea"
              />
            </fieldset>
            {newsletterData.slots && newsletterData.slots.length
              ? newsletterData.slots.sort(sortById).map((slot, idx) => {
                  if (!slot.owner) {
                    const index = counter++;
                    return (
                      <fieldset key={slot.id}>
                        <h4>Newsletter Slot {idx + 1}</h4>
                        <HookedField
                          name={`slots[${index}].price`}
                          placeholder={t('Enter Price')}
                          label={t('Price')}
                          defaultValue={slot.price || 0}
                          fieldType="numberField"
                          type="price"
                          min={0}
                          error={
                            methods.errors.slots &&
                            methods.errors.slots[index] &&
                            methods.errors.slots[index].price
                          }
                        />
                        <input
                          type="hidden"
                          ref={methods.register}
                          name={`slots[${index}].id`}
                          value={slot.id}
                        />
                      </fieldset>
                    );
                  }
                  return (
                    <NewsletterSlot
                      key={slot.id}
                      id={slot.id}
                      subject={slot.subject}
                      description={slot.description}
                      cover={slot.cover}
                      price={slot.price}
                      owner={slot.owner}
                    />
                  );
                })
              : null}
            <div className="newsletterForm-settings">
              <h4 className="newsletterForm-settings-title">{t('Newsletter Settings')}</h4>
              <HookedField
                name="date"
                placeholder={t('Date')}
                label={t('Date')}
                defaultValue={newsletterData.date || null}
                type="datePicker"
                fieldType="dateField"
              />
              <HookedField
                name="mailingTime"
                placeholder={t('Select Time')}
                label={t('Mailing Time')}
                defaultValue={newsletterData.mailingTime || null}
                type="timePicker"
                fieldType="timeField"
              />
              <ErrorMessages errorMessages={onUpdateNewsletterErrors} />
              <Spin spinning={updateNewsletterLoading}>
                <div className="modal-actions modal-actions--fullsize">
                  <button
                    type="button"
                    className="btn btn--small btn--secondary"
                    onClick={cancelHandler}
                  >
                    {t('Cancel')}
                  </button>
                  <button type="submit" className="btn btn--small btn--primary">
                    {t('Save')}
                  </button>
                </div>
              </Spin>
            </div>
          </form>
        ) : (
          <ErrorMessages errorMessages={onGetNewsletterErrors} />
        )}
      </FormProvider>
    </div>
  );
};

export default NewsletterEditForm;
