import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import './SubMenu.scss';

const SubMenu = ({ id, buttonText, buttonImage, deleteHandler, defaultOpen }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(defaultOpen);

  const classes = ['subMenu', isOpen ? 'open' : ''];

  const onButtonClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={classes.join(' ')}>
      <button className="menuLink menuLink--parent" onClick={onButtonClick}>
        <div className="menuLink-icon">
          <img crossOrigin="anonymous" src={buttonImage} alt={buttonText} />
        </div>
        <div className="menuLink-text" title={buttonText}>
          {buttonText}
        </div>
      </button>
      <ul className="subMenu-list">
        <li className="subMenu-item">
          <NavLink
            className="subMenu-link"
            to={`/main/business-profile/${id}/main-info`}
            activeClassName="selected"
          >
            {t('Main Info')}
          </NavLink>
        </li>
        <li className="subMenu-item">
          <NavLink
            className="subMenu-link"
            to={`/main/business-profile/${id}/cards-and-gifts`}
            activeClassName="selected"
          >
            {t('Cards & Gifts')}
          </NavLink>
        </li>
        <li className="subMenu-item">
          <NavLink
            className="subMenu-link"
            to={`/main/business-profile/${id}/my-team`}
            activeClassName="selected"
          >
            {t('My Team')}
          </NavLink>
        </li>
        <button onClick={() => deleteHandler(id)} className="subMenu-link subMenu-deleteBtn">
          {t('Delete Business')}
        </button>
      </ul>
    </div>
  );
};

export default SubMenu;
