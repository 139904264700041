import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, updateUser } from '../../../redux/actions/user';
import { showModal } from '../../../redux/actions/modal';
import { MODAL_TYPES, ROLES } from '../../../constants';

import ProfilePreview from '../../Profile/ProfilePreview/ProfilePreview';
import BackBtn from '../../BackBtn/BackBtn';
import { getCorrectErrorMessages } from '../../../utils/errors';

const BusinessPartnerEdit = () => {
  const { t } = useTranslation();

  const { id: partnerId } = useParams();
  const dispatch = useDispatch();

  const currentPartner = useSelector((state) => state.users.currentUser);

  const [profileDataLoading, setProfileDataLoading] = useState(true);

  useEffect(() => {
    setProfileDataLoading(true);
    if (partnerId) {
      dispatch(
        getUser({
          id: partnerId,
          onSuccessCallback: () => setProfileDataLoading(false),
          onErrorCallback: () => setProfileDataLoading(false),
        }),
      );
    }
  }, [partnerId]);

  // update profile information

  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [onUpdateProfileErrors, setOnUpdateProfileErrors] = useState([]);

  const updateProfileHandler = (data) => {
    setUpdateProfileLoading(true);
    dispatch(
      updateUser({
        id: partnerId,
        userData: {
          ...data,
          phone: data.phone.replace(/\s/g, ''),
          role: ROLES.PARTNER,
        },
        onSuccessCallback: () => {
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your data was successfully updated'),
            }),
          );
          setUpdateProfileLoading(false);
          setOnUpdateProfileErrors([]);
        },
        onErrorCallback: (errors) => {
          setUpdateProfileLoading(false);
          setOnUpdateProfileErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="editPartner">
      <BackBtn className="profile-backBtn" />
      <ProfilePreview
        defaultValues={currentPartner}
        handleSubmit={updateProfileHandler}
        profileDataLoading={profileDataLoading}
        updateProfileLoading={updateProfileLoading}
        onUpdateProfileErrors={onUpdateProfileErrors}
        withAvatar={false}
      />
    </div>
  );
};

export default BusinessPartnerEdit;
