import React from 'react';

const GenderMaleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="99" height="98" fill="none" viewBox="0 0 99 98">
    <g opacity="0.8">
      <ellipse cx="66.912" cy="36.307" fill="#FFDDCE" rx="2.932" ry="5.582" />
      <ellipse fill="#FFDDCE" rx="2.932" ry="5.582" transform="matrix(-1 0 0 1 31.808 38.425)" />
      <circle cx="49.255" cy="48.554" r="45.325" fill="#F9F9FA" />
      <path
        fill="#EFEFEF"
        d="M51.048 73.922l2.486-1.463 1.143 1.463 2.867-21.584H41.746l2.24 21.584h7.061z"
      />
      <path
        fill="#7F7F7F"
        d="M72.91 66.621l-15.413-2.665c-4.684 2.995-3.316 3.634-7.68 3.634-3.964-.474-5.533-5.238-11.008-3.634l-13.473 2.665c-5.13 1.015-8.839 5.63-8.839 11v4.306c8.334 8.512 19.804 13.786 32.472 13.829h.308c12.668-.043 24.138-5.316 32.472-13.83V77.62c0-5.37-3.709-9.984-8.84-10.999z"
      />
      <path
        fill="#000"
        d="M17.945 75.525l-1.816-.28-.003.02-.003.022 1.822.238zm-2.824 7.413a1.838 1.838 0 103.644.477l-3.644-.477zm27.055-18.982l-.202-1.826h-.002l-.01.001a6.261 6.261 0 00-.156.018l-.468.054a185.287 185.287 0 00-7.12.98c-2.066.327-4.282.72-6.253 1.166-1.923.435-3.774.953-5.009 1.568l1.64 3.29c.802-.4 2.27-.841 4.18-1.273 1.86-.42 3.99-.8 6.015-1.121a181.557 181.557 0 017.584-1.03h.001l-.2-1.827zm-19.22 1.96c-4.749 2.367-6.34 6.176-6.826 9.329l3.631.56c.359-2.32 1.433-4.904 4.834-6.599l-1.639-3.29zm-6.832 9.37l-1.003 7.652 3.644.477 1.002-7.651-3.643-.477z"
      />
      <path
        stroke="#000"
        strokeWidth="3.675"
        d="M56.67 63.956s14.325 1.575 18.4 3.606c4.075 2.03 5.408 5.226 5.83 7.963l.931 7.8"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="3.675"
        d="M57.963 53.614c-.066 2.59-.027 4.635.015 6.266l.015.576c.048 1.804.077 2.867-.03 3.647-.092.67-.25.903-.584 1.173-.515.416-1.421.859-3.265 1.629-2.733 1.14-5.86 1.089-8.637-.08-1.853-.78-2.826-1.21-3.426-1.645-.418-.303-.58-.542-.689-1.118-.147-.778-.136-1.942-.057-4.072l.03-.776c.058-1.505.13-3.35.16-5.615.45.07.954.146 1.492.222 2.048.288 4.68.59 6.788.59 2.109 0 4.74-.302 6.788-.59.502-.07.973-.14 1.4-.207z"
      />
      <rect
        width="30.777"
        height="41.859"
        x="34.111"
        y="17.107"
        fill="#EFEFEF"
        stroke="#000"
        strokeWidth="3.675"
        rx="15.389"
      />
      <path
        stroke="#000"
        strokeWidth="3.675"
        d="M65.04 29.726c6.617-.861 6.192 13.117 0 12.11M33.68 29.726c-6.616-.861-6.191 13.117 0 12.11"
      />
      <circle cx="49.5" cy="49" r="47.163" stroke="#000" strokeWidth="3.675" />
      <path
        fill="#E6E6E6"
        d="M63.095 34.447C61.177 18.56 54.05 26.26 57.56 22.326l.27 12.121c-.27 15.354-5.074 21.241-10.742 22.238 1.188.545 3.118.591 4.189.517 6.803-.471 13.736-6.866 11.818-22.755z"
      />
      <path
        fill="#E6E6E6"
        d="M63.282 42.516c-1.528 18.294-11.221.282-5.29 4.773l-.446-7.807c.446-15.99-4.535-18.701-9.886-19.698 1.122-.545 2.944-.591 3.955-.517 6.4-.458 13.196 4.955 11.667 23.25z"
      />
      <path
        fill="#717171"
        d="M41.323 10.539l12.828 1.19 5.299 2.445 5.59 8.035.278 4.213-3.982-.51-10.376-4.157-18.098 6.02.945-8.78 3.955-5.35 3.561-3.106z"
      />
      <path
        stroke="#000"
        strokeWidth="3.675"
        d="M54.176 22.227h-9.962L32.6 28.053l1.111-7.581c.09-.62.39-1.182.76-1.686 1.123-1.532 1.446-3.31 2.046-6.037 3.51.416 2.33-1.002 4.869-3.147l13.198 1.407 4.899 2.222a3 3 0 011.194.978l4.532 6.286a3 3 0 01.558 1.524l.465 6.035-12.056-5.827z"
      />
      <path
        fill="#A1A1A1"
        d="M50.662 1.432c-2.048 0-4.066.13-6.047.381C68.031 4.783 86.136 24.777 86.136 49c0 24.222-18.105 44.216-41.52 47.187 1.98.251 3.998.38 6.046.38C76.933 96.567 98.23 75.272 98.23 49S76.933 1.432 50.662 1.432z"
        opacity="0.2"
      />
    </g>
  </svg>
);

export default GenderMaleIcon;
