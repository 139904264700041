import * as yup from 'yup';

export const schema = yup.object().shape({
  region: yup.object().required('This field is required'),
  price: yup
    .number()
    .min(1, 'Price is required')
    .typeError('It has to be a number')
    .required('This field is required'),
  period: yup
    .array()
    .min(2, 'Period is required')
    .typeError('Period is required')
    .required('Period is required'),
});
