import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch } from 'react-redux';
import { Radio, Spin } from 'antd';
import { createAutopilot } from '../../../redux/actions/autopilots';
import { prevModal, showModal } from '../../../redux/actions/modal';

import { AUTOPILOT_TYPES, MODAL_TYPES, PRODUCT_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';
import { getFormattedPostAutopilotData, renderAutopilotType } from '../../../utils/autopilots';

import Toogle from '../../ui/Toogle';
import HookedField from '../../HookedField/HookedField';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import ProductChooser from '../../ProductChooser/ProductChooser';
import Images from '../../Images/Images';

import './MessageForm.scss';

const MessageAddForm = ({ autopilotType }) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    subject: yup.string().required('This field is required'),
    cover: yup.object().typeError('Main image is required').required('Main image is required'),
    description: yup.string().required('This field is required'),
    withAttachments: yup.bool(),
    products: yup.array().when('withAttachments', {
      is: true,
      then: yup.array().min(1, 'Pick at least one').required('Pick at least one'),
    }),
    points: yup
      .number()
      .min(1, 'Minimum 1 point')
      .max(20, 'Maximum 20 points')
      .typeError('It has to be a number')
      .when('cardType', {
        is: PRODUCT_TYPES.CARD,
        then: yup
          .number()
          .min(1, 'Minimum 1 point')
          .max(20, 'Maximum 20 points')
          .typeError('It has to be a number')
          .required('This field is required'),
      }),
    pointsReceived:
      autopilotType === AUTOPILOT_TYPES.FREQUENT_CUSTOMER
        ? yup
            .number()
            .min(1, 'Minimum 1 point')
            .max(20, 'Maximum 20 points')
            .typeError('It has to be a number')
            .required('This field is required')
        : null,
    mailingTime: yup.object().nullable(),
  });

  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // create Message

  const [createMessageLoading, setCreateMessageLoading] = useState(false);
  const [onCreateMessageErrors, setOnCreateMessageErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(prevModal());
  };

  const submitHandler = (data) => {
    setCreateMessageLoading(true);
    const formattedData = getFormattedPostAutopilotData(data);
    dispatch(
      createAutopilot({
        autopilotData: formattedData,
        onSuccessCallback: () => {
          setCreateMessageLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Message was successfully created'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setOnCreateMessageErrors(getCorrectErrorMessages(errors));
          setCreateMessageLoading(false);
        },
      }),
    );
  };

  const [currentCardType, setCurrentCardType] = useState(PRODUCT_TYPES.CARD);
  const [withAttachments, setWithAttachments] = useState(false);

  const onChangeCardType = (e) => {
    setCurrentCardType(e.target.value);
    methods.clearErrors();
  };

  const changeAttachmentHandler = (e) => {
    setWithAttachments(e.target.checked);
  };

  useEffect(() => {
    if (!withAttachments) {
      methods.setValue('products', []);
    }
  }, [withAttachments]);

  return (
    <div className="messageModal">
      <FormProvider {...methods}>
        <h2 className="messageModal-title">{t('Adding an Autopilot Message')}</h2>
        <div className="messageModal-type">{renderAutopilotType(autopilotType)}</div>
        <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
          <input type="hidden" name="type" value={autopilotType} ref={methods.register} />
          <input type="hidden" name="title" value={autopilotType} ref={methods.register} />
          <HookedField
            name="subject"
            placeholder={t('Subject')}
            label={`${t('Subject')} (${t('required')})`}
            defaultValue=""
            fieldType="textField"
            type="text"
          />
          <Images defaultCover={null} />
          <HookedField
            name="description"
            placeholder={t('Message Text')}
            label={`${t('Message Text')} (${t('required')})`}
            defaultValue=""
            fieldType="textField"
            type="textarea"
          />
          <div className="toogleBlock">
            <div className="toogleBlock-title">{t('Attachments')}</div>
            <Toogle
              name="withAttachments"
              checked={withAttachments}
              changeHandler={changeAttachmentHandler}
            />
          </div>
          {withAttachments && (
            <>
              <div className="cardType-chooser">
                <Radio.Group onChange={onChangeCardType} value={currentCardType}>
                  <Radio name="cardType" value={PRODUCT_TYPES.CARD}>
                    {t('Give Points')}
                  </Radio>
                  <Radio name="cardType" value={PRODUCT_TYPES.GIFT}>
                    {t('Give Gift')}
                  </Radio>
                </Radio.Group>
              </div>
              <ProductChooser defaultValue={null} productType={currentCardType} />
              {currentCardType === PRODUCT_TYPES.CARD && (
                <HookedField
                  name="points"
                  placeholder={t('Quantity of Points')}
                  label={`${t('Quantity of Points')} (${t('required')})`}
                  defaultValue={null}
                  fieldType="numberField"
                  type="number"
                  min={0}
                />
              )}
            </>
          )}
          {autopilotType === AUTOPILOT_TYPES.FREQUENT_CUSTOMER ? (
            <HookedField
              name="pointsReceived"
              placeholder={t('Points to receive')}
              label={`${t('Points to receive')} (${t('required')})`}
              defaultValue={null}
              fieldType="numberField"
              type="number"
              min={0}
            />
          ) : null}
          <HookedField
            name="mailingTime"
            placeholder={t('Select Time')}
            label={t('Mailing Time')}
            defaultValue={null}
            type="timePicker"
            fieldType="timeField"
          />
          <ErrorMessages errorMessages={onCreateMessageErrors} />
          <Spin spinning={createMessageLoading}>
            <div className="modal-actions modal-actions--fullsize">
              <button
                type="button"
                className="btn btn--small btn--secondary"
                onClick={cancelHandler}
              >
                {t('Cancel')}
              </button>
              <button type="submit" className="btn btn--small btn--primary">
                {t('Save')}
              </button>
            </div>
          </Spin>
        </form>
      </FormProvider>
    </div>
  );
};

export default MessageAddForm;
