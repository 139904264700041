import {
  REQUEST_GET_USERS,
  REQUEST_DELETE_USER,
  REQUEST_GET_USER,
  REQUEST_UPDATE_USER,
} from '../types';

export function getUsers(payload) {
  return {
    type: REQUEST_GET_USERS,
    payload,
  };
}

export function getUser(payload) {
  return {
    type: REQUEST_GET_USER,
    payload,
  };
}

export function updateUser(payload) {
  return {
    type: REQUEST_UPDATE_USER,
    payload,
  };
}

export function deleteUser(payload) {
  return {
    type: REQUEST_DELETE_USER,
    payload,
  };
}
