import {
  REQUEST_GET_AUTOPILOTS,
  REQUEST_CREATE_AUTOPILOT,
  REQUEST_UPDATE_AUTOPILOT,
  REQUEST_DELETE_AUTOPILOT,
} from '../types';

export function getAutopilots(payload) {
  return {
    type: REQUEST_GET_AUTOPILOTS,
    payload,
  };
}

export function createAutopilot(payload) {
  return {
    type: REQUEST_CREATE_AUTOPILOT,
    payload,
  };
}

export function updateAutopilot(payload) {
  return {
    type: REQUEST_UPDATE_AUTOPILOT,
    payload,
  };
}

export function deleteAutopilot(payload) {
  return {
    type: REQUEST_DELETE_AUTOPILOT,
    payload,
  };
}
