import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROLES } from '../constants';

import ProfilePage from '../pages/main/ProfilePage';
import NotificationsPage from '../pages/main/NotificationsPage';
import StatisticsPage from '../pages/main/Statistics/StatisticsPage';
import AdditionalOffersPage from '../pages/main/AdditionalOffers/AdditionalOffersPage';
import AccountSettings from '../pages/main/AccountSettings/AccountSettings';
import AddBusinessProfile from '../pages/main/AddBusinessProfile';
import Messages from '../pages/main/Messages/Messages';
import BusinessPartners from '../pages/main/BusinessPartners';
import BusinessPartnerEdit from '../components/BusinessPartner/BusinessPartnerEdit/BusinessPartnerEdit';
import BusinessEditForm from '../components/Business/BusinessForms/BusinessEditForm';
import NotFound from '../pages/shared/NotFound/NotFound';
import SignIn from '../pages/auth/SignIn';
import SignUp from '../pages/auth/SignUp';
import ResetPassword from '../pages/auth/ResetPassword';
import CreateBusinessProfile from '../pages/auth/CreateBusinessProfile';
import NewAccountDescription from '../pages/auth/NewAccountDescription/NewAccountDescription';
import CardsAndGifts from '../pages/main/CardsAndGifts';
import MyTeam from '../pages/main/MyTeam';
import BusinessAddForm from '../components/Business/BusinessForms/BusinessAddForm';
import LayoutHeader from '../components/LayoutHeader/LayoutHeader';
import BusinessPartnerView from '../components/BusinessPartner/BusinessPartnerView/BusinessPartnerView';

const authProtectedMainRoutes = [
  // partner
  { path: '/main/messages/', exact: true, roles: [ROLES.PARTNER], component: Messages },
  {
    path: '/main/add-business-profile',
    exact: true,
    roles: [ROLES.PARTNER],
    component: AddBusinessProfile,
  },
  {
    path: '/main/account-settings',
    exact: true,
    roles: [ROLES.PARTNER],
    component: AccountSettings,
  },
  {
    path: '/main/business-profile/:businessId/main-info',
    exact: true,
    roles: [ROLES.PARTNER],
    component: BusinessEditForm,
  },
  {
    path: '/main/business-profile/:businessId/cards-and-gifts',
    exact: true,
    roles: [ROLES.PARTNER],
    component: CardsAndGifts,
  },
  {
    path: '/main/business-profile/:businessId/my-team',
    exact: true,
    roles: [ROLES.PARTNER],
    component: MyTeam,
  },

  // admin
  {
    path: '/main/business-partners',
    exact: true,
    roles: [ROLES.ADMIN],
    component: BusinessPartners,
  },
  {
    path: '/main/business-partners/:id',
    exact: true,
    roles: [ROLES.ADMIN],
    component: BusinessPartnerView,
  },
  {
    path: '/main/business-partners/:id/edit-partner',
    exact: true,
    roles: [ROLES.ADMIN],
    component: BusinessPartnerEdit,
  },
  {
    path: '/main/business-partners/:id/edit-business/:businessId',
    exact: true,
    roles: [ROLES.ADMIN],
    component: (props) => <BusinessEditForm {...props} withBackBtn />,
  },

  // all
  {
    path: '/main/additional-offers',
    exact: true,
    roles: [ROLES.ADMIN, ROLES.PARTNER],
    component: AdditionalOffersPage,
  },
  {
    path: '/main/statistics',
    exact: true,
    roles: [ROLES.ADMIN, ROLES.PARTNER],
    component: StatisticsPage,
  },
  {
    path: '/main/notifications',
    exact: true,
    roles: [ROLES.ADMIN, ROLES.PARTNER],
    component: NotificationsPage,
  },
  { path: '/main', exact: true, roles: [ROLES.ADMIN, ROLES.PARTNER], component: ProfilePage },
];

const authProtectedAdditionalRoutes = [
  {
    path: '/create-business-profile',
    exact: true,
    roles: [ROLES.PARTNER],
    component: CreateBusinessProfile,
  },
  {
    path: '/create-business-profile/form',
    exact: true,
    roles: [ROLES.PARTNER],
    component: () => <BusinessAddForm title="Create Business Profile" />,
    layout: LayoutHeader,
  },
];

const publicRoutes = [
  { path: '/sign-in', component: SignIn },
  { path: '/sign-up', component: SignUp },
  { path: '/reset-password', component: ResetPassword },
  { path: '/page-404', component: NotFound },
  { path: '/new-account-description', component: NewAccountDescription },
  { path: '/', exact: true, component: () => <Redirect to="/main" /> },
  { path: '*', component: NotFound },
];

export { authProtectedMainRoutes, authProtectedAdditionalRoutes, publicRoutes };
