import React from 'react';
import { useSelector } from 'react-redux';

import { ROLES } from '../../../constants';

import PartnerStatistics from '../../../components/Statistic/PartnerStatistics/PartnerStatistics';
import AdminStatistics from '../../../components/Statistic/AdminStatistics/AdminStatistics';

import './StatisticsPage.scss';

const StatisticsPage = () => {
  const currentUser = useSelector((state) => state.profile.data);

  const renderStatisticsPage = () => {
    switch (currentUser.role) {
      case ROLES.PARTNER:
        return <PartnerStatistics />;
      case ROLES.ADMIN:
        return <AdminStatistics />;
      default:
        return null;
    }
  };

  return renderStatisticsPage();
};

export default StatisticsPage;
