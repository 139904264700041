import React from 'react';

import ProductPreview from '../../Product/ProductPreview/ProductPreview';

import './NotificationView.scss';
import BusinessPartnerPreview from '../../BusinessPartner/BusinessPartnerPreview/BusinessPartnerPreview';

const NotificationView = ({ data: { subject, title, product, owner, description } }) => (
  <div className="notificationView">
    {subject || title ? <h3 className="notificationView-subject">{subject || title}</h3> : null}
    {product && Object.keys(product).length ? (
      <div className="notificationView-product">
        <ProductPreview
          title={product.title}
          cover={product.cover}
          miniCover={product.miniCover}
          period={product.period}
          expirationDays={product.expirationDays}
          withDeleteBtn={false}
          withClickEvent={false}
        />
      </div>
    ) : null}
    {owner && Object.keys(owner).length ? (
      <div className="notificationView-product">
        <BusinessPartnerPreview
          name={`${owner.firstName} ${owner.lastName}`}
          subscriptionType={owner.customer.plan_id}
          isSimple
        />
      </div>
    ) : null}
    {description && <div className="notificationView-description">{description}</div>}
  </div>
);

export default NotificationView;
