import {
  REQUEST_SIGN_IN,
  REQUEST_RESET_PASSWORD,
  REQUEST_SIGN_OUT,
  REQUEST_SIGN_UP,
  REQUEST_SIGN_UP_HOOK_FORM,
  REQUEST_EMAIL_VERIFICATION_CODE,
  REQUEST_CONFIRM_PASSWORD,
  REQUEST_CONFIRM_EMAIL,
  REQUEST_SIGN_IN_GOOGLE,
  REQUEST_SIGN_UP_GOOGLE,
} from '../types';

export function signIn(payload) {
  return {
    type: REQUEST_SIGN_IN,
    payload,
  };
}

export function resetPassword(payload) {
  return {
    type: REQUEST_RESET_PASSWORD,
    payload,
  };
}

export function signOut(payload) {
  return {
    type: REQUEST_SIGN_OUT,
    payload,
  };
}

export function signUp(payload) {
  return {
    type: REQUEST_SIGN_UP,
    payload,
  };
}

export function signUpHookForm(payload) {
  return {
    type: REQUEST_SIGN_UP_HOOK_FORM,
    payload,
  };
}

export function confirmPassword(payload) {
  return {
    type: REQUEST_CONFIRM_PASSWORD,
    payload,
  };
}

export function confirmEmail(payload) {
  return {
    type: REQUEST_CONFIRM_EMAIL,
    payload,
  };
}

export function passEmailVerificationCode(payload) {
  return {
    type: REQUEST_EMAIL_VERIFICATION_CODE,
    payload,
  };
}

export function signInGoogle(payload) {
  return {
    type: REQUEST_SIGN_IN_GOOGLE,
    payload,
  };
}

export function signUpGoogle(payload) {
  return {
    type: REQUEST_SIGN_UP_GOOGLE,
    payload,
  };
}
