import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { Radio, Spin } from 'antd';
import { schema } from '../../../utils/validation_schemas/regularMessage';

import { createRegularMessage } from '../../../redux/actions/regulars';
import { prevModal, showModal } from '../../../redux/actions/modal';

import { MODAL_TYPES, PRODUCT_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';
import { getFormattedPostRegularMessageData } from '../../../utils/regulars';

import Toogle from '../../ui/Toogle';
import HookedField from '../../HookedField/HookedField';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import ProductChooser from '../../ProductChooser/ProductChooser';
import Images from '../../Images/Images';

import './RegularMessageForm.scss';

const RegularMessageAddForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // create Message

  const [createMessageLoading, setCreateMessageLoading] = useState(false);
  const [onCreateMessageErrors, setOnCreateMessageErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(prevModal());
  };

  const submitHandler = (data) => {
    setCreateMessageLoading(true);
    const formattedData = getFormattedPostRegularMessageData(data);
    dispatch(
      createRegularMessage({
        regularMessageData: formattedData,
        onSuccessCallback: () => {
          setCreateMessageLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Regular message was successfully created'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setCreateMessageLoading(false);
          setOnCreateMessageErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  const [currentCardType, setCurrentCardType] = useState(PRODUCT_TYPES.CARD);
  const [withAttachments, setWithAttachments] = useState(false);

  const onChangeCardType = (e) => {
    setCurrentCardType(e.target.value);
    methods.clearErrors();
  };

  const changeAttachmentHandler = (e) => {
    setWithAttachments(e.target.checked);
  };

  useEffect(() => {
    if (!withAttachments) {
      methods.setValue('products', []);
    }
  }, [withAttachments]);

  return (
    <div className="messageModal">
      <FormProvider {...methods}>
        <h2 className="messageModal-title">{t('Adding a Regular Message')}</h2>
        <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
          <HookedField
            name="subject"
            placeholder={t('Subject')}
            label={t('Subject')}
            defaultValue=""
            fieldType="textField"
            type="text"
          />
          <Images defaultCover={null} />
          <HookedField
            name="description"
            placeholder={t('Message Text')}
            label={t('Message Text')}
            defaultValue=""
            fieldType="textField"
            type="textarea"
          />
          <div className="toogleBlock">
            <div className="toogleBlock-title">{t('Attachments')}</div>
            <Toogle
              name="withAttachments"
              checked={withAttachments}
              changeHandler={changeAttachmentHandler}
            />
          </div>
          {withAttachments && (
            <>
              <div className="cardType-chooser">
                <Radio.Group onChange={onChangeCardType} value={currentCardType}>
                  <Radio name="cardType" value={PRODUCT_TYPES.CARD}>
                    {t('Give Points')}
                  </Radio>
                  <Radio name="cardType" value={PRODUCT_TYPES.GIFT}>
                    {t('Give Gift')}
                  </Radio>
                </Radio.Group>
              </div>
              <ProductChooser defaultValue={null} productType={currentCardType} />
              {currentCardType === PRODUCT_TYPES.CARD && (
                <HookedField
                  name="points"
                  placeholder={t('Quantity of Points')}
                  label={t('Quantity of Points')}
                  defaultValue={null}
                  fieldType="numberField"
                  type="number"
                  min={0}
                />
              )}
            </>
          )}
          <ErrorMessages errorMessages={onCreateMessageErrors} />
          <Spin spinning={createMessageLoading}>
            <div className="modal-actions modal-actions--fullsize">
              <button
                type="button"
                className="btn btn--small btn--secondary"
                onClick={cancelHandler}
              >
                {t('Cancel')}
              </button>
              <button type="submit" className="btn btn--small btn--primary">
                {t('Save')}
              </button>
            </div>
          </Spin>
        </form>
      </FormProvider>
    </div>
  );
};

export default RegularMessageAddForm;
