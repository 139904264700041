import * as yup from 'yup';
import { PRODUCT_TYPES } from '../../constants';

export const schema = yup.object().shape({
  subject: yup.string().required('This field is required'),
  cover: yup.object().typeError('Main image is required').required('Main image is required'),
  description: yup.string().required('This field is required'),
  withAttachments: yup.bool(),
  products: yup.array().when('withAttachments', {
    is: true,
    then: yup.array().min(1, 'Pick at least one').required('Pick at least one'),
  }),
  points: yup
    .number()
    .min(1, 'minimum 1 point')
    .max(20, 'maximum 20 points')
    .typeError('It has to be a number')
    .when('cardType', {
      is: PRODUCT_TYPES.CARD,
      then: yup
        .number()
        .min(1, 'minimum 1 point')
        .max(20, 'maximum 20 points')
        .typeError('It has to be a number')
        .required('This field is required'),
    }),
});
