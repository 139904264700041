import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { hideModal, showModal } from '../../../redux/actions/modal';
import { deleteBusiness } from '../../../redux/actions/businesses';

import { MODAL_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';

import ErrorMessages from '../../ErrorMessages/ErrorMessages';

const BusinessDelete = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [deleteBusinessLoading, setDeleteBusinessLoading] = useState(false);
  const [onDeleteBusinessErrors, setOnDeleteBusinessErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const deleteHandler = () => {
    setDeleteBusinessLoading(true);
    dispatch(
      deleteBusiness({
        id,
        onSuccessCallback: () => {
          setDeleteBusinessLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your business was successfully deleted'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setDeleteBusinessLoading(false);
          setOnDeleteBusinessErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="confirmModal">
      <h4 className="confirmModal-question">
        {t('Are you sure you want to delete business profile?')}
      </h4>
      <ErrorMessages errorMessages={onDeleteBusinessErrors} />
      <Spin spinning={deleteBusinessLoading}>
        <div className="modal-actions modal-actions--fullsize">
          <button type="button" className="btn btn--small btn--secondary" onClick={cancelHandler}>
            {t('Cancel')}
          </button>
          <button type="button" className="btn btn--small btn--primary" onClick={deleteHandler}>
            {t('Save')}
          </button>
        </div>
      </Spin>
    </div>
  );
};

export default BusinessDelete;
