import React from 'react';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';
import AvatarPlaceholder from '../../../svg/avatarPlaceholder';

import './BusinessPartnerPreview.scss';

const BusinessPartnerPreview = ({
  id,
  cover,
  name,
  phone,
  email,
  subscriptionType,
  deadline,
  isLarge,
  isPreview,
  isSimple,
  className,
  clickHandler,
  editHandler,
  deleteHandler,
}) => {
  const classes = [
    'businessPartnerPreview',
    isLarge ? 'businessPartnerPreview--large' : '',
    isPreview ? 'businessPartnerPreview--preview' : '',
    isSimple ? 'businessPartnerPreview--simple' : '',
    className,
  ];

  function renderCardContent() {
    return (
      <>
        {cover ? (
          <img crossOrigin="anonymous" className="businessPartnerPreview-icon" src={cover} alt={name} />
        ) : (
          <AvatarPlaceholder className="businessPartnerPreview-icon" />
        )}
        <div className="businessPartnerPreview-content">
          {isLarge ? (
            <h2 className="businessPartnerPreview-name">{name}</h2>
          ) : (
            <h3 className="businessPartnerPreview-name">{name}</h3>
          )}
          {subscriptionType && (
            <div className="businessPartnerPreview-subscription">{subscriptionType}</div>
          )}
          {deadline && (
            <div className="businessPartnerPreview-deadline">Valid until {deadline}</div>
          )}
          {phone && (
            <div className="businessPartnerPreview-phone">
              <Icon icon={ICONS.PHONE} size={16} color="#FABB18" />
              {phone}
            </div>
          )}
          {email && (
            <div className="businessPartnerPreview-email">
              <Icon icon={ICONS.EMAIL} size={16} color="#FABB18" />
              {email}
            </div>
          )}
        </div>
      </>
    );
  }
  return (
    <>
      {isPreview ? (
        <div className={classes.join(' ')}>
          {renderCardContent()}
          <div className="businessPartnerPreview-actions">
            <button
              className="businessPartnerPreview-btn businessPartnerPreview-btn--edit"
              onClick={editHandler}
              title="Edit Business"
            >
              <Icon icon={ICONS.EDIT} size={18} />
            </button>
            <button
              className="businessPartnerPreview-btn businessPartnerPreview-btn--delete"
              onClick={deleteHandler}
              title="Delete Business"
            >
              <Icon icon={ICONS.TRASH} size={16} />
            </button>
          </div>
        </div>
      ) : isSimple ? (
        <div className={classes.join(' ')}>{renderCardContent()}</div>
      ) : (
        <div className={classes.join(' ')} onClick={() => clickHandler(id)}>
          {renderCardContent()}
          <button className="card-deleteBtn" onClick={(event) => deleteHandler(event, id)}>
            <Icon icon={ICONS.TRASH} size={16} />
          </button>
        </div>
      )}
    </>
  );
};

export default BusinessPartnerPreview;
