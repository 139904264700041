import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import MessagesSettingsItem from './MessagesSettingsItem/MessagesSettingsItem';
import { MODAL_TYPES, ELITE_MESSAGES, PREMIUM_MESSAGES } from '../../constants';

import './MessagesSettings.scss';
import { showModal } from '../../redux/actions/modal';

const MessagesSettings = ({ autopilotMessages }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const changeHandler = (type) => {
    if (!autopilotMessages.some((message) => message.type === type)) {
      dispatch(
        showModal({
          type: MODAL_TYPES.MESSAGE_ADD,
          autopilotType: type,
        }),
      );
    } else {
      const messageId = autopilotMessages.find((message) => message.type === type).id;
      dispatch(
        showModal({
          type: MODAL_TYPES.MESSAGE_DELETE,
          id: messageId,
        }),
      );
    }
  };

  return (
    <div className="messagesSettings">
      <h3 className="messagesSettings-title">{t('Autopilot Messages Settings')}</h3>
      <div className="messagesSettings-grid">
        <div>
          <h4 className="messagesSettings-type">{t('Elite Messages')}</h4>
          <div className="messagesSettings-items">
            {PREMIUM_MESSAGES.map(({ id, title, type, description }) => (
              <MessagesSettingsItem
                key={id}
                title={t(title)}
                description={t(description)}
                enabled={autopilotMessages.some((message) => message.type === type)}
                changeHandler={() => changeHandler(type)}
              />
            ))}
          </div>
        </div>
        <div>
          <h4 className="messagesSettings-type">{t('Enterprise Messages')}</h4>
          <div className="messagesSettings-items">
            {ELITE_MESSAGES.map(({ id, title, type, description }) => (
              <MessagesSettingsItem
                key={id}
                title={t(title)}
                description={t(description)}
                enabled={autopilotMessages.some((message) => message.type === type)}
                changeHandler={() => changeHandler(type)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesSettings;
