import {
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_HOOK_FORM,
  PASS_EMAIL_VERIFICATION_CODE,
} from '../types';

const initialState = {
  isAuthenticated: false,
  isCompletedBusinessProfile: false,
  isChangePasswordRequested: false,
  isChangePasswordSuccess: false,
  isChangePasswordCodeValidating: true,
  signUpHookForm: {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    terms: null,
    password: null,
    code: null,
    gender: null,
  },
  isVerificationCodePassed: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SIGN_OUT:
      return { ...state, isAuthenticated: false };
    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        isCompletedBusinessProfile: false,
      };
    case SIGN_UP_HOOK_FORM:
      return {
        ...state,
        signUpHookForm: {
          ...state.signUpHookForm,
          ...action.payload,
        },
      };
    case PASS_EMAIL_VERIFICATION_CODE:
      return {
        ...state,
        isVerificationCodePassed: action.payload,
      };
    default:
      return state;
  }
}
