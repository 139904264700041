import * as yup from 'yup';

export const schema = yup.object().shape({
  subject: yup.string().required('This field is required'),
  cover: yup.object().typeError('Main image is required').required('Main image is required'),
  description: yup.string().required('This field is required'),
  slots: yup
    .array()
    .of(
      yup.object().shape({
        price: yup
          .number()
          .min(1, 'Price is required')
          .typeError('It has to be a number')
          .required('This field is required'),
      }),
    )
    .min(3, 'Slots information is required'),
  date: yup.object().nullable().required('This field is required'),
  mailingTime: yup.object().nullable(),
});
