import React from 'react';

const AvatarMember = (props) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="32.5287" cy="17.7828" rx="1.43619" ry="2.73393" fill="#FFDDCE" />
    <ellipse
      rx="1.43619"
      ry="2.73393"
      transform="matrix(-1 0 0 1 15.3343 18.8199)"
      fill="#FFDDCE"
    />
    <circle cx="23.8799" cy="23.782" r="22.2" fill="white" />
    <path
      d="M24.7586 36.2072L25.9763 35.4906L26.5358 36.2072L27.9399 25.6358L20.2029 25.6357L21.2999 36.2072H24.7586Z"
      fill="#EFEFEF"
    />
    <path
      d="M35.4657 32.6316L30.0001 31.3263C27.7063 32.7933 26.0275 38.4006 23.8903 38.4006C21.949 38.1685 20.0821 30.5406 17.4001 31.3263L12.1641 32.6316C9.65115 33.1286 7.83496 35.3888 7.83496 38.019V40.1281C11.9167 44.2977 17.5346 46.8807 23.7395 46.9015H23.7418C23.7788 46.9016 23.8311 46.9017 23.888 46.9015H23.8903C30.0953 46.8808 35.7131 44.2978 39.7948 40.1281V38.019C39.7948 35.3888 37.9786 33.1286 35.4657 32.6316Z"
      fill="#7F7F7F"
    />
    <path
      d="M16.8021 31.7998C16.7417 36.053 17.9953 37.7576 21.3442 39.9611C21.5621 40.1045 21.8483 40.0871 22.0494 39.9209L24.6 37.8126"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M31.4908 31.7998C31.5512 36.053 30.2976 37.7576 26.9487 39.9611C26.7308 40.1045 26.4446 40.0871 26.2436 39.9209L23.6929 37.8126"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M8.54452 36.9924L7.65508 36.855L7.65349 36.8652L7.65214 36.8755L8.54452 36.9924ZM7.16133 40.6234C7.09679 41.1162 7.444 41.5681 7.93685 41.6326C8.42969 41.6972 8.88155 41.35 8.94609 40.8571L7.16133 40.6234ZM20.4124 31.3262C20.3141 30.4316 20.314 30.4316 20.3139 30.4316C20.3138 30.4316 20.3137 30.4316 20.3135 30.4316C20.3133 30.4316 20.3129 30.4317 20.3124 30.4317C20.3114 30.4319 20.31 30.432 20.3082 30.4322C20.3046 30.4326 20.2992 30.4332 20.2923 30.434C20.2784 30.4355 20.2581 30.4378 20.2316 30.4408C20.1785 30.4468 20.101 30.4557 20.0019 30.4674C19.8036 30.4907 19.5188 30.5251 19.1711 30.5694C18.4762 30.658 17.5274 30.7867 16.5141 30.9471C15.5027 31.1072 14.4172 31.3003 13.452 31.5186C12.5102 31.7315 11.6036 31.985 10.9989 32.2864L11.8017 33.8974C12.1949 33.7015 12.9138 33.4857 13.8489 33.2742C14.7606 33.0681 15.8034 32.882 16.7955 32.725C17.7858 32.5683 18.7157 32.442 19.3987 32.355C19.74 32.3115 20.0189 32.2778 20.2122 32.2551C20.3088 32.2437 20.3839 32.2351 20.4346 32.2293C20.46 32.2264 20.4793 32.2243 20.4921 32.2229C20.4985 32.2221 20.5033 32.2216 20.5064 32.2213C20.5079 32.2211 20.5091 32.221 20.5098 32.2209C20.5102 32.2208 20.5104 32.2208 20.5106 32.2208C20.5107 32.2208 20.5107 32.2208 20.5107 32.2208C20.5107 32.2208 20.5107 32.2208 20.4124 31.3262ZM10.9989 32.2864C8.67282 33.4454 7.89366 35.3109 7.65508 36.855L9.43397 37.1298C9.60964 35.9929 10.1358 34.7276 11.8017 33.8974L10.9989 32.2864ZM7.65214 36.8755L7.16133 40.6234L8.94609 40.8571L9.4369 37.1093L7.65214 36.8755Z"
      fill="black"
    />
    <path
      d="M27.512 31.3262C27.512 31.3262 34.5282 32.0973 36.5241 33.0919C38.5201 34.0865 39.1727 35.6519 39.3798 36.9924L39.8357 40.8129"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M20.0146 28.3838C20.0379 27.712 20.0627 26.9976 20.0761 26.2518C20.2979 26.2865 20.5457 26.3238 20.8104 26.361C21.8133 26.5022 23.1025 26.6502 24.1352 26.6502C25.1679 26.6502 26.4571 26.5022 27.4599 26.361C27.7255 26.3236 27.9742 26.2863 28.1966 26.2514C28.2138 27.0278 28.2464 27.7742 28.2771 28.4785C28.2967 28.9274 28.3156 29.3592 28.3292 29.7708C28.3749 31.1475 28.3641 32.3208 28.1723 33.3561C27.9841 34.3725 27.6242 35.2377 26.975 36.005C26.3731 36.7164 25.484 37.3889 24.148 38.0084C22.8476 37.3656 21.9734 36.6953 21.3754 35.9941C20.7309 35.2382 20.3669 34.3993 20.1686 33.4107C19.9664 32.4023 19.9383 31.2501 19.9688 29.8706C19.9791 29.4042 19.9964 28.9069 20.0146 28.3838Z"
      stroke="black"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <path
      d="M15.9001 15.7493C12.6594 15.3984 12.5864 22.8531 15.6192 22.443"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M31.8335 15.7493C35.0743 15.3984 35.1472 22.8531 32.1144 22.443"
      stroke="black"
      strokeWidth="1.8"
    />
    <circle cx="24" cy="24" r="23.1" stroke="black" strokeWidth="1.8" />
    <path
      d="M15.579 12.2295C15.579 8.52619 18.5811 5.52402 22.2845 5.52402H25.4755C29.1789 5.52402 32.181 8.52618 32.181 12.2295V20.5797C32.181 25.1642 28.4645 28.8807 23.88 28.8807C19.2955 28.8807 15.579 25.1642 15.579 20.5797V12.2295Z"
      fill="#EFEFEF"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M35.0459 39.6846H31.1569C30.9912 39.6846 30.8569 39.8189 30.8569 39.9846V41.641C30.8569 41.8067 30.9912 41.941 31.1569 41.941H35.0459C35.2116 41.941 35.3459 41.8067 35.3459 41.641V39.9846C35.3459 39.8189 35.2116 39.6846 35.0459 39.6846Z"
      stroke="black"
      strokeWidth="1.2"
    />
    <path
      opacity="0.2"
      d="M24.5694 0.701172C23.5661 0.701172 22.5776 0.764641 21.6077 0.887734C33.0766 2.34283 41.9444 12.1356 41.9444 23.9996C41.9444 35.8637 33.0766 45.6565 21.6077 47.1115C22.5776 47.2346 23.5661 47.2981 24.5694 47.2981C37.4368 47.2981 47.8679 36.867 47.8679 23.9996C47.8679 11.1323 37.4368 0.701172 24.5694 0.701172Z"
      fill="#A1A1A1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4265 10.8892C25.6456 11.6527 28.0404 14.5459 28.0404 17.9985V20.017C28.0404 23.894 25.0207 27.0656 21.2051 27.3084C22.1156 27.7159 23.1247 27.9425 24.1867 27.9425C28.2219 27.9425 31.493 24.6713 31.493 20.6362V17.9821C31.493 13.9469 28.2219 10.6758 24.1867 10.6758C23.5799 10.6758 22.9903 10.7498 22.4265 10.8892Z"
      fill="#E6E6E6"
    />
    <path d="M15.3105 8.24316H33.0686L33.7975 15.5933H14.2542L15.3105 8.24316Z" fill="#C4C4C4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3601 16.628H34.1692C34.7631 16.628 35.1927 16.1039 35.1213 15.5456L35.106 15.4247C35.0198 14.7456 34.938 14.1013 34.7189 13.5909C34.4372 12.9343 33.9619 12.534 33.29 12.2465C33.146 12.1849 32.9936 12.1553 32.8439 12.1553H23.3601V13.9553H32.6996C32.9522 14.0802 33.0134 14.181 33.0648 14.3006C33.1197 14.4286 33.1611 14.5903 33.2026 14.828H23.3601V16.628Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.6401 16.628H13.831C13.237 16.628 12.8075 16.1039 12.8788 15.5456L12.8942 15.4247C12.9804 14.7456 13.0622 14.1013 13.2812 13.5909C13.5629 12.9343 14.0382 12.534 14.7102 12.2465C14.8541 12.1849 15.0066 12.1553 15.1562 12.1553H24.6401V13.9553H15.3006C15.0479 14.0802 14.9867 14.181 14.9354 14.3006C14.8804 14.4286 14.839 14.5903 14.7975 14.828H24.6401V16.628Z"
      fill="black"
    />
    <path
      d="M15.1477 13.9488V8.95691C15.1477 8.89064 15.2014 8.83691 15.2677 8.83691H32.9484C33.0147 8.83691 33.0684 8.89064 33.0684 8.95691V13.9488"
      stroke="black"
      strokeWidth="1.8"
    />
  </svg>
);

export default AvatarMember;
