import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import {
  getFormattedBusinessPostData,
  getFormattedBusinessGetData,
} from '../../../utils/businesses';

import { updateBusiness } from '../../../redux/actions/businesses';
import Api from '../../../redux/middlewares/Api';

import BusinessMainInfo from '../BusinessMainInfo/BusinessMainInfo';
import BackBtn from '../../BackBtn/BackBtn';
import NoData from '../../NoData/NoData';
import { getCorrectErrorMessages } from '../../../utils/errors';
import { showModal } from '../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../constants';

import './BusinessForm.scss';

const api = new Api();

const BusinessEditForm = ({ bpId, withBackBtn = false, match }) => {
  const { t } = useTranslation();

  const { businessId } = match.params;

  const [businessDataLoading, setBusinessDataLoading] = useState(false);

  const [currentId, setCurrentId] = useState(null);
  const [businessData, setBusinessData] = useState({});

  const dispatch = useDispatch();

  const getBusinessData = async (id) => await api.getBusiness(id);

  useEffect(() => {
    setCurrentId(businessId || bpId);
  }, [bpId, businessId]);

  useEffect(() => {
    setBusinessDataLoading(true);
    if (currentId) {
      getBusinessData(currentId).then(
        (data) => {
          if (data && Object.keys(data).length) {
            const formattedData = getFormattedBusinessGetData(data);
            setBusinessData(formattedData);
            setBusinessDataLoading(false);
          }
        },
        (errors) => {
          console.log(errors);
          setBusinessDataLoading(false);
        },
      );
    }
  }, [currentId]);

  // update business

  const [updateBusinessLoading, setUpdateBusinessLoading] = useState(false);
  const [onUpdateBusinessErrors, setOnUpdateBusinessErrors] = useState([]);

  const updateBusinessHandler = (data) => {
    setUpdateBusinessLoading(true);
    const formattedData = getFormattedBusinessPostData(data);
    dispatch(
      updateBusiness({
        id: currentId,
        businessData: formattedData,
        onSuccessCallback: () => {
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('The business profile was successfully updated'),
            }),
          );
        },
        onErrorCallback: (errors) => setOnUpdateBusinessErrors(getCorrectErrorMessages(errors)),
        onFinallyCallback: () => setUpdateBusinessLoading(false),
      }),
    );
  };

  const renderMainInfo = () => {
    if (businessDataLoading) {
      return <Spin size="large" />;
    }
    if (businessData && Object.keys(businessData).length) {
      return (
        <BusinessMainInfo
          submitBtnText={t('Save')}
          defaultValues={businessData}
          submitHandler={updateBusinessHandler}
          onSubmitErrors={onUpdateBusinessErrors}
          onSubmitLoading={updateBusinessLoading}
        />
      );
    }
    return <NoData />;
  };

  return (
    <div className="businessForm-wrapper">
      <div className="businessForm-container">
        {withBackBtn ? (
          <BackBtn className="businessForm-backBtn" />
        ) : (
          <h2 className="pageTitle">{t('Main Info')}</h2>
        )}
        {renderMainInfo()}
      </div>
    </div>
  );
};

export default BusinessEditForm;
