import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { schema } from '../../utils/validation_schemas/changePassword';

import HookedField from '../HookedField/HookedField';

const ChangePasswordForm = ({ changePasswordHandler, changePasswordLoading }) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form className="form form--signIn" onSubmit={methods.handleSubmit(changePasswordHandler)}>
        <h2 className="form-heading">{t('Enter your new password')}</h2>
        <fieldset className="form-fields">
          <HookedField
            name="password"
            defaultValue=""
            fieldType="passwordField"
            type="password"
            placeholder={t('New password')}
          />
        </fieldset>
        <Spin spinning={changePasswordLoading}>
          <div className="form-actions">
            <button disabled={!methods.formState.isValid} className="btn btn--primary">
              {t('Submit')}
            </button>
          </div>
        </Spin>
      </form>
    </FormProvider>
  );
};

export default ChangePasswordForm;
