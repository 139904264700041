import moment from 'moment';

export const getFormattedGetProductData = (data) => {
  const formattedData = {};
  // id
  if (data.id) {
    formattedData.id = data.id;
  }
  // title
  if (data.title) {
    formattedData.title = data.title;
  }
  // type
  if (data.type) {
    formattedData.type = data.type;
  }
  // description
  if (data.description) {
    formattedData.description = data.description;
  }
  // point
  if (data.point) {
    formattedData.point = data.point;
  }
  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  } else {
    formattedData.miniCover = null;
  }

  // miniCover
  if (data.miniCover) {
    formattedData.miniCover = data.miniCover;
  } else {
    formattedData.miniCover = null;
  }
  // conditions
  if (data.conditions) {
    formattedData.conditions = data.conditions;
  }
  // period
  if (data.period && data.period.length) {
    formattedData.period = data.period;
  }
  // expirationDays
  if (data.expirationDays) {
    formattedData.expirationDays = data.expirationDays;
  }
  // businesses
  if (data.businesses && data.businesses.length) {
    formattedData.businesses = data.businesses.map((business) => business.id);
  }

  return formattedData;
};

export const getFormattedPostProductData = (data) => {
  const formattedData = {};
  // id
  if (data.id) {
    formattedData.id = data.id;
  }
  // title
  if (data.title) {
    formattedData.title = data.title;
  }
  // title
  if (data.type) {
    formattedData.type = data.type;
  }
  // description
  if (data.description) {
    formattedData.description = data.description;
  }
  // point
  if (data.point) {
    formattedData.point = data.point;
  }
  // point
  if (data.cover) {
    formattedData.cover = data.cover;
  } else {
    formattedData.cover = null;
  }
  // miniCover
  if (data.miniCover) {
    formattedData.miniCover = data.miniCover;
  } else {
    formattedData.miniCover = data.cover;
  }

  // conditions
  if (data.conditions) {
    formattedData.conditions = data.conditions;
  }
  // period
  if (data.period && data.period.length) {
    formattedData.period = data.period.map((date) => moment(date).toISOString());
  } else {
    formattedData.period = null;
  }
  // expirationDays
  if (data.expirationDays) {
    formattedData.expirationDays = data.expirationDays;
  }
  // businesses
  if (data.businesses && data.businesses.length) {
    formattedData.businesses = data.businesses.map((id) => ({
      id,
    }));
  }
  return formattedData;
};

export const getExpiredDate = (period) => !!period && moment(period[1]).format('D MMMM');

export const getUniqueProductsFromBusinesses = (businesses) => {
  const productArray = [];
  businesses.forEach((business) => {
    productArray.push(...business.products);
  });

  const uniqueProductsArray = [];
  productArray.forEach((product) => {
    if (uniqueProductsArray.findIndex((uniqueProduct) => uniqueProduct.id === product.id) === -1) {
      uniqueProductsArray.push(product);
    }
  });

  return uniqueProductsArray;
};
