import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { createNewsletter } from '../../../redux/actions/newsletter';
import { hideModal, showModal } from '../../../redux/actions/modal';

import { schema } from '../../../utils/validation_schemas/newsletter';

import { getFormattedPostNewsletterData } from '../../../utils/newsletters';
import { MODAL_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';

import HookedField from '../../HookedField/HookedField';
import Images from '../../Images/Images';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';

import './NewsletterForm.scss';

const NewsletterAddForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [createNewsletterLoading, setCreateNewsletterLoading] = useState(false);
  const [onCreateNewsletterErrors, setOnCreateNewsletterErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = (data) => {
    setCreateNewsletterLoading(true);
    const formattedData = getFormattedPostNewsletterData(data);
    dispatch(
      createNewsletter({
        newsletterData: formattedData,
        onSuccessCallback: () => {
          setCreateNewsletterLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your newsletter was successfully created'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setCreateNewsletterLoading(false);
          setOnCreateNewsletterErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="newsletterForm">
      <h3 className="newsletterForm-heading">{t('Adding Newsletter')}</h3>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
          <fieldset>
            <HookedField
              name="subject"
              placeholder={t('Main Subject')}
              label={t('Main Subject')}
              defaultValue=""
              fieldType="textField"
              type="text"
            />
            <Images defaultCover={null} />
            <HookedField
              name="description"
              placeholder={t('Message Text')}
              label={t('Message Text')}
              defaultValue=""
              fieldType="textField"
              type="textarea"
            />
          </fieldset>
          <fieldset>
            <h4>{t('Newsletter Slot')} 1</h4>
            <HookedField
              name="slots[0].price"
              placeholder={t('Enter Price')}
              label={t('Price')}
              defaultValue={0}
              fieldType="numberField"
              type="price"
              min={0}
              error={
                methods.errors.slots && methods.errors.slots[0] && methods.errors.slots[0].price
              }
            />
          </fieldset>
          <fieldset>
            <h4>{t('Newsletter Slot')} 2</h4>
            <HookedField
              name="slots[1].price"
              placeholder={t('Enter Price')}
              label={t('Price')}
              defaultValue={0}
              fieldType="numberField"
              type="price"
              min={0}
              error={
                methods.errors.slots && methods.errors.slots[1] && methods.errors.slots[1].price
              }
            />
          </fieldset>
          <fieldset>
            <h4>{t('Newsletter Slot')} 3</h4>
            <HookedField
              name="slots[2].price"
              placeholder={t('Enter Price')}
              label={t('Price')}
              defaultValue={0}
              fieldType="numberField"
              type="price"
              min={0}
              error={
                methods.errors.slots && methods.errors.slots[2] && methods.errors.slots[2].price
              }
            />
          </fieldset>
          <div className="newsletterForm-settings">
            <h4 className="newsletterForm-settings-title">{t('Newsletter Settings')}</h4>
            <HookedField
              name="date"
              placeholder={t('Date')}
              label={t('Date')}
              defaultValue={null}
              type="datePicker"
              fieldType="dateField"
            />
            <HookedField
              name="mailingTime"
              placeholder={t('Select Time')}
              label={t('Mailing Time')}
              defaultValue={null}
              type="timePicker"
              fieldType="timeField"
            />
            <ErrorMessages errorMessages={onCreateNewsletterErrors} />
            <Spin spinning={createNewsletterLoading}>
              <div className="modal-actions modal-actions--fullsize">
                <button
                  type="button"
                  className="btn btn--small btn--secondary"
                  onClick={cancelHandler}
                >
                  {t('Cancel')}
                </button>
                <button type="submit" className="btn btn--small btn--primary">
                  {t('Save')}
                </button>
              </div>
            </Spin>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewsletterAddForm;
