import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Icon from '../Icon';
import { ICONS } from '../../constants';

import './BackBtn.scss';

const BackBtn = ({ onClick, className }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = ['backBtn', className];
  return (
    <button onClick={onClick || history.goBack} className={classes.join(' ')}>
      <Icon icon={ICONS.ARROW_BACK} />
      <span>{t('Back')}</span>
    </button>
  );
};

export default BackBtn;
