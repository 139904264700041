import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  REQUEST_GET_PROFILE,
  GET_PROFILE,
  REQUEST_UPDATE_PROFILE,
  UPDATE_PROFILE,
  REQUEST_DELETE_PROFILE,
  DELETE_PROFILE,
} from '../types/index';

const api = new Api();

function* getProfileWatcher() {
  yield takeEvery(REQUEST_GET_PROFILE, getProfileWorker);
}

function* updateProfileWatcher() {
  yield takeEvery(REQUEST_UPDATE_PROFILE, updateProfileWorker);
}

function* deleteProfileWatcher() {
  yield takeEvery(REQUEST_DELETE_PROFILE, deleteProfileWorker);
}

function* getProfileWorker({ payload: { onSuccessCallback, onErrorCallback, onFinallyCallback } }) {
  try {
    const data = yield call(api.getProfile);
    yield put({ type: GET_PROFILE, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* updateProfileWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const response = yield call(() => api.updateProfile(data));
    yield put({ type: UPDATE_PROFILE, payload: response });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteProfileWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.deleteProfile);
    yield put({ type: DELETE_PROFILE, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* profile() {
  yield all([fork(getProfileWatcher), fork(updateProfileWatcher), fork(deleteProfileWatcher)]);
}
