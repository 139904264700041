import * as yup from 'yup';
import 'yup-phone';

export const schema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup
    .string()
    .required('This field is required')
    .email('It must be a valid email')
    .nullable(),
  phone: yup
    .string()
    .required('This field is required')
    .phone('CH', true, 'Invalid phone')
    .nullable(),
});
