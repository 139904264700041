import React from 'react';

const GenderFemaleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="102"
    fill="none"
    viewBox="0 0 100 102"
  >
    <g opacity="0.8">
      <g filter="url(#filter0_d)">
        <circle cx="50" cy="48.554" r="45.325" fill="#F9F9FA" />
      </g>
      <path
        fill="#757575"
        stroke="#000"
        strokeWidth="3.675"
        d="M24.157 36.584c0-14.01 11.358-25.368 25.369-25.368 14.01 0 25.368 11.358 25.368 25.368v3.567c0 3.223-.673 6.436-1.46 9.803-.553 2.364-.74 4.429-.45 6.419.198 1.363.61 2.63 1.213 3.886-9.288 4.508-16.613 6.958-24.034 7.117-7.51.16-15.33-2.023-25.579-7.218 1.15-3.451 1.617-6.308 1.145-9.528-.181-1.237-.422-2.512-.653-3.74a155.37 155.37 0 01-.358-1.937c-.323-1.84-.561-3.596-.561-5.339v-3.03z"
      />
      <ellipse cx="68.991" cy="39.282" fill="#EFEFEF" rx="2.932" ry="4.23" />
      <path
        fill="#EFEFEF"
        d="M49.524 93.77L63.81 80.767l-8.633-6.846 2.867-21.583H42.246l2.24 21.583-9.247 6.846L49.524 93.77z"
      />
      <path
        fill="#8C8C8C"
        d="M73.409 66.62l-13.473-2.664c-4.683 2.995-5.797 22.108-10.16 22.108-3.964-.474-4.993-23.712-10.468-22.108L25.835 66.62c-5.13 1.015-8.839 5.629-8.839 11v4.305C25.33 90.44 36.8 95.713 49.468 95.756h.308c12.668-.043 24.138-5.317 32.471-13.83V77.62c0-5.37-3.707-9.984-8.838-11z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="3.675"
        d="M43.232 56.35c.296.048.615.097.948.147 1.726.255 3.962.526 5.765.526 1.802 0 4.039-.27 5.764-.526.276-.041.54-.082.791-.122-.636 12.036-2.082 19.799-6.392 31.618-.297-.936-.587-1.845-.869-2.729-3.74-11.724-6.07-19.028-6.007-28.914z"
      />
      <path
        fill="#000"
        d="M18.445 75.525l-1.816-.281-.003.021-.003.021 1.822.239zm-2.824 7.413a1.838 1.838 0 103.644.477l-3.644-.477zm27.055-18.982l-.202-1.826h-.012a6.261 6.261 0 00-.156.018c-.108.013-.266.03-.468.055a184.987 184.987 0 00-7.12.98c-2.066.326-4.282.72-6.253 1.166-1.923.434-3.774.952-5.009 1.567l1.64 3.29c.802-.4 2.27-.841 4.18-1.273 1.86-.42 3.99-.8 6.015-1.121a181.557 181.557 0 017.577-1.028l.007-.001h.001l-.2-1.827zm-19.22 1.96c-4.749 2.367-6.34 6.175-6.826 9.328l3.631.561c.359-2.321 1.433-4.905 4.834-6.6l-1.639-3.288zm-6.832 9.37l-1.003 7.652 3.644.477 1.002-7.652-3.643-.477z"
      />
      <path
        stroke="#000"
        strokeWidth="3.675"
        d="M57.17 63.956s14.325 1.574 18.4 3.605c4.075 2.03 5.408 5.227 5.83 7.964l.931 7.8"
      />
      <rect
        width="32.843"
        height="37.363"
        x="33.103"
        y="19.633"
        fill="#EFEFEF"
        stroke="#000"
        strokeWidth="3.675"
        rx="16.421"
      />
      <path
        fill="#E6E6E6"
        d="M64.07 34.694c-.956-7.573-5.634-10.218-6.68-10.925l.325 10.925c-.324 13.838-5.9 18.81-12.741 19.708 1.433.491 3.143.667 4.743.667 7.674.183 16.173-5.959 14.353-20.375z"
      />
      <path
        fill="#919191"
        d="M45.324 14.398l11.293 2.63-11.303 13.17-14.57 9.076-.9-9.571 15.48-15.305z"
      />
      <path
        fill="#000"
        d="M58.972 13.887a1.838 1.838 0 00-3.257-1.704l3.257 1.704zM32.51 37.133a1.838 1.838 0 001.222 3.467l-1.221-3.467zm23.205-24.95c-7.43 14.205-13.332 21.472-23.205 24.95l1.222 3.467c11.26-3.968 17.723-12.342 25.24-26.713l-3.257-1.704z"
      />
      <path stroke="#000" strokeWidth="3.675" d="M65.54 33.674c10.27-.77 9.61 11.749 0 10.848" />
      <path
        fill="#A1A1A1"
        d="M51.162 1.432c-2.048 0-4.066.13-6.047.381C68.531 4.783 86.636 24.777 86.636 49c0 24.222-18.105 44.216-41.52 47.187 1.98.251 3.998.38 6.046.38C77.433 96.567 98.73 75.272 98.73 49S77.433 1.432 51.162 1.432z"
        opacity="0.2"
      />
      <circle cx="50" cy="49" r="47.163" stroke="#000" strokeWidth="3.675" />
    </g>
    <defs>
      <filter
        id="filter0_d"
        width="98.65"
        height="98.65"
        x="0.675"
        y="3.229"
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default GenderFemaleIcon;
