import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { deleteMessage, getMessages } from '../../redux/actions/messages';
import { hideModal, showModal } from '../../redux/actions/modal';
import Api from '../../redux/middlewares/Api';

import { MODAL_TYPES } from '../../constants';

import NotificationPreview from '../Notification/NotificationPreview/NotificationPreview';
import NoData from '../NoData/NoData';

const api = new Api();

const PartnerNotifications = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [messagesLoading, setMessagesLoading] = useState(false);
  const [loadingMessageId, setLoadingMessageId] = useState(null);
  const messages = useSelector((state) => state.messages.items);

  const getMessagesHandler = () => {
    setMessagesLoading(true);
    dispatch(
      getMessages({
        onFinallyCallback: () => {
          setMessagesLoading(false);
        },
      }),
    );
  };

  useEffect(() => {
    getMessagesHandler();
  }, []);

  const deleteNotificationHandler = (event, notificationId) => {
    event.stopPropagation();
    dispatch(
      showModal({
        type: MODAL_TYPES.CONFIRM,
        question: t('Are you sure you want to delete notification?'),
        cancelHandler: () => dispatch(hideModal()),
        okHandler: () =>
          dispatch(
            deleteMessage({
              id: notificationId,
              onSuccessCallback: () => {
                dispatch(
                  showModal({
                    type: MODAL_TYPES.NOTIFICATION,
                    title: t('The Notification was deleted'),
                  }),
                );
                getMessagesHandler();
              },
            }),
          ),
      }),
    );
  };

  const viewNotificationHandler = (notificationId) => {
    setLoadingMessageId(notificationId);
    api.getMessage(notificationId).then(
      (notification) => {
        setLoadingMessageId(null);
        dispatch(
          showModal({
            type: MODAL_TYPES.NOTIFICATION_VIEW,
            data: notification,
          }),
        );
        getMessagesHandler();
      },
      (error) => {
        console.log(error);
        setLoadingMessageId(null);
      },
    );
  };

  // read all

  const [readAllLoading, setReadAllLoading] = useState(false);

  const readAllHandler = () => {
    setReadAllLoading(true);
    api.readAllMessages().then(
      () => {
        dispatch(
          showModal({
            type: MODAL_TYPES.NOTIFICATION,
            title: t('All notifications marked as read'),
          }),
        );
        setReadAllLoading(false);
        getMessagesHandler();
      },
      (error) => {
        console.log(error);
        setReadAllLoading(false);
      },
    );
  };

  const renderNotifications = () => {
    if (messagesLoading) {
      return <Spin size="large" />;
    }
    if (messages && messages.length) {
      return messages.map((message) => (
        <NotificationPreview
          key={message.id}
          isForPartner
          status={message.status}
          createdAt={message.createdAt}
          subject={message.subject}
          product={message.product}
          loading={message.id === loadingMessageId}
          deleteHandler={(event) => deleteNotificationHandler(event, message.id)}
          clickHandler={() => viewNotificationHandler(message.id)}
        />
      ));
    }
    return <NoData />;
  };

  return (
    <div className="notifications">
      <div className="bar">
        <h2>{t('Notifications')}</h2>
        {messages.length ? (
          <Spin spinning={readAllLoading}>
            <button className="btn btn--secondary btn--small" onClick={readAllHandler}>
              {t('Mark All As Read')}
            </button>
          </Spin>
        ) : null}
      </div>
      <div className="cards">{renderNotifications()}</div>
    </div>
  );
};

export default PartnerNotifications;
