import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import { GET_PREFERENCES, REQUEST_GET_PREFERENCES } from '../types/index';

const api = new Api();

function* getPreferencesWatcher() {
  yield takeEvery(REQUEST_GET_PREFERENCES, getPreferencesWorker);
}

function* getPreferencesWorker() {
  try {
    const data = yield call(api.getPreferences);
    yield put({ type: GET_PREFERENCES, payload: data.result });
  } catch (e) {
    console.log(e);
  }
}

export function* preferences() {
  yield all([fork(getPreferencesWatcher)]);
}
