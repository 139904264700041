import React from 'react';
import SendInvitations from '../../SendInvitations/SendInvitations';

const InvitationModal = ({ onInvitationsSent, businessId }) => (
  <div className="invitationModal">
    <SendInvitations isSingle onInvitationsSent={onInvitationsSent} businessId={businessId} />
  </div>
);

export default InvitationModal;
