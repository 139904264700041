import axios from 'axios';

// const BASE_URL = 'https://2e8a-188-163-110-81.eu.ngrok.io/api';
const BASE_URL = 'https://api.loial.pro/api';

const ENDPOINTS = {
  signIn: {
    url: 'auth/signin',
    method: 'POST',
  },
  signInGoogle: {
    url: 'auth/signin/3rd',
    method: 'POST',
  },
  signUp: {
    url: 'auth/signup',
    method: 'POST',
  },
  signUpGoogle: {
    url: 'auth/signup/3rd',
    method: 'POST',
  },
  resetPassword: {
    url: 'auth/reset/password',
    method: 'POST',
  },
  confirmEmail: {
    url: 'auth/confirmation/email',
    method: 'POST',
  },
  confirmPassword: {
    url: 'auth/confirmation/password',
    method: 'POST',
    token: true,
  },
  getProfile: {
    url: 'auth/profile',
    method: 'GET',
    token: true,
  },
  updateProfile: {
    url: 'auth/profile',
    method: 'PATCH',
    token: true,
  },
  deleteProfile: {
    url: 'auth/profile',
    method: 'DELETE',
    token: true,
  },
  getProducts: {
    url: 'products',
    method: 'GET',
    token: true,
  },
  getProductsByBusinessesIds(arrayOfBusinessesIds) {
    return {
      url: `products?businesses=${arrayOfBusinessesIds.join('&businesses=')}`,
      method: 'GET',
      token: true,
    };
  },
  getProduct(id) {
    return {
      url: `products/${id}`,
      method: 'GET',
      token: true,
    };
  },
  deleteProduct(id) {
    return {
      url: `products/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  updateProduct(id) {
    return {
      url: `products/${id}`,
      method: 'PATCH',
      token: true,
    };
  },
  createProduct: {
    url: `products`,
    method: 'POST',
    token: true,
  },
  uploadFile: {
    url: `files`,
    method: 'POST',
    token: true,
    contentType: 'multipart/form-data',
  },
  getFile(id) {
    return {
      url: `files/${id}`,
      method: 'GET',
      token: true,
    };
  },
  getFAQ: {
    url: 'faq',
    method: 'GET',
    token: true,
  },
  createFAQ: {
    url: 'faq',
    method: 'POST',
    token: true,
  },
  getPreferences: {
    url: 'preferences',
    method: 'GET',
  },
  getBusinesses: {
    url: 'businesses',
    method: 'GET',
    token: true,
  },
  getBusiness(id) {
    return {
      url: `businesses/${id}`,
      method: 'GET',
      token: true,
    };
  },
  getBusinessByOwnersIds(arrayOfBusinessesIds) {
    return {
      url: `businesses?owners=${arrayOfBusinessesIds.join('&owners=')}`,
      method: 'GET',
      token: true,
    };
  },
  createBusiness: {
    url: 'businesses',
    method: 'POST',
    token: true,
  },
  updateBusiness(id) {
    return {
      url: `businesses/${id}`,
      method: 'PATCH',
      token: true,
    };
  },
  deleteBusiness(id) {
    return {
      url: `businesses/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  deleteBusinessSchedule(bpId) {
    return {
      url: `businesses/${bpId}/schedule`,
      method: 'DELETE',
      token: true,
    };
  },
  getMembers(id) {
    return {
      url: `businesses/${id}/members`,
      method: 'GET',
      token: true,
    };
  },
  deleteMember(id) {
    return {
      url: `businesses/${id}/members`,
      method: 'DELETE',
      token: true,
    };
  },
  createMember(id) {
    return {
      url: `businesses/${id}/members`,
      method: 'POST',
      token: true,
    };
  },
  resendMember(id) {
    return {
      url: `businesses/${id}/members`,
      method: 'PATCH',
      token: true,
    };
  },
  getUsers: {
    url: 'users?role=PARTNER',
    method: 'GET',
    token: true,
  },
  getUser(id) {
    return {
      url: `users/${id}`,
      method: 'GET',
      token: true,
    };
  },
  updateUser(id) {
    return {
      url: `users/${id}`,
      method: 'PATCH',
      token: true,
    };
  },
  deleteUser(id) {
    return {
      url: `users/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  getPlans: {
    url: 'subscriptions/plans',
    method: 'GET',
  },
  subscribePlan: {
    url: 'subscriptions',
    method: 'POST',
    token: true,
  },
  updatePlan: {
    url: 'subscriptions',
    method: 'PATCH',
    token: true,
  },
  getAutopilots: {
    url: 'autopilots',
    method: 'GET',
    token: true,
  },
  getAutopilot(id) {
    return {
      url: `autopilots/${id}`,
      method: 'GET',
      token: true,
    };
  },
  createAutopilot: {
    url: 'autopilots',
    method: 'POST',
    token: true,
  },
  updateAutopilot(id) {
    return {
      url: `autopilots/${id}`,
      method: 'PATCH',
      token: true,
    };
  },
  deleteAutopilot(id) {
    return {
      url: `autopilots/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  getCustomerStatistics: {
    url: 'statistics/partner',
    method: 'GET',
    token: true,
  },
  getDetailsStatistics: {
    url: 'statistics/partner/details',
    method: 'GET',
    token: true,
  },
  getGenderStatistics: {
    url: 'statistics/partner/charts/pie/gender',
    method: 'GET',
    token: true,
  },
  getAgeStatistics: {
    url: 'statistics/partner/charts/pie/age',
    method: 'GET',
    token: true,
  },
  getLinesStatistics(period, status) {
    return {
      url: `statistics/partner/charts/line?period=${period}&status=${status}`,
      method: 'GET',
      token: true,
    };
  },
  getAdminStatistics(period, option) {
    return {
      url: `statistics/admin/charts/line?period=${period}&option=${option}`,
      method: 'GET',
      token: true,
    };
  },
  getOpenedApp(period) {
    return {
      url: `statistics/admin/charts/line/app/opened?period=${period}`,
      method: 'GET',
      token: true,
    };
  },
  getSlots: {
    url: 'slots',
    method: 'GET',
    token: true,
  },
  getSlot(id) {
    return {
      url: `slots/${id}`,
      method: 'GET',
      token: true,
    };
  },
  updateSlot(id) {
    return {
      url: `slots/${id}`,
      method: 'PATCH',
      token: true,
    };
  },
  deleteSlot(id) {
    return {
      url: `slots/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  createSlot: {
    url: 'slots',
    method: 'POST',
    token: true,
  },
  buySlot(id) {
    return {
      url: `slots/${id}/buy`,
      method: 'POST',
      token: true,
    };
  },
  getMessages: {
    url: 'messages',
    method: 'GET',
    token: true,
  },
  getMessage(id) {
    return {
      url: `messages/${id}`,
      method: 'GET',
      token: true,
    };
  },
  deleteMessage(id) {
    return {
      url: `messages/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  readAllMessages: {
    url: 'messages/mark/read',
    method: 'PATCH',
    token: true,
  },
  getNotifications: {
    url: 'notifications',
    method: 'GET',
    token: true,
  },
  getNotification(id) {
    return {
      url: `notifications/${id}`,
      method: 'GET',
      token: true,
    };
  },
  deleteNotification(id) {
    return {
      url: `notifications/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  readAllNotifications: {
    url: 'notifications/mark/read',
    method: 'PATCH',
    token: true,
  },
  getNewsletters: {
    url: 'newsletters',
    method: 'GET',
    token: true,
  },
  getNewsletter(id) {
    return {
      url: `newsletters/${id}`,
      method: 'GET',
      token: true,
    };
  },
  createNewsletter: {
    url: 'newsletters',
    method: 'POST',
    token: true,
  },
  updateNewsletter(id) {
    return {
      url: `newsletters/${id}`,
      method: 'PATCH',
      token: true,
    };
  },
  deleteNewsletter(id) {
    return {
      url: `newsletters/${id}`,
      method: 'DELETE',
      token: true,
    };
  },
  deleteNewsletterSlots(id) {
    return {
      url: `newsletters/${id}/slots`,
      method: 'DELETE',
      token: true,
    };
  },
  buyNewsletterSlot(newsletterId, slotId) {
    return {
      url: `newsletters/${newsletterId}/slots/${slotId}/buy`,
      method: 'POST',
      token: true,
    };
  },
  getRegularMessages: {
    url: `messages/regular`,
    method: 'GET',
    token: true,
  },
  getRegularMessage(id) {
    return {
      url: `messages/regular/${id}`,
      method: 'GET',
      token: true,
    };
  },
  createRegularMessage: {
    url: `messages/regular/buy`,
    method: 'POST',
    token: true,
  },
};
class Api {
  constructor(baseUrl = BASE_URL, endpoints = ENDPOINTS) {
    this.baseUrl = baseUrl;
    this.endpoints = endpoints;
  }

  static getToken() {
    return localStorage.getItem('access_token');
  }

  static setToken(token) {
    localStorage.setItem('access_token', token);
  }

  static removeToken() {
    if (localStorage && localStorage.getItem('access_token')) {
      localStorage.removeItem('access_token');
    }
  }

  call(endpoint, data) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': endpoint.contentType || 'application/json',
    };

    if (endpoint.token) {
      headers.Authorization = `Bearer ${Api.getToken()}`;
    }

    return axios({
      url: `${this.baseUrl}/${endpoint.url}`,
      method: endpoint.method,
      headers,
      data,
    })
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        throw error.response.data.message;
      });
  }

  signUp = (signUpData) => this.call(this.endpoints.signUp, signUpData);

  signUpGoogle = (signUpData) => this.call(this.endpoints.signUpGoogle, signUpData);

  signIn = (signInData) => this.call(this.endpoints.signIn, signInData);

  signInGoogle = (signInData) => this.call(this.endpoints.signInGoogle, signInData);

  resetPassword = (resetPasswordData) => this.call(this.endpoints.resetPassword, resetPasswordData);

  confirmEmail = (confirmEmailData) => this.call(this.endpoints.confirmEmail, confirmEmailData);

  confirmPassword = (data) => this.call(this.endpoints.confirmPassword, data);

  getProfile = () => this.call(this.endpoints.getProfile);

  updateProfile = (newData) => this.call(this.endpoints.updateProfile, newData);

  deleteProfile = () => this.call(this.endpoints.deleteProfile);

  getProducts = () => this.call(this.endpoints.getProducts);

  getProductsByBusinessesIds = (arrayOfBusinessesIds) =>
    this.call(this.endpoints.getProductsByBusinessesIds(arrayOfBusinessesIds));

  getProduct = (id) => this.call(this.endpoints.getProduct(id));

  deleteProduct = (id) => this.call(this.endpoints.deleteProduct(id));

  updateProduct = (id, productData) => this.call(this.endpoints.updateProduct(id), productData);

  createProduct = (productData) => this.call(this.endpoints.createProduct, productData);

  uploadFile = (file) => this.call(this.endpoints.uploadFile, file);

  getFile = (id) => this.call(this.endpoints.getFile(id));

  getFAQ = () => this.call(this.endpoints.getFAQ);

  createFAQ = (newFAQData) => this.call(this.endpoints.createFAQ, newFAQData);

  getPreferences = () => this.call(this.endpoints.getPreferences);

  getBusinesses = () => this.call(this.endpoints.getBusinesses);

  getBusiness = (id) => this.call(this.endpoints.getBusiness(id));

  getBusinessByOwnersIds = (arrayOfOwnersIds) =>
    this.call(this.endpoints.getBusinessByOwnersIds(arrayOfOwnersIds));

  createBusiness = (businessData) => this.call(this.endpoints.createBusiness, businessData);

  updateBusiness = (id, newBusinessData) =>
    this.call(this.endpoints.updateBusiness(id), newBusinessData);

  deleteBusiness = (id) => this.call(this.endpoints.deleteBusiness(id));

  deleteBusinessSchedule = (bpId) => this.call(this.endpoints.deleteBusinessSchedule(bpId));

  getMembers = (id) => this.call(this.endpoints.getMembers(id));

  deleteMember = (id, memberData) => this.call(this.endpoints.deleteMember(id), memberData);

  createMember = (id, memberData) => this.call(this.endpoints.createMember(id), memberData);

  resendMember = (id, memberData) => this.call(this.endpoints.resendMember(id), memberData);

  getUsers = () => this.call(this.endpoints.getUsers);

  getUser = (id) => this.call(this.endpoints.getUser(id));

  updateUser = (id, userData) => this.call(this.endpoints.updateUser(id), userData);

  deleteUser = (id) => this.call(this.endpoints.deleteUser(id));

  getPlans = () => this.call(this.endpoints.getPlans);

  subscribePlan = (data) => this.call(this.endpoints.subscribePlan, data);

  updatePlan = (data) => this.call(this.endpoints.updatePlan, data);

  getAutopilots = () => this.call(this.endpoints.getAutopilots);

  getAutopilot = (id) => this.call(this.endpoints.getAutopilot(id));

  createAutopilot = (autopilotData) => this.call(this.endpoints.createAutopilot, autopilotData);

  updateAutopilot = (id, autopilotData) =>
    this.call(this.endpoints.updateAutopilot(id), autopilotData);

  deleteAutopilot = (id) => this.call(this.endpoints.deleteAutopilot(id));

  getCustomerStatistics = () => this.call(this.endpoints.getCustomerStatistics);

  getDetailsStatistics = () => this.call(this.endpoints.getDetailsStatistics);

  getGenderStatistics = () => this.call(this.endpoints.getGenderStatistics);

  getAgeStatistics = () => this.call(this.endpoints.getAgeStatistics);

  getLinesStatistics = (period, status) =>
    this.call(this.endpoints.getLinesStatistics(period, status));

  getAdminStatistics = (period, option) =>
    this.call(this.endpoints.getAdminStatistics(period, option));

  getOpenedApp = (period) => this.call(this.endpoints.getOpenedApp(period));

  getSlots = () => this.call(this.endpoints.getSlots);

  getSlot = (id) => this.call(this.endpoints.getSlot(id));

  createSlot = (slotData) => this.call(this.endpoints.createSlot, slotData);

  updateSlot = (id, slotData) => this.call(this.endpoints.updateSlot(id), slotData);

  deleteSlot = (id) => this.call(this.endpoints.deleteSlot(id));

  buySlot = (id) => this.call(this.endpoints.buySlot(id));

  getMessages = () => this.call(this.endpoints.getMessages);

  getMessage = (id) => this.call(this.endpoints.getMessage(id));

  deleteMessage = (id) => this.call(this.endpoints.deleteMessage(id));

  readAllMessages = () => this.call(this.endpoints.readAllMessages);

  getNewsletters = () => this.call(this.endpoints.getNewsletters);

  getNewsletter = (id) => this.call(this.endpoints.getNewsletter(id));

  createNewsletter = (newsletterData) => this.call(this.endpoints.createNewsletter, newsletterData);

  updateNewsletter = (id, newsletterData) =>
    this.call(this.endpoints.updateNewsletter(id), newsletterData);

  deleteNewsletter = (id) => this.call(this.endpoints.deleteNewsletter(id));

  deleteNewsletterSlots = (id) => this.call(this.endpoints.deleteNewsletterSlots(id));

  buyNewsletterSlot = (newsletterId, slotId, slotData) =>
    this.call(this.endpoints.buyNewsletterSlot(newsletterId, slotId), slotData);

  getRegularMessages = () => this.call(this.endpoints.getRegularMessages);

  getRegularMessage = (id) => this.call(this.endpoints.getRegularMessage(id));

  createRegularMessage = (regularMessageData) =>
    this.call(this.endpoints.createRegularMessage, regularMessageData);

  getNotifications = () => this.call(this.endpoints.getNotifications);

  getNotification = (id) => this.call(this.endpoints.getNotification(id));

  deleteNotification = (id) => this.call(this.endpoints.deleteNotification(id));

  readAllNotifications = () => this.call(this.endpoints.readAllNotifications);
}

export default Api;
