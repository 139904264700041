export const getFormattedPostRegularMessageData = (data) => {
  const formattedData = {};

  // subject
  if (data.subject) {
    formattedData.subject = data.subject;
  }

  // description
  if (data.description) {
    formattedData.description = data.description;
  }

  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  }

  // products
  if (data.products && data.products.length) {
    formattedData.products = data.products.map((product) => ({ id: product.id }));
  } else {
    formattedData.products = [];
  }

  // points
  if (data.points) {
    formattedData.points = data.points;
  }

  return formattedData;
};
