import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  REQUEST_GET_USERS,
  GET_USERS,
  REQUEST_GET_USER,
  GET_USER,
  REQUEST_UPDATE_USER,
  UPDATE_USER,
  REQUEST_DELETE_USER,
  DELETE_USER,
  SHOW_MODAL,
  GET_PRODUCTS,
  GET_BUSINESSES,
} from '../types/index';

import { MODAL_TYPES } from '../../constants';

const api = new Api();

function* getUsersWatcher() {
  yield takeEvery(REQUEST_GET_USERS, getUsersWorker);
}

function* getUserWatcher() {
  yield takeEvery(REQUEST_GET_USER, getUserWorker);
}

function* updateUserWatcher() {
  yield takeEvery(REQUEST_UPDATE_USER, updateUserWorker);
}

function* deleteUserWatcher() {
  yield takeEvery(REQUEST_DELETE_USER, deleteUserWorker);
}

function* getUsersWorker({ payload: { onSuccessCallback, onErrorCallback, onFinallyCallback } }) {
  try {
    const data = yield call(api.getUsers);
    yield put({ type: GET_USERS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getUserWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.getUser(id));
    yield put({ type: GET_USER, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* updateUserWorker({
  payload: { id, userData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.updateUser(id, userData));
    yield put({ type: UPDATE_USER, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteUserWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteUser(id));
    yield put({ type: DELETE_USER, payload: id });
    yield put({ type: GET_PRODUCTS, payload: [] });
    yield put({ type: GET_BUSINESSES, payload: [] });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* users() {
  yield all([
    fork(getUsersWatcher),
    fork(deleteUserWatcher),
    fork(getUserWatcher),
    fork(updateUserWatcher),
  ]);
}
