import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  GET_NOTIFICATIONS,
  REQUEST_GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  REQUEST_DELETE_NOTIFICATION,
} from '../types/index';

const api = new Api();

function* getNotificationsWatcher() {
  yield takeEvery(REQUEST_GET_NOTIFICATIONS, getNotificationsWorker);
}

function* deleteNotificationWatcher() {
  yield takeEvery(REQUEST_DELETE_NOTIFICATION, deleteNotificationWorker);
}

function* getNotificationsWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getNotifications);
    yield put({ type: GET_NOTIFICATIONS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteNotificationWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteNotification(id));
    yield put({ type: DELETE_NOTIFICATION, payload: id });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* notifications() {
  yield all([fork(getNotificationsWatcher), fork(deleteNotificationWatcher)]);
}
