import React, { useEffect, useRef, useCallback } from 'react';

const SearchBox = ({ label, maps, onPlacesChanged, placeholder, name, value }) => {
  const input = useRef(null);
  const searchBox = useRef(null);

  const handleOnPlacesChanged = useCallback(() => {
    if (onPlacesChanged) {
      onPlacesChanged(searchBox.current.getPlaces());
    }
  }, [onPlacesChanged, searchBox]);

  useEffect(() => {
    if (!searchBox.current && maps) {
      searchBox.current = new maps.places.SearchBox(input.current);
      searchBox.current.addListener('places_changed', handleOnPlacesChanged);
    }

    return () => {
      if (maps) {
        searchBox.current = null;
        maps.event.clearInstanceListeners(searchBox);
      }
    };
  }, [maps, handleOnPlacesChanged]);

  return (
    <div className="field">
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field-wrapper">
        <input
          type="text"
          ref={input}
          id={name}
          name={name}
          defaultValue={value}
          placeholder={placeholder}
          autoComplete="off"
        />
        <div className="field-border" />
      </div>
    </div>
  );
};

export default SearchBox;
