import React from 'react';
import PropTypes from 'prop-types';

const Toogle = ({ name, checked, changeHandler }) => (
  <label className="toogle">
    <input
      className="toogle-input"
      type="checkbox"
      name={name}
      checked={checked}
      onChange={changeHandler}
    />
    <span className="toogle-box" />
  </label>
);

Toogle.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

Toogle.defaultProps = {
  checked: false,
};

export default Toogle;
