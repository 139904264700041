import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import Api from '../../../redux/middlewares/Api';

import ProductCard from '../../Product/ProductCard/ProductCard';

import { getCorrectErrorMessages } from '../../../utils/errors';
import { getFormattedGetAutopilotData } from '../../../utils/autopilots';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import { hideModal, showModal } from '../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../constants';

import './MessageView.scss';

const api = new Api();

const MessageView = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [messageData, setMessageData] = useState({});
  const [messageLoading, setMessageLoading] = useState(true);
  const [onGetMessageErrors, setOnGetMessageErrors] = useState([]);

  useEffect(() => {
    api.getAutopilot(id).then(
      (autopilot) => {
        const formattedData = getFormattedGetAutopilotData(autopilot);
        setMessageData(formattedData);
        setMessageLoading(false);
      },
      (errors) => {
        setOnGetMessageErrors(getCorrectErrorMessages(errors));
        setMessageLoading(false);
      },
    );
  }, [id]);

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const editHandler = (id) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.MESSAGE_EDIT,
        id,
      }),
    );
  };

  return (
    <div className="messageModal messageModal--view">
      {messageLoading ? (
        <Spin size="large" />
      ) : Object.keys(messageData).length ? (
        <>
          <div className="messageView">
            <h3 className="messageView-title">{messageData.subject}</h3>
            <img crossOrigin="anonymous" className="messageView-image" src={messageData.cover} alt={messageData.subject} />
            <div className="messageView-description">{messageData.description}</div>
            {messageData.products && messageData.products.length
              ? messageData.products.map((product) => (
                  <ProductCard
                    key={product.id}
                    title={product.title}
                    cover={product.cover}
                    conditions={product.conditions}
                    description={product.description}
                    type={product.type}
                  />
                ))
              : null}
          </div>
          <div className="modal-actions modal-actions--fullsize">
            <button className="btn btn--small btn--secondary" onClick={cancelHandler}>
              {t('Cancel')}
            </button>
            <button className="btn btn--small btn--primary" onClick={() => editHandler(id)}>
              {t('Edit')}
            </button>
          </div>
        </>
      ) : (
        <ErrorMessages errorMessages={onGetMessageErrors} />
      )}
    </div>
  );
};

export default MessageView;
