import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useFormContext } from 'react-hook-form';

import SearchBox from './SearchBox';
import Icon from '../Icon';
import { ICONS } from '../../constants';
import FieldError from '../HookedField/FieldError/FieldError';

import './LocationPicker.scss';

const LocationPicker = ({ label, name, defaultValue, placeholder }) => {
  const [location, setLocation] = useState({
    lat: 46.947974,
    lng: 7.447447,
  });
  const [googleMap, handleGoogleMap] = useState({
    map: null,
    maps: null,
  });

  const { errors, setValue, register, clearErrors } = useFormContext();

  useEffect(() => {
    register({ name });
  }, [register, name]);

  const onPlacesChanged = (val) => {
    if (val) {
      const lat = val[0].geometry.location.lat();
      const lng = val[0].geometry.location.lng();
      setLocation({ lat, lng });
      setValue(name, {
        latitude: lat,
        longitude: lng,
        placeId: val[0].place_id,
        location: val[0].formatted_address,
      });
      clearErrors(name);
    }
  };

  const Marker = () => (
    <div className="marker">
      <Icon icon={ICONS.LOCATION} color="#FABB18" />
    </div>
  );

  // if we have defaultValue

  useEffect(() => {
    if (defaultValue && Object.keys(defaultValue).length) {
      setLocation({
        lat: defaultValue.latitude,
        lng: defaultValue.longitude,
      });
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="locationPicker">
      <SearchBox
        label={label}
        name={name}
        maps={googleMap.maps}
        value={defaultValue.location}
        placeholder={placeholder}
        onPlacesChanged={onPlacesChanged}
      />
      <div className="locationPicker-mapWrapper">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: 'AIzaSyAwIoxcAVRSvXdNZlbjRpCS72AAvMGk9lQ',
          }}
          center={location}
          defaultZoom={11}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => handleGoogleMap({ map, maps })}
        >
          <Marker lat={location.lat} lng={location.lng} />
        </GoogleMapReact>
      </div>
      <FieldError error={errors[name]} />
    </div>
  );
};

export default LocationPicker;
