import { call, select, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  REQUEST_GET_BUSINESSES,
  REQUEST_CREATE_BUSINESSES,
  REQUEST_UPDATE_BUSINESS,
  REQUEST_DELETE_BUSINESS,
  GET_BUSINESSES,
  CREATE_BUSINESSES,
  UPDATE_BUSINESS,
  REQUEST_GET_MEMBERS,
  GET_MEMBERS,
  REQUEST_CREATE_MEMBER,
  CREATE_MEMBER,
  DELETE_BUSINESS,
  DELETE_MEMBER,
  REQUEST_DELETE_MEMBER,
  REQUEST_RESEND_MEMBER,
  REQUEST_GET_PROFILE,
  REQUEST_GET_PARTNER_BUSINESSES,
  GET_PRODUCTS,
} from '../types/index';
import { getUniqueProductsFromBusinesses } from '../../utils/products';

const api = new Api();

function* getBusinessesWatcher() {
  yield takeEvery(REQUEST_GET_BUSINESSES, getBusinessesWorker);
}

function* getPartnerBusinessesWatcher() {
  yield takeEvery(REQUEST_GET_PARTNER_BUSINESSES, getPartnerBusinessesWorker);
}

function* createBusinessWatcher() {
  yield takeEvery(REQUEST_CREATE_BUSINESSES, createBusinessWorker);
}

function* updateBusinessWatcher() {
  yield takeEvery(REQUEST_UPDATE_BUSINESS, updateBusinessWorker);
}

function* deleteBusinessWatcher() {
  yield takeEvery(REQUEST_DELETE_BUSINESS, deleteBusinessWorker);
}

function* getMembersWatcher() {
  yield takeEvery(REQUEST_GET_MEMBERS, getMembersWorker);
}

function* deleteMemberWatcher() {
  yield takeEvery(REQUEST_DELETE_MEMBER, deleteMemberWorker);
}

function* createMemberWatcher() {
  yield takeEvery(REQUEST_CREATE_MEMBER, createMemberWorker);
}

function* resendMemberWatcher() {
  yield takeEvery(REQUEST_RESEND_MEMBER, resendMemberWorker);
}

function* getBusinessesWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getBusinesses);
    yield put({ type: GET_BUSINESSES, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getPartnerBusinessesWorker({
  payload: { partnersIds, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const {result: businesses} = yield call(() => api.getBusinessByOwnersIds(partnersIds));
    const businessIds = businesses.map(business => business.id)
    yield put({ type: GET_BUSINESSES, payload: businesses });
    const {result: products} = yield call(() => api.getProductsByBusinessesIds(businessIds));
    yield put({ type: GET_PRODUCTS, payload: products });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* createBusinessWorker({
  payload: { businessData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.createBusiness(businessData));
    yield put({ type: CREATE_BUSINESSES, payload: data });
    yield put({ type: REQUEST_GET_PROFILE, payload: {} });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* updateBusinessWorker({
  payload: { id, businessData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteBusinessSchedule(id));
    const data = yield call(() => api.updateBusiness(id, businessData));
    yield put({ type: UPDATE_BUSINESS, payload: data });
    yield put({ type: REQUEST_GET_PROFILE, payload: {} });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteBusinessWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteBusiness(id));
    yield put({ type: DELETE_BUSINESS, payload: id });
    const businesses = yield select((state) => state.businesses.items);
    const products = getUniqueProductsFromBusinesses(businesses);
    yield put({ type: GET_PRODUCTS, payload: products });
    yield put({ type: REQUEST_GET_PROFILE, payload: {} });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getMembersWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.getMembers(id));
    yield put({ type: GET_MEMBERS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteMemberWorker({
  payload: { id, memberData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteMember(id, memberData));
    yield put({ type: DELETE_MEMBER, payload: memberData.user.id });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* createMemberWorker({
  payload: { id, memberData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.createMember(id, memberData));
    yield put({ type: CREATE_MEMBER, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* resendMemberWorker({
  payload: { id, memberData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.resendMember(id, memberData));
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* businesses() {
  yield all([
    fork(getBusinessesWatcher),
    fork(getPartnerBusinessesWatcher),
    fork(createBusinessWatcher),
    fork(updateBusinessWatcher),
    fork(deleteBusinessWatcher),
    fork(getMembersWatcher),
    fork(deleteMemberWatcher),
    fork(createMemberWatcher),
    fork(resendMemberWatcher),
  ]);
}
