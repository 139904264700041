import React, { useEffect, useState } from 'react';
import { Switch, useHistory, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { notification } from 'antd';
import { getPreferences } from '../../redux/actions/preferences';
import { getProfile, updateProfile } from '../../redux/actions/profile';
import { getMessages } from '../../redux/actions/messages';

import { getToken, onMessageListener } from '../../firebase';
import { authProtectedMainRoutes } from '../../router';

import PrivateRoute from '../../router/PrivateRoute';

import LayoutHeaderSidebar from '../../components/LayoutHeaderSidebar/LayoutHeaderSidebar';
import NotFound from '../shared/NotFound/NotFound';

import { signOut } from '../../redux/actions/auth';

const Main = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(
      getProfile({
        onSuccessCallback: () => {
          dispatch(getPreferences({}));
          dispatch(getMessages({}));
        },
        onErrorCallback: () => {
          dispatch(signOut({}));
        },
      }),
    );
  }, []);

  const [notificationToken, setNotificationToken] = useState(null);

  getToken(setNotificationToken);

  useEffect(() => {
    if (notificationToken) {
      dispatch(
        updateProfile({
          data: {
            notification: {
              token: notificationToken,
            },
          },
        }),
      );
    }
  }, [notificationToken]);

  onMessageListener()
    .then((payload) => {
      notification.open({
        message: payload.notification.title,
        description: payload.notification.body,
        onClick: () => {
          history.push('/main/notifications');
        },
      });
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <Switch>
      {authProtectedMainRoutes.map((route, idx) => (
        <PrivateRoute
          path={route.path}
          roles={route.roles}
          exact={route.exact}
          component={route.component}
          layout={LayoutHeaderSidebar}
          key={idx}
        />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};

export default Main;
