import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  GET_NEWSLETTERS,
  REQUEST_GET_NEWSLETTERS,
  CREATE_NEWSLETTER,
  REQUEST_CREATE_NEWSLETTER,
  UPDATE_NEWSLETTER,
  REQUEST_UPDATE_NEWSLETTER,
  DELETE_NEWSLETTER,
  REQUEST_DELETE_NEWSLETTER,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
} from '../types/index';

const api = new Api();

function* getNewslettersWatcher() {
  yield takeEvery(REQUEST_GET_NEWSLETTERS, getNewslettersWorker);
}

function* createNewsletterWatcher() {
  yield takeEvery(REQUEST_CREATE_NEWSLETTER, createNewsletterWorker);
}

function* updateNewsletterWatcher() {
  yield takeEvery(REQUEST_UPDATE_NEWSLETTER, updateNewsletterWorker);
}

function* deleteNewsletterWatcher() {
  yield takeEvery(REQUEST_DELETE_NEWSLETTER, deleteNewsletterWorker);
}

function* getNewslettersWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getNewsletters);
    yield put({ type: GET_NEWSLETTERS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* createNewsletterWorker({
  payload: { newsletterData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.createNewsletter(newsletterData));
    yield put({ type: CREATE_NEWSLETTER, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* updateNewsletterWorker({
  payload: { id, newsletterData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    // yield call(() => api.deleteNewsletterSlots(id));
    const data = yield call(() => api.updateNewsletter(id, newsletterData));
    yield put({ type: UPDATE_NEWSLETTER, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteNewsletterWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteNewsletter(id));
    yield put({ type: DELETE_NEWSLETTER, payload: id });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* newsletters() {
  yield all([
    fork(getNewslettersWatcher),
    fork(createNewsletterWatcher),
    fork(updateNewsletterWatcher),
    fork(deleteNewsletterWatcher),
  ]);
}
