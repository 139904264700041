import React from 'react';
import { useTranslation } from 'react-i18next';

import { Select } from 'antd';
import { LANGUAGES } from '../../constants';

const LanguagePicker = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    return language;
  };

  return (
    <Select
      options={LANGUAGES.map((language) => ({
        ...language,
        label: language.label,
      }))}
      onChange={changeLanguage}
      value={i18n.language}
    />
  );
};

export default LanguagePicker;
