import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, Switch, Route, Link } from 'react-router-dom';

import Screen from '../../components/Screen/Screen';
import Info from '../../components/SignUpForm/Info';
import EmailVerificationCode from '../../components/SignUpForm/EmailVerificationCode';
import Password from '../../components/SignUpForm/Password';
import Gender from '../../components/SignUpForm/Gender';
import { signUpHookForm, signUp, confirmEmail, signUpGoogle } from '../../redux/actions/auth';
import { updateProfile } from '../../redux/actions/profile';
import { getCorrectErrorMessages } from '../../utils/errors';
import { ROLES } from '../../constants';

const SignUp = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { firstName, lastName, email, phone, password, terms } = useSelector(
    (state) => state.auth.signUpHookForm,
  );

  // info

  const infoSubmitHandler = (data) => {
    dispatch(
      signUpHookForm({
        data,
        onSuccessCallback: () => history.push(`${props.match.path}/password`),
      }),
    );
  };

  // password

  const [onPasswordSubmitErrors, setOnPasswordSubmitErrors] = useState([]);
  const [passwordSubmitLoading, setPasswordSubmitLoading] = useState(false);

  const passwordSubmitHandler = (data) => {
    dispatch(
      signUpHookForm({
        data,
        onSuccessCallback: () => {
          setPasswordSubmitLoading(true);
          dispatch(
            confirmEmail({
              data: { firstName, lastName, email },
              onSuccessCallback: () => {
                setPasswordSubmitLoading(false);
                history.push(`${props.match.path}/email-verification-code`);
              },
              onErrorCallback: (errors) => {
                setPasswordSubmitLoading(false);
                setOnPasswordSubmitErrors(getCorrectErrorMessages(errors));
              },
            }),
          );
        },
      }),
    );
  };

  // sign up

  const [onSignUpErrors, setOnSignUpErrors] = useState([]);
  const [signUpLoading, setSignUpLoading] = useState(false);

  const signUpHandler = (data) => {
    setSignUpLoading(true);
    dispatch(
      signUp({
        data: {
          firstName,
          lastName,
          email,
          phone,
          password,
          code: data.code,
          role: 'PARTNER',
        },
        onSuccessCallback: () => history.push(`${props.match.path}/gender`),
        onErrorCallback: (errors) => {
          setSignUpLoading(false);
          setOnSignUpErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  const signUpWithGoogleHandler = ({ token }) => {
    setSignUpLoading(true);
    dispatch(
      signUpGoogle({
        data: {
          token,
          role: ROLES.PARTNER,
        },
        onSuccessCallback: () => history.push(`${props.match.path}/gender`),
        onErrorCallback: (errors) => {
          setSignUpLoading(false);
          setOnSignUpErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  // gender

  const [updateGenderLoading, setUpdateGenderLoading] = useState(false);
  const [onUpdateGenderErrors, setOnUpdateGenderErrors] = useState([]);

  const updateGenderHandler = (data) => {
    setUpdateGenderLoading(true);
    dispatch(
      updateProfile({
        data,
        onSuccessCallback: () => history.push('/create-business-profile'),
        onErrorCallback: (errors) => {
          setUpdateGenderLoading(false);
          setOnUpdateGenderErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  // skip

  const onSkipClick = () => {
    history.push('/create-business-profile');
  };

  const { t } = useTranslation();

  return (
    <Switch>
      <Screen
        info={
          <>
            <span>{t('Already have an account?')}</span>
            &nbsp;
            <Link className="link" to="/sign-in">
              {t('Log in')}
            </Link>
          </>
        }
      >
        <Route exact path={`${props.match.path}`}>
          <Info
            defaultValues={{
              firstName,
              lastName,
              email,
              phone,
              terms,
            }}
            infoSubmitHandler={infoSubmitHandler}
            signUpWithGoogleHandler={signUpWithGoogleHandler}
            signUpLoading={signUpLoading}
            onSignUpErrors={onSignUpErrors}
          />
        </Route>
        <Route exact path={`${props.match.path}/password`}>
          <Password
            defaultValues={{
              password,
            }}
            onPasswordSubmitErrors={onPasswordSubmitErrors}
            passwordSubmitHandler={passwordSubmitHandler}
            passwordSubmitLoading={passwordSubmitLoading}
          />
        </Route>
        <Route exact path={`${props.match.path}/email-verification-code`}>
          <EmailVerificationCode
            email={email}
            signUpHandler={signUpHandler}
            onSignUpErrors={onSignUpErrors}
            signUpLoading={signUpLoading}
          />
        </Route>
        <Route exact path={`${props.match.path}/gender`}>
          <Gender
            updateGenderHandler={updateGenderHandler}
            updateGenderLoading={updateGenderLoading}
            onUpdateGenderErrors={onUpdateGenderErrors}
            onSkipClick={onSkipClick}
          />
        </Route>
      </Screen>
    </Switch>
  );
};

export default SignUp;
