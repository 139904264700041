import React from 'react';
import { useTranslation } from 'react-i18next';

import './NoData.scss';

const NoData = () => {
  const { t } = useTranslation();
  return (
    <div className="noData">
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41.8335 42.9561L48.4145 57.0045C46.7229 58.1133 44.4526 57.6408 43.3439 55.9492L36.9328 46.1685L41.8335 42.9561Z"
          fill="#1B74D6"
        />
        <path
          d="M39.383 44.5623L45.7942 54.3432C46.9029 56.0348 48.0762 57.2263 48.4144 57.0046C50.106 55.8959 50.5784 53.6256 49.4697 51.934L43.0586 42.1532L39.383 44.5623Z"
          fill="#1E8AFF"
        />
        <path
          d="M41.8377 42.9551L37.823 36.8302L34.148 39.239L38.1627 45.3639L41.8377 42.9551Z"
          fill="#FFEAC3"
        />
        <path
          d="M39.8704 34.6181L40.6487 38.4778L34.5228 42.4931L31.2941 40.2396L39.8704 34.6181Z"
          fill="#FFD17E"
        />
        <path
          d="M36.1292 44.9438L38.5795 43.3377L40.1856 45.788L37.7353 47.3941C37.0586 47.8376 36.1506 47.6487 35.7071 46.972C35.2635 46.2953 35.4525 45.3873 36.1292 44.9438Z"
          fill="#FFB739"
        />
        <path
          d="M38.1573 45.3656C37.7138 44.6889 37.9028 43.7809 38.5794 43.3373L42.255 40.9281C42.9316 40.4846 43.8396 40.6736 44.2832 41.3502C44.7267 42.0269 44.5377 42.9349 43.8611 43.3784L40.1856 45.7877C39.509 46.2312 38.6009 46.0422 38.1573 45.3656Z"
          fill="#FFD17E"
        />
        <path
          d="M22.137 40.6615C12.1833 38.59 5.79348 28.8417 7.86498 18.8879C9.93648 8.93422 19.6849 2.54441 29.6386 4.61592C39.5923 6.68742 45.9821 16.4358 43.9106 26.3895C41.8391 36.3432 32.0907 42.733 22.137 40.6615Z"
          fill="#FFB739"
        />
        <path
          d="M29.0419 7.48438L22.7342 37.7933C14.3647 36.0515 8.99181 27.8546 10.7336 19.485C12.4754 11.1154 20.6724 5.74256 29.0419 7.48438V7.48438Z"
          fill="#DBEBFF"
        />
        <path
          d="M13.6021 20.0819C15.3439 11.7123 22.2566 6.0722 29.042 7.48434C37.4116 9.22616 42.7845 17.4231 41.0426 25.7926C39.3008 34.1622 31.1039 39.5351 22.7344 37.7933C15.949 36.3812 11.8603 28.4516 13.6021 20.0819V20.0819Z"
          fill="white"
        />
        <path
          d="M29.7887 3.90014C40.122 6.05062 46.7791 16.2068 44.6286 26.5401C43.9738 29.6866 42.5756 32.4913 40.6571 34.8112L41.3676 38.3341C41.4268 38.6275 41.3015 38.9274 41.0512 39.0915L40.4386 39.493L41.2416 40.7181L41.8542 40.3166C42.3451 39.9948 42.9318 39.8835 43.5064 40.0031C44.081 40.1227 44.5747 40.4589 44.8964 40.9497C45.2182 41.4406 45.3296 42.0274 45.21 42.602C45.1421 42.9282 45.0023 43.2268 44.8061 43.4841L50.0826 51.534C51.411 53.5606 50.843 56.2902 48.8163 57.6187C47.803 58.2829 46.6139 58.473 45.5121 58.2437C44.4104 58.0144 43.3958 57.3658 42.7316 56.3524L37.4547 48.3018C37.1338 48.38 36.8026 48.386 36.4856 48.32C35.9346 48.2054 35.4274 47.881 35.0952 47.3744C34.4311 46.361 34.7152 44.9963 35.7283 44.332L36.341 43.9304L35.538 42.7053L34.9254 43.1068C34.7595 43.2155 34.561 43.2502 34.3744 43.2113C34.2794 43.1915 34.1875 43.1527 34.1047 43.0949L31.1577 41.038C28.2649 41.8716 25.1353 42.0348 21.9887 41.3799C11.6554 39.2295 4.99832 29.0733 7.14881 18.74C9.29929 8.40678 19.4555 1.74965 29.7887 3.90014L29.7887 3.90014ZM43.9568 55.5493C44.8422 56.9004 46.6621 57.2791 48.0132 56.3935C49.3643 55.5079 49.7431 53.6881 48.8574 52.337L43.6507 44.3937L38.7501 47.6059L43.9568 55.5493ZM36.3203 46.5713C36.5419 46.9091 36.9968 47.0038 37.3345 46.7823L43.4603 42.7669C43.6239 42.6596 43.7359 42.4951 43.7758 42.3036C43.8157 42.1121 43.7785 41.9165 43.6714 41.7528C43.5641 41.5892 43.3995 41.4771 43.2079 41.4372C43.0164 41.3974 42.8208 41.4345 42.6572 41.5418L36.5314 45.5571C36.1936 45.7786 36.0989 46.2335 36.3203 46.5713ZM37.5661 43.1274L40.0164 41.5213L39.2134 40.2962L36.7631 41.9023L37.5661 43.1274ZM34.5367 41.61L39.8337 38.1381L39.4339 36.1554C37.5677 38.0199 35.3323 39.4851 32.8781 40.4525L34.5367 41.61ZM22.2872 39.9458C31.8296 41.9317 41.2085 35.784 43.1944 26.2416C45.1803 16.6992 39.0327 7.32022 29.4903 5.33431C19.9478 3.34841 10.5689 9.49606 8.58298 19.0385C6.59708 28.5809 12.7447 37.9599 22.2872 39.9458Z"
          fill="black"
        />
        <path
          d="M29.1922 6.7687C32.8774 7.53563 36.1994 9.57454 38.5461 12.51C38.7987 12.826 38.7474 13.2868 38.4313 13.5395C38.1154 13.7921 37.6546 13.7407 37.4018 13.4248C35.2676 10.755 32.246 8.90052 28.8937 8.20288C20.9329 6.54612 13.1085 11.6748 11.4518 19.6356C9.79501 27.5965 14.9237 35.4209 22.8845 37.0776C30.8454 38.7344 38.6698 33.6057 40.3265 25.6449C41.0241 22.2927 40.5291 18.7821 38.9326 15.7597C38.7436 15.4021 38.8804 14.9589 39.2381 14.7699C39.5957 14.581 40.039 14.7177 40.2278 15.0755C41.9832 18.3985 42.5276 22.2582 41.7607 25.9432C39.9394 34.6949 31.3376 40.333 22.5861 38.5117C13.8345 36.6904 8.19628 28.0887 10.0176 19.3371C11.8389 10.5854 20.4407 4.94739 29.1922 6.7687V6.7687Z"
          fill="black"
        />
      </svg>
      <h4 className="noData-text">{t('Nothing found')}</h4>
    </div>
  );
};

export default NoData;
