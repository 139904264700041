import { GET_REGULAR_MESSAGES, GET_REGULAR_MESSAGE, CREATE_REGULAR_MESSAGE } from '../types';

const initialState = {
  messages: [],
  currentMessage: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_REGULAR_MESSAGES:
      return { ...state, messages: action.payload };
    case GET_REGULAR_MESSAGE:
      return { ...state, currentMessage: action.payload };
    case CREATE_REGULAR_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };
    default:
      return state;
  }
}
