import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { Spin } from 'antd';
import { hideModal, showModal } from '../../redux/actions/modal';
import { MODAL_TYPES } from '../../constants';
import { schema } from '../../utils/validation_schemas/adminProfile';

import Tabs from '../Tabs/Tabs';
import ResetPasswordForm from '../ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from '../../redux/actions/auth';
import BackBtn from '../BackBtn/BackBtn';
import HookedField from '../HookedField/HookedField';
import { updateProfile } from '../../redux/actions/profile';
import ChangePassword from '../ChangePassword/ChangePassword';
import { getCorrectErrorMessages } from '../../utils/errors';

const AdminProfile = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      text: t('General Info'),
    },
    {
      id: 2,
      text: t('Change password'),
    },
  ];

  const dispatch = useDispatch();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(1);

  const onTabClick = (id) => {
    setActiveTab(id);
  };

  const [isResetForm, setIsResetForm] = useState(false);

  // reset password

  const [onResetPasswordErrors, setOnResetPasswordErrors] = useState([]);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const handleResetPassword = (data) => {
    setResetPasswordLoading(true);
    dispatch(
      resetPassword({
        data,
        onSuccessCallback: () =>
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Instructions Sent!'),
              description: t(
                'Please check your email and follow the instructions to reset your password',
              ),
              onClose: () => {
                history.push('/auth');
                dispatch(hideModal());
              },
            }),
          ),
        onErrorCallback: (errors) => setOnResetPasswordErrors(getCorrectErrorMessages(errors)),
        onFinallyCallback: () => setResetPasswordLoading(false),
      }),
    );
  };

  // change profile data form

  const [loading, setLoading] = useState(true);
  const [initialData, setInitialData] = useState({});

  const defaultValues = useSelector((state) => state.profile.data);

  const onUpdateProfile = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.NOTIFICATION,
        title: t('Your data was successfully updated'),
      }),
    );
  };

  const handleSubmit = (data) => {
    dispatch(updateProfile({ data, onOk: onUpdateProfile }));
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (defaultValues && Object.keys(defaultValues).length) {
      setInitialData(defaultValues);
      setLoading(false);
    }
  }, [defaultValues]);

  return (
    <div className="accountSettings">
      <h2 className="pageTitle">{t('My Profile')}</h2>
      <Tabs activeTab={activeTab} tabs={tabs} onTabClick={onTabClick} />
      {activeTab === 1 ? (
        <>
          <div className="accountSettings-tab">
            {loading ? (
              <Spin size="large" />
            ) : (
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                  <HookedField
                    name={t('Email')}
                    placeholder="Enter your Email"
                    label="Email"
                    defaultValue={initialData.email || ''}
                    fieldType="textField"
                    type="email"
                  />
                  <button className="btn btn--primary">{t('Save')}</button>
                </form>
              </FormProvider>
            )}
          </div>
          {/* <div className="accountSettings-description"> */}
          {/*    <h3>Lorem Ipsum</h3> */}
          {/*    <p> */}
          {/*        There are many variations of passages of Lorem Ipsum */}
          {/*        available, but the majority have suffered alteration */}
          {/*        in some form, by injected humour, or randomised */}
          {/*        words which don't look even slightly believable. */}
          {/*    </p> */}
          {/*    <p> */}
          {/*        There are many variations of passages of Lorem Ipsum */}
          {/*        available, but the majority have suffered alteration */}
          {/*        in some form, by injected humour, or randomised */}
          {/*        words which don't look even slightly believable. */}
          {/*    </p> */}
          {/*    <p> */}
          {/*        There are many variations of passages of Lorem Ipsum */}
          {/*        available, but the majority have suffered alteration */}
          {/*        in some form, by injected humour, or randomised */}
          {/*        words which don't look even slightly believable. */}
          {/*    </p> */}
          {/*    <p> */}
          {/*        There are many variations of passages of Lorem Ipsum */}
          {/*        available, but the majority have suffered alteration */}
          {/*        in some form, by injected humour, or randomised */}
          {/*        words which don't look even slightly believable. */}
          {/*    </p> */}
          {/* </div> */}
        </>
      ) : (
        <div className="accountSettings-tab">
          {isResetForm ? (
            <>
              <BackBtn onClick={() => setIsResetForm(false)} className="accountSettings-backBtn" />
              <ResetPasswordForm
                resetPasswordLoading={resetPasswordLoading}
                handleResetPassword={handleResetPassword}
                onResetPasswordErrors={onResetPasswordErrors}
              />
            </>
          ) : (
            <ChangePassword
              goToResetPasswordForm={() => setIsResetForm(true)}
              onSuccessChangePassword={() => setActiveTab(1)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AdminProfile;
