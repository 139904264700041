import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Spin } from 'antd';
import { createSlot } from '../../../redux/actions/slots';
import { prevModal, showModal } from '../../../redux/actions/modal';

import { schema } from '../../../utils/validation_schemas/slot';

import { getFormattedPostSlotData } from '../../../utils/slots';
import { getCorrectErrorMessages } from '../../../utils/errors';
import { MODAL_TYPES } from '../../../constants';

import LocationPicker from '../../LocationPicker/LocationPicker';
import HookedField from '../../HookedField/HookedField';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';

import './SlotForm.scss';

const SlotAddForm = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // create Slot

  const [createSlotLoading, setCreateSlotLoading] = useState(false);
  const [onCreateSlotErrors, setOnCreateSlotErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(prevModal());
  };

  const submitHandler = (data) => {
    setCreateSlotLoading(true);
    const formattedData = getFormattedPostSlotData(data);
    dispatch(
      createSlot({
        slotData: formattedData,
        onSuccessCallback: () => {
          setCreateSlotLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Slot was successfully created'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setCreateSlotLoading(false);
          setOnCreateSlotErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <FormProvider {...methods}>
      <div className="slotForm">
        <h3 className="slotForm-heading">{t('Adding Featured Partner Slot')}</h3>
        <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
          <LocationPicker
            label={t('Region')}
            name="region"
            defaultValue={{}}
            placeholder={t('Select Region')}
          />
          <HookedField
            name="period"
            label={t('Activity Period')}
            defaultValue={[]}
            type="dateRange"
            fieldType="dateRange"
            disabledDate={(current) => current && current < moment()}
          />
          <HookedField
            name="price"
            placeholder={t('Enter Price')}
            label={t('Price')}
            defaultValue={0}
            fieldType="numberField"
            type="price"
            min={0}
          />
          <ErrorMessages errorMessages={onCreateSlotErrors} />
          <Spin spinning={createSlotLoading}>
            <div className="modal-actions modal-actions--fullsize">
              <button
                type="button"
                className="btn btn--small btn--secondary"
                onClick={cancelHandler}
              >
                {t('Cancel')}
              </button>
              <button type="submit" className="btn btn--small btn--primary">
                {t('Create')}
              </button>
            </div>
          </Spin>
        </form>
      </div>
    </FormProvider>
  );
};

export default SlotAddForm;
