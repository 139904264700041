import {
  REQUEST_GET_CUSTOMER_STATISTICS,
  REQUEST_GET_DETAILS_STATISTICS,
  REQUEST_GET_GENDER_STATISTICS,
  REQUEST_GET_AGE_STATISTICS,
  REQUEST_GET_LINES_STATISTICS,
  REQUEST_GET_ADMIN_STATISTICS,
} from '../types';

export function getCustomerStatistics(payload) {
  return {
    type: REQUEST_GET_CUSTOMER_STATISTICS,
    payload,
  };
}

export function getDetailsStatistics(payload) {
  return {
    type: REQUEST_GET_DETAILS_STATISTICS,
    payload,
  };
}

export function getGenderStatistics(payload) {
  return {
    type: REQUEST_GET_GENDER_STATISTICS,
    payload,
  };
}

export function getAgeStatistics(payload) {
  return {
    type: REQUEST_GET_AGE_STATISTICS,
    payload,
  };
}

export function getLinesStatistics(payload) {
  return {
    type: REQUEST_GET_LINES_STATISTICS,
    payload,
  };
}

export function getAdminStatistics(payload) {
  return {
    type: REQUEST_GET_ADMIN_STATISTICS,
    payload,
  };
}
