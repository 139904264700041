import React from 'react';
import PropTypes from 'prop-types';

import './Tabs.scss';

const Tabs = ({ tabs, onTabClick, activeTab, isSteps, disabledTab = [] }) => (
  <div className="tabs">
    {tabs.map(({ id, text }) => {
      const classes = [
        'tab',
        isSteps ? 'tab--step' : '',
        activeTab === id ? 'tab--active' : '',
        disabledTab.includes(id) ? 'tab--disabled' : '',
      ];
      return (
        <div
          key={id}
          className={classes.join(' ')}
          onClick={() => !disabledTab.includes(id) && onTabClick(id)}
        >
          {text}
        </div>
      );
    })}
  </div>
);

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onTabClick: PropTypes.func,
  activeTab: PropTypes.number,
  isSteps: PropTypes.bool,
};

Tabs.defaultProps = {
  isSteps: false,
};

export default Tabs;
