import React from 'react';

import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';

const LayoutHeaderSidebar = ({ children }) => (
  <div className="wrapper">
    <Header />
    <Sidebar />
    <main className="main">
      <div className="container">{children}</div>
    </main>
  </div>
);

export default LayoutHeaderSidebar;
