import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  GET_SLOTS,
  REQUEST_GET_SLOTS,
  CREATE_SLOT,
  REQUEST_CREATE_SLOT,
  DELETE_SLOT,
  REQUEST_DELETE_SLOT,
  UPDATE_SLOT,
  REQUEST_UPDATE_SLOT,
  REQUEST_BUY_SLOT,
} from '../types/index';

const api = new Api();

function* getSlotsWatcher() {
  yield takeEvery(REQUEST_GET_SLOTS, getSlotsWorker);
}

function* deleteSlotWatcher() {
  yield takeEvery(REQUEST_DELETE_SLOT, deleteSlotWorker);
}

function* updateSlotWatcher() {
  yield takeEvery(REQUEST_UPDATE_SLOT, updateSlotWorker);
}

function* createSlotWatcher() {
  yield takeEvery(REQUEST_CREATE_SLOT, createSlotWorker);
}

function* buySlotWatcher() {
  yield takeEvery(REQUEST_BUY_SLOT, buySlotWorker);
}

function* getSlotsWorker({ payload: { onSuccessCallback, onErrorCallback, onFinallyCallback } }) {
  try {
    const data = yield call(api.getSlots);
    yield put({ type: GET_SLOTS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteSlotWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteSlot(id));
    yield put({ type: DELETE_SLOT, payload: id });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* updateSlotWorker({
  payload: { id, slotData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.updateSlot(id, slotData));
    yield put({ type: UPDATE_SLOT, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* createSlotWorker({
  payload: { slotData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.createSlot(slotData));
    yield put({ type: CREATE_SLOT, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* buySlotWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.buySlot(id));
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    console.log(errors);
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* slots() {
  yield all([
    fork(getSlotsWatcher),
    fork(deleteSlotWatcher),
    fork(updateSlotWatcher),
    fork(createSlotWatcher),
    fork(buySlotWatcher),
  ]);
}
