import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Screen from '../../components/Screen/Screen';
import BackBtn from '../../components/BackBtn/BackBtn';
import ResetPasswordForm from '../../components/ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from '../../redux/actions/auth';
import { hideModal, showModal } from '../../redux/actions/modal';
import { MODAL_TYPES } from '../../constants';
import { getCorrectErrorMessages } from '../../utils/errors';

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  // reset password

  const [onResetPasswordErrors, setOnResetPasswordErrors] = useState([]);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);

  const handleResetPassword = (data) => {
    setResetPasswordLoading(true);
    dispatch(
      resetPassword({
        data,
        onSuccessCallback: (email) =>
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Instructions Sent!'),
              description: t('Reset password popup text', { email: email || 'your email' }),
              onClose: () => {
                history.push('/sign-in');
                dispatch(hideModal());
              },
            }),
          ),
        onErrorCallback: (errors) => setOnResetPasswordErrors(getCorrectErrorMessages(errors)),
        onFinallyCallback: () => setResetPasswordLoading(false),
      }),
    );
  };

  return (
    <Screen info={<BackBtn />}>
      <ResetPasswordForm
        resetPasswordLoading={resetPasswordLoading}
        handleResetPassword={handleResetPassword}
        onResetPasswordErrors={onResetPasswordErrors}
      />
    </Screen>
  );
};

export default ResetPassword;
