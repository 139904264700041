export const getCorrectErrorMessages = (errors = []) =>
  errors.map((error) => {
    let message = '';
    switch (error) {
      case 'AUTH_PASSWORDS_DO_NOT_MATCH':
        message = 'Passwords do not match';
        break;
      case 'AUTH_INCORRECT_CREDENTIALS':
        message = 'The email address or password is incorrect';
        break;
      case 'AUTH_INVALID_CONFIRM_TOKEN':
        message = 'Auth invalid confirm token';
        break;
      case 'email must be an email':
        message = 'The email address is incorrect';
        break;
      case 'USER_EMAIL_ALREADY_EXIST':
        message = 'This email address already exist, please login in';
        break;
      case 'USER_PHONE_ALREADY_EXIST':
        message = 'This phone already exist, please login in';
        break;
      case 'BUSINESS_ALREADY_EXIST':
        message = 'This email address already exist';
        break;
      case 'PRODUCT_ALREADY_EXIST':
        message = 'Product already exist';
        break;
      case 'BUSINESS_MEMBER_ALREADY_EXIST':
        message = 'Member already exist';
        break;
      case 'RESOURCE_NOT_FOUND':
        message = 'You have not valid card for payment, please change settings in profile';
        break;
      case 'CHARGEBEE_UPDATE_SUBSCRIPTION_PLAN':
        message = 'Please change your subscription plan';
        break;
      default:
        message = error;
    }
    return {
      message,
    };
  });
