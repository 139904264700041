import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Radio, Spin } from 'antd';
import { createProduct } from '../../../redux/actions/products';
import { hideModal } from '../../../redux/actions/modal';

import { addSchema } from '../../../utils/validation_schemas/product';

import { PRODUCT_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';
import { getFormattedPostProductData } from '../../../utils/products';

import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import HookedField from '../../HookedField/HookedField';
import Images from '../../Images/Images';
import BusinessChooser from '../../Business/BusinessChooser/BusinessChooser';

import './ProductForm.scss';
import Toogle from '../../ui/Toogle';

const ProductAddForm = ({ onSuccessCallback, skip, skipHandler, forcedProductType }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(addSchema),
  });

  // create Product

  const [createProductLoading, setCreateProductLoading] = useState(false);
  const [onCreateProductErrors, setOnCreateProductErrors] = useState([]);

  const submitHandler = (data) => {
    setCreateProductLoading(true);
    const formattedData = getFormattedPostProductData(data);
    dispatch(
      createProduct({
        productData: formattedData,
        onSuccessCallback: (product) => {
          setCreateProductLoading(false);
          onSuccessCallback(product);
        },
        onErrorCallback: (errors) => {
          setCreateProductLoading(false);
          setOnCreateProductErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const renderHeading = () => {
    switch (forcedProductType) {
      case PRODUCT_TYPES.CARD:
        return t('Adding Card');
      case PRODUCT_TYPES.GIFT:
        return t('Adding Gift');
      default:
        return t('Adding Card/Gift');
    }
  };

  const [currentCardType, setCurrentCardType] = useState(PRODUCT_TYPES.CARD);

  const onChangeCardType = (e) => {
    setCurrentCardType(e.target.value);
    methods.clearErrors();
  };

  const [withValidityPeriod, setWithValidityPeriod] = useState(false);

  const validityPeriodHandler = (event) => {
    setWithValidityPeriod(event.target.checked);
  };

  return (
    <FormProvider {...methods}>
      <div className="productForm">
        <h2 className="productForm-heading">{renderHeading()}</h2>
        <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
          {!forcedProductType && (
            <div className="cardType-chooser">
              <Radio.Group onChange={onChangeCardType} value={currentCardType}>
                <Radio name="type" value={PRODUCT_TYPES.CARD}>
                  {t('Card')}
                </Radio>
                <Radio name="type" value={PRODUCT_TYPES.GIFT}>
                  {t('Gift')}
                </Radio>
              </Radio.Group>
            </div>
          )}
          <BusinessChooser defaultValue={[]} />
          <HookedField
            name="title"
            placeholder={t('Name of Product')}
            label={t('Name of Product')}
            defaultValue=""
            fieldType="textField"
            type="text"
          />
          <Images defaultCover={null} withMiniCover defaultMiniCover={null} />
          <HookedField
            name="description"
            placeholder={t('Description')}
            label={t('Description')}
            defaultValue=""
            fieldType="textField"
            type="textarea"
          />
          {currentCardType === PRODUCT_TYPES.CARD && (
            <HookedField
              name="point"
              placeholder={t('Quantity of Points')}
              label={t('Quantity of Points')}
              defaultValue={null}
              fieldType="numberField"
              type="number"
              min={0}
            />
          )}
          {currentCardType === PRODUCT_TYPES.GIFT && (
            <HookedField
              name="expirationDays"
              placeholder={t('Expiration date in days')}
              label={t('Expiration date in days')}
              defaultValue={null}
              fieldType="numberField"
              type="number"
              min={0}
            />
          )}
          <HookedField
            name="conditions"
            placeholder={t('Conditions')}
            label={t('Conditions')}
            defaultValue=""
            fieldType="textField"
            type="textarea"
          />
          {currentCardType === PRODUCT_TYPES.CARD && (
            <>
              <div className="toogleBlock">
                <div className="toogleBlock-title">{t('Validity Period')}</div>
                <Toogle
                  name="withPeriod"
                  checked={withValidityPeriod}
                  changeHandler={validityPeriodHandler}
                />
              </div>
              {withValidityPeriod && (
                <HookedField
                  name="period"
                  label={t('Validity')}
                  defaultValue={[]}
                  type="dateRange"
                  fieldType="dateRange"
                  disabledDate={(current) => current && current < moment()}
                />
              )}
            </>
          )}
          <input
            type="hidden"
            name="type"
            value={forcedProductType || currentCardType}
            ref={methods.register}
          />
          <ErrorMessages errorMessages={onCreateProductErrors} />
          {skip ? (
            <Spin spinning={createProductLoading}>
              <div>
                <button type="submit" className="btn btn--primary">
                  {t('Next step')}
                </button>
                <button type="button" className="btn btn--skip" onClick={skipHandler}>
                  {t('Skip')}
                </button>
              </div>
            </Spin>
          ) : (
            <Spin spinning={createProductLoading}>
              <div className="modal-actions modal-actions--fullsize">
                <button
                  type="button"
                  className="btn btn--small btn--secondary"
                  onClick={cancelHandler}
                >
                  {t('Cancel')}
                </button>

                <button type="submit" className="btn btn--small btn--primary">
                  {t('Save')}
                </button>
              </div>
            </Spin>
          )}
        </form>
      </div>
    </FormProvider>
  );
};

export default ProductAddForm;
