import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import Api from '../../../redux/middlewares/Api';

import HookedField from '../../HookedField/HookedField';

import './SupportModal.scss';
import { hideModal, showModal } from '../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../constants';
import { schema } from '../../../utils/validation_schemas/support';

const api = new Api();

const SupportModal = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const submitHandler = async (data) => {
    setLoading(true);
    const responseData = await api.createFAQ(JSON.stringify(data));
    if (responseData.id) {
      setLoading(false);
      dispatch(
        showModal({
          type: MODAL_TYPES.NOTIFICATION,
          title: t('The question was successfully sent'),
          okBtnText: t('Close modal'),
          onOk: cancelHandler,
        }),
      );
    }
  };

  return (
    <div className="supportModal">
      <h2 className="supportModal-title">{t('We can Help you!')}</h2>
      <p className="supportModal-info">
        {t('Please select a problem topic and describe your problem as detailed as possible.')}
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitHandler)}>
          <HookedField
            name="type"
            placeholder={t('Type of problem')}
            label={t('Type of problem')}
            defaultValue=""
            fieldType="textField"
            type="text"
          />
          <HookedField
            name="question"
            placeholder={t('Description')}
            label={t('Description')}
            defaultValue=""
            fieldType="textField"
            type="textarea"
          />
          <div className="modal-actions modal-actions--fullsize">
            <button type="button" className="btn btn--secondary btn--small" onClick={cancelHandler}>
              {t('Cancel')}
            </button>
            <button type="submit" disabled={loading} className="btn btn--primary btn--small">
              {t('Send')}
            </button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SupportModal;
