import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import MemberPreview from '../../components/Member/MemberPreview/MemberPreview';
import Search from '../../components/Search/Search';

import { showModal } from '../../redux/actions/modal';
import { MODAL_TYPES } from '../../constants';
import { getMembers, resendMember } from '../../redux/actions/businesses';
import NoData from '../../components/NoData/NoData';

const MyTeam = ({ match }) => {
  const { t } = useTranslation();
  const bpId = match.params.businessId;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const [initialMembers, setInitialMembers] = useState([]);
  const [currentMembers, setCurrentMembers] = useState([]);

  useEffect(() => {
    if (bpId) {
      setLoading(true);
      dispatch(
        getMembers({
          id: bpId,
          onFinallyCallback: () => {
            setLoading(false);
          },
        }),
      );
    }
  }, [bpId]);

  const { members } = useSelector((state) => state.businesses);

  // search

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setInitialMembers(members);
    setCurrentMembers(members);
    setLoading(false);
    setSearchValue('');
  }, [members]);

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  // resend invitation

  const [loadingMemberIdx, setLoadingMemberIdx] = useState(null);

  const resendHandler = (businessId, memberId) => {
    setLoadingMemberIdx(memberId);
    dispatch(
      resendMember({
        id: businessId,
        memberData: {
          user: {
            id: memberId,
          },
        },
        onSuccessCallback: () => {
          setLoadingMemberIdx(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('You sent invitation!'),
            }),
          );
        },
        onErrorCallback: () => {
          setLoadingMemberIdx(false);
        },
      }),
    );
  };

  const deleteHandler = (businessId, memberId) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.MEMBER_DELETE,
        businessId,
        memberId,
      }),
    );
  };

  useEffect(() => {
    const filteredMembers = initialMembers.filter((item) => {
      const name = `${item.user.firstName} ${item.user.lastName}`;
      return name.trim().toLowerCase().includes(searchValue.trim().toLowerCase());
    });

    setCurrentMembers(filteredMembers);
  }, [searchValue]);

  const renderMembers = () => {
    if (loading) {
      return <Spin size="large" />;
    }
    if (currentMembers.length) {
      return (
        <div className="cards">
          {currentMembers.map((item) => (
            <MemberPreview
              key={item.id}
              member={item.user}
              loading={loadingMemberIdx === item.user.id}
              deleteHandler={() => deleteHandler(bpId, item.user.id)}
              resendHandler={() => resendHandler(bpId, item.user.id)}
            />
          ))}
        </div>
      );
    }
    return <NoData />;
  };

  return (
    <div className="team-page">
      <h2 className="pageTitle">{t('My Team')}</h2>
      <div className="bar">
        <Search value={searchValue} onChange={onSearchChange} />
        <button
          className="btn btn--primary btn--small"
          onClick={() =>
            dispatch(
              showModal({
                type: MODAL_TYPES.INVITATION,
                businessId: bpId,
                onInvitationsSent: () =>
                  dispatch(
                    showModal({
                      type: MODAL_TYPES.NOTIFICATION,
                      title: t('You sent invitation!'),
                    }),
                  ),
              }),
            )
          }
        >
          + {t('Add Team Member')}
        </button>
      </div>
      {renderMembers()}
    </div>
  );
};

export default MyTeam;
