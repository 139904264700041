import React from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider, useForm } from 'react-hook-form';

import { Spin } from 'antd';
import { schema } from '../../../utils/validation_schemas/profile';

import Avatar from '../Avatar/Avatar';
import HookedField from '../../HookedField/HookedField';

import './ProfilePreview.scss';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';

const ProfilePreview = ({
  defaultValues = {},
  handleSubmit,
  profileDataLoading,
  updateProfileLoading,
  onUpdateProfileErrors,
  withAvatar = true,
}) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <div className="profilePreview">
      {profileDataLoading ? (
        <Spin />
      ) : (
        <FormProvider {...methods}>
          <form
            className="profilePreview-form"
            onSubmit={methods.handleSubmit(handleSubmit)}
            autoComplete="new-password"
          >
            {withAvatar && (
              <div className="profilePreview-top">
                <Avatar defaultCover={defaultValues.cover} />
              </div>
            )}
            <HookedField
              name="firstName"
              placeholder={t('Enter your First Name')}
              label={t('First Name')}
              defaultValue={defaultValues.firstName || ''}
              fieldType="textField"
              type="text"
            />
            <HookedField
              name="lastName"
              placeholder={t('Enter your Last Name')}
              label={t('Last Name')}
              defaultValue={defaultValues.lastName || ''}
              fieldType="textField"
              type="text"
            />
            <HookedField
              name="phone"
              placeholder={t('Enter your Phone')}
              label={t('Phone Number')}
              defaultValue={defaultValues.phone || ''}
              autoComplete="new-password"
              fieldType="textField"
              type="tel"
            />
            <HookedField
              name="email"
              placeholder={t('Enter your Email')}
              label={t('Email')}
              defaultValue={defaultValues.email || ''}
              fieldType="textField"
              type="email"
            />
            <ErrorMessages errorMessages={onUpdateProfileErrors} />
            <Spin spinning={updateProfileLoading}>
              <button className="btn btn--primary">{t('Save')}</button>
            </Spin>
          </form>
        </FormProvider>
      )}
    </div>
  );
};

export default ProfilePreview;
