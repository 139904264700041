import { REQUEST_GET_MESSAGES, REQUEST_DELETE_MESSAGE } from '../types';

export function getMessages(payload) {
  return {
    type: REQUEST_GET_MESSAGES,
    payload,
  };
}

export function deleteMessage(payload) {
  return {
    type: REQUEST_DELETE_MESSAGE,
    payload,
  };
}
