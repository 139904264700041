import { all, fork } from 'redux-saga/effects';
import { auth } from './auth';
import { products } from './products';
import { preferences } from './preferences';
import { businesses } from './businesses';
import { profile } from './profile';
import { users } from './users';
import { plans } from './plans';
import { autopilots } from './autopilots';
import { statistics } from './statistics';
import { slots } from './slots';
import { messages } from './messages';
import { newsletters } from './newsletters';
import { regulars } from './regulars';
import { notifications } from './notifications';

function* rootSaga() {
  yield all([
    fork(auth),
    fork(products),
    fork(preferences),
    fork(businesses),
    fork(profile),
    fork(users),
    fork(plans),
    fork(autopilots),
    fork(statistics),
    fork(slots),
    fork(messages),
    fork(newsletters),
    fork(regulars),
    fork(notifications),
  ]);
}

export default rootSaga;
