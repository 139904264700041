import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { hideModal, showModal } from '../../../redux/actions/modal';
import { deleteProduct } from '../../../redux/actions/products';

import { MODAL_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';

import ErrorMessages from '../../ErrorMessages/ErrorMessages';

const ProductDelete = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [deleteProductLoading, setDeleteProductLoading] = useState(false);
  const [onDeleteProductErrors, setOnDeleteProductErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const deleteHandler = () => {
    setDeleteProductLoading(true);
    dispatch(
      deleteProduct({
        id,
        onSuccessCallback: () => {
          setDeleteProductLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your card was successfully deleted'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setDeleteProductLoading(false);
          setOnDeleteProductErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="confirmModal">
      <h4 className="confirmModal-question">{t('Are you sure you want to delete card?')}</h4>
      <ErrorMessages errorMessages={onDeleteProductErrors} />
      <Spin spinning={deleteProductLoading}>
        <div className="modal-actions modal-actions--fullsize">
          <button type="button" className="btn btn--small btn--secondary" onClick={cancelHandler}>
            {t('Cancel')}
          </button>
          <button type="button" className="btn btn--small btn--primary" onClick={deleteHandler}>
            {t('Delete')}
          </button>
        </div>
      </Spin>
    </div>
  );
};

export default ProductDelete;
