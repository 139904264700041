import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { DATE_FORMAT } from '../../../constants';

import BusinessPartnerPreview from '../../BusinessPartner/BusinessPartnerPreview/BusinessPartnerPreview';

import './SlotView.scss';

const SlotView = ({ data: { region, period, price, owner } }) => {
  const { t } = useTranslation();
  return (
    <div className="slotView">
      <h3 className="slotView-heading">{t('Featured Partner Slot Info')}</h3>
      <table>
        <tbody>
          <tr>
            <td>{t('Region')}:</td>
            <td>{region.location}</td>
          </tr>
          <tr>
            <td>{t('Period')}:</td>
            <td>{period.map((date) => moment(date).format(DATE_FORMAT)).join('-')}</td>
          </tr>
          <tr>
            <td>{t('Price')}:</td>
            <td>{parseInt(price) / 100}</td>
          </tr>
        </tbody>
      </table>
      {owner && (
        <>
          <h4 className="slotView-title">{t('Business Partner')}</h4>
          <BusinessPartnerPreview
            image={null}
            isSimple
            name={`${owner?.firstName || ''} ${owner?.lastName || ''}`}
            phone={owner?.phone || ''}
            email={owner?.email || ''}
          />
        </>
      )}
    </div>
  );
};

export default SlotView;
