import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { Spin } from 'antd';
import { getUsers } from '../../redux/actions/user';

import BusinessPartnerPreview from '../../components/BusinessPartner/BusinessPartnerPreview/BusinessPartnerPreview';
import Search from '../../components/Search/Search';
import NoData from '../../components/NoData/NoData';
import { showModal } from '../../redux/actions/modal';
import { MODAL_TYPES } from '../../constants';

const BusinessPartners = () => {
  const { t } = useTranslation();

  const [currentPartners, setCurrentPartners] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const history = useHistory();
  const dispatch = useDispatch();

  const partners = useSelector((state) => state.users.items);

  // get partner profiles

  const [partnersLoading, setPartnersLoading] = useState(false);

  useEffect(() => {
    setPartnersLoading(true);
    dispatch(
      getUsers({
        onFinallyCallback: () => {
          setPartnersLoading(false);
        },
      }),
    );
  }, []);

  useEffect(() => {
    setCurrentPartners(partners);
  }, [partners]);

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const filteredPartners = partners.filter((partner) => {
      const name = `${partner.firstName} ${partner.lastName}`;
      return name.trim().toLowerCase().includes(searchValue.trim().toLowerCase());
    });

    setCurrentPartners(filteredPartners);
  }, [searchValue]);

  const deleteHandler = (event, id) => {
    event.stopPropagation();
    dispatch(
      showModal({
        type: MODAL_TYPES.PARTNER_DELETE,
        id,
      }),
    );
  };

  const clickHandler = (id) => {
    history.push(`/main/business-partners/${id}`);
  };

  const renderPartners = () => {
    if (partnersLoading) {
      return <Spin size="large" />;
    }
    if (currentPartners.length) {
      return (
        <div className="cards">
          {currentPartners.map((partner) => (
            <BusinessPartnerPreview
              key={partner.id}
              id={partner.id}
              image={partner.cover}
              name={`${partner.firstName} ${partner.lastName}`}
              phone={partner.phone}
              email={partner.email}
              deleteHandler={deleteHandler}
              clickHandler={clickHandler}
            />
          ))}
        </div>
      );
    }
    return <NoData />;
  };

  return (
    <div className="businessPartners">
      <h2 className="pageTitle">{t('Business Partners')}</h2>
      <div className="bar">
        <Search value={searchValue} onChange={onSearchChange} />
      </div>
      {renderPartners()}
    </div>
  );
};

export default BusinessPartners;
