import React from 'react';
import { renderAutopilotType } from '../../../utils/autopilots';

import './MessagePreview.scss';
import { getShortString } from '../../../utils/additional';

const MessagePreview = ({ type, cover, description, subject, clickHandler }) => (
  <div className="messagePreview" onClick={clickHandler}>
    <div className="messagePreview-imgWrapper">
      <img crossOrigin="anonymous" src={cover} alt={subject} />
    </div>
    <div className="messagePreview-content">
      <h4 className="messagePreview-title">
        <span title={subject}>{getShortString(subject, 20)}</span>
      </h4>
      <div className="messagePreview-description">{description}</div>
    </div>
    <div className="messagePreview-type">{renderAutopilotType(type)}</div>
  </div>
);

export default MessagePreview;
