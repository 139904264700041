import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';
import { renderTime } from '../../../utils/additional';

import './BusinessView.scss';
import CategoriesItem from '../../Categories/CategoriesItem/CategoriesItem';

const Marker = () => (
  <div className="marker">
    <Icon icon={ICONS.LOCATION} color="#FABB18" />
  </div>
);

const BusinessView = ({
  cover,
  title,
  description,
  schedule,
  phones,
  networks,
  address,
  preferences,
  products,
  editHandler,
  deleteHandler,
}) => {
  const { t } = useTranslation();
  return (
    <div className="businessView">
      <div className="businessView-banner">
        {cover && (
          <div className="businessView-img">
            <img crossOrigin="anonymous" src={cover} alt={title} />
          </div>
        )}
        <div className="businessView-content">
          <h3 className="businessView-title">{title}</h3>
          <div className="businessView-description">{description}</div>
          {/* <div className="businessView-todayWorkingHours"> */}
          {/*    <Icon icon={ICONS.CLOCK} size={16} color="#FABB18" /> */}
          {/* </div> */}
          {address && address.location ? (
            <div className="businessView-distance">
              <Icon icon={ICONS.LOCATION} size={16} color="#FABB18" />
              {address.location}
            </div>
          ) : null}
        </div>
        {products && products.length ? (
          <div className="businessView-cardsCount">
            {products.length}
            <span>{t('Cards')}</span>
          </div>
        ) : null}
      </div>
      <h3 className="businessView-infoTitle">{t('Info about Partner')}</h3>
      <div className="businessView-info">{description}</div>
      <div className="businessView-additional">
        {schedule && schedule.length ? (
          <div className="businessView-workingHours">
            <Icon icon={ICONS.CLOCK} size={16} color="#FABB18" />
            <table>
              <thead>
                <tr>
                  <th>{t('Day')}</th>
                  <th>{t('Working hours')}</th>
                  <th>{t('Lunch hours')}</th>
                </tr>
              </thead>
              <tbody>
                {schedule.map((scheduleItem, index) => (
                  <tr key={index}>
                    <td>{scheduleItem.day.toLowerCase()}</td>
                    <td>
                      {renderTime(scheduleItem.work[0])} - {renderTime(scheduleItem.work[1])}
                    </td>
                    <td>
                      {scheduleItem.lunch && renderTime(scheduleItem.lunch[0])} -{' '}
                      {scheduleItem.lunch && renderTime(scheduleItem.lunch[1])}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
        {phones && phones.length
          ? phones.map((phone, index) => (
              <div key={index} className="businessView-phone">
                <Icon icon={ICONS.PHONE} size={16} color="#FABB18" />
                {phone}
              </div>
            ))
          : null}
        {networks && networks.link ? (
          <div className="businessView-link">
            <Icon icon={ICONS.LINK} size={16} color="#FABB18" />
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="link"
              href={`https://${networks.link}`}
            >
              {networks.link}
            </a>
          </div>
        ) : null}
        {address && address.location ? (
          <div className="businessView-address">
            <Icon icon={ICONS.LOCATION} size={16} color="#FABB18" />
            {address.location}
          </div>
        ) : null}
      </div>
      {address && address.location ? (
        <div className="businessView-mapWrapper">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: 'AIzaSyAwIoxcAVRSvXdNZlbjRpCS72AAvMGk9lQ',
            }}
            defaultCenter={{
              lat: address.latitude,
              lng: address.longitude,
            }}
            defaultZoom={8}
          >
            <Marker lat={address.latitude} lng={address.longitude} />
          </GoogleMapReact>
        </div>
      ) : null}
      {preferences && preferences.length ? (
        <div className="categories businessView-categories">
          {preferences.map((preference) => (
            <CategoriesItem
              key={preference.id}
              name="preference"
              isPreview
              title={preference.title}
              value={preference.id}
              icon={preference.cover}
              readOnly
            />
          ))}
        </div>
      ) : null}
      <div className="businessView-actions">
        <button
          className="businessView-btn businessView-btn--edit"
          onClick={editHandler}
          title={t('Edit Business')}
        >
          <Icon icon={ICONS.EDIT} size={18} />
        </button>
        <button
          className="businessView-btn businessView-btn--delete"
          onClick={deleteHandler}
          title={t('Delete Business')}
        >
          <Icon icon={ICONS.TRASH} size={16} />
        </button>
      </div>
    </div>
  );
};

export default BusinessView;
