import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';

import en from 'antd/lib/locale-provider/en_US';
import it from 'antd/lib/locale-provider/it_IT';
import es from 'antd/lib/locale-provider/es_ES';
import de from 'antd/lib/locale-provider/de_DE';
import fr from 'antd/lib/locale-provider/fr_FR';

import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/fr';

import PrivateRoute from './router/PrivateRoute';
import { publicRoutes, authProtectedAdditionalRoutes } from './router';

import Main from './pages/main/Main';
import Modal from './components/Modal/Modal';

import './styles/antd.less';
import './styles/main.scss';

const App = () => {
  const { i18n } = useTranslation();

  document.dir = i18n.dir();
  document.documentElement.lang = i18n.language;

  let locale;

  switch (i18n.language) {
    case 'it':
      locale = it;
      break;
    case 'es':
      locale = es;
      break;
    case 'de':
      locale = de;
      break;
    case 'fr':
      locale = fr;
      break;
    default:
      locale = en;
  }

  moment.locale(i18n.language);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('../firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Registration successful, scope is:', registration.scope);
      })
      .catch((err) => {
        console.log('Service worker registration failed, error:', err);
      });
  }

  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <Route path="/main" component={Main} />
        {authProtectedAdditionalRoutes.map((route, idx) => (
          <PrivateRoute
            path={route.path}
            roles={route.roles}
            exact={route.exact}
            component={route.component}
            layout={route.layout}
            key={idx}
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route path={route.path} exact={route.exact} component={route.component} key={idx} />
        ))}
      </Switch>
      <Modal />
    </ConfigProvider>
  );
};

export default App;
