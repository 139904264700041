import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmModal = ({ question, cancelHandler, okHandler, cancelBtnText, okBtnText }) => {
  const { t } = useTranslation();

  return (
    <div className="confirmModal">
      <h4 className="confirmModal-question">{question}</h4>
      <div className="modal-actions">
        <button className="btn btn--small btn--secondary" onClick={cancelHandler}>
          {cancelBtnText || t('Cancel')}
        </button>
        <button className="btn btn--small btn--primary" onClick={okHandler}>
          {okBtnText || t('Delete')}
        </button>
      </div>
    </div>
  );
};

export default ConfirmModal;
