import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import BusinessMainInfo from '../BusinessMainInfo/BusinessMainInfo';
import Tabs from '../../Tabs/Tabs';
import SendInvitations from '../../SendInvitations/SendInvitations';
import {
  getFormattedBusinessGetData,
  getFormattedBusinessPostData,
} from '../../../utils/businesses';
import {
  createBusinesses,
  updateBusiness,
  cleanLastCreatedBusiness,
} from '../../../redux/actions/businesses';
import { cleanLastCreatedProduct } from '../../../redux/actions/products';
import { hideModal, showModal } from '../../../redux/actions/modal';
import { PRODUCT_TYPES, MODAL_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';
import ProductEditForm from '../../Product/ProductForms/ProductEditForm';
import ProductAddForm from '../../Product/ProductForms/ProductAddForm';

import './BusinessForm.scss';

const BusinessAddForm = ({ title = 'Add Business PartnerProfile' }) => {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  // tabs

  const [activeTab, setActiveTab] = useState(1);

  const handleTabNavigation = (id) => {
    setActiveTab(id);
  };

  const [disabledTab, setDisabledTab] = useState([2, 3]);

  const tabs = [
    {
      id: 1,
      text: '1',
    },
    {
      id: 2,
      text: '2',
    },
    {
      id: 3,
      text: '3',
    },
  ];

  // prefill data for existing business

  const [businessData, setBusinessData] = useState({});
  const { lastCreatedBusinessId, items: businesses } = useSelector((state) => state.businesses);

  useEffect(() => {
    if (!lastCreatedBusinessId) {
      return;
    }
    const data = businesses.find((business) => business.id === lastCreatedBusinessId);
    if (data) {
      const formattedData = getFormattedBusinessGetData(data);
      setBusinessData(formattedData);
    }
  }, [lastCreatedBusinessId, businesses]);

  // create business

  const [createBusinessLoading, setCreateBusinessLoading] = useState(false);
  const [onCreateBusinessErrors, setOnCreateBusinessErrors] = useState([]);

  const createBusinessHandler = (data) => {
    // setCreateBusinessLoading(true);
    const formattedData = getFormattedBusinessPostData(data);
    if (lastCreatedBusinessId !== null) {
      dispatch(
        updateBusiness({
          id: lastCreatedBusinessId,
          businessData: formattedData,
          onSuccessCallback: () => {
            setCreateBusinessLoading(false);
            setActiveTab(2);
            setDisabledTab([3]);
          },
          onErrorCallback: (errors) => {
            setCreateBusinessLoading(false);
            setOnCreateBusinessErrors(getCorrectErrorMessages(errors));
          },
        }),
      );
    } else {
      dispatch(
        createBusinesses({
          businessData: formattedData,
          onSuccessCallback: () => {
            setCreateBusinessLoading(false);
            setActiveTab(2);
            setDisabledTab([3]);
          },
          onErrorCallback: (errors) => {
            setCreateBusinessLoading(false);
            setOnCreateBusinessErrors(getCorrectErrorMessages(errors));
          },
        }),
      );
    }
  };

  const { lastCreatedProductId } = useSelector((state) => state.products);

  // callback after passing form

  const finishFormHandler = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.NOTIFICATION,
        title: t('You have completed the  business profile basic settings!'),
        description: t('Please go through the rest of the in the BP profile'),
        onClose: () => {
          history.push('/main');
          dispatch(hideModal());
        },
      }),
    );
  };

  // cleaning function on unmounting form

  useEffect(
    () =>
      function cleanup() {
        // set lastCreatedBusinessId to null
        dispatch(cleanLastCreatedBusiness());
        // set lastCreatedProductId to null
        dispatch(cleanLastCreatedProduct());
      },
    [],
  );

  return (
    <div className="businessForm-wrapper">
      <div className="businessForm-container">
        <h2 className="pageTitle">{t(title)}</h2>
        <Tabs
          activeTab={activeTab}
          tabs={tabs}
          onTabClick={handleTabNavigation}
          isSteps
          disabledTab={disabledTab}
        />
        {activeTab === 1 && (
          <BusinessMainInfo
            title={t('Main Info about Business Profile')}
            submitBtnText={t('Next step')}
            defaultValues={businessData}
            submitHandler={createBusinessHandler}
            onSubmitErrors={onCreateBusinessErrors}
            onSubmitLoading={createBusinessLoading}
          />
        )}
        {activeTab === 2 && (
          <div className="card-tab">
            {lastCreatedProductId !== null ? (
              <ProductEditForm
                id={lastCreatedProductId}
                skip
                skipHandler={() => {
                  setActiveTab(3);
                  setDisabledTab([]);
                }}
                onSuccessCallback={() => {
                  setActiveTab(3);
                  setDisabledTab([]);
                }}
              />
            ) : (
              <ProductAddForm
                skip
                forcedProductType={PRODUCT_TYPES.CARD}
                skipHandler={() => {
                  setActiveTab(3);
                  setDisabledTab([]);
                }}
                onSuccessCallback={() => {
                  setActiveTab(3);
                  setDisabledTab([]);
                }}
              />
            )}
          </div>
        )}
        {activeTab === 3 && (
          <SendInvitations
            skip
            isSingle
            businessId={lastCreatedBusinessId}
            cancelHandler={finishFormHandler}
            onInvitationsSent={finishFormHandler}
          />
        )}
      </div>
    </div>
  );
};

export default BusinessAddForm;
