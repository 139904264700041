import { GET_NEWSLETTERS, CREATE_NEWSLETTER, UPDATE_NEWSLETTER, DELETE_NEWSLETTER } from '../types';

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_NEWSLETTERS:
      return { ...state, items: action.payload };
    case CREATE_NEWSLETTER:
      return { ...state, items: [...state.items, action.payload] };
    case UPDATE_NEWSLETTER:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id);
      const newItems = [...state.items];
      newItems[itemIndex] = { ...action.payload };
      return { ...state, items: newItems };
    case DELETE_NEWSLETTER:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload) };
    default:
      return state;
  }
}
