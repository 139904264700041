import {
  GET_CUSTOMER_STATISTICS,
  GET_DETAILS_STATISTICS,
  GET_GENDER_STATISTICS,
  GET_AGE_STATISTICS,
  GET_LINES_STATISTICS,
  GET_ADMIN_STATISTICS,
} from '../types';

const initialState = {
  customerStatistics: {},
  detailsStatistics: {},
  genderStatistics: {},
  ageStatistics: {},
  linesStatistics: {},
  adminStatistics: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_STATISTICS:
      return { ...state, customerStatistics: action.payload };
    case GET_DETAILS_STATISTICS:
      return { ...state, detailsStatistics: action.payload };
    case GET_GENDER_STATISTICS:
      return { ...state, genderStatistics: action.payload };
    case GET_AGE_STATISTICS:
      return { ...state, ageStatistics: action.payload };
    case GET_LINES_STATISTICS:
      return { ...state, linesStatistics: action.payload };
    case GET_ADMIN_STATISTICS:
      return { ...state, adminStatistics: action.payload };
    default:
      return state;
  }
}
