import {
  REQUEST_GET_PRODUCTS,
  REQUEST_DELETE_PRODUCTS,
  REQUEST_CREATE_PRODUCTS,
  REQUEST_UPDATE_PRODUCTS,
  CLEAN_LAST_CREATED_PRODUCT,
} from '../types';

export function getProducts(payload) {
  return {
    type: REQUEST_GET_PRODUCTS,
    payload,
  };
}

export function deleteProduct(payload) {
  return {
    type: REQUEST_DELETE_PRODUCTS,
    payload,
  };
}

export function updateProduct(payload) {
  return {
    type: REQUEST_UPDATE_PRODUCTS,
    payload,
  };
}

export function createProduct(payload) {
  return {
    type: REQUEST_CREATE_PRODUCTS,
    payload,
  };
}

export function cleanLastCreatedProduct(payload) {
  return {
    type: CLEAN_LAST_CREATED_PRODUCT,
    payload,
  };
}
