import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Calendar } from 'antd';
import moment from 'moment';
import ProductPreview from '../../Product/ProductPreview/ProductPreview';

import './CalendarModal.scss';

const DATE_FORMAT = 'MMM DD, YYYY';
const MONTH_FORMAT = 'MMMM YYYY';

function getReformattedCards(cards) {
  const result = [];
  cards.forEach((card) => {
    const month = moment(card.period[1]).format(MONTH_FORMAT);
    const date = moment(card.period[1]).format(DATE_FORMAT);
    if (!result[month]) {
      result[month] = [card];
    } else {
      result[month].push(card);
    }

    if (!result[date]) {
      result[date] = [card];
    } else {
      result[date].push(card);
    }
  });
  return result;
}

function dateCellRender(date, reformattedCards) {
  const dateString = date.format(DATE_FORMAT);

  if (reformattedCards[dateString]) {
    const count = reformattedCards[dateString].length;
    return <div className="cardsCount">{count}</div>;
  }
}

function monthCellRender(date, reformattedCards) {
  const monthString = date.format(MONTH_FORMAT);
  if (reformattedCards[monthString]) {
    const count = reformattedCards[monthString].length;
    return <div className="cardsCount">{count}</div>;
  }
}

const CalendarModal = ({ cards }) => {
  const { t } = useTranslation();

  const reformattedCards = getReformattedCards(cards);

  const [currentMode, setCurrentMode] = useState('month');
  const [currentCards, setCurrentCards] = useState(
    reformattedCards[moment().format(DATE_FORMAT)] || [],
  );
  const [currentDate, setCurrentDate] = useState(moment().format(DATE_FORMAT) || '');

  const setDateAndCards = (date, mode) => {
    const dateString = mode === 'month' ? date.format(DATE_FORMAT) : date.format(MONTH_FORMAT);
    setCurrentDate(dateString);
    if (reformattedCards[dateString]) {
      setCurrentCards(reformattedCards[dateString]);
    } else {
      setCurrentCards([]);
    }
  };

  const onSelect = (date) => {
    setDateAndCards(date, currentMode);
  };

  const onPanelChange = (date, mode) => {
    setCurrentMode(mode);
    setDateAndCards(date, mode);
  };

  return (
    <div className="calendarModal">
      <div className="calendarModal-calendar">
        <Calendar
          fullscreen={false}
          dateCellRender={(date) => dateCellRender(date, reformattedCards)}
          monthCellRender={(date) => monthCellRender(date, reformattedCards)}
          onSelect={onSelect}
          onPanelChange={onPanelChange}
          defaultValue={moment()}
        />
      </div>
      <div className="cardsExpire">
        <div className="cardsExpire-header">
          <div className="cardsExpire-date">{currentDate}</div>
          <div className="cardsExpire-count">
            {currentCards.length
              ? `${currentCards.length} ${t('Cards Expire')}`
              : t('No Expiring Cards')}
          </div>
        </div>
        <div className="cardsExpire-cards">
          {currentCards.length
            ? currentCards.map((card) => (
                <ProductPreview
                  key={card.id}
                  title={card.title}
                  cover={card.cover}
                  miniCover={card.miniCover}
                  period={card.period}
                  expirationDays={card.expirationDays}
                  withDeleteBtn={false}
                />
              ))
            : ''}
        </div>
      </div>
    </div>
  );
};

export default CalendarModal;
