import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Logo from '../Logo/Logo';
import NotificationBtn from './NotificationBtn/NotificationBtn';

import './Header.scss';
import LanguagePicker from '../LanguagePicker/LanguagePicker';

const Header = ({ isSimple }) => {
  const { firstName, lastName } = useSelector((state) => state.profile.data);

  let fullName = '';

  if (firstName && lastName) {
    fullName = `${firstName} ${lastName}`;
  }

  return (
    <header className="header">
      <div className="header-container">
        <div className="header-left">
          <Logo isLink />
        </div>
        <div className="header-right">
          <LanguagePicker />
          {!isSimple && (
            <>
              <NotificationBtn />
              {fullName && (
                <div className="userInfo">
                  <p className="userInfo-name">{fullName}</p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  isSimple: PropTypes.bool,
};

Header.defaultProps = {
  isSimple: false,
};

export default Header;
