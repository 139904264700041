import React from 'react';
import Header from '../Header/Header';

const LayoutHeader = ({ children }) => (
  <div className="wrapper">
    <Header isSimple />
    <main className="main">
      <div className="container">{children}</div>
    </main>
  </div>
);

export default LayoutHeader;
