import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';

import './NotificationBtn.scss';

const NotificationBtn = () => {
  const { unreadMessagesCount } = useSelector((state) => state.messages);

  return (
    <NavLink className="notificationBtn" to="/main/notifications" activeClassName="selected">
      {unreadMessagesCount > 0 && (
        <span className="notificationBtn-count">{unreadMessagesCount}</span>
      )}
      <Icon className="notificationBtn-icon" icon={ICONS.BELL} color="#000000" />
    </NavLink>
  );
};

export default NotificationBtn;
