import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { hideModal } from '../../../redux/actions/modal';
import { buySlot } from '../../../redux/actions/slots';
import { getCorrectErrorMessages } from '../../../utils/errors';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';

const SlotBuy = ({ slotId, onSuccessCallback }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const [onSubmitLoading, setOnSubmitLoading] = useState(false);
  const [onSubmitErrors, setOnSubmitErrors] = useState([]);

  const submitHandler = (slotId) => {
    setOnSubmitLoading(true);
    dispatch(
      buySlot({
        id: slotId,
        onSuccessCallback: () => {
          setOnSubmitLoading(false);
          onSuccessCallback();
        },
        onErrorCallback: (errors) => {
          setOnSubmitLoading(false);
          setOnSubmitErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="confirmModal">
      <h4 className="confirmModal-question">{t('Are you sure you want to buy slot?')}</h4>
      <ErrorMessages errorMessages={onSubmitErrors} />
      <Spin spinning={onSubmitLoading}>
        <div className="modal-actions">
          <button className="btn btn--small btn--secondary" onClick={cancelHandler}>
            {t('No')}
          </button>
          <button className="btn btn--small btn--primary" onClick={() => submitHandler(slotId)}>
            {t('Yes')}
          </button>
        </div>
      </Spin>
    </div>
  );
};

export default SlotBuy;
