import React from 'react';
import { useTranslation } from 'react-i18next';

import Icon from '../../Icon';
import { ICONS } from '../../../constants';

import './FieldError.scss';

const FieldError = ({ error, className }) => {
  const { t } = useTranslation();

  const classes = ['field-error-wrapper', className];

  return error && error.message ? (
    <div className={classes.join(' ')}>
      <Icon className="field-error-icon" icon={ICONS.ALERT} size={14} color="#FF3B30" />
      <span className="field-error-text">{t(error.message)}</span>
    </div>
  ) : null;
};

export default FieldError;
