import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Icon from '../Icon';
import { PRODUCT_TYPES, ICONS } from '../../constants';

import BusinessPreview from '../Business/BusinessPreview/BusinessPreview';
import NoData from '../NoData/NoData';
import FieldError from '../HookedField/FieldError/FieldError';

const ProductChooser = ({ defaultValue, productType }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const onBtnClick = () => {
    setIsOpen(!isOpen);
  };

  const products = useSelector((state) => state.products.items);

  const [currentProducts, setCurrentProducts] = useState([]);
  const [chosenProducts, setChosenProducts] = useState(defaultValue || []);

  useEffect(() => {
    setValue('products', []);
    setChosenProducts(
      (defaultValue && defaultValue.filter((product) => product.type === productType)) || [],
    );
    setCurrentProducts(products.filter((product) => product.type === productType));
  }, [products, productType, defaultValue]);

  const [searchValue, setSearchValue] = useState('');

  const searchHandler = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const filteredProducts = products.filter(
      (product) =>
        product.type === productType &&
        product.title.trim().toLowerCase().includes(searchValue.trim().toLowerCase()),
    );
    setCurrentProducts(filteredProducts);
  }, [searchValue]);

  const chooseHandler = (id) => {
    const productIndex = chosenProducts.findIndex((product) => product.id === id);
    if (productIndex !== -1) {
      const filteredProducts = [...chosenProducts];
      filteredProducts.splice(productIndex, 1);
      setChosenProducts(filteredProducts);
    } else {
      setChosenProducts([...chosenProducts, { id }]);
    }
  };

  const checkIsChosen = (id) => chosenProducts.some((product) => product.id === id);

  const { register, setValue, errors, clearErrors, setError } = useFormContext();

  useEffect(() => {
    register('products');
  }, []);

  useEffect(() => {
    setValue('products', chosenProducts);
    if (chosenProducts.length > 0) {
      clearErrors('products');
    } else {
      setError('products', t('Pick at least one'));
    }
  }, [chosenProducts]);

  return (
    <div className="businessChooser">
      {!isOpen ? (
        <>
          <div className="businessChooser-headline">
            <span className="businessChooser-label">
              {productType === PRODUCT_TYPES.CARD ? t('Cards') : t('Gifts')}
            </span>
            {chosenProducts.length > 0 && (
              <span className="businessChooser-edit" onClick={onBtnClick}>
                {t('Edit')}
              </span>
            )}
          </div>
          {!chosenProducts.length && (
            <button type="button" className="businessChooser-btn" onClick={onBtnClick}>
              Choose {productType === PRODUCT_TYPES.CARD ? t('Cards') : t('Gifts')}
              <Icon icon={ICONS.ARROW_RIGHT} color="#FABB18" size={20} />
            </button>
          )}
          {chosenProducts.length > 0 &&
            products
              .filter(
                (product) =>
                  product.type === productType &&
                  chosenProducts.some((chosenProduct) => chosenProduct.id === product.id),
              )
              .map(({ id, title, cover, description }) => (
                <BusinessPreview key={id} title={title} cover={cover} description={description} />
              ))}
        </>
      ) : (
        <div className="field businessChooser-search">
          <div className="field-wrapper">
            <Icon
              className="businessChooser-search-icon"
              icon={ICONS.SEARCH}
              color="#FABB18"
              size={20}
            />
            <input
              type="text"
              name="search"
              placeholder={t('Type to search')}
              value={searchValue}
              onChange={searchHandler}
            />
            <button className="businessChooser-search-close" onClick={onBtnClick}>
              <Icon icon={ICONS.ARROW_UP} color="#FABB18" size={20} />
            </button>
            <div className="field-border" />
          </div>
        </div>
      )}
      {isOpen && (
        <ul className="businessChooser-list">
          {currentProducts.length ? (
            currentProducts.map(({ id, title, cover, description }) => (
              <li key={id} className="businessChooser-item">
                <BusinessPreview
                  title={title}
                  cover={cover}
                  description={description}
                  clickHandler={() => chooseHandler(id)}
                  isChosen={checkIsChosen(id)}
                />
              </li>
            ))
          ) : (
            <NoData />
          )}
        </ul>
      )}
      <FieldError error={errors.products} />
    </div>
  );
};

export default ProductChooser;
