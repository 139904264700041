import { getMillisecondsToTime, getTimeToMilliseconds } from './additional';

export const getFormattedBusinessGetData = (data) => {
  const formattedData = {};
  // title
  if (data.title) {
    formattedData.title = data.title;
  }
  // description
  if (data.description) {
    formattedData.description = data.description;
  }
  // additional
  if (data.additional) {
    formattedData.additional = data.additional;
  }
  // email
  if (data.email) {
    formattedData.email = data.email;
  }
  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  } else {
    formattedData.cover = null;
  }
  // miniCover
  if (data.miniCover) {
    formattedData.miniCover = data.miniCover;
  } else {
    formattedData.miniCover = null;
  }
  // logo
  if (data.logo) {
    formattedData.logo = data.logo;
  } else {
    formattedData.logo = null;
  }
  // images
  if (data.images && data.images.length) {
    // image1
    if (data.images[0]) {
      formattedData.image1 = data.images[0];
    } else {
      formattedData.image1 = null;
    }
    // image2
    if (data.images[1]) {
      formattedData.image2 = data.images[1];
    } else {
      formattedData.image2 = null;
    }
    // image3
    if (data.images[2]) {
      formattedData.image3 = data.images[2];
    } else {
      formattedData.image3 = null;
    }
  }
  // phones
  if (data.phones && data.phones.length) {
    const phones = data.phones.map((phone) => ({
      value: phone,
    }));
    if (phones && phones.length) {
      formattedData.phones = phones;
    }
  }
  // preferences
  if (data.preferences && data.preferences.length) {
    formattedData.preferences = data.preferences.map((preference) => ({
      id: preference.id,
    }));
  }
  // networks
  if (data.networks) {
    // link
    if (data.networks.link) {
      formattedData.link = data.networks.link;
    }
    // facebook
    if (data.networks.facebook) {
      formattedData.facebook = data.networks.facebook.replace('https://facebook.com/', '');
    }
    // instagram
    if (data.networks.instagram) {
      formattedData.instagram = data.networks.instagram.replace('https://instagram.com/', '');
    }
    // youtube
    if (data.networks.youtube) {
      formattedData.youtube = data.networks.youtube.replace('https://youtube.com/user/', '');
    }
    // twitter
    if (data.networks.twitter) {
      formattedData.twitter = data.networks.twitter.replace('https://twitter.com/', '');
    }
  }
  // address
  if (data.address && Object.keys(data.address).length) {
    formattedData.address = data.address;
  }
  // schedule
  if (data.schedule && data.schedule.length) {
    let isWorkHoursSet = false;
    let isLunchHoursSet = false;
    const weekDays = [];
    let workHours = [];
    let lunchHours = [];
    const custom = [];

    data.schedule.forEach((item) => {
      if (item.day) {
        weekDays.push(item.day);
      }
      if (!item.custom && item.work && !isWorkHoursSet) {
        workHours = item.work.map((workItem) => getMillisecondsToTime(workItem));
        isWorkHoursSet = true;
      }

      if (!item.custom && item.lunch && item.lunch.length && !isLunchHoursSet) {
        lunchHours = item.lunch.map((lunchItem) => getMillisecondsToTime(lunchItem));
        isLunchHoursSet = true;
      }

      let lunch = [];

      if (item.custom && item.lunch && item.lunch.length) {
        lunch = item.lunch.map((lunchItem) => getMillisecondsToTime(lunchItem));
      }

      if (item.custom) {
        custom.push({
          day: item.day,
          work: item.work.map((workItem) => getMillisecondsToTime(workItem)),
          lunch,
        });
      }
    });
    // weekDays
    if (weekDays && weekDays.length) {
      formattedData.weekDays = weekDays;
    }
    // workHours
    if (workHours && workHours.length) {
      formattedData.workHours = workHours;
    }
    // lunchHours
    if (lunchHours && lunchHours.length) {
      formattedData.lunchHours = lunchHours;
    }
    // custom
    if (custom && custom.length) {
      formattedData.custom = custom;
    }
  }

  return formattedData;
};

export const getFormattedBusinessPostData = (data) => {
  const formattedData = {};
  // title
  if (data.title) {
    formattedData.title = data.title;
  }
  // description
  if (data.description) {
    formattedData.description = data.description;
  }
  // additional
  if (data.additional) {
    formattedData.additional = data.additional;
  }
  // email
  if (data.email) {
    formattedData.email = data.email;
  }
  // phones
  if (data.phones && data.phones.length) {
    const phones = [];
    data.phones.forEach((phone) => {
      if (phone.value) {
        phones.push(phone.value.replace(/\s/g, '', ''));
      }
    });
    if (phones.length) {
      formattedData.phones = phones;
    }
  }
  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  } else {
    formattedData.cover = null;
  }
  // miniCover
  if (data.miniCover) {
    formattedData.miniCover = data.miniCover;
  } else {
    formattedData.miniCover = null;
  }
  // logo
  if (data.logo) {
    formattedData.logo = data.logo;
  } else {
    formattedData.logo = null;
  }
  // image1
  if (data.image1) {
    if (!formattedData.images) formattedData.images = [];
    formattedData.images.push(data.image1);
  }
  // image2
  if (data.image2) {
    if (!formattedData.images) formattedData.images = [];
    formattedData.images.push(data.image2);
  }
  // image3
  if (data.image3) {
    if (!formattedData.images) formattedData.images = [];
    formattedData.images.push(data.image3);
  }
  // preferences
  if (data.preferences && data.preferences.length) {
    formattedData.preferences = data.preferences;
  }
  // link
  if (data.link) {
    if (!formattedData.networks) formattedData.networks = {};
    formattedData.networks.link = data.link;
  }
  // facebook
  if (data.facebook) {
    if (!formattedData.networks) formattedData.networks = {};
    formattedData.networks.facebook = data.facebook;
  }
  // instagram
  if (data.instagram) {
    if (!formattedData.networks) formattedData.networks = {};
    formattedData.networks.instagram = data.instagram;
  }
  // youtube
  if (data.youtube) {
    if (!formattedData.networks) formattedData.networks = {};
    formattedData.networks.youtube = data.youtube;
  }
  // twitter
  if (data.twitter) {
    if (!formattedData.networks) formattedData.networks = {};
    formattedData.networks.twitter = data.twitter;
  }
  // address
  if (data.address && Object.keys(data.address).length) {
    formattedData.address = data.address;
  }
  // schedule
  if (data.weekDays && data.weekDays.length) {
    const commonWorkHours = data.workHours.map((item) => getTimeToMilliseconds(item));

    // lunchHours
    let commonLunchHours = null;
    if (data.lunchHours && data.lunchHours.length) {
      commonLunchHours = data.lunchHours.map((item) => getTimeToMilliseconds(item));
    }

    const schedule = data.weekDays.map((day) => ({
      day,
      work: commonWorkHours,
      lunch: commonLunchHours,
      custom: false,
    }));

    if (data.custom && data.custom.length) {
      data.custom.forEach((customItem) => {
        const index = schedule.findIndex((scheduleItem) => customItem.day === scheduleItem.day);
        let lunch = null;
        if (customItem.lunch && customItem.lunch.length) {
          lunch = customItem.lunch.map((item) => getTimeToMilliseconds(item));
        }
        const customData = {
          day: customItem.day,
          work: customItem.work.map((item) => getTimeToMilliseconds(item)),
          lunch,
          custom: true,
        };
        if (index !== -1) {
          schedule[index] = { ...schedule[index], ...customData };
        } else {
          schedule.push({ ...customData });
        }
      });
    }

    if (schedule.length) {
      formattedData.schedule = schedule;
    }
  }

  return formattedData;
};
