import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROLES } from '../constants';
import Api from '../redux/middlewares/Api';
import CreateBusinessProfile from '../pages/auth/CreateBusinessProfile';

export default function PrivateRoute({
  component: Component,
  layout: Layout,
  roles,
  location,
  ...rest
}) {
  const { role } = useSelector((state) => state.profile.data);

  return (
    <Route
      {...rest}
      render={(props) => {
        // unauthorized

        if (!Api.getToken()) {
          return (
            <Redirect
              to={{
                pathname: '/sign-in',
                state: { from: props.location },
              }}
            />
          );
        }

        if (!role) {
          if (location.pathname.indexOf('/create-business-profile') === -1) {
            return null;
          }
          return <CreateBusinessProfile {...props} />;
        }

        if (role !== ROLES.ADMIN && role !== ROLES.PARTNER) {
          Api.removeToken();
          return <Redirect to={{ pathname: '/sign-in' }} />;
        }

        // check role

        if (roles && roles.indexOf(role) === -1) {
          return <Redirect to={{ pathname: '/main' }} />;
        }

        if (Layout) {
          return (
            <Layout>
              <Component {...props} />
            </Layout>
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}
