import {
  REQUEST_GET_SLOTS,
  REQUEST_CREATE_SLOT,
  REQUEST_UPDATE_SLOT,
  REQUEST_DELETE_SLOT,
  REQUEST_BUY_SLOT,
} from '../types';

export function getSlots(payload) {
  return {
    type: REQUEST_GET_SLOTS,
    payload,
  };
}

export function createSlot(payload) {
  return {
    type: REQUEST_CREATE_SLOT,
    payload,
  };
}

export function updateSlot(payload) {
  return {
    type: REQUEST_UPDATE_SLOT,
    payload,
  };
}

export function deleteSlot(payload) {
  return {
    type: REQUEST_DELETE_SLOT,
    payload,
  };
}

export function buySlot(payload) {
  return {
    type: REQUEST_BUY_SLOT,
    payload,
  };
}
