import { REQUEST_GET_PROFILE, REQUEST_UPDATE_PROFILE, REQUEST_DELETE_PROFILE } from '../types';

export function getProfile(payload) {
  return {
    type: REQUEST_GET_PROFILE,
    payload,
  };
}

export function updateProfile(payload) {
  return {
    type: REQUEST_UPDATE_PROFILE,
    payload,
  };
}

export function deleteProfile(payload) {
  return {
    type: REQUEST_DELETE_PROFILE,
    payload,
  };
}
