import React from 'react';

import { useSelector } from 'react-redux';

import { ROLES } from '../../constants';

import PartnerNotifications from '../../components/NotificationPage/PartnerNotifications';
import AdminNotifications from '../../components/NotificationPage/AdminNotifications';

const NotificationsPage = () => {
  const { role } = useSelector((state) => state.profile.data);

  const renderNotificationPage = () => {
    switch (role) {
      case ROLES.PARTNER:
        return <PartnerNotifications />;
      case ROLES.ADMIN:
        return <AdminNotifications />;
      default:
        return null;
    }
  };

  return renderNotificationPage();
};

export default NotificationsPage;
