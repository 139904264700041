import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { Select, Radio, Spin } from 'antd';

import {
  COLORS,
  GENDERS,
  ICONS,
  PIE_CHART_VIEWS,
  LINE_CHART_STATUSES,
  LINE_CHART_PERIODS,
} from '../../../../constants';
import { getCapitalized } from '../../../../utils/additional';

import {
  getAgeStatistics,
  getGenderStatistics,
  getLinesStatistics,
} from '../../../../redux/actions/statistics';
import NoData from '../../../NoData/NoData';
import Icon from '../../../Icon';

import './Charts.scss';

const Charts = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const { genderStatistics, ageStatistics, linesStatistics } = useSelector(
    (state) => state.statistics,
  );

  const [onLoadingPieChartStatistics, setOnLoadingPieChartStatistics] = useState(false);

  const [genderData, setGenderData] = useState([]);
  const [ageData, setAgeData] = useState([]);

  const [pieChartView, setPieChartView] = useState(PIE_CHART_VIEWS.AGE);

  const onPieChartViewChange = (event) => {
    setPieChartView(event.target.value);
  };

  useEffect(() => {
    if (pieChartView === PIE_CHART_VIEWS.GENDER) {
      setOnLoadingPieChartStatistics(true);
      dispatch(
        getGenderStatistics({
          onSuccessCallback: () => setOnLoadingPieChartStatistics(false),
          onErrorCallback: () => setOnLoadingPieChartStatistics(false),
        }),
      );
    } else if (pieChartView === PIE_CHART_VIEWS.AGE) {
      setOnLoadingPieChartStatistics(true);
      dispatch(
        getAgeStatistics({
          onSuccessCallback: () => setOnLoadingPieChartStatistics(false),
          onErrorCallback: () => setOnLoadingPieChartStatistics(false),
        }),
      );
    }
  }, [pieChartView, i18n.language]);

  const getGenderData = (genderStatistics) => {
    if (genderStatistics && genderStatistics.length) {
      const genderData = [
        { id: GENDERS.MALE, value: 0, name: t("Man")},
        { id: GENDERS.FEMALE, value: 0, name: t("Female") },
        { id: GENDERS.OTHER, value: 0, name: t("Other") },
      ];

      genderStatistics.forEach((data) => {
        const idx = genderData.findIndex((item) => item.id === data.gender);
        genderData[idx].value = data.total
      });

      return genderData;
    }
    return [];
  };

  useEffect(() => {
    setGenderData(getGenderData(genderStatistics));
  }, [genderStatistics]);

  const getAgeData = (ageStatistics) => {
    if (ageStatistics && ageStatistics.length && ageStatistics[0].group !== null) {
      const ageData = [
        { name: `10-20 ${t('y.o.')}`, value: 0 },
        { name: `20-30 ${t('y.o.')}`, value: 0 },
        { name: `30-40 ${t('y.o.')}`, value: 0 },
        { name: `40-50 ${t('y.o.')}`, value: 0 },
        { name: `60-70 ${t('y.o.')}`, value: 0 },
        { name: `70-80 ${t('y.o.')}`, value: 0 },
      ];
      ageStatistics.forEach((data) => {
        if (data.group !== null) {
          ageData[data.group] = {
            ...ageData[data.group],
            value: data.total,
          };
        }
      });
      return ageData;
    }
    return [];
  };

  useEffect(() => {
    setAgeData(getAgeData(ageStatistics));
  }, [ageStatistics]);

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul className="customLegend">
        {payload.map((entry, index) => (
          <li className="customLegend-item" key={`item-${index}`}>
            <span className="customLegend-icon" style={{ backgroundColor: entry.color }} />
            <span className="customLegend-text">{getCapitalized(entry.value)}</span>
            <span className="customLegend-value">
              {entry.payload.value} {t('ppl')}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  const renderPieChart = () => {
    if (
      (pieChartView === PIE_CHART_VIEWS.AGE && ageData.length) ||
      (pieChartView === PIE_CHART_VIEWS.GENDER && genderData.length)
    ) {
      const data = pieChartView === PIE_CHART_VIEWS.AGE ? ageData : genderData;
      return (
        <PieChart>
          <Legend layout="vertical" align="right" verticalAlign="middle" content={renderLegend} />
          <Pie
            data={data}
            cx={300}
            cy={200}
            labelLine={false}
            outerRadius={165}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      );
    }
    if (onLoadingPieChartStatistics) {
      return <></>;
    }
    return <NoData />;
  };

  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartCards, setLineChartCards] = useState([]);
  const [lineChartPeriod, setLineChartPeriod] = useState(LINE_CHART_PERIODS.MONTH);
  const [lineChartStatus, setLineChartStatus] = useState(LINE_CHART_STATUSES.OPEN);
  const [lineChartLoading, setLineChartLoading] = useState(false);

  useEffect(() => {
    if (lineChartPeriod && lineChartStatus) {
      setLineChartLoading(true);
      dispatch(
        getLinesStatistics({
          period: lineChartPeriod,
          status: lineChartStatus,
          onSuccessCallback: () => setLineChartLoading(false),
          onErrorCallback: () => setLineChartLoading(false),
        }),
      );
    }
  }, [lineChartPeriod, lineChartStatus, i18n.language]);

  const getLineCards = () => {
    if (linesStatistics && linesStatistics.length) {
      const cards = [];
      linesStatistics.forEach((item) => {
        if (cards.indexOf(item.title) === -1) {
          cards.push(item.title);
        }
      });
      return cards;
    }
    return [];
  };

  const getLineData = () => {
    if (linesStatistics && linesStatistics.length) {
      const data = {};

      linesStatistics.forEach((item) => {
        if (!item.title) {
        } else if (!data[item.createdAt]) {
          data[item.createdAt] = {
            [item.title]:
              lineChartStatus === LINE_CHART_STATUSES.COLLECTED
                ? parseInt(item.points)
                : parseInt(item.total),
          };
        } else {
          data[item.createdAt][item.title] =
            lineChartStatus === LINE_CHART_STATUSES.COLLECTED
              ? parseInt(item.points)
              : parseInt(item.total);
        }
      });

      const formattedData = [];

      for (const [key, value] of Object.entries(data)) {
        formattedData.push({
          name: moment(key).format('D MMM'),
          ...value,
        });
      }
      return formattedData;
    }
    return [];
  };

  useEffect(() => {
    setLineChartData(getLineData(linesStatistics));
    setLineChartCards(getLineCards(linesStatistics));
  }, [linesStatistics]);

  const renderLineChart = () => {
    if (lineChartData && lineChartData.length) {
      return (
        <LineChart
          data={lineChartData}
          margin={{
            top: 90,
            right: 10,
          }}
        >
          <Legend
            align="left"
            iconSize={12}
            iconType="circle"
            wrapperStyle={{
              top: 20,
            }}
          />
          <CartesianGrid stroke="#F0F0F0" strokeWidth={1} />
          <XAxis axisLine={{ stroke: '#F0F0F0', strokeWidth: 1 }} tickLine={false} dataKey="name" />
          <YAxis axisLine={{ stroke: '#F0F0F0', strokeWidth: 1 }} tickLine={false} />
          <Tooltip
            contentStyle={{
              border: 'none',
              boxShadow: '0px 4px 20px rgba(30, 30, 52, 0.05)',
            }}
          />
          {lineChartCards && lineChartCards.length
            ? lineChartCards.map((card, index) => (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={card}
                  stroke={COLORS[index % COLORS.length]}
                  dot={{
                    fill: COLORS[index % COLORS.length],
                    r: 4,
                  }}
                  activeDot={{ r: 5 }}
                />
              ))
            : null}
        </LineChart>
      );
    }
    if (lineChartLoading) {
      return <></>;
    }
    return <NoData />;
  };

  return (
    <div className="charts">
      <div className="chart-wrapper">
        <h3>{t('Distribution')}</h3>
        <div className="sorters">
          <Radio.Group onChange={onPieChartViewChange} value={pieChartView}>
            <Radio name="view" value={PIE_CHART_VIEWS.AGE}>
              {t('by age')}
            </Radio>
            <Radio name="view" value={PIE_CHART_VIEWS.GENDER}>
              {t('by gender')}
            </Radio>
          </Radio.Group>
        </div>
        <Spin spinning={onLoadingPieChartStatistics}>
          <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
            {renderPieChart()}
          </ResponsiveContainer>
        </Spin>
      </div>
      <div className="chart-wrapper">
        <div className="chart-header">
          <Select
            className="chart-title"
            showArrow
            defaultValue={lineChartStatus}
            onChange={setLineChartStatus}
            suffixIcon={<Icon size={20} icon={ICONS.ARROW_DOWN} color="#000000" />}
            options={[
              {
                value: LINE_CHART_STATUSES.OPEN,
                label: t('Open Cards'),
              },
              {
                value: LINE_CHART_STATUSES.COLLECTED,
                label: t('Collected points'),
              },
              {
                value: LINE_CHART_STATUSES.REDEEMED,
                label: t('Redeemed cards'),
              },
              {
                value: LINE_CHART_STATUSES.FULL,
                label: t('Full cards'),
              },
            ]}
          />
          <Select
            className="chart-date"
            showArrow
            defaultValue={lineChartPeriod}
            onChange={(value) => setLineChartPeriod(value)}
            suffixIcon={<Icon size={16} icon={ICONS.ARROW_DOWN} color="#FABB18" />}
            options={[
              { value: LINE_CHART_PERIODS.WEEK, label: t('Week') },
              { value: LINE_CHART_PERIODS.MONTH, label: t('Month') },
              { value: LINE_CHART_PERIODS.YEAR, label: t('Year') },
            ]}
          />
        </div>
        <Spin spinning={onLoadingPieChartStatistics}>
          <ResponsiveContainer width="100%" aspect={2.0 / 1.0}>
            {renderLineChart()}
          </ResponsiveContainer>
        </Spin>
      </div>
    </div>
  );
};

export default Charts;
