import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  GET_AUTOPILOTS,
  REQUEST_GET_AUTOPILOTS,
  CREATE_AUTOPILOT,
  REQUEST_CREATE_AUTOPILOT,
  UPDATE_AUTOPILOT,
  REQUEST_UPDATE_AUTOPILOT,
  DELETE_AUTOPILOT,
  REQUEST_DELETE_AUTOPILOT,
} from '../types/index';

const api = new Api();

function* getAutopilotsWatcher() {
  yield takeEvery(REQUEST_GET_AUTOPILOTS, getAutopilotsWorker);
}

function* createAutopilotWatcher() {
  yield takeEvery(REQUEST_CREATE_AUTOPILOT, createAutopilotWorker);
}

function* updateAutopilotWatcher() {
  yield takeEvery(REQUEST_UPDATE_AUTOPILOT, updateAutopilotWorker);
}

function* deleteAutopilotWatcher() {
  yield takeEvery(REQUEST_DELETE_AUTOPILOT, deleteAutopilotWorker);
}

function* getAutopilotsWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getAutopilots);
    yield put({ type: GET_AUTOPILOTS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* createAutopilotWorker({
  payload: { autopilotData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.createAutopilot, autopilotData);
    yield put({ type: CREATE_AUTOPILOT, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* updateAutopilotWorker({
  payload: { id, autopilotData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.updateAutopilot(id, autopilotData));
    yield put({ type: UPDATE_AUTOPILOT, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteAutopilotWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteAutopilot(id));
    yield put({ type: DELETE_AUTOPILOT, payload: id });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* autopilots() {
  yield all([
    fork(getAutopilotsWatcher),
    fork(createAutopilotWatcher),
    fork(updateAutopilotWatcher),
    fork(deleteAutopilotWatcher),
  ]);
}
