import { GET_PROFILE, UPDATE_PROFILE, DELETE_PROFILE, UPDATE_PROFILE_ERRORS } from '../types';

const initialState = {
  data: {},
  onUpdateProfileErrorMessages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE:
      return { ...state, data: action.payload };
    case DELETE_PROFILE:
      return { ...state, data: {} };
    case UPDATE_PROFILE:
      return { ...state, data: { ...state.data, ...action.payload } };
    case UPDATE_PROFILE_ERRORS:
      return { ...state, onUpdateProfileErrorMessages: action.payload };
    default:
      return state;
  }
}
