import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { ICONS } from '../../../constants';
import { getCapitalized } from '../../../utils/additional';

import Icon from '../../Icon';

import './SubscriptionInfo.scss';

const SubscriptionInfo = ({ plans = [], currentPlan, subscribeHandler, manageSubscription }) => {
  const { t } = useTranslation();

  return (
    <div className="subscriptionInfo">
      {currentPlan ? (
        <>
          <div className="subscriptionInfo-row">
            <h2 className="subscriptionInfo-heading">
              {t('My Subscription Plan')} - <span>{currentPlan.invoice_name}</span>
            </h2>
            <button className="btn btn--primary btn--small" onClick={manageSubscription}>
              {t('Manage')}
            </button>
          </div>
          <p className="subscriptionInfo-description">{currentPlan.description}</p>
        </>
      ) : (
        <Spin />
      )}
      {plans && Object.keys(plans).length ? (
        <div className="subscriptionInfo-overview">
          <h3>{t('Other Subscription plans')}</h3>
          <table>
            <thead>
              <tr>
                <td>{t('Functions')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>{plan?.invoice_name}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t('Number of Cards')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>{plan?.meta_data?.products}</td>
                ))}
              </tr>
              <tr>
                <td>{t('Number of Locations')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>{plan?.meta_data?.businesses}</td>
                ))}
              </tr>
              <tr>
                <td>{t('Analytics')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>
                    {plan?.meta_data?.statistics && (
                      <Icon icon={ICONS.CIRCLE_CHECKED} color="#FABB18" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('Welcome Message')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>
                    {plan?.meta_data?.autopilots?.WELCOME && (
                      <Icon icon={ICONS.CIRCLE_CHECKED} color="#FABB18" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('First Visit Message')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>
                    {plan?.meta_data?.autopilots?.FIRST_VISIT && (
                      <Icon icon={ICONS.CIRCLE_CHECKED} color="#FABB18" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('Frequent Customer Message')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>
                    {plan?.meta_data?.autopilots?.FREQUENT_CUSTOMER && (
                      <Icon icon={ICONS.CIRCLE_CHECKED} color="#FABB18" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('Birthday Message')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>
                    {plan?.meta_data?.autopilots?.BIRTHDAY && (
                      <Icon icon={ICONS.CIRCLE_CHECKED} color="#FABB18" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('Reactivation Message')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>
                    {plan?.meta_data?.autopilots?.REACTIVATION && (
                      <Icon icon={ICONS.CIRCLE_CHECKED} color="#FABB18" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('New Offering Message')}</td>
                {plans.map((plan, index) => (
                  <td key={index}>
                    {plan?.meta_data?.autopilots?.NEW_OFFERING && (
                      <Icon icon={ICONS.CIRCLE_CHECKED} color="#FABB18" />
                    )}
                  </td>
                ))}
              </tr>
              <tr>
                <td>{t('Price')}</td>
                {plans.map((plan, index) => {
                  if (index === 0) {
                    return (
                      <td
                        key={index}
                        style={{
                          minWidth: '141px',
                          verticalAlign: 'middle',
                        }}
                      >
                        <div
                          className="price"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          <span className="price-value">{t('Free')}</span>
                        </div>
                      </td>
                    );
                  }
                  return (
                    <td key={index}>
                      <div className="price">
                        <span className="price-value">{parseInt(plan.price) / 100}</span>
                        <span className="price-period">
                          {plan.currency_code} / {t(getCapitalized(plan.period_unit))}
                        </span>
                      </div>
                      <button
                        className="btn btn--primary btn--small"
                        onClick={() => subscribeHandler(plan.id)}
                      >
                        {t('Subscribe')}
                      </button>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </div>
      ) : (
        <Spin size="Large" />
      )}
    </div>
  );
};

export default SubscriptionInfo;
