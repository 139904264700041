import { GET_MESSAGES, DELETE_MESSAGE } from '../types';
import { MESSAGE_STATUSES } from '../../constants';

const initialState = {
  items: [],
  unreadMessagesCount: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        items: action.payload,
        unreadMessagesCount: action.payload.reduce((acc, currentValue) => {
          if (currentValue.status === MESSAGE_STATUSES.UNREAD) {
            return acc + 1;
          }
          return acc;
        }, 0),
      };
    case DELETE_MESSAGE:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    default:
      return state;
  }
}
