import { message } from 'antd';
import i18n from '../i18n';

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error(i18n.t('You can only upload JPG/PNG file!'));
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error(i18n.t('Image must smaller than 2MB!'));
  }
  return isJpgOrPng && isLt2M;
};
