import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';
import { MODAL_TYPES } from '../../constants';
import { getSlots } from '../../redux/actions/slots';
import { showModal } from '../../redux/actions/modal';

import SlotPreview from '../Slot/SlotPreview/SlotPreview';
import NoData from '../NoData/NoData';
import { getNewsletters } from '../../redux/actions/newsletter';
import NewsletterPreview from '../Newsletter/NewsletterPreview/NewsletterPreview';

const AdminAdditionalOffers = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [slotsLoading, setSlotsLoading] = useState(false);
  const slots = useSelector((state) => state.slots.items);

  useEffect(() => {
    setSlotsLoading(true);
    dispatch(
      getSlots({
        onSuccessCallback: () => {
          setSlotsLoading(false);
        },
        onErrorCallback: () => {
          setSlotsLoading(false);
        },
      }),
    );
  }, []);

  const deleteSlotHandler = (event, slotId) => {
    event.stopPropagation();
    dispatch(
      showModal({
        type: MODAL_TYPES.SLOT_DELETE,
        id: slotId,
      }),
    );
  };

  const addSlotHandler = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.SLOT_ADD,
      }),
    );
  };

  const editSlotHandler = (event, slotId) => {
    event.stopPropagation();
    dispatch(
      showModal({
        type: MODAL_TYPES.SLOT_EDIT,
        id: slotId,
      }),
    );
  };

  const viewSlotHandler = (slotId) => {
    const slot = slots.find((slot) => slot.id === slotId);
    dispatch(
      showModal({
        type: MODAL_TYPES.SLOT_VIEW,
        data: slot,
      }),
    );
  };

  const renderSlots = () => {
    if (slotsLoading) {
      return <Spin size="large" />;
    }
    if (slots && slots.length) {
      return slots.map((slot) => (
        <SlotPreview
          key={slot.id}
          owner={slot.owner}
          createdAt={slot.createdAt}
          period={slot.period}
          price={slot.price}
          region={slot.region}
          isForPartner={false}
          deleteHandler={(event) => deleteSlotHandler(event, slot.id)}
          editHandler={(event) => editSlotHandler(event, slot.id)}
          clickHandler={() => viewSlotHandler(slot.id)}
        />
      ));
    }
    return <NoData />;
  };

  // newsletters

  const [newslettersLoading, setNewslettersLoading] = useState(false);
  const newsletters = useSelector((state) => state.newsletters.items);

  useEffect(() => {
    setNewslettersLoading(true);
    dispatch(
      getNewsletters({
        onFinallyCallback: () => setNewslettersLoading(false),
      }),
    );
  }, []);

  // open add newsletter modal on button click

  const addNewsletterHandler = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.NEWSLETTER_ADD,
      }),
    );
  };

  const deleteNewsletterHandler = (event, id) => {
    event.stopPropagation();
    dispatch(
      showModal({
        type: MODAL_TYPES.NEWSLETTER_DELETE,
        id,
      }),
    );
  };

  const clickNewsletterHandler = (id) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.NEWSLETTER_EDIT,
        id,
      }),
    );
  };

  const renderNewsletters = () => {
    if (newslettersLoading) {
      return <Spin size="large" />;
    }
    if (newsletters && newsletters.length) {
      return newsletters.map(({ id, subject, description, date }) => (
        <NewsletterPreview
          key={id}
          subject={subject}
          description={description}
          date={date}
          clickHandler={() => clickNewsletterHandler(id)}
          deleteHandler={(event) => deleteNewsletterHandler(event, id)}
        />
      ));
    }
    return <NoData />;
  };

  return (
    <div className="additionalOffers">
      <h2 className="pageTitle">{t('Additional Offers')}</h2>
      <div className="pageRow">
        <h3>{t('Featured Partner Slot')}</h3>
        <button className="btn btn--primary btn--small" onClick={addSlotHandler}>
          {t('Add FP Slot')}
        </button>
      </div>
      <div className="slots">{renderSlots()}</div>
      <div className="pageRow">
        <h3>{t('Slot in newsletter')}</h3>
        <button className="btn btn--primary btn--small" onClick={addNewsletterHandler}>
          {t('Add Newsletter')}
        </button>
      </div>
      <div className="newsletters">{renderNewsletters()}</div>
    </div>
  );
};

export default AdminAdditionalOffers;
