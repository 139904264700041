import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { hideModal, showModal } from '../../../redux/actions/modal';
import { deleteSlot } from '../../../redux/actions/slots';

import { MODAL_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';

import ErrorMessages from '../../ErrorMessages/ErrorMessages';

const SlotDelete = ({ id }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [deleteSlotLoading, setDeleteSlotLoading] = useState(false);
  const [onDeleteSlotErrors, setOnDeleteSlotErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const deleteHandler = () => {
    setDeleteSlotLoading(true);
    dispatch(
      deleteSlot({
        id,
        onSuccessCallback: () => {
          setDeleteSlotLoading(false);
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your slot was successfully deleted'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setDeleteSlotLoading(false);
          setOnDeleteSlotErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  return (
    <div className="confirmModal">
      <h4 className="confirmModal-question">{t('Are you sure you want to delete slot?')}</h4>
      <ErrorMessages errorMessages={onDeleteSlotErrors} />
      <Spin spinning={deleteSlotLoading}>
        <div className="modal-actions modal-actions--fullsize">
          <button type="button" className="btn btn--small btn--secondary" onClick={cancelHandler}>
            {t('Cancel')}
          </button>
          <button type="button" className="btn btn--small btn--primary" onClick={deleteHandler}>
            {t('Save')}
          </button>
        </div>
      </Spin>
    </div>
  );
};

export default SlotDelete;
