import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../redux/actions/modal';

import { ICONS, MODAL_TYPES } from '../../constants';
import Icon from '../Icon';
import SubMenu from './SubMenu/SubMenu';

import './Sidebar.scss';

const PartnerSidebar = ({ handleSignOut }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const { businessId } = useParams();

  const deleteBPHandler = (id) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.BUSINESS_DELETE,
        id,
      }),
    );
  };

  const { businesses } = useSelector((state) => state.profile.data);

  return (
    <aside className="sidebar">
      <div className="sidebar-container">
        <nav>
          <NavLink className="menuLink" exact to="/main" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.USER} />
            {t('My Profile')}
          </NavLink>
          <NavLink
            className="menuLink menuLink--accent"
            to="/main/add-business-profile"
            activeClassName="selected"
          >
            <Icon className="menuLink-icon" icon={ICONS.PLUS} />
            {t('Add business profile')}
          </NavLink>
          {businesses &&
            businesses.length &&
            businesses.map((bp) => (
              <SubMenu
                key={bp.id}
                buttonText={bp.title}
                buttonImage={bp.cover}
                id={bp.id}
                defaultOpen={!!businessId && parseInt(businessId) === bp.id}
                deleteHandler={(id) => deleteBPHandler(id)}
              />
            ))}
          <NavLink className="menuLink" to="/main/messages" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.MESSAGES} />
            {t('Messages')}
          </NavLink>
          <NavLink className="menuLink" to="/main/statistics" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.STATISTIC} />
            {t('Statistics')}
          </NavLink>
          <NavLink className="menuLink" to="/main/additional-offers" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.OFFERS} />
            {t('Additional Offers')}
          </NavLink>
          <NavLink className="menuLink" to="/main/account-settings" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.SETTINGS} />
            {t('Account Settings')}
          </NavLink>
        </nav>
        <button className="menuLink menuLink--logOut" onClick={handleSignOut}>
          <Icon className="menuLink-icon" icon={ICONS.LOG_OUT} />
          {t('Log out')}
        </button>
      </div>
    </aside>
  );
};

export default PartnerSidebar;
