export const ICONS = {
  USER: 'USER',
  LOG_OUT: 'LOG_OUT',
  FLAG: 'FLAG',
  OFFERS: 'OFFERS',
  PARTNERSHIP: 'PARTNERSHIP',
  PLUS: 'PLUS',
  SETTINGS: 'SETTINGS',
  STATISTIC: 'STATISTIC',
  CHECKED: 'CHECKED',
  CIRCLE_CHECKED: 'CIRCLE_CHECKED',
  BELL: 'BELL',
  DOCUMENT: 'DOCUMENT',
  MESSAGES: 'MESSAGES',
  TRASH: 'TRASH',
  CALENDAR: 'CALENDAR',
  CLOCK: 'CLOCK',
  FILTER: 'FILTER',
  CLOSE: 'CLOSE',
  ARROW_LEFT: 'ARROW_LEFT',
  ARROW_UP: 'ARROW_UP',
  ARROW_DOWN: 'ARROW_DOWN',
  ARROW_RIGHT: 'ARROW_RIGHT',
  ARROW_BACK: 'ARROW_BACK',
  EYE: 'EYE',
  EYE_NO: 'EYE_NO',
  SEARCH: 'SEARCH',
  PHONE: 'PHONE',
  EMAIL: 'EMAIL',
  LOCATION: 'LOCATION',
  IMAGE: 'IMAGE',
  CHECKBOX: 'CHECKBOX',
  ADD: 'ADD',
  LINK: 'LINK',
  DOWNLOAD: 'DOWNLOAD',
  FRAME: 'FRAME',
  PLUS_CIRCLE: 'PLUS_CIRCLE',
  STAR: 'STAR',
  GIFT: 'GIFT',
  LIKE: 'LIKE',
  PAY: 'PAY',
  DECREASE: 'DECREASE',
  INCREASE: 'INCREASE',
  ALERT: 'ALERT',
  FACEBOOK: 'FACEBOOK',
  INSTAGRAM: 'INSTAGRAM',
  YOUTUBE: 'YOUTUBE',
  TWITTER: 'TWITTER',
  EDIT: 'EDIT',
  BP_PROFILE_CREATED: 'BP_PROFILE_CREATED',
  EU_PROFILE_CREATED: 'EU_PROFILE_CREATED',
  PRODUCTS_CREATED: 'PRODUCTS_CREATED',
  COLLECTED_POINTS: 'COLLECTED_POINTS',
  REDEEMED_CARDS: 'REDEEMED_CARDS',
};

export const MODAL_TYPES = {
  BUSINESS_DELETE: 'BUSINESS_DELETE',
  PRODUCT_ADD: 'PRODUCT_ADD',
  PRODUCT_EDIT: 'PRODUCT_EDIT',
  PRODUCT_VIEW: 'PRODUCT_VIEW',
  PRODUCT_DELETE: 'PRODUCT_DELETE',
  PARTNER_DELETE: 'PARTNER_DELETE',
  MEMBER_DELETE: 'MEMBER_DELETE',
  CONFIRM: 'CONFIRM',
  NOTIFICATION: 'NOTIFICATION',
  QR_CODE: 'QR_CODE',
  SUPPORT: 'SUPPORT',
  CALENDAR: 'CALENDAR',
  INVITATION: 'INVITATION',
  MESSAGE: 'MESSAGE',
  MESSAGE_VIEW: 'MESSAGE_VIEW',
  MESSAGE_EDIT: 'MESSAGE_EDIT',
  MESSAGE_ADD: 'MESSAGE_ADD',
  MESSAGE_DELETE: 'MESSAGE_DELETE',
  SLOT: 'SLOT',
  SLOT_ADD: 'SLOT_ADD',
  SLOT_EDIT: 'SLOT_EDIT',
  SLOT_VIEW: 'SLOT_VIEW',
  SLOT_BUY: 'SLOT_BUY',
  SLOT_DELETE: 'SLOT_DELETE',
  NOTIFICATION_VIEW: 'NOTIFICATION_VIEW',
  NEWSLETTER_ADD: 'NEWSLETTER_ADD',
  NEWSLETTER_DELETE: 'NEWSLETTER_DELETE',
  NEWSLETTER_EDIT: 'NEWSLETTER_EDIT',
  NEWSLETTER_VIEW: 'NEWSLETTER_VIEW',
  ACCESS: 'ACCESS',
  REGULAR_MESSAGE_ADD: 'REGULAR_MESSAGE_ADD',
};

export const PRODUCT_TYPES = {
  CARD: 'CARD',
  GIFT: 'GIFT',
};

export const CARD_DISPlAY = {
  PREVIEW: 'PREVIEW',
  VIEW: 'VIEW',
  EDIT: 'EDIT',
  ADD: 'ADD',
};

export const AUTOPILOT_TYPES = {
  WELCOME: 'WELCOME',
  FIRST_VISIT: 'FIRST_VISIT',
  FREQUENT_CUSTOMER: 'FREQUENT_CUSTOMER',
  BIRTHDAY: 'BIRTHDAY',
  REACTIVATION: 'REACTIVATION',
  NEW_OFFERING: 'NEW_OFFERING',
};

export const ROLES = {
  PARTNER: 'PARTNER',
  ADMIN: 'ADMIN',
  USER: 'USER',
};

export const OFFER_STATUSES = {
  AVAILABLE: 'AVAILABLE',
  BOUGHT: 'BOUGHT',
};

export const MESSAGE_STATUSES = {
  READ: 'READ',
  UNREAD: 'UNREAD',
};

export const DATE_FORMAT = 'DD/MM/YY';
export const TIME_FORMAT = 'HH:mm';

export const COLORS = [
  '#D2E307',
  '#9800BE',
  '#004FC6',
  '#1FABD7',
  '#7EB996',
  '#FF8F51',
  '#000000',
  '#56B38E',
  '#FF8E8E',
  '#FAF2D6',
];

export const PIE_CHART_VIEWS = {
  AGE: 'AGE',
  GENDER: 'GENDER',
};

export const GENDERS = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
};

export const LINE_CHART_STATUSES = {
  OPEN: 'OPEN',
  COLLECTED: 'COLLECTED',
  REDEEMED: 'REDEEMED',
  FULL: 'FULL',
};

export const LINE_CHART_PERIODS = {
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  YEAR: 'YEAR',
};

export const ADMIN_STATISTICS_OPTIONS = {
  BP_PROFILE_CREATED: 'BP_PROFILE_CREATED',
  EU_PROFILE_CREATED: 'EU_PROFILE_CREATED',
  PRODUCTS_CREATED: 'PRODUCTS_CREATED',
  COLLECTED_POINTS: 'COLLECTED_POINTS',
  REDEEMED_CARDS: 'REDEEMED_CARDS',
  APP_OPENED: 'APP_OPENED',
};

export const ADMIN_STATISTICS_PERIODS = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

export const ADMIN_STATISTICS_TABS = [
  {
    title: 'BP Profile Created',
    icon: ICONS.BP_PROFILE_CREATED,
    value: ADMIN_STATISTICS_OPTIONS.BP_PROFILE_CREATED,
  },
  {
    title: 'EU Profile Created',
    icon: ICONS.EU_PROFILE_CREATED,
    value: ADMIN_STATISTICS_OPTIONS.EU_PROFILE_CREATED,
  },
  {
    title: 'Products Created',
    icon: ICONS.PRODUCTS_CREATED,
    value: ADMIN_STATISTICS_OPTIONS.PRODUCTS_CREATED,
  },
  {
    title: 'Collected Points',
    icon: ICONS.COLLECTED_POINTS,
    value: ADMIN_STATISTICS_OPTIONS.COLLECTED_POINTS,
  },
  {
    title: 'Redeemed Cards',
    icon: ICONS.REDEEMED_CARDS,
    value: ADMIN_STATISTICS_OPTIONS.REDEEMED_CARDS,
  },
  {
    title: 'App Opened',
    icon: ICONS.FRAME,
    value: ADMIN_STATISTICS_OPTIONS.APP_OPENED,
  },
];

export const LANGUAGES = [
  { value: 'en', label: 'English' },
  { value: 'de', label: 'Deutsch' },
  { value: 'es', label: 'Español' },
  { value: 'it', label: 'Italiano' },
  { value: 'fr', label: 'Français' },
];

export const DAYS = [
  { value: 'MONDAY', label: 'Monday' },
  { value: 'TUESDAY', label: 'Tuesday' },
  { value: 'WEDNESDAY', label: 'Wednesday' },
  { value: 'THURSDAY', label: 'Thursday' },
  { value: 'FRIDAY', label: 'Friday' },
  { value: 'SATURDAY', label: 'Saturday' },
  { value: 'SUNDAY', label: 'Sunday' },
];

export const FAQ_DATA = [
  {
    id: 1,
    title: 'faq.0.title',
    content: 'faq.0.content',
  },
  {
    id: 2,
    title: 'faq.1.title',
    content: 'faq.1.content',
  },
  {
    id: 3,
    title: 'faq.2.title',
    content: 'faq.2.content',
  },
  {
    id: 4,
    title: 'faq.3.title',
    content: 'faq.3.content',
  },
  {
    id: 5,
    title: 'faq.4.title',
    content: 'faq.4.content',
  },
  {
    id: 6,
    title: 'faq.5.title',
    content: 'faq.5.content',
  },
];

export const PREMIUM_MESSAGES = [
  {
    id: 1,
    title: 'autopilots.Welcome.title',
    type: AUTOPILOT_TYPES.WELCOME,
    description: 'autopilots.Welcome.description',
  },
  {
    id: 2,
    title: 'autopilots.First Visit.title',
    type: AUTOPILOT_TYPES.FIRST_VISIT,
    description: 'autopilots.First Visit.description',
  },
  {
    id: 3,
    title: 'autopilots.Frequent Customer Message.title',
    type: AUTOPILOT_TYPES.FREQUENT_CUSTOMER,
    description: 'autopilots.Frequent Customer Message.description',
  },
];

export const ELITE_MESSAGES = [
  {
    id: 1,
    title: 'autopilots.Birthday Message.title',
    type: AUTOPILOT_TYPES.BIRTHDAY,
    description: 'autopilots.Birthday Message.description',
  },
  {
    id: 2,
    title: 'autopilots.Reactivation Message.title',
    type: AUTOPILOT_TYPES.REACTIVATION,
    description: 'autopilots.Reactivation Message.description',
  },
  {
    id: 3,
    title: 'autopilots.New Offering Message.title',
    type: AUTOPILOT_TYPES.NEW_OFFERING,
    description: 'autopilots.New Offering Message.description',
  },
];
