import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';

import './BusinessChooser.scss';
import BusinessPreview from '../BusinessPreview/BusinessPreview';
import NoData from '../../NoData/NoData';
import FieldError from '../../HookedField/FieldError/FieldError';

const BusinessChooser = ({ defaultValue, className }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const onBtnClick = () => {
    setIsOpen(!isOpen);
  };

  const { businesses } = useSelector((state) => state.profile.data);

  const [currentBusinesses, setCurrentBusinesses] = useState([]);

  useEffect(() => {
    setCurrentBusinesses(businesses);
  }, [businesses]);

  const [searchValue, setSearchValue] = useState('');

  const searchHandler = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    const filteredBusinesses = businesses.filter((business) => {
      if (business.title.trim().toLowerCase().includes(searchValue.trim().toLowerCase())) {
        return business;
      }
    });
    setCurrentBusinesses(filteredBusinesses);
  }, [searchValue]);

  const [chosenBusinesses, setChosenBusinesses] = useState(defaultValue || []);

  const chooseHandler = (id) => {
    const businessIndex = chosenBusinesses.findIndex((businessId) => businessId === id);
    if (businessIndex !== -1) {
      const filteredBusinesses = [...chosenBusinesses];
      filteredBusinesses.splice(businessIndex, 1);
      setChosenBusinesses(filteredBusinesses);
    } else {
      setChosenBusinesses([...chosenBusinesses, id]);
    }
  };

  const checkIsChosen = (id) => chosenBusinesses.includes(id);

  const { register, setValue, errors, clearErrors, setError } = useFormContext();

  useEffect(() => {
    register('businesses');
  }, []);

  useEffect(() => {
    setValue('businesses', chosenBusinesses);
    if (chosenBusinesses.length > 0) {
      clearErrors('businesses');
    } else {
      setError('businesses', t('Pick at least one'));
    }
  }, [chosenBusinesses]);

  const classes = ['businessChooser', className];

  return (
    <div className={classes.join(' ')}>
      {!isOpen ? (
        <>
          <div className="businessChooser-headline">
            <span className="businessChooser-label">{t('Available at')}</span>
            {chosenBusinesses.length > 0 && (
              <span className="businessChooser-edit" onClick={onBtnClick}>
                {t('Edit')}
              </span>
            )}
          </div>
          {!chosenBusinesses.length && (
            <button type="button" className="businessChooser-btn" onClick={onBtnClick}>
              {t('Choose where Card will be available')}
              <Icon icon={ICONS.ARROW_RIGHT} color="#FABB18" size={20} />
            </button>
          )}
          {chosenBusinesses.length > 0 &&
            businesses
              .filter((business) => chosenBusinesses.includes(business.id))
              .map(({ id, title, cover, description }) => (
                <BusinessPreview key={id} title={title} cover={cover} description={description} />
              ))}
        </>
      ) : (
        <div className="field businessChooser-search">
          <div className="field-wrapper">
            <Icon
              className="businessChooser-search-icon"
              icon={ICONS.SEARCH}
              color="#FABB18"
              size={20}
            />
            <input
              type="text"
              name="search"
              placeholder="Choose BP Profile"
              value={searchValue}
              onChange={searchHandler}
            />
            <button className="businessChooser-search-close" onClick={onBtnClick}>
              <Icon icon={ICONS.ARROW_UP} color="#FABB18" size={20} />
            </button>
            <div className="field-border" />
          </div>
        </div>
      )}
      {isOpen && (
        <ul className="businessChooser-list">
          {currentBusinesses.length ? (
            currentBusinesses.map(({ id, title, cover, description }) => (
              <li key={id} className="businessChooser-item">
                <BusinessPreview
                  title={title}
                  cover={cover}
                  description={description}
                  clickHandler={() => chooseHandler(id)}
                  isChosen={checkIsChosen(id)}
                />
              </li>
            ))
          ) : (
            <NoData />
          )}
        </ul>
      )}
      <FieldError error={errors.businesses} />
    </div>
  );
};

export default BusinessChooser;
