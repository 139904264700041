import {
  REQUEST_GET_NEWSLETTERS,
  REQUEST_CREATE_NEWSLETTER,
  REQUEST_UPDATE_NEWSLETTER,
  REQUEST_DELETE_NEWSLETTER,
} from '../types';

export function getNewsletters(payload) {
  return {
    type: REQUEST_GET_NEWSLETTERS,
    payload,
  };
}

export function createNewsletter(payload) {
  return {
    type: REQUEST_CREATE_NEWSLETTER,
    payload,
  };
}

export function updateNewsletter(payload) {
  return {
    type: REQUEST_UPDATE_NEWSLETTER,
    payload,
  };
}

export function deleteNewsletter(payload) {
  return {
    type: REQUEST_DELETE_NEWSLETTER,
    payload,
  };
}
