import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import Screen from '../../components/Screen/Screen';
import CreateBusinessProfileIcon from '../../svg/createBusinessProfileIcon';
import { getPreferences } from '../../redux/actions/preferences';
import { getProfile } from '../../redux/actions/profile';
import { signOut } from '../../redux/actions/auth';
import { hideModal, showModal } from '../../redux/actions/modal';
import { MODAL_TYPES } from '../../constants';

const CreateBusinessProfile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { firstName, lastName } = useSelector((state) => state.profile.data);

  let fullName = '';

  if (firstName && lastName) {
    fullName = `${firstName} ${lastName}`;
  }

  const handleSignOut = () => {
    dispatch(
      signOut({
        onSuccessCallback: () => {
          dispatch(hideModal());
          history.push('/sign-in');
        },
      }),
    );
  };

  useEffect(() => {
    dispatch(getPreferences());
    dispatch(
      getProfile({
        onErrorCallback: () => {
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Something went wrong please log in!'),
              okBtnText: t('Ok'),
              okHandler: handleSignOut,
              onClose: handleSignOut,
            }),
          );
        },
      }),
    );
  }, []);

  return (
    <Screen
      info={
        <button className="link" onClick={handleSignOut}>
          {t('Log out')}
        </button>
      }
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {fullName && (
            <h1 className="form-heading">
              {t('Welcome')}, {fullName}
            </h1>
          )}
        </div>
        <CreateBusinessProfileIcon />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Link className="btn btn--primary" to={`${props.match.path}/form`}>
            {t('Create Business Profile')}
          </Link>
        </div>
      </div>
    </Screen>
  );
};

export default CreateBusinessProfile;
