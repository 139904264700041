import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from '../../redux/actions/modal';
import { MODAL_TYPES, ROLES } from '../../constants';
import { signOut } from '../../redux/actions/auth';
import PartnerSidebar from './PartnerSidebar';
import AdminSidebar from './AdminSidebar';

const Sidebar = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const { role } = useSelector((state) => state.profile.data);

  const handleSignOut = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CONFIRM,
        question: t('Are you sure you want to Log Out?'),
        cancelBtnText: t('No'),
        okBtnText: t('Yes'),
        cancelHandler: () => {
          dispatch(hideModal());
        },
        okHandler: () => {
          dispatch(
            signOut({
              onSuccessCallback: () => {
                dispatch(hideModal());
                history.push('/sign-in');
              },
            }),
          );
        },
      }),
    );
  };

  const renderSidebar = () => {
    switch (role) {
      case ROLES.PARTNER:
        return <PartnerSidebar handleSignOut={handleSignOut} />;
      case ROLES.ADMIN:
        return <AdminSidebar handleSignOut={handleSignOut} />;
      default:
        return null;
    }
  };

  return renderSidebar();
};

export default Sidebar;
