import React from 'react';
import { useSelector } from 'react-redux';

import { ROLES } from '../../../constants';

import PartnerAdditionalOffers from '../../../components/AdditionalOffers/PartnerAdditionalOffers';
import AdminAdditionalOffers from '../../../components/AdditionalOffers/AdminAdditionalOffers';

import './AdditionalOffersPage.scss';

const AdditionalOffersPage = () => {
  const currentUser = useSelector((state) => state.profile.data);

  const renderAdditionalOffers = () => {
    switch (currentUser.role) {
      case ROLES.PARTNER:
        return <PartnerAdditionalOffers />;
      case ROLES.ADMIN:
        return <AdminAdditionalOffers />;
      default:
        return null;
    }
  };

  return renderAdditionalOffers();
};

export default AdditionalOffersPage;
