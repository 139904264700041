import React from 'react';
import { Link } from 'react-router-dom';
import LogoImg from '../../images/logo.svg';
import LogoImgInverse from '../../images/logo-inverse.svg';

import './Logo.scss';

const Logo = ({ inverse, isLink }) => (
  <>
    {isLink ? (
      <Link className="logo" to="/main/">
        <img crossOrigin="anonymous" src={inverse ? LogoImgInverse : LogoImg} alt="Loial" />
      </Link>
    ) : (
      <img crossOrigin="anonymous" src={inverse ? LogoImgInverse : LogoImg} alt="Loial" />
    )}
  </>
);

export default Logo;
