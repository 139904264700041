import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDaLh7MOubJSPD3Rz_8WSKi2n_FZ3yL8bY',
  authDomain: 'loial-5c719.firebaseapp.com',
  databaseURL: 'https://loial-5c719.firebaseio.com',
  projectId: 'loial-5c719',
  storageBucket: 'loial-5c719.appspot.com',
  messagingSenderId: '467402116725',
  appId: '1:467402116725:web:899e400f12836299f7f23c',
  measurementId: 'G-MXD1JVFDDZ',
};

firebase.initializeApp(firebaseConfig);

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const getToken = (setNotificationToken) => {
  if (!messaging) return;
  return messaging
    .getToken({
      vapidKey:
        'BI6g0cPObgJgaoHkjrls6z4izJmgnOaaXSYPtOMXkOWwp-Yyj1SEXXCOJkFLcHUdldYFo_C4ymtrCgXXA3D-RDI',
    })
    .then((currentToken) => {
      if (currentToken) {
        setNotificationToken(currentToken);
      } else {
        setNotificationToken(null);
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

// auth

export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = (signInHandler) => {
  auth
    .signInWithPopup(googleProvider)
    .then((res) => {
      firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          signInHandler({
            token: idToken,
          });
        })
        .catch((error) => {
          // Handle error
        });
    })
    .catch((error) => {
      console.log(error.message);
    });
};
