import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { schema } from '../../utils/validation_schemas/info';

import HookedField from '../HookedField/HookedField';
import { signInWithGoogle } from '../../firebase';
import GoogleSignIn from '../ui/GoogleSignIn';
import ErrorMessages from '../ErrorMessages/ErrorMessages';

const Info = ({
  defaultValues = {},
  infoSubmitHandler,
  signUpWithGoogleHandler,
  signUpLoading,
  onSignUpErrors,
}) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    infoSubmitHandler({ ...data, phone: data.phone.replace(/\s/g, '') });
  };

  return (
    <FormProvider {...methods}>
      <form
        className="form form--signIn"
        onSubmit={methods.handleSubmit(onSubmitHandler)}
        autoComplete="off"
      >
        <h1 className="form-heading">{t('New account')}</h1>
        <fieldset className="form-fields">
          <HookedField
            name="firstName"
            defaultValue={defaultValues.firstName || ''}
            fieldType="textField"
            type="text"
            placeholder={t('First Name')}
          />
          <HookedField
            name="lastName"
            defaultValue={defaultValues.lastName || ''}
            fieldType="textField"
            type="text"
            placeholder={t('Last Name')}
          />
          <HookedField
            name="email"
            defaultValue={defaultValues.email || ''}
            fieldType="textField"
            type="email"
            placeholder={t('Email')}
          />
          <HookedField
            name="phone"
            defaultValue={defaultValues.phone || ''}
            fieldType="textField"
            type="tel"
            placeholder={t('Phone Number')}
            autoComplete="new-password"
          />
        </fieldset>
        <div className="form-acceptance">
          <HookedField
            name="terms"
            defaultValue={!!defaultValues.terms}
            fieldType="checkboxField"
            type="checkbox"
            text={
              <div className="customCheckbox-text">
                <span>{t('I accept to the')} </span>
                <a
                  className="link"
                  href="https://www.iubenda.com/terms-and-conditions/17562457"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Terms of service')}
                </a>{' '}
                {t('and')}{' '}
                <a
                  className="link"
                  href="https://www.iubenda.com/privacy-policy/17562457"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Privacy policy')}
                </a>
              </div>
            }
          />
        </div>
        <div className="form-actions">
          <Spin spinning={signUpLoading}>
            <button
              type="submit"
              className="btn btn--primary"
              disabled={!methods.formState.isValid}
            >
              {t('Submit')}
            </button>
          </Spin>
        </div>
        {/* <p className="form-separator">{t("or")}</p>
                <GoogleSignIn
                    title={t("Sign up with google")}
                    onClick={() => signInWithGoogle(signUpWithGoogleHandler)}
                /> */}
        <ErrorMessages errorMessages={onSignUpErrors} />
      </form>
    </FormProvider>
  );
};

export default Info;
