import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { getProfile, updateProfile } from '../../redux/actions/profile';

import SubscriptionInfo from './SubscriptionInfo/SubscriptionInfo';
import ProfilePreview from './ProfilePreview/ProfilePreview';
import { showModal } from '../../redux/actions/modal';
import { MODAL_TYPES } from '../../constants';
import { getPlans } from '../../redux/actions/plans';

import Api from '../../redux/middlewares/Api';
import { getCorrectErrorMessages } from '../../utils/errors';

const api = new Api();

const cbInstance = window.Chargebee.init({
  site: 'loial-test',
  publishableKey: 'test_16udGGU2gbZDEegZT7nHcdLj6R8UcdgaL8',
});

cbInstance.setPortalSession(() =>
  api.updatePlan({
    embed: true,
  }),
);

const PartnerProfile = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // load profile information

  const currentUser = useSelector((state) => state.profile.data);

  const [profileDataLoading, setProfileDataLoading] = useState(true);

  useEffect(() => {
    setProfileDataLoading(true);
    dispatch(
      getProfile({
        onSuccessCallback: () => setProfileDataLoading(false),
        onErrorCallback: () => setProfileDataLoading(false),
      }),
    );
  }, []);

  // update profile information

  const [updateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [onUpdateProfileErrors, setOnUpdateProfileErrors] = useState([]);

  const updateProfileHandler = (data) => {
    setUpdateProfileLoading(true);
    dispatch(
      updateProfile({
        data: { ...data, phone: data.phone.replace(/\s/g, '') },
        onSuccessCallback: () => {
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your data was successfully updated'),
            }),
          );
          setUpdateProfileLoading(false);
          setOnUpdateProfileErrors([]);
        },
        onErrorCallback: (errors) => {
          setOnUpdateProfileErrors(getCorrectErrorMessages(errors));
          setUpdateProfileLoading(false);
        },
      }),
    );
  };

  // load subscription plans information

  const plans = useSelector((state) => state.plans.items);

  useEffect(() => {
    dispatch(getPlans({}));
  }, []);

  const [currentPlan, setCurrentPlan] = useState(null);

  useEffect(() => {
    if (plans && plans.length) {
      const planId = currentUser.customer && currentUser.customer.plan_id;
      setCurrentPlan(plans.find((plan) => plan.id === planId));
    }
  }, [plans, currentUser]);

  const subscribeHandler = (planId) => {
    cbInstance.openCheckout({
      hostedPage: () =>
        new Promise((resolve, reject) => {
          const response = api.subscribePlan({
            subscription: {
              plan_id: planId,
            },
            embed: true,
          });

          resolve(response);
        }),
    });
  };

  const manageSubscriptionHandler = () => {
    cbInstance.createChargebeePortal().open({
      close: () => {
        dispatch(getProfile({}));
      },
    });
  };

  const { businesses } = useSelector((state) => state.profile.data);

  useEffect(() => {
    if (!businesses) {
      return;
    }
    if (!businesses.length) {
      history.push('/create-business-profile');
    }
  }, [businesses]);

  return (
    <div className="profile">
      <div className="profile-row">
        <div className="profile-left">
          <ProfilePreview
            defaultValues={currentUser}
            handleSubmit={updateProfileHandler}
            profileDataLoading={profileDataLoading}
            updateProfileLoading={updateProfileLoading}
            onUpdateProfileErrors={onUpdateProfileErrors}
          />
        </div>
        <div className="profile-right">
          <SubscriptionInfo
            plans={plans}
            currentPlan={currentPlan}
            subscribeHandler={subscribeHandler}
            manageSubscription={manageSubscriptionHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerProfile;
