import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  GET_REGULAR_MESSAGES,
  REQUEST_GET_REGULAR_MESSAGES,
  GET_REGULAR_MESSAGE,
  REQUEST_GET_REGULAR_MESSAGE,
  CREATE_REGULAR_MESSAGE,
  REQUEST_CREATE_REGULAR_MESSAGE,
} from '../types/index';

const api = new Api();

function* getRegularMessagesWatcher() {
  yield takeEvery(REQUEST_GET_REGULAR_MESSAGES, getRegularMessagesWorker);
}

function* getRegularMessageWatcher() {
  yield takeEvery(REQUEST_GET_REGULAR_MESSAGE, getRegularMessageWorker);
}

function* createRegularMessage() {
  yield takeEvery(REQUEST_CREATE_REGULAR_MESSAGE, createRegularMessageWorker);
}

function* getRegularMessagesWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getRegularMessages);
    yield put({ type: GET_REGULAR_MESSAGES, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* getRegularMessageWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.getRegularMessage(id));
    yield put({ type: GET_REGULAR_MESSAGE, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* createRegularMessageWorker({
  payload: { regularMessageData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.createRegularMessage, regularMessageData);
    yield put({ type: CREATE_REGULAR_MESSAGE, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* regulars() {
  yield all([
    fork(getRegularMessagesWatcher),
    fork(getRegularMessageWatcher),
    fork(createRegularMessage),
  ]);
}
