import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../redux/actions/modal';

import './NotificationModal.scss';

const NotificationModal = ({
  title,
  description,
  cancelBtnText,
  okBtnText,
  okHandler,
  cancelHandler,
}) => {
  const dispatch = useDispatch();

  const actionClasses = [
    'modal-actions',
    cancelBtnText && okBtnText ? 'modal-actions--dual' : 'modal-actions--single',
  ];

  const onCancel = function () {
    dispatch(hideModal());
  };

  const onOk = function () {
    dispatch(hideModal());
  };

  return (
    <div className="notificationModal">
      <h2 className="notificationModal-title">{title}</h2>
      <svg
        className="notificationModal-icon"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="80" height="80" fill="white" />
        <circle cx="40" cy="40" r="37.5" fill="#FABB18" stroke="#191919" strokeWidth="5" />
        <path
          d="M39.2861 75C30.0036 75 21.1012 71.3125 14.5374 64.7487C7.97363 58.185 4.28613 49.2826 4.28614 40C4.28614 30.7174 7.97362 21.815 14.5374 15.2513C21.1012 8.68749 30.0036 5 39.2861 5C21.4206 12.6896 13.277 24.4338 13.3667 40C14.2535 54.5467 23.6758 66.5248 39.2861 75Z"
          fill="#F3A632"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.9301 45.8491L57.0561 23.7231L60.4233 27.0903L34.9301 52.5834L21.3418 38.9951L24.709 35.6279L34.9301 45.8491Z"
          fill="black"
        />
      </svg>
      {description && <p className="notificationModal-description">{description}</p>}
      {cancelBtnText || okBtnText ? (
        <div className={actionClasses.join(' ')}>
          {cancelBtnText && (
            <button className="btn btn--secondary" onClick={cancelHandler || onCancel}>
              {cancelBtnText}
            </button>
          )}
          {okBtnText && (
            <button className="btn btn--primary" onClick={okHandler || onOk}>
              {okBtnText}
            </button>
          )}
        </div>
      ) : null}
    </div>
  );
};

NotificationModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cancelBtnText: PropTypes.string,
  okBtnText: PropTypes.string,
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
};

export default NotificationModal;
