import React from 'react';

import './CustomerStat.scss';

const CustomerStat = ({ value, text }) => (
  <div className="customerStat">
    <div className="customerStat-value">{value}</div>
    <div className="customerStat-text">{text}</div>
  </div>
);

export default CustomerStat;
