import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from '../constants';

export const getCapitalized = (string = '') => {
  let capitalizedString = string.toLowerCase();
  capitalizedString = capitalizedString.charAt(0).toUpperCase() + capitalizedString.slice(1);
  return capitalizedString;
};

export const getDateString = (date) => !!date && moment(date).format(DATE_FORMAT);

export const getShortString = (string, limit) =>
  string.length > limit ? `${string.substr(0, limit - 1)}...` : string;

export const renderTime = (time) => {
  return moment(time).format(TIME_FORMAT);
};

export const getTimeToMilliseconds = (time) => {
  const momentTime = moment.utc(time);
  const hoursInMilliseconds = momentTime.hours() * 60 * 60 * 1000;
  const minutesInMilliseconds = momentTime.minutes() * 60 * 1000;
  const secondsInMilliseconds = momentTime.seconds() * 1000;
  return hoursInMilliseconds + minutesInMilliseconds + secondsInMilliseconds;
};

export const getMillisecondsToTime = (milliseconds) => moment(milliseconds);
