import * as yup from 'yup';

export const schema = yup.object().shape({
  password: yup
    .string()
    .required('This field is required')
    .matches(
      /(?=.*[0-9])(?=.*[A-Z])[0-9a-zA-Z=@$!%*#?&]{8,256}/g,
      'Must contain minimum 8 characters, one uppercase and one number',
    ),
});
