import * as yup from 'yup';
import 'yup-phone';

export const schema = yup.object().shape({
  title: yup.string().required('This field is required'),
  email: yup.string().email('It must be a valid email').nullable(),
  phones: yup
    .array()
    .min(1)
    .max(3)
    .of(
      yup.object().shape({
        value: yup.string().phone('CH', true, 'Invalid phone').required('This field is required'),
      }),
    ),
  cover: yup.object().typeError('Main image is required').required('Main image is required'),
  miniCover: yup.object().typeError('Miniature is required').required('Miniature is required'),
  logo: yup.object().typeError('Logo is required').required('Logo is required'),
  weekDays: yup.array().nullable(),
  workHours: yup
    .array()
    .nullable()
    .when('weekDays', (weekDays) => {
      if (weekDays && weekDays.length) {
        return yup
          .array()
          .typeError('This field is required')
          .min(2, 'This field is required')
          .required();
      }
    }),
  lunchHours: yup.array().nullable(),
  custom: yup.array().of(
    yup.object().shape({
      day: yup.string().typeError('This field is required').required('This field is required'),
      work: yup
        .array()
        .typeError('This field is required')
        .min(2, 'This field is required')
        .required('This field is required'),
      lunch: yup.array().nullable(),
    }),
  ),
  preferences: yup
    .array()
    .min(1, '1 preference is minimum')
    .max(3, '3 preferences are maximum')
    .of(
      yup.object().shape({
        id: yup.number().required('This field is required'),
      }),
    ),
  address: yup.object().required('This field is required'),
});
