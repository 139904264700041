import React from 'react';
import { useTranslation } from 'react-i18next';

import { getShortString } from '../../../utils/additional';

import './BusinessPreview.scss';

const BusinessPreview = ({ title, description, cover, clickHandler, isChosen }) => {
  const { t } = useTranslation();

  const btnClasses = [
    'btn',
    'btn--short',
    'businessPreview-btn',
    isChosen ? 'btn--secondary' : 'btn--primary',
  ];

  return (
    <div className="businessPreview">
      <div className="businessPreview-imgWrapper">
        <img crossOrigin="anonymous" src={cover} alt={title} />
      </div>
      <div className="businessPreview-content">
        <h3 className="businessPreview-title" title={title}>
          {getShortString(title, 14)}
        </h3>
        {description && (
          <p className="businessPreview-description" title={description}>
            {getShortString(description, 16)}
          </p>
        )}
      </div>
      {clickHandler && (
        <button type="button" className={btnClasses.join(' ')} onClick={clickHandler}>
          {isChosen ? t('Chosen') : t('Choose')}
        </button>
      )}
    </div>
  );
};

export default BusinessPreview;
