import {
  GET_BUSINESSES,
  CREATE_BUSINESSES,
  UPDATE_BUSINESS,
  DELETE_BUSINESS,
  CLEAN_LAST_CREATED_BUSINESS,
  GET_MEMBERS,
  CREATE_MEMBER,
  DELETE_MEMBER,
} from '../types';

const initialState = {
  items: [],
  members: [],
  lastCreatedBusinessId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_BUSINESSES:
      return { ...state, items: action.payload };
    case CREATE_BUSINESSES:
      return {
        ...state,
        items: [...state.items, action.payload],
        lastCreatedBusinessId: action.payload.id,
      };
    case UPDATE_BUSINESS:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id);
      const newItems = [...state.items];
      newItems[itemIndex] = { ...action.payload };
      return { ...state, items: newItems };
    case DELETE_BUSINESS:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload) };
    case CLEAN_LAST_CREATED_BUSINESS:
      return { ...state, lastCreatedBusinessId: null };
    case GET_MEMBERS:
      return { ...state, members: action.payload };
    case DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter((member) => member.user.id !== action.payload),
      };
    case CREATE_MEMBER:
      return { ...state, members: [...state.members, action.payload] };
    default:
      return state;
  }
}
