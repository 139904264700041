import React from 'react';
import Logo from '../Logo/Logo';

import './Screen.scss';
import LanguagePicker from '../LanguagePicker/LanguagePicker';

const Screen = ({ info, children }) => (
  <div className="screen">
    <div className="screen-left">
      <Logo inverse isLink={false} />
    </div>
    <div className="screen-right">
      <div className="screen-info">
        {info && <span className="screen-info-text">{info}</span>}
        <LanguagePicker />
      </div>
      {children}
    </div>
  </div>
);

export default Screen;
