import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { schema } from '../../utils/validation_schemas/resetPassword';

import HookedField from '../HookedField/HookedField';
import ErrorMessages from '../ErrorMessages/ErrorMessages';

const ResetPasswordForm = ({
  handleResetPassword,
  resetPasswordLoading,
  onResetPasswordErrors,
}) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form className="form form--signIn" onSubmit={methods.handleSubmit(handleResetPassword)}>
        <h1 className="form-heading">{t('Reset your password')}</h1>
        <p className="form-subheading">
          {t(
            'Enter your email address below to receive an email with instructions to reset your password.',
          )}
        </p>
        <fieldset className="form-fields">
          <HookedField
            name="email"
            defaultValue=""
            fieldType="textField"
            type="email"
            placeholder={t('Email')}
          />
        </fieldset>
        <ErrorMessages errorMessages={onResetPasswordErrors} />
        <div className="form-actions">
          <Spin spinning={resetPasswordLoading}>
            <button className="btn btn--primary" disabled={!methods.formState.isValid}>
              {t('Submit')}
            </button>
          </Spin>
        </div>
      </form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
