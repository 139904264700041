import React from 'react';
import moment from 'moment';
import Icon from '../../Icon';
import { ICONS, MESSAGE_STATUSES, DATE_FORMAT } from '../../../constants';
import ProductPreview from '../../Product/ProductPreview/ProductPreview';

import './NotificationPreview.scss';
import { Spin } from 'antd';
import BusinessPartnerPreview from '../../BusinessPartner/BusinessPartnerPreview/BusinessPartnerPreview';

const NotificationPreview = ({
  isForPartner,
  status,
  createdAt,
  subject,
  product,
  partner,
  loading,
  deleteHandler,
  clickHandler,
}) => {
  const classes = [
    'notification',
    isForPartner ? 'notification--partner' : 'notification--admin',
    status === MESSAGE_STATUSES.UNREAD ? 'notification--new' : '',
  ];

  return (
    <Spin spinning={loading}>
      <div className={classes.join(' ')} onClick={clickHandler}>
        <div className="notification-info">
          <div className="notification-date">{moment(createdAt).format(DATE_FORMAT)}</div>
          <span className="notification-message">{subject}</span>
        </div>
        {product && Object.keys(product).length ? (
          <ProductPreview
            title={product.title}
            cover={product.miniCover}
            period={product.period}
            expirationDays={product.expirationDays}
            withDeleteBtn={false}
            withClickEvent={false}
          />
        ) : null}
        {partner && Object.keys(partner).length ? (
          <BusinessPartnerPreview
            name={`${partner.firstName} ${partner.lastName}`}
            subscriptionType={partner.customer.plan_id}
            isSimple
          />
        ) : null}
        {deleteHandler && (
          <button className="card-deleteBtn" onClick={deleteHandler}>
            <Icon icon={ICONS.TRASH} size={16} />
          </button>
        )}
      </div>
    </Spin>
  );
};

export default NotificationPreview;
