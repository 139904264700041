import {
  DELETE_PRODUCT,
  UPDATE_PRODUCT,
  CREATE_PRODUCT,
  GET_PRODUCTS,
  CLEAN_LAST_CREATED_PRODUCT,
} from '../types';

const initialState = {
  items: [],
  lastCreatedProductId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return { ...state, items: action.payload };
    case DELETE_PRODUCT:
      return { ...state, items: state.items.filter((item) => item.id !== action.payload) };
    case UPDATE_PRODUCT:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id);
      const newItems = [...state.items];
      newItems[itemIndex] = { ...action.payload };
      return { ...state, items: newItems };
    case CREATE_PRODUCT:
      return {
        ...state,
        items: [...state.items, action.payload],
        lastCreatedProductId: action.payload.id,
      };
    case CLEAN_LAST_CREATED_PRODUCT:
      return { ...state, lastCreatedProductId: null };
    default:
      return state;
  }
}
