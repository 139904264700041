import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { updateProfile } from '../../redux/actions/profile';
import { hideModal, showModal } from '../../redux/actions/modal';
import { confirmPassword, signIn } from '../../redux/actions/auth';

import { getCorrectErrorMessages } from '../../utils/errors';
import { MODAL_TYPES } from '../../constants';

import ConfirmPasswordForm from './ConfirmPasswordForm';
import ChangePasswordForm from './ChangePasswordForm';

import './ChangePasswordForm.scss';

const ChangePassword = ({ goToResetPasswordForm, onSuccessChangePassword }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();

  const [isConfirmed, setIsConfirmed] = useState(false);

  // confirm password

  const [onConfirmPasswordErrors, setOnConfirmPasswordErrors] = useState([]);
  const [confirmPasswordLoading, setConfirmPasswordLoading] = useState(false);

  const confirmPasswordHandler = (data) => {
    setConfirmPasswordLoading(true);
    dispatch(
      confirmPassword({
        data,
        onSuccessCallback: () => setIsConfirmed(true),
        onErrorCallback: (errors) => setOnConfirmPasswordErrors(getCorrectErrorMessages(errors)),
        onFinallyCallback: () => setConfirmPasswordLoading(false),
      }),
    );
  };

  // change password

  const { email } = useSelector((state) => state.profile.data);
  const [changePasswordLoading, setChangePasswordLoading] = useState(false);

  const changePasswordHandler = (data) => {
    setChangePasswordLoading(true);
    dispatch(
      updateProfile({
        data,
        onSuccessCallback: () => {
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Your password has been changed!'),
              onClose: () => {
                dispatch(
                  signIn({
                    data: {
                      email,
                      password: data.password,
                    },
                    onSuccessCallback: () => {
                      history.push('/main');
                      onSuccessChangePassword && onSuccessChangePassword();
                    },
                    onFinallyCallback: () => dispatch(hideModal()),
                  }),
                );
              },
            }),
          );
        },
        onFinallyCallback: () => setChangePasswordLoading(false),
      }),
    );
  };

  // useEffect(() => {
  //     return function cleanup() {
  //         setOnConfirmPasswordErrors([]);
  //         setConfirmPasswordLoading(false);
  //         setChangePasswordLoading(false);
  //     };
  // }, []);

  const renderForm = () => {
    if (isConfirmed) {
      return (
        <ChangePasswordForm
          changePasswordHandler={changePasswordHandler}
          changePasswordLoading={changePasswordLoading}
        />
      );
    }
    return (
      <ConfirmPasswordForm
        confirmPasswordHandler={confirmPasswordHandler}
        goToResetPasswordForm={goToResetPasswordForm}
        onConfirmPasswordErrors={onConfirmPasswordErrors}
        confirmPasswordLoading={confirmPasswordLoading}
      />
    );
  };

  return <div className="changePassword">{renderForm()}</div>;
};

export default ChangePassword;
