// auth
export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';

export const REQUEST_SIGN_IN = 'REQUEST_SIGN_IN';
export const REQUEST_SIGN_OUT = 'REQUEST_SIGN_OUT';

export const REQUEST_SIGN_IN_GOOGLE = 'REQUEST_SIGN_IN_GOOGLE';
export const REQUEST_SIGN_UP_GOOGLE = 'REQUEST_SIGN_UP_GOOGLE';

export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';

export const REQUEST_CONFIRM_PASSWORD = 'REQUEST_CONFIRM_PASSWORD';
export const REQUEST_CONFIRM_EMAIL = 'REQUEST_CONFIRM_EMAIL';

export const SIGN_UP = 'SIGN_UP';
export const SIGN_UP_HOOK_FORM = 'SIGN_UP_HOOK_FORM';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const REQUEST_SIGN_UP = 'REQUEST_SIGN_UP';
export const REQUEST_SIGN_UP_HOOK_FORM = 'REQUEST_SIGN_UP_HOOK_FORM';

export const PASS_EMAIL_VERIFICATION_CODE = 'PASS_EMAIL_VERIFICATION_CODE';
export const REQUEST_EMAIL_VERIFICATION_CODE = 'REQUEST_EMAIL_VERIFICATION_CODE';

// PROFILE

export const GET_PROFILE = 'GET_PROFILE';
export const REQUEST_GET_PROFILE = 'REQUEST_GET_PROFILE';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const REQUEST_UPDATE_PROFILE = 'REQUEST_UPDATE_PROFILE';

export const DELETE_PROFILE = 'DELETE_PROFILE';
export const REQUEST_DELETE_PROFILE = 'REQUEST_DELETE_PROFILE';

export const UPDATE_PROFILE_ERRORS = 'UPDATE_PROFILE_ERRORS';

// Products

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const REQUEST_GET_PRODUCTS = 'REQUEST_GET_PRODUCTS';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const REQUEST_DELETE_PRODUCTS = 'REQUEST_DELETE_PRODUCTS';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const REQUEST_UPDATE_PRODUCTS = 'REQUEST_UPDATE_PRODUCTS';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const REQUEST_CREATE_PRODUCTS = 'REQUEST_CREATE_PRODUCTS';

export const CLEAN_LAST_CREATED_PRODUCT = 'CLEAN_LAST_CREATED_PRODUCT';

// Preference

export const GET_PREFERENCES = 'GET_PREFERENCES';
export const REQUEST_GET_PREFERENCES = 'REQUEST_GET_PREFERENCES';

// Businesses

export const GET_BUSINESSES = 'GET_BUSINESSES';
export const REQUEST_GET_BUSINESSES = 'REQUEST_GET_BUSINESSES';
export const REQUEST_GET_PARTNER_BUSINESSES = 'REQUEST_GET_PARTNER_BUSINESSES';

export const CREATE_BUSINESSES = 'CREATE_BUSINESSES';
export const REQUEST_CREATE_BUSINESSES = 'REQUEST_CREATE_BUSINESSES';

export const CLEAN_CREATE_BUSINESSES_ERRORS = 'CLEAN_CREATE_BUSINESSES_ERRORS';
export const CLEAN_LAST_CREATED_BUSINESS = 'CLEAN_LAST_CREATED_BUSINESS';

export const UPDATE_BUSINESS = 'UPDATE_BUSINESS';
export const REQUEST_UPDATE_BUSINESS = 'REQUEST_UPDATE_BUSINESS';

export const DELETE_BUSINESS = 'DELETE_BUSINESS';
export const REQUEST_DELETE_BUSINESS = 'REQUEST_DELETE_BUSINESS';

export const GET_MEMBERS = 'GET_MEMBERS';
export const REQUEST_GET_MEMBERS = 'REQUEST_GET_MEMBERS';

export const DELETE_MEMBER = 'DELETE_MEMBER';
export const REQUEST_DELETE_MEMBER = 'REQUEST_DELETE_MEMBER';

export const CREATE_MEMBER = 'CREATE_MEMBER';
export const REQUEST_CREATE_MEMBER = 'REQUEST_CREATE_MEMBER';
export const CREATE_MEMBER_ERRORS = 'CREATE_MEMBER_ERRORS';

export const REQUEST_RESEND_MEMBER = 'REQUEST_RESEND_MEMBER';

// User

export const GET_USERS = 'GET_USERS';
export const REQUEST_GET_USERS = 'REQUEST_GET_USERS';

export const GET_USER = 'GET_USER';
export const REQUEST_GET_USER = 'REQUEST_GET_USER';

export const UPDATE_USER = 'UPDATE_USER';
export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';

export const DELETE_USER = 'DELETE_USER';
export const REQUEST_DELETE_USER = 'REQUEST_DELETE_USER';

// Modal

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const PREV_MODAL = 'PREV_MODAL';

// Subscription

export const GET_PLANS = 'GET_PLANS';
export const REQUEST_GET_PLANS = 'REQUEST_GET_PLANS';

// Autopilots

export const GET_AUTOPILOTS = 'GET_AUTOPILOTS';
export const REQUEST_GET_AUTOPILOTS = 'REQUEST_GET_AUTOPILOTS';

export const CREATE_AUTOPILOT = 'CREATE_AUTOPILOT';
export const REQUEST_CREATE_AUTOPILOT = 'REQUEST_CREATE_AUTOPILOT';

export const UPDATE_AUTOPILOT = 'UPDATE_AUTOPILOT';
export const REQUEST_UPDATE_AUTOPILOT = 'REQUEST_UPDATE_AUTOPILOT';

export const DELETE_AUTOPILOT = 'DELETE_AUTOPILOT';
export const REQUEST_DELETE_AUTOPILOT = 'REQUEST_DELETE_AUTOPILOT';

// Statistics

export const GET_CUSTOMER_STATISTICS = 'GET_CUSTOMER_STATISTICS';
export const REQUEST_GET_CUSTOMER_STATISTICS = 'REQUEST_GET_CUSTOMER_STATISTICS';

export const GET_DETAILS_STATISTICS = 'GET_DETAILS_STATISTICS';
export const REQUEST_GET_DETAILS_STATISTICS = 'REQUEST_GET_DETAILS_STATISTICS';

export const GET_GENDER_STATISTICS = 'GET_GENDER_STATISTICS';
export const REQUEST_GET_GENDER_STATISTICS = 'REQUEST_GET_GENDER_STATISTICS';

export const GET_AGE_STATISTICS = 'GET_AGE_STATISTICS';
export const REQUEST_GET_AGE_STATISTICS = 'REQUEST_GET_AGE_STATISTICS';

export const GET_LINES_STATISTICS = 'GET_LINES_STATISTICS';
export const REQUEST_GET_LINES_STATISTICS = 'REQUEST_GET_LINES_STATISTICS';

export const GET_ADMIN_STATISTICS = 'GET_ADMIN_STATISTICS';
export const REQUEST_GET_ADMIN_STATISTICS = 'REQUEST_GET_ADMIN_STATISTICS';

// Slots

export const GET_SLOTS = 'GET_SLOTS';
export const REQUEST_GET_SLOTS = 'REQUEST_GET_SLOTS';

export const REQUEST_BUY_SLOT = 'REQUEST_BUY_SLOT';

export const CREATE_SLOT = 'CREATE_SLOT';
export const REQUEST_CREATE_SLOT = 'REQUEST_CREATE_SLOT';

export const UPDATE_SLOT = 'UPDATE_SLOT';
export const REQUEST_UPDATE_SLOT = 'REQUEST_UPDATE_SLOT';

export const DELETE_SLOT = 'DELETE_SLOT';
export const REQUEST_DELETE_SLOT = 'REQUEST_DELETE_SLOT';

// Messages

export const GET_MESSAGES = 'GET_MESSAGES';
export const REQUEST_GET_MESSAGES = 'REQUEST_GET_MESSAGES';

export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const REQUEST_DELETE_MESSAGE = 'REQUEST_DELETE_MESSAGE';

// Notifications

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const REQUEST_GET_NOTIFICATIONS = 'REQUEST_GET_NOTIFICATIONS';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const REQUEST_DELETE_NOTIFICATION = 'REQUEST_DELETE_NOTIFICATION';

// Newsletters

export const GET_NEWSLETTERS = 'GET_NEWSLETTERS';
export const REQUEST_GET_NEWSLETTERS = 'REQUEST_GET_NEWSLETTERS';

export const CREATE_NEWSLETTER = 'CREATE_NEWSLETTER';
export const REQUEST_CREATE_NEWSLETTER = 'REQUEST_CREATE_NEWSLETTER';

export const UPDATE_NEWSLETTER = 'UPDATE_NEWSLETTER';
export const REQUEST_UPDATE_NEWSLETTER = 'REQUEST_UPDATE_NEWSLETTER';

export const DELETE_NEWSLETTER = 'DELETE_NEWSLETTER';
export const REQUEST_DELETE_NEWSLETTER = 'REQUEST_DELETE_NEWSLETTER';

// Regular Messages

export const GET_REGULAR_MESSAGES = 'GET_REGULAR_MESSAGES';
export const REQUEST_GET_REGULAR_MESSAGES = 'REQUEST_GET_REGULAR_MESSAGES';

export const GET_REGULAR_MESSAGE = 'GET_REGULAR_MESSAGE';
export const REQUEST_GET_REGULAR_MESSAGE = 'REQUEST_GET_REGULAR_MESSAGE';

export const CREATE_REGULAR_MESSAGE = 'CREATE_REGULAR_MESSAGE';
export const REQUEST_CREATE_REGULAR_MESSAGE = 'REQUEST_CREATE_REGULAR_MESSAGE';
