import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { ICONS, DATE_FORMAT } from '../../../constants';
import Icon from '../../Icon';

import './SlotPreview.scss';

const PartnerSlot = ({ createdAt, period, price, region, owner, buyHandler }) => {
  const { t } = useTranslation();

  return (
    <div className="slot">
      <div className="slot-date">
        {t('Publish date')}: {moment(createdAt).format(DATE_FORMAT)}
      </div>
      <table>
        <tbody>
          <tr>
            <td>{t('Region')}:</td>
            <td>{region.location}</td>
          </tr>
          <tr>
            <td>{t('Period')}:</td>
            <td>{period.map((date) => moment(date).format(DATE_FORMAT)).join('-')}</td>
          </tr>
          <tr>
            <td>{t('Price')}:</td>
            <td>{price / 100}</td>
          </tr>
        </tbody>
      </table>
      {owner ? (
        <button disabled className="btn btn--primary btn--small">
          {t('Bought')}
        </button>
      ) : (
        <button onClick={buyHandler} className="btn btn--primary btn--small">
          {t('Buy')}
        </button>
      )}
    </div>
  );
};

const AdminSlot = ({
  createdAt,
  period,
  price,
  region,
  owner,
  deleteHandler,
  editHandler,
  clickHandler,
}) => {
  const { t } = useTranslation();
  return (
    <div className="slot slot--admin" onClick={clickHandler}>
      <div className="slot-date">
        {t('Publish date')}: {moment(createdAt).format(DATE_FORMAT)}
      </div>
      <table>
        <tbody>
          <tr>
            <td>{t('Region')}:</td>
            <td>{region.location}</td>
          </tr>
          <tr>
            <td>{t('Period')}:</td>
            <td>{period.map((date) => moment(date).format(DATE_FORMAT)).join('-')}</td>
          </tr>
          <tr>
            <td>{t('Price')}:</td>
            <td>{price / 100}</td>
          </tr>
        </tbody>
      </table>
      {owner ? (
        <button disabled className="btn btn--primary btn--small">
          {t('Bought')}
        </button>
      ) : (
        <button onClick={editHandler} className="btn btn--primary btn--small">
          {t('Edit')}
        </button>
      )}
      {!owner && deleteHandler ? (
        <button className="card-deleteBtn" onClick={deleteHandler}>
          <Icon icon={ICONS.TRASH} size={16} />
        </button>
      ) : null}
    </div>
  );
};

const SlotPreview = ({
  createdAt,
  period,
  price,
  region,
  owner,
  isForPartner,
  deleteHandler,
  editHandler,
  clickHandler,
  buyHandler,
}) => (
  <>
    {isForPartner ? (
      <PartnerSlot
        createdAt={createdAt}
        period={period}
        price={price}
        region={region}
        owner={owner}
        buyHandler={buyHandler}
      />
    ) : (
      <AdminSlot
        createdAt={createdAt}
        period={period}
        price={price}
        region={region}
        owner={owner}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        clickHandler={clickHandler}
      />
    )}
  </>
);

export default SlotPreview;
