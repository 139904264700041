import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { formSchema, schemaMultiple } from '../../utils/validation_schemas/invitation';

import HookedField from '../HookedField/HookedField';
import FieldError from '../HookedField/FieldError/FieldError';
import Icon from '../Icon';
import { ICONS } from '../../constants';

import './SendInvitations.scss';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import { createMember } from '../../redux/actions/businesses';
import { getCorrectErrorMessages } from '../../utils/errors';

const SendInvitations = ({
  cancelHandler,
  onInvitationsSent,
  businessId,
  isSingle = false,
  skip = false,
}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [invitationsCount, setInvitationsCount] = useState(1);

  const schemaSingle = yup.object().shape(formSchema);

  const createArrayWithNumbers = (length) => Array.from({ length }, (_, k) => k);

  const schema = isSingle ? schemaSingle : schemaMultiple;

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const increaseInvitationsCount = async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      setInvitationsCount(invitationsCount + 1);
    }
  };

  const decreaseInvitationsCount = () => {
    if (invitationsCount - 1 >= 1) {
      setInvitationsCount(invitationsCount - 1);
    }
  };

  // send invitation (create member)

  const [createMemberLoading, setCreateMemberLoading] = useState(false);
  const [onCreateMemberErrors, setOnCreateMemberErrors] = useState([]);

  const sendInvitation = (data) => {
    setCreateMemberLoading(true);
    dispatch(
      createMember({
        id: businessId,
        memberData: data,
        onSuccessCallback: () => {
          setCreateMemberLoading(false);
          onInvitationsSent();
        },
        onErrorCallback: (errors) => {
          setCreateMemberLoading(false);
          setOnCreateMemberErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  function renderSingleFields() {
    return (
      <fieldset>
        <HookedField
          name="firstName"
          placeholder={t('First Name')}
          defaultValue=""
          fieldType="textField"
          type="text"
        />
        <HookedField
          name="lastName"
          placeholder={t('Last Name')}
          defaultValue=""
          fieldType="textField"
          type="text"
        />
        <HookedField
          name="email"
          placeholder={t('Email')}
          defaultValue=""
          fieldType="textField"
          type="email"
        />
      </fieldset>
    );
  }

  function renderFields(count) {
    const additionalArray = createArrayWithNumbers(count);
    return additionalArray.map((number) => (
      <fieldset key={number}>
        <HookedField
          name={`invitations[${number}][firstName]`}
          placeholder={t('First Name')}
          defaultValue=""
          fieldType="textField"
          type="text"
          error={
            methods.errors.invitations &&
            methods.errors.invitations[number] &&
            methods.errors.invitations[number].firstName
          }
        />
        <HookedField
          name={`invitations[${number}][lastName]`}
          placeholder={t('Last Name')}
          defaultValue=""
          fieldType="textField"
          type="text"
          error={
            methods.errors.invitations &&
            methods.errors.invitations[number] &&
            methods.errors.invitations[number].lastName
          }
        />
        <HookedField
          name={`invitations[${number}][email]`}
          placeholder={t('Email')}
          defaultValue=""
          fieldType="textField"
          type="email"
          error={
            methods.errors.invitations &&
            methods.errors.invitations[number] &&
            methods.errors.invitations[number].email
          }
        />
        {number === additionalArray.length - 1 && number > 0 && (
          <button
            type="button"
            className="sendInvitations-close"
            onClick={decreaseInvitationsCount}
          >
            <Icon icon={ICONS.TRASH} size={16} />
          </button>
        )}
      </fieldset>
    ));
  }

  return (
    <FormProvider {...methods}>
      <form
        className="sendInvitations"
        onSubmit={methods.handleSubmit(sendInvitation)}
        autoComplete="off"
      >
        <h2 className="sendInvitations-title">{t('Send Invitation')}</h2>
        <p className="sendInvitations-subtitle">{t('Enter team member address')}</p>
        {isSingle ? renderSingleFields() : renderFields(invitationsCount)}
        <FieldError error={methods.errors.invitations} />
        {!isSingle && (
          <button
            type="button"
            className="btn btn--secondary sendInvitations-btn"
            onClick={increaseInvitationsCount}
          >
            + {t('Add Email')}
          </button>
        )}
        <ErrorMessages errorMessages={onCreateMemberErrors} />
        <Spin spinning={createMemberLoading}>
          <button type="submit" className="btn btn--primary sendInvitations-btn">
            {t('Send Invitation')}
          </button>
        </Spin>
        {skip && (
          <button type="button" className="btn btn--skip" onClick={cancelHandler}>
            {t('Skip')}
          </button>
        )}
      </form>
    </FormProvider>
  );
};

export default SendInvitations;
