import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { Spin } from 'antd';
import BackBtn from '../../BackBtn/BackBtn';
import BusinessPartnerPreview from '../BusinessPartnerPreview/BusinessPartnerPreview';
import BusinessView from '../../Business/BusinessView/BusinessView';
import { PRODUCT_TYPES, MODAL_TYPES } from '../../../constants';
import ProductPreview from '../../Product/ProductPreview/ProductPreview';
import NoData from '../../NoData/NoData';
import { showModal } from '../../../redux/actions/modal';

import './BusinessPartnerView.scss';
import { getPartnerBusinesses } from '../../../redux/actions/businesses';
import { getUser } from '../../../redux/actions/user';

const BusinessPartnerView = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();

  const partnerId = params.id;

  const businesses = useSelector((state) => state.businesses.items);
  const products = useSelector((state) => state.products.items);
  const partner = useSelector((state) => state.users.currentUser);

  const [partnerLoading, setPartnerLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);

  const getPartner = (partnerId) => {
    setPartnerLoading(true);
    dispatch(
      getUser({
        id: partnerId,
        onSuccessCallback: () => {
          setPartnerLoading(false);
        },
        onErrorCallback: () => {
          setPartnerLoading(false);
        },
      }),
    );
  };

  const getDetails = (partnerId) => {
    setDetailsLoading(true);
    dispatch(
      getPartnerBusinesses({
        partnersIds: [partnerId],
        onSuccessCallback: () => {
          setDetailsLoading(false);
        },
        onErrorCallback: () => {
          setDetailsLoading(false);
        },
      }),
    );
  };

  useEffect(() => {
    if (partnerId) {
      getPartner(partnerId);
      getDetails(partnerId);
    }
  }, [partnerId]);

  const partnerEditHandler = () => {
    history.push(`/main/business-partners/${partnerId}/edit-partner`);
  };

  const businessEditHandler = (businessId) => {
    history.push(`/main/business-partners/${partnerId}/edit-business/${businessId}`);
  };

  const partnerDeleteHandler = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.PARTNER_DELETE,
        id: partnerId,
      }),
    );
  };

  const businessDeleteHandler = (id) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.BUSINESS_DELETE,
        id,
      }),
    );
  };

  const renderPartnerCard = () => {
    if (partnerLoading) {
      return <Spin size="large" />;
    }
    if (Object.keys(partner).length) {
      return (
        <div className="businessPartnerView-partnerWrapper">
          <BusinessPartnerPreview
            id={partner.id}
            cover={null}
            name={`${partner.firstName} ${partner.lastName}`}
            phone={partner.phone}
            email={partner.email}
            editHandler={partnerEditHandler}
            deleteHandler={partnerDeleteHandler}
            isPreview
            isLarge
          />
        </div>
      );
    }
    return <NoData />;
  };

  // delete product handler

  const deleteProductHandler = (event, productId) => {
    event.stopPropagation();
    dispatch(
      showModal({
        type: MODAL_TYPES.PRODUCT_DELETE,
        id: productId,
      }),
    );
  };

  // click product handler

  const clickProductHandler = (productId) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.PRODUCT_VIEW,
        id: productId,
      }),
    );
  };

  const renderBusinesses = () => {
    if (businesses.length) {
      return (
        <div className="businessPartnerView-businessWrapper">
          {businesses.map((business) => (
            <BusinessView
              key={business.id}
              cover={business.cover}
              title={business.title}
              description={business.description}
              schedule={business.schedule}
              phones={business.phones}
              networks={business.networks}
              address={business.address}
              preferences={business.preferences}
              products={business.products}
              editHandler={() => businessEditHandler(business.id)}
              deleteHandler={() => businessDeleteHandler(business.id)}
            />
          ))}
        </div>
      );
    }
    return <NoData />;
  };

  const renderCards = () => {
    const cards = products.filter((product) => product.type === PRODUCT_TYPES.CARD);
    if (cards.length) {
      return (
        <div className="cards">
          {cards.map((card) => (
            <ProductPreview
              key={card.id}
              title={card.title}
              cover={card.cover}
              miniCover={card.miniCover}
              period={card.period}
              expirationDays={card.expirationDays}
              deleteHandler={(event) => deleteProductHandler(event, card.id)}
              clickHandler={() => clickProductHandler(card.id)}
            />
          ))}
        </div>
      );
    }
    return <NoData />;
  };

  const renderGifts = () => {
    const gifts = products.filter((product) => product.type === PRODUCT_TYPES.GIFT);
    if (gifts.length) {
      return (
        <div className="cards">
          {gifts.map((gift) => (
            <ProductPreview
              key={gift.id}
              title={gift.title}
              cover={gift.cover}
              miniCover={gift.miniCover}
              period={gift.period}
              expirationDays={gift.expirationDays}
              deleteHandler={(event) => deleteProductHandler(event, gift.id)}
              clickHandler={() => clickProductHandler(gift.id)}
            />
          ))}
        </div>
      );
    }
    return <NoData />;
  };

  return (
    <div className="businessPartnerView">
      <div className="businessPartnerView">
        <BackBtn />
        {renderPartnerCard()}
        {detailsLoading ? (
          <Spin size="large" />
        ) : (
          <>
            <h3 className="pageSubtitle">{t('Business Profiles')}</h3>
            {renderBusinesses()}
            <h3 className="pageSubtitle">{t('Cards')}</h3>
            {renderCards()}
            <h3 className="pageSubtitle">{t('Gifts')}</h3>
            {renderGifts()}
          </>
        )}
      </div>
    </div>
  );
};

export default BusinessPartnerView;
