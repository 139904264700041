import moment from 'moment';

export const getFormattedGetNewsletterData = (data) => {
  const formattedData = {};

  // subject
  if (data.subject) {
    formattedData.subject = data.subject;
  }

  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  }

  // description
  if (data.description) {
    formattedData.description = data.description;
  }

  // slots
  if (data.slots && data.slots.length) {
    formattedData.slots = data.slots.map((slot) => ({
      ...slot,
      price: slot.price / 100,
    }));
  }

  // date
  if (data.date) {
    formattedData.date = moment(data.date);
  }

  // mailingTime
  if (data.mailingTime) {
    formattedData.mailingTime = moment().startOf('day').add(data.mailingTime, 'seconds');
  }

  return formattedData;
};

export const getFormattedPostNewsletterData = (data) => {
  const formattedData = {};

  // subject
  if (data.subject) {
    formattedData.subject = data.subject;
  }

  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  }

  // description
  if (data.description) {
    formattedData.description = data.description;
  }

  // slots
  if (data.slots && data.slots.length) {
    formattedData.slots = data.slots.map(({ id, price, ...rest }) => {
      if (id) {
        return {
          id: parseInt(id),
          price: price * 100,
          ...rest,
        };
      }
      return {
        price: price * 100,
        ...rest,
      };
    });
  }

  // date
  if (data.date) {
    formattedData.date = moment(data.date).toISOString();
  }

  // mailingTime
  if (data.mailingTime) {
    formattedData.mailingTime = moment(data.mailingTime, 'HH:mm:ss: A').diff(
      moment().startOf('day'),
      'seconds',
    );
  }

  return formattedData;
};
