import { GET_PLANS } from '../types';

const initialState = {
  items: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PLANS:
      return { ...state, items: action.payload };
    default:
      return state;
  }
}
