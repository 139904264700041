import { GET_USERS, DELETE_USER, GET_USER, UPDATE_USER } from '../types';

const initialState = {
  items: [],
  currentUser: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USERS:
      return { ...state, items: action.payload };
    case GET_USER:
      return { ...state, currentUser: action.payload };
    case UPDATE_USER:
      const itemIndex = state.items.findIndex((item) => item.id === action.payload.id);
      const newItems = [...state.items];
      newItems[itemIndex] = { ...action.payload };
      return { ...state, items: newItems };
    case DELETE_USER:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
        currentUser: {},
      };
    default:
      return state;
  }
}
