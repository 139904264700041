import React from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';
import AvatarMember from '../../../svg/avatarMember';

const MemberPreview = ({ member, deleteHandler, resendHandler, loading = false }) => {
  const { t } = useTranslation();

  const name = `${member.firstName} ${member.lastName}`;
  return (
    <Spin spinning={loading}>
      <div className="card card--team">
        <div className="card-icon">
          <AvatarMember />
        </div>
        <div className="card-content">
          <h3 className="card-title" title={member.name}>
            {name}
          </h3>
          {member.phone && (
            <p className="card-contact card-contact--phone">
              <Icon icon={ICONS.PHONE} size={16} color="#FABB18" />
              <span className="card-contact-text" title={member.phone}>
                {member.phone}
              </span>
            </p>
          )}
          {member.email && (
            <p className="card-contact card-contact--email">
              <Icon icon={ICONS.EMAIL} size={16} color="#FABB18" />
              <span className="card-contact-text" title={member.email}>
                {member.email}
              </span>
            </p>
          )}
        </div>
        {deleteHandler && (
          <button title={t('Delete member')} className="card-deleteBtn" onClick={deleteHandler}>
            <Icon icon={ICONS.TRASH} size={16} />
          </button>
        )}
        {member.status === 'PENDING' && (
          <button
            className="btn btn--small btn--secondary card-resendBtn"
            onClick={() => resendHandler(member.id)}
          >
            {t('Resend')}
          </button>
        )}
      </div>
    </Spin>
  );
};

export default MemberPreview;
