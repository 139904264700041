import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ICONS } from '../../constants';

import Icon from '../Icon';

import './Search.scss';

const Search = ({ value, onChange }) => {
  const { t } = useTranslation();
  return (
    <label className="search">
      <Icon className="search-icon" icon={ICONS.SEARCH} size={20} color="#FABB18" />
      <input
        className="search-input"
        type="search"
        name="search"
        placeholder={t('Search')}
        autoComplete="off"
        value={value}
        onChange={onChange}
      />
    </label>
  );
};

Search.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Search;
