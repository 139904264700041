import * as yup from 'yup';

export const formSchema = {
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup.string().required('This field is required').email('It must be a valid email'),
};

export const schemaMultiple = yup.object().shape({
  invitations: yup
    .array()
    .of(yup.object().shape(formSchema))
    .required('Must have fields')
    .min(1, 'Minimum of 1 field'),
});
