import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Select, Spin } from 'antd';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { getAdminStatistics } from '../../../redux/actions/statistics';

import {
  ICONS,
  ADMIN_STATISTICS_OPTIONS,
  ADMIN_STATISTICS_PERIODS,
  ADMIN_STATISTICS_TABS,
} from '../../../constants';
import { sortByAntiquity } from '../../../utils/sorters';

import AdminStatisticsInfo from './AdminStatisticsInfo';
import AdminStatisticsTabs from './AdminStatisticsTabs';
import NoData from '../../NoData/NoData';
import Icon from '../../Icon';

const defaultRatio = {
  day: {
    current: '0',
    previous: '0',
  },
  week: {
    current: '0',
    previous: '0',
  },
  month: {
    current: '0',
    previous: '0',
  },
};

const AdminStatistics = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { adminStatistics } = useSelector((state) => state.statistics);

  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState(ADMIN_STATISTICS_PERIODS.DAY);
  const [option, setOption] = useState(ADMIN_STATISTICS_OPTIONS.BP_PROFILE_CREATED);
  const [ratio, setRatio] = useState(defaultRatio);
  const [chartTitle, setChartTitle] = useState(ADMIN_STATISTICS_TABS[0].title);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getAdminStatistics({
        period,
        option,
        onSuccessCallback: () => setLoading(false),
        onErrorCallback: () => setLoading(false),
      }),
    );
  }, [period, option]);

  useEffect(() => {
    if (adminStatistics && Object.keys(adminStatistics).length) {
      // ratio

      setRatio(Object.assign(defaultRatio, adminStatistics.ratio));
      // chart data

      const initialChartData = adminStatistics.chart;

      initialChartData.sort(sortByAntiquity);

      const lineChartData = initialChartData.map(({ total, createdAt }) => {
        const format = period === ADMIN_STATISTICS_PERIODS.DAY ? 'DD/MM hh:mm' : 'D MMM';
        return {
          name: moment(createdAt).format(format),
          value: parseInt(total),
        };
      });

      setChartData(lineChartData);
    }
  }, [adminStatistics]);

  const changeOptionHandler = (option, title) => {
    setOption(option);
    setChartTitle(title);
  };

  return (
    <div className="statistics">
      <div className="statistic-container">
        <h2 className="pageTitle">{t('Statistic')}</h2>
        <AdminStatisticsTabs
          tabs={ADMIN_STATISTICS_TABS.map((tab) => ({
            ...tab,
            title: t(tab.title),
          }))}
          activeTab={option}
          changeOptionHandler={changeOptionHandler}
        />
        {loading ? (
          <Spin
            size="large"
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ) : Object.keys(adminStatistics).length ? (
          <div className="adminStatistic-grid">
            <div className="chart-wrapper">
              <div className="chart-header">
                <h3>{chartTitle}</h3>
                <Select
                  className="chart-date"
                  showArrow
                  defaultValue={period}
                  onChange={(value) => setPeriod(value)}
                  suffixIcon={<Icon size={16} icon={ICONS.ARROW_DOWN} color="#FABB18" />}
                  options={[
                    { value: ADMIN_STATISTICS_PERIODS.DAY, label: t('Day') },
                    { value: ADMIN_STATISTICS_PERIODS.WEEK, label: t('Week') },
                    { value: ADMIN_STATISTICS_PERIODS.MONTH, label: t('Month') },
                  ]}
                />
              </div>
              <ResponsiveContainer width="100%" aspect={2.0 / 1.5}>
                <LineChart
                  data={chartData}
                  margin={{
                    top: 40,
                    left: 0,
                  }}
                >
                  <CartesianGrid stroke="#F0F0F0" strokeWidth={1} />
                  <XAxis
                    axisLine={{ stroke: '#F0F0F0', strokeWidth: 1 }}
                    tickLine={false}
                    dataKey="name"
                  />
                  <YAxis axisLine={{ stroke: '#F0F0F0', strokeWidth: 1 }} tickLine={false} />
                  <Tooltip
                    contentStyle={{
                      border: 'none',
                      boxShadow: '0px 4px 20px rgba(30, 30, 52, 0.05)',
                    }}
                  />
                  <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 4 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className="adminStatistic-info">
              <AdminStatisticsInfo
                title={t('Today')}
                current={ratio.day.current}
                previous={ratio.day.previous}
                style="purple"
              />
              <AdminStatisticsInfo
                title={t('This week')}
                current={ratio.week.current}
                previous={ratio.week.previous}
                style="blue"
              />
              <AdminStatisticsInfo
                title={t('This month')}
                current={ratio.month.current}
                previous={ratio.month.previous}
                style="yellow"
              />
            </div>
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default AdminStatistics;
