import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { schema } from '../../utils/validation_schemas/gender';

import GenderFemaleIcon from '../../svg/genderFemaleIcon';
import GenderMaleIcon from '../../svg/genderMaleIcon';
import GenderOtherIcon from '../../svg/genderOtherIcon';
import InputError from '../HookedField/FieldError/FieldError';
import ErrorMessages from '../ErrorMessages/ErrorMessages';

const Gender = ({
  updateGenderHandler,
  updateGenderLoading,
  onUpdateGenderErrors,
  onSkipClick,
}) => {
  const { t } = useTranslation();

  const { handleSubmit, register, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <form className="form form--signIn" onSubmit={handleSubmit(updateGenderHandler)}>
      <h1 className="form-heading">{t('What is your gender?')}</h1>
      <div className="genderPicker">
        <label className="genderPicker-item">
          <input
            className="genderPicker-input"
            type="radio"
            name="gender"
            ref={register}
            value="FEMALE"
          />
          <div className="genderPicker-box">
            <GenderFemaleIcon />
          </div>
        </label>
        <label className="genderPicker-item">
          <input
            className="genderPicker-input"
            type="radio"
            name="gender"
            ref={register}
            value="MALE"
          />
          <div className="genderPicker-box">
            <GenderMaleIcon />
          </div>
        </label>
        <label className="genderPicker-item">
          <input
            className="genderPicker-input"
            type="radio"
            name="gender"
            ref={register}
            value="OTHER"
            defaultChecked
          />
          <div className="genderPicker-box">
            <GenderOtherIcon />
            {t('Other')}
          </div>
        </label>
      </div>
      <InputError error={errors.gender} />
      <ErrorMessages errorMessages={onUpdateGenderErrors} />
      <div className="form-actions">
        <Spin spinning={updateGenderLoading}>
          <button type="submit" className="btn btn--primary">
            {t('Submit')}
          </button>
        </Spin>
        <button type="button" className="btn btn--skip" onClick={onSkipClick}>
          {t('Skip')}
        </button>
      </div>
    </form>
  );
};

export default Gender;
