import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { getFormattedGetNewsletterData } from '../../../utils/newsletters';
import { getCorrectErrorMessages } from '../../../utils/errors';

import Api from '../../../redux/middlewares/Api';

import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import NewsletterSlot from '../NewsletterSlot/NewsletterSlot';

import './NewsletterView.scss';
import { showModal } from '../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../constants';
import { sortById } from '../../../utils/sorters';

const api = new Api();

const NewsletterView = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  // get newsletter

  const [newsletterData, setNewsletterData] = useState({});
  const [newsletterLoading, setNewsletterLoading] = useState(true);
  const [onGetNewsletterErrors, setOnGetNewsletterErrors] = useState([]);

  useEffect(() => {
    api.getNewsletter(id).then(
      (newsletter) => {
        const formattedData = getFormattedGetNewsletterData(newsletter);
        setNewsletterData(formattedData);
        setNewsletterLoading(false);
      },
      (errors) => {
        setNewsletterLoading(false);
        setOnGetNewsletterErrors(getCorrectErrorMessages(errors));
      },
    );
  }, [id]);

  // save and buy slot

  const [loadingSlotId, setLoadingSlotId] = useState(null);
  const [onBuySlotErrors, setOnBuySlotErrors] = useState({
    id: null,
    errors: [],
  });

  const slotSubmitHandler = (slotId, data) => {
    setLoadingSlotId(slotId);
    api.buyNewsletterSlot(id, slotId, data).then(
      (response) => {
        setLoadingSlotId(null);
        dispatch(
          showModal({
            type: MODAL_TYPES.NOTIFICATION,
            title: t('Your newsletter slot was successfully saved and bought'),
          }),
        );
      },
      (errors) => {
        setOnBuySlotErrors({
          id: slotId,
          errors: getCorrectErrorMessages(errors),
        });
        setLoadingSlotId(null);
      },
    );
  };

  return (
    <div className="newsletterView">
      <h3 className="newsletterView-heading">{t('Newsletter')}</h3>
      {newsletterLoading ? (
        <Spin size="large" />
      ) : Object.keys(newsletterData).length ? (
        <>
          <div className="newsletterView-info">
            <h4 className="newsletterView-info-subject">{newsletterData.subject}</h4>
            <div className="newsletterView-info-imgWrapper">
              <img
                className="newsletterView-info-img"
                src={newsletterData.cover}
                alt={newsletterData.subject}
              />
            </div>
            <div className="newsletterView-info-description">{newsletterData.description}</div>
          </div>
          {newsletterData.slots && newsletterData.slots.length
            ? newsletterData.slots
                .sort(sortById)
                .map(({ id, price, owner, subject, description, cover }) => (
                  <NewsletterSlot
                    key={id}
                    id={id}
                    subject={subject}
                    description={description}
                    cover={cover}
                    price={price}
                    owner={owner}
                    loading={loadingSlotId === id}
                    onSubmitErrors={onBuySlotErrors.id === id ? onBuySlotErrors.errors : []}
                    submitHandler={(data) => slotSubmitHandler(id, data)}
                  />
                ))
            : null}
        </>
      ) : (
        <ErrorMessages errorMessages={onGetNewsletterErrors} />
      )}
    </div>
  );
};

export default NewsletterView;
