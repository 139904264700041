import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import './AccessModal.scss';
import { hideModal } from '../../../redux/actions/modal';

const AccessModal = ({ title, description }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const history = useHistory();
  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const changeHandler = () => {
    dispatch(hideModal());
    history.push('/main');
  };

  return (
    <div className="accessModal">
      <h2 className="accessModal-title">{title}</h2>
      {description && <p className="accessModal-description">{description}</p>}
      <div className="modal-actions">
        <button className="btn btn--secondary" onClick={cancelHandler}>
          {t('Later')}
        </button>
        <button className="btn btn--primary" onClick={changeHandler}>
          {t('Change')}
        </button>
      </div>
    </div>
  );
};

AccessModal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default AccessModal;
