import React from 'react';
import { getDateString, getShortString } from '../../../utils/additional';

import './NewsletterPreview.scss';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';

const NewsletterPreview = ({
  subject,
  description,
  date,
  deleteHandler,
  clickHandler,
  className,
}) => {
  const classes = ['newsletterPreview', className, clickHandler ? 'newsletterPreview--hover' : ''];

  return (
    <div className={classes.join(' ')} onClick={clickHandler}>
      <h4 className="newsletterPreview-title">{getShortString(subject, 25)}</h4>
      <div className="newsletterPreview-description">{getShortString(description, 95)}</div>
      <div className="newsletterPreview-extra">
        <div className="newsletterPreview-date">{getDateString(date)}</div>
        {deleteHandler && (
          <button className="newsletterPreview-deleteBtn" onClick={deleteHandler}>
            <Icon icon={ICONS.TRASH} size={16} />
          </button>
        )}
      </div>
    </div>
  );
};

export default NewsletterPreview;
