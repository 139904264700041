import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { ICONS } from '../../constants';
import Icon from '../Icon';

import './Sidebar.scss';

const AdminSidebar = ({ handleSignOut }) => {
  const { t } = useTranslation();
  return (
    <aside className="sidebar">
      <div className="sidebar-container">
        <nav>
          <NavLink className="menuLink" exact to="/main" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.USER} />
            {t('My Profile')}
          </NavLink>
          <NavLink className="menuLink" to="/main/statistics" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.STATISTIC} />
            {t('Statistics')}
          </NavLink>
          <NavLink className="menuLink" to="/main/business-partners" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.FLAG} />
            {t('Business Partners')}
          </NavLink>
          <NavLink className="menuLink" to="/main/additional-offers" activeClassName="selected">
            <Icon className="menuLink-icon" icon={ICONS.OFFERS} />
            {t('Additional Offers')}
          </NavLink>
        </nav>
        <button className="menuLink menuLink--logOut" onClick={handleSignOut}>
          <Icon className="menuLink-icon" icon={ICONS.LOG_OUT} />
          {t('Log out')}
        </button>
      </div>
    </aside>
  );
};

export default AdminSidebar;
