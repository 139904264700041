import i18n from '../i18n';
import { AUTOPILOT_TYPES } from '../constants';
import { getMillisecondsToTime, getTimeToMilliseconds } from './additional';

export const getFormattedGetAutopilotData = (data) => {
  const formattedData = {};

  // status
  if (data.status) {
    formattedData.status = data.status;
  }

  // type
  if (data.type) {
    formattedData.type = data.type;
  }

  // title
  if (data.title) {
    formattedData.title = data.title;
  }

  // subject
  if (data.subject) {
    formattedData.subject = data.subject;
  }

  // description
  if (data.description) {
    formattedData.description = data.description;
  }

  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  }

  if (data.products && data.products.length) {
    formattedData.products = data.products;
  }

  // point
  if (data.points) {
    formattedData.points = data.points;
  }

  // points receive

  if (data.pointsReceived) {
    formattedData.pointsReceived = data.pointsReceived;
  }

  // mailingTime
  if (data.mailingTime) {
    formattedData.mailingTime = getMillisecondsToTime(data.mailingTime);
  }

  return formattedData;
};

export const getFormattedPostAutopilotData = (data) => {
  const formattedData = {};

  // status
  formattedData.status = 'ENABLED';

  // type
  if (data.type) {
    formattedData.type = data.type;
  }

  // title
  if (data.title) {
    formattedData.title = data.title;
  }

  // subject
  if (data.subject) {
    formattedData.subject = data.subject;
  }

  // description
  if (data.description) {
    formattedData.description = data.description;
  }

  // cover
  if (data.cover) {
    formattedData.cover = data.cover;
  }

  // products
  if (data.products && data.products.length) {
    formattedData.products = data.products.map((product) => ({ id: product.id }));
  } else {
    formattedData.products = [];
  }

  // point
  if (data.points) {
    formattedData.points = data.points;
  } else {
    formattedData.points = 0;
  }

  // points receive

  if (data.pointsReceived) {
    formattedData.pointsReceived = data.pointsReceived;
  } else {
    formattedData.pointsReceived = 0;
  }

  // mailingTime
  if (data.mailingTime) {
    formattedData.mailingTime = getTimeToMilliseconds(data.mailingTime);
  } else {
    formattedData.mailingTime = 0;
  }

  return formattedData;
};

export const renderAutopilotType = (type) => {
  switch (type) {
    case AUTOPILOT_TYPES.WELCOME:
      return i18n.t('autopilots.Welcome.title');
    case AUTOPILOT_TYPES.FIRST_VISIT:
      return i18n.t('autopilots.First Visit.title');
    case AUTOPILOT_TYPES.FREQUENT_CUSTOMER:
      return i18n.t('autopilots.Frequent Customer Message.title');
    case AUTOPILOT_TYPES.BIRTHDAY:
      return i18n.t('autopilots.Birthday Message.title');
    case AUTOPILOT_TYPES.REACTIVATION:
      return i18n.t('autopilots.Reactivation Message.title');
    case AUTOPILOT_TYPES.NEW_OFFERING:
      return i18n.t('autopilots.New Offering Message.title');
    default:
      return '';
  }
};
