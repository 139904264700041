import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';

import {
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  SIGN_UP_HOOK_FORM,
  REQUEST_SIGN_IN,
  REQUEST_RESET_PASSWORD,
  REQUEST_SIGN_OUT,
  REQUEST_SIGN_UP,
  REQUEST_SIGN_UP_HOOK_FORM,
  REQUEST_CONFIRM_PASSWORD,
  REQUEST_CONFIRM_EMAIL,
  REQUEST_SIGN_UP_GOOGLE,
  REQUEST_SIGN_IN_GOOGLE,
} from '../types/index';
import { ROLES } from '../../constants';

const api = new Api();

function* signInWatcher() {
  yield takeEvery(REQUEST_SIGN_IN, signInWorker);
}

function* resetPasswordWatcher() {
  yield takeEvery(REQUEST_RESET_PASSWORD, resetPasswordWorker);
}

function* signOutWatcher() {
  yield takeEvery(REQUEST_SIGN_OUT, signOutWorker);
}

function* signUpWatcher() {
  yield takeEvery(REQUEST_SIGN_UP, signUpWorker);
}

function* signInGoogleWatcher() {
  yield takeEvery(REQUEST_SIGN_IN_GOOGLE, signInGoogleWorker);
}

function* signUpGoogleWatcher() {
  yield takeEvery(REQUEST_SIGN_UP_GOOGLE, signUpGoogleWorker);
}

function* signUpHookFormWatcher() {
  yield takeEvery(REQUEST_SIGN_UP_HOOK_FORM, signUpHookFormWorker);
}

function* confirmPasswordWatcher() {
  yield takeEvery(REQUEST_CONFIRM_PASSWORD, confirmPasswordWorker);
}

function* confirmEmailWatcher() {
  yield takeEvery(REQUEST_CONFIRM_EMAIL, confirmEmailWorker);
}

function* signInWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const response = yield call(() => api.signIn(data));
    if (response.role !== ROLES.ADMIN && response.role !== ROLES.PARTNER) {
      throw ['Please sign in as business partner or admin'];
    }
    Api.setToken(response.token);
    yield put({ type: SIGN_IN });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* resetPasswordWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.resetPassword(data));
    Api.removeToken();
    onSuccessCallback && onSuccessCallback(data.email);
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* signOutWorker({ payload: { onSuccessCallback, onErrorCallback, onFinallyCallback } }) {
  try {
    yield put({ type: SIGN_OUT });
    Api.removeToken();
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* signUpWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const response = yield call(api.signUp, data);
    Api.setToken(response.token);
    yield put({ type: SIGN_UP });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* signUpHookFormWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield put({ type: SIGN_UP_HOOK_FORM, payload: data });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* confirmPasswordWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.confirmPassword(data));
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* confirmEmailWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.confirmEmail(data));
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* signInGoogleWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const response = yield call(() => api.signInGoogle(data));
    if (response.role !== ROLES.ADMIN && response.role !== ROLES.PARTNER) {
      throw ['Please sign in as business partner or admin'];
    }
    Api.setToken(response.token);
    yield put({ type: SIGN_IN });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* signUpGoogleWorker({
  payload: { data, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const response = yield call(() => api.signUpGoogle(data));
    Api.setToken(response.token);
    yield put({ type: SIGN_UP });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* auth() {
  yield all([
    fork(signInWatcher),
    fork(signOutWatcher),
    fork(signUpWatcher),
    fork(signUpHookFormWatcher),
    fork(resetPasswordWatcher),
    fork(confirmPasswordWatcher),
    fork(confirmEmailWatcher),
    fork(signInGoogleWatcher),
    fork(signUpGoogleWatcher),
  ]);
}
