import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CategoriesItem from './CategoriesItem/CategoriesItem';

import './Categories.scss';

const Categories = ({ defaultValue = [], name, options, isPreview, className }) => {
  const [currentlyChecked, setCurrentlyChecked] = useState(defaultValue);

  const classes = ['categories', className];

  const { register, setValue, clearErrors } = useFormContext();

  useEffect(() => {
    if (!isPreview) {
      register('preferences');
    }
  }, []);

  useEffect(() => {
    clearErrors('preferences');
    setValue('preferences', currentlyChecked);
  }, [currentlyChecked]);

  const changeHandler = (event) => {
    const id = parseInt(event.target.value);
    if (currentlyChecked.findIndex((item) => item.id === id) !== -1) {
      setCurrentlyChecked(currentlyChecked.filter((item) => item.id !== id));
    } else {
      setCurrentlyChecked([...currentlyChecked, { id }]);
    }
  };

  return (
    <div className={classes.join(' ')}>
      {options.map((item) => (
        <CategoriesItem
          key={item.id}
          title={item.title}
          value={item.id}
          name={name}
          icon={item.cover}
          isPreview={isPreview}
          checked={currentlyChecked.findIndex((obj) => obj.id === item.id) !== -1}
          onChange={changeHandler}
        />
      ))}
    </div>
  );
};

export default Categories;
