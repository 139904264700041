import * as yup from 'yup';
import 'yup-phone';

export const schema = yup.object().shape({
  firstName: yup.string().required('This field is required'),
  lastName: yup.string().required('This field is required'),
  email: yup.string().required('This field is required').email('It must be a valid email'),
  phone: yup.string().phone('CH', true, 'Invalid phone').required('This field is required'),
  terms: yup
    .boolean()
    .required('This field is required')
    .oneOf([true], 'The terms and conditions must be accepted.'),
});
