import React from 'react';

const AvatarPlaceholder = ({ className }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse cx="32.5287" cy="17.7847" rx="1.43619" ry="2.73393" fill="#FFDDCE" />
    <ellipse
      rx="1.43619"
      ry="2.73393"
      transform="matrix(-1 0 0 1 15.3343 18.8199)"
      fill="#FFDDCE"
    />
    <circle cx="23.8799" cy="23.782" r="22.2" fill="white" />
    <path
      d="M24.7584 36.2081L25.976 35.4916L26.5355 36.2081L27.9397 25.6367L20.2026 25.6367L21.2997 36.2081H24.7584Z"
      fill="#EFEFEF"
    />
    <path
      d="M35.4657 32.6303L27.9163 31.325C25.6225 32.7921 26.2922 33.1048 24.155 33.1048C22.2136 32.8726 21.4451 30.5393 18.763 31.325L12.1641 32.6303C9.65115 33.1274 7.83496 35.3875 7.83496 38.0178V40.1269C11.9167 44.2965 17.5346 46.8795 23.7395 46.9003H23.7418C23.7788 46.9004 23.8311 46.9005 23.888 46.9003H23.8903C30.0953 46.8796 35.7131 44.2966 39.7948 40.1269V38.0178C39.7948 35.3875 37.9786 33.1274 35.4657 32.6303Z"
      fill="#7F7F7F"
    />
    <path
      d="M8.54452 36.9905L7.65508 36.853L7.65349 36.8633L7.65214 36.8736L8.54452 36.9905ZM7.16133 40.6214C7.09679 41.1143 7.444 41.5661 7.93685 41.6307C8.42969 41.6952 8.88155 41.348 8.94609 40.8552L7.16133 40.6214ZM20.4124 31.3242C20.3141 30.4296 20.314 30.4296 20.3139 30.4296C20.3138 30.4296 20.3137 30.4296 20.3135 30.4297C20.3133 30.4297 20.3129 30.4297 20.3124 30.4298C20.3114 30.4299 20.31 30.4301 20.3082 30.4303C20.3046 30.4307 20.2992 30.4312 20.2923 30.432C20.2784 30.4336 20.2581 30.4359 20.2316 30.4389C20.1785 30.4449 20.101 30.4538 20.0019 30.4655C19.8036 30.4888 19.5188 30.5231 19.1711 30.5675C18.4762 30.6561 17.5274 30.7848 16.5141 30.9452C15.5027 31.1052 14.4172 31.2984 13.452 31.5166C12.5102 31.7295 11.6036 31.9831 10.9989 32.2844L11.8017 33.8955C12.1949 33.6995 12.9138 33.4837 13.8489 33.2723C14.7606 33.0662 15.8034 32.8801 16.7955 32.723C17.7858 32.5663 18.7157 32.4401 19.3987 32.353C19.74 32.3095 20.0189 32.2759 20.2122 32.2531C20.3088 32.2418 20.3839 32.2331 20.4346 32.2274C20.46 32.2245 20.4793 32.2223 20.4921 32.2209C20.4985 32.2202 20.5033 32.2197 20.5064 32.2193C20.5079 32.2191 20.5091 32.219 20.5098 32.2189C20.5102 32.2189 20.5104 32.2189 20.5106 32.2188C20.5107 32.2188 20.5107 32.2188 20.5107 32.2188C20.5107 32.2188 20.5107 32.2188 20.4124 31.3242ZM10.9989 32.2844C8.67282 33.4435 7.89366 35.3089 7.65508 36.853L9.43397 37.1279C9.60964 35.991 10.1358 34.7256 11.8017 33.8955L10.9989 32.2844ZM7.65214 36.8736L7.16133 40.6214L8.94609 40.8552L9.4369 37.1073L7.65214 36.8736Z"
      fill="black"
    />
    <path
      d="M27.5117 31.3242C27.5117 31.3242 34.5279 32.0953 36.5239 33.0899C38.5198 34.0845 39.1725 35.6499 39.3796 36.9905L39.8354 40.811"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M27.4601 26.363C27.7057 26.3284 27.9368 26.2938 28.146 26.2613C28.1093 27.7186 28.1397 28.8552 28.1624 29.7016C28.1739 30.1334 28.1835 30.4896 28.181 30.7743C28.1765 31.3011 28.1338 31.5518 28.0732 31.7029C28.031 31.8081 27.9615 31.9158 27.7211 32.0738C25.6749 33.4186 22.709 33.3381 20.5206 32.0361C20.236 31.8667 20.1481 31.7524 20.099 31.6538C20.0355 31.5261 19.9772 31.301 19.9625 30.8022C19.9493 30.3548 19.9712 29.786 20.0016 28.9965C20.005 28.9081 20.0085 28.817 20.0121 28.7229C20.0381 28.0394 20.0665 27.2259 20.0794 26.2542C20.3004 26.2888 20.5472 26.3259 20.8106 26.363C21.8135 26.5042 23.1027 26.6521 24.1354 26.6521C25.1681 26.6521 26.4573 26.5042 27.4601 26.363Z"
      stroke="black"
      strokeWidth="1.8"
      strokeLinecap="round"
    />
    <rect
      x="16.4627"
      y="8.38047"
      width="15.0744"
      height="20.5022"
      rx="7.53722"
      fill="#EFEFEF"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M31.6116 14.5578C34.8523 14.136 34.6444 20.9825 31.6116 20.4897"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      d="M16.2515 14.5578C13.0107 14.136 13.2186 20.9825 16.2515 20.4897"
      stroke="black"
      strokeWidth="1.8"
    />
    <circle cx="24" cy="24" r="23.1" stroke="black" strokeWidth="1.8" />
    <path
      d="M30.6589 16.8708C29.7195 9.08866 26.2291 12.8609 27.9482 10.9336L28.0799 16.8708C27.9482 24.391 25.5952 27.2744 22.8188 27.7627C23.4006 28.0296 24.346 28.0522 24.8706 28.0159C28.203 27.7851 31.5983 24.6529 30.6589 16.8708Z"
      fill="#E6E6E6"
    />
    <path
      d="M30.7503 20.8251C30.0016 29.7856 25.254 20.9633 28.1593 23.1628L27.9408 19.339C28.1593 11.5073 25.7195 10.1792 23.0986 9.69096C23.6479 9.42405 24.5403 9.40145 25.0355 9.43778C28.1705 9.21324 31.4991 11.8646 30.7503 20.8251Z"
      fill="#E6E6E6"
    />
    <path
      d="M19.9951 5.16016L26.278 5.74291L28.8734 6.94081L31.6112 10.8764L31.7477 12.9397L29.7974 12.6903L24.7154 10.654L15.8511 13.6021L16.3138 9.30211L18.251 6.68187L19.9951 5.16016Z"
      fill="#717171"
    />
    <path
      d="M26.2902 10.8864H21.4113L15.7224 13.7403L16.1956 10.5122C16.2863 9.89343 16.6056 9.33806 16.8989 8.78573C17.2498 8.12513 17.3996 7.34179 17.6413 6.24408C19.3603 6.44807 18.7825 5.75351 20.0259 4.7031L26.4904 5.39193L28.5186 6.31201C28.9951 6.52816 29.4068 6.86517 29.7127 7.28959L31.443 9.68958C31.7653 10.1366 31.9582 10.6638 32.0006 11.2133L32.1956 13.7403L26.2902 10.8864Z"
      stroke="black"
      strokeWidth="1.8"
    />
    <path
      opacity="0.2"
      d="M24.5694 0.703125C23.5661 0.703125 22.5776 0.766594 21.6077 0.889688C33.0766 2.34478 41.9444 12.1375 41.9444 24.0016C41.9444 35.8657 33.0766 45.6585 21.6077 47.1135C22.5776 47.2366 23.5661 47.3001 24.5694 47.3001C37.4368 47.3001 47.8679 36.869 47.8679 24.0016C47.8679 11.1342 37.4368 0.703125 24.5694 0.703125V0.703125Z"
      fill="#A1A1A1"
    />
  </svg>
);

export default AvatarPlaceholder;
