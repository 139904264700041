import React from 'react';

const GenderOtherIcon = () => (
  <svg width="98" height="98" viewBox="0 0 98 98" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <circle className="genderPicker-itemBg" cx="48.9998" cy="48.5545" r="45.325" fill="white" />
      <path
        className="genderPicker-itemMoon"
        opacity="0.2"
        d="M50.1621 1.43213C48.1137 1.43213 46.0955 1.56171 44.1152 1.81303C67.5309 4.78384 85.636 24.7774 85.636 48.9998C85.636 73.2223 67.5309 93.216 44.1152 96.1866C46.0955 96.438 48.1137 96.5675 50.1621 96.5675C76.433 96.5675 97.7298 75.2707 97.7298 48.9998C97.7298 22.7289 76.433 1.43213 50.1621 1.43213V1.43213Z"
        fill="#A1A1A1"
      />
    </g>
  </svg>
);

export default GenderOtherIcon;
