import React from 'react';
import Icon from '../../Icon';

const AdminStatisticsTab = ({ icon, title, isActive, onClick }) => {
  const classes = ['adminStatisticTab', isActive ? 'active' : ''];

  return (
    <div className={classes.join(' ')} onClick={onClick}>
      {icon && (
        <div className="adminStatisticTab-icon">
          <Icon icon={icon} size={32} />
        </div>
      )}
      {title && <div className="adminStatisticTab-title">{title}</div>}
    </div>
  );
};

export default AdminStatisticsTab;
