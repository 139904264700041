import React from 'react';
import img from '../../../images/example.png';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';
import { getExpiredDate } from '../../../utils/products';

const ProductPreview = ({
  title,
  cover,
  miniCover,
  period,
  expirationDays,
  clickHandler,
  deleteHandler,
  withDeleteBtn = true,
  withClickEvent = true,
}) => {
  const classes = ['card', !withClickEvent ? 'card--noClick' : null];

  return (
    <div className={classes.join(' ')} onClick={withClickEvent ? clickHandler : null}>
      <div className="card-imgWrapper">
        <img crossOrigin="anonymous" src={miniCover || cover} alt={title} />
      </div>
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        {period && period.length ? (
          <p className="card-deadline">Expires on {getExpiredDate(period)}</p>
        ) : expirationDays ? (
          <p className="card-deadline">Expiration days {expirationDays}</p>
        ) : null}
      </div>
      {deleteHandler && withDeleteBtn && (
        <button className="card-deleteBtn" onClick={deleteHandler}>
          <Icon icon={ICONS.TRASH} size={16} />
        </button>
      )}
    </div>
  );
};

export default ProductPreview;
