export function roundPlus(x, n) {
  // x - число, n - количество знаков
  if (isNaN(x) || isNaN(n)) return false;
  const m = 10 ** n;
  return Math.round(x * m) / m;
}

export const getCardsByCustomer = (cards, customers) => {
  if (customers === 0 || cards === 0) {
    return 0;
  }
  return roundPlus(cards / customers, 1);
};

export const getCollectedPoints = (collectedPoints, totalPoints) => {
  if (parseInt(collectedPoints) === 0 || parseInt(totalPoints) === 0) {
    return 0;
  }
  return `${roundPlus((collectedPoints / totalPoints) * 100, 1)}%`;
};

export const getCollectedPointsByCards = (cards) => {
  if (cards.length) {
    const sum = cards.reduce(
      (acc, currentCard) => {
        const points = parseInt(currentCard.points);
        const total = parseInt(currentCard.total);
        if(points === 0 || total === 0) {
          return acc + 0
        }
        return acc + points / total
      },
      0,
    );
    return `${roundPlus((sum / cards.length) * 100, 1)}%`;
  }
  return '0%';
};

export const getFullCards = (collectedCards, openCards) => {
  if (collectedCards === 0 || openCards === 0) {
    return 0;
  }
  return `${roundPlus((collectedCards / openCards) * 100, 1)}%`;
};
