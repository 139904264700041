import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '../../Tabs/Tabs';
import GeneralInfo from './GeneralInfo';
import Charts from './Charts/Charts';

const PartnerStatistics = () => {
  const { t } = useTranslation();

  const tabs = [
    {
      id: 1,
      text: t('General Info'),
    },
    {
      id: 2,
      text: t('Charts'),
    },
  ];

  const [activeTab, setActiveTab] = useState(1);

  const onTabClick = (id) => {
    setActiveTab(id);
  };

  return (
    <div className="statistics">
      <div className="statistic-container">
        <h2 className="pageTitle">{t('Statistic')}</h2>
        <Tabs activeTab={activeTab} tabs={tabs} onTabClick={onTabClick} />
        {activeTab === 1 ? <GeneralInfo /> : <Charts />}
      </div>
    </div>
  );
};

export default PartnerStatistics;
