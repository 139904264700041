import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Upload, Spin, message } from 'antd';
import { useFormContext } from 'react-hook-form';
import ImgCrop from 'antd-img-crop';

import Api from '../../redux/middlewares/Api';

import { getBase64, beforeUpload } from '../../utils/files';

import Icon from '../Icon';
import { ICONS } from '../../constants';

import './Images.scss';

import FieldError from '../HookedField/FieldError/FieldError';

const api = new Api();

const Images = ({
  defaultCover,
  defaultMiniCover,
  withAdditionalImages,
  withMiniCover,
  defaultLogo,
  defaultImage1,
  defaultImage2,
  defaultImage3,
}) => {
  const { t } = useTranslation();

  const [loadingCover, setLoadingCover] = useState(false);
  const [loadingMiniCover, setLoadingMiniCover] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingImage1, setLoadingImage1] = useState(false);
  const [loadingImage2, setLoadingImage2] = useState(false);
  const [loadingImage3, setLoadingImage3] = useState(false);

  const [currentCover, setCurrentCover] = useState(defaultCover);
  const [currentMiniCover, setCurrentMiniCover] = useState(defaultMiniCover);
  const [currentLogo, setCurrentLogo] = useState(defaultLogo);
  const [currentImage1, setCurrentImage1] = useState(defaultImage1);
  const [currentImage2, setCurrentImage2] = useState(defaultImage2);
  const [currentImage3, setCurrentImage3] = useState(defaultImage3);

  const { register, setValue, errors, clearErrors } = useFormContext();

  useEffect(() => {
    register('cover');

    if (withMiniCover) {
      register('miniCover');
    }

    if (withAdditionalImages) {
      register('logo');
      register('image1');
      register('image2');
      register('image3');
    }

    if (defaultCover) {
      const coverId = parseInt(defaultCover.substring(defaultCover.lastIndexOf('/') + 1));
      setValue('cover', { id: coverId });
    }

    if (defaultMiniCover) {
      const miniCoverId = parseInt(
        defaultMiniCover.substring(defaultMiniCover.lastIndexOf('/') + 1),
      );
      setValue('miniCover', { id: miniCoverId });
    }

    if (defaultLogo) {
      const logoId = parseInt(defaultLogo.substring(defaultLogo.lastIndexOf('/') + 1));
      setValue('logo', { id: logoId });
    }

    if (defaultImage1) {
      const image1Id = parseInt(defaultImage1.substring(defaultImage1.lastIndexOf('/') + 1));
      setValue('image1', { id: image1Id });
    }

    if (defaultImage2) {
      const image2Id = parseInt(defaultImage2.substring(defaultImage2.lastIndexOf('/') + 1));
      setValue('image2', { id: image2Id });
    }

    if (defaultImage3) {
      const image3Id = parseInt(defaultImage3.substring(defaultImage3.lastIndexOf('/') + 1));
      setValue('image3', { id: image3Id });
    }
  }, [defaultCover, defaultMiniCover, defaultLogo, defaultImage1, defaultImage2, defaultImage3]);

  const handleImageChange = (info, name) => {
    if (info.file.status === 'uploading') {
      switch (name) {
        case 'cover':
          setLoadingCover(true);
          return;
        case 'miniCover':
          setLoadingMiniCover(true);
          return;
        default:
          return;
      }
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        switch (name) {
          case 'cover':
            setCurrentCover(imageUrl);
            setLoadingCover(false);
            clearErrors('cover');
            break;
          case 'miniCover':
            setCurrentMiniCover(imageUrl);
            setLoadingMiniCover(false);
            clearErrors('miniCover');
            break;
          case 'logo':
            setCurrentLogo(imageUrl);
            setLoadingLogo(false);
            clearErrors('logo');
            break;
          case 'image1':
            setCurrentImage1(imageUrl);
            setLoadingImage1(false);
            break;
          case 'image2':
            setCurrentImage2(imageUrl);
            setLoadingImage2(false);
            break;
          case 'image3':
            setCurrentImage3(imageUrl);
            setLoadingImage3(false);
            break;
          default:
        }
      });
    }
  };

  const removeImage = (event, name) => {
    event.stopPropagation();
    switch (name) {
      case 'cover':
        setCurrentCover(null);
        setValue('cover', null);
        break;
      case 'miniCover':
        setCurrentMiniCover(null);
        setValue('miniCover', null);
        break;
      case 'logo':
        setCurrentLogo(null);
        setValue('logo', null);
        break;
      case 'image1':
        setCurrentImage1(null);
        setValue('image1', null);
        break;
      case 'image2':
        setCurrentImage2(null);
        setValue('image2', null);
        break;
      case 'image3':
        setCurrentImage3(null);
        setValue('image3', null);
        break;
    }
  };

  const customRequest = async ({ file, onSuccess, onError }, name) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const imageData = await api.uploadFile(formData);
      switch (name) {
        case 'cover':
          setValue('cover', { id: imageData.id });
          break;
        case 'miniCover':
          setValue('miniCover', { id: imageData.id });
          break;
        case 'logo':
          setValue('logo', { id: imageData.id });
          break;
        case 'image1':
          setValue('image1', { id: imageData.id });
          break;
        case 'image2':
          setValue('image2', { id: imageData.id });
          break;
        case 'image3':
          setValue('image3', { id: imageData.id });
          break;
        default:
          return;
      }
      onSuccess();
    } catch (e) {
      onError();
    }
  };

  const UploadButton = ({ name, textMode }) => {
    let loading;
    switch (name) {
      case 'cover':
        loading = loadingCover;
        break;
      case 'miniCover':
        loading = loadingMiniCover;
        break;
      case 'logo':
        loading = loadingLogo;
        break;
      case 'image1':
        loading = loadingImage1;
        break;
      case 'image2':
        loading = loadingImage2;
        break;
      case 'image3':
        loading = loadingImage3;
        break;
    }
    return (
      <>
        {loading ? (
          <Spin spinning={loading} size="small" />
        ) : (
          <div className="images-uploadButton">
            <Icon className="images-uploadIcon" icon={ICONS.IMAGE} size={32} />
            {(textMode === 'full' || textMode === 'part') && (
              <div className="images-text images-text--underline">
                {t('Click here to Upload Image')}
              </div>
            )}
            {textMode === 'full' && (
              <div className="images-text">{t('Or drag the image here')}</div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className={`images ${withMiniCover ? 'images--withMiniCover' : 'images--single'}`}>
        <div className="images-block">
          <ImgCrop aspect={261 / 118}>
            <Upload
              name="cover"
              listType="picture-card"
              className="images-cover"
              customRequest={(event) => customRequest(event, 'cover')}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={(info) => handleImageChange(info, 'cover')}
            >
              {currentCover ? (
                <>
                  <img crossOrigin="anonymous" src={currentCover} alt={t('Main Image')} style={{ width: '100%' }} />
                  <button
                    className="images-remove"
                    type="button"
                    onClick={(event) => removeImage(event, 'cover')}
                  >
                    <Icon icon={ICONS.TRASH} size={16} />
                  </button>
                </>
              ) : (
                <UploadButton name="cover" textMode="full" />
              )}
            </Upload>
          </ImgCrop>
          <div className="images-text images-text--title">
            {t('Main Image')} ({t('required')})
          </div>
        </div>
        {withMiniCover && (
          <div className="images-block">
            <ImgCrop aspect={85 / 118}>
              <Upload
                name="miniCover"
                listType="picture-card"
                className="images-miniCover"
                customRequest={(event) => customRequest(event, 'miniCover')}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleImageChange(info, 'miniCover')}
              >
                {currentMiniCover ? (
                  <>
                    <img crossOrigin="anonymous" src={currentMiniCover} alt={t('Miniature')} style={{ width: '100%' }} />
                    <button
                      className="images-remove"
                      type="button"
                      onClick={(event) => removeImage(event, 'miniCover')}
                    >
                      <Icon icon={ICONS.TRASH} size={16} />
                    </button>
                  </>
                ) : (
                  <UploadButton name="miniCover" textMode="part" />
                )}
              </Upload>
            </ImgCrop>
            <div className="images-text images-text--title">
              {t('Miniature')} <br />({t('required')})
            </div>
          </div>
        )}
      </div>
      {withAdditionalImages && (
        <div className="additionalImages">
          <div className="images-block">
            <ImgCrop aspect={1 / 1}>
              <Upload
                name="logo"
                listType="picture-card"
                className="images-cover"
                customRequest={(event) => customRequest(event, 'logo')}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleImageChange(info, 'logo')}
              >
                {currentLogo ? (
                  <>
                    <img crossOrigin="anonymous" src={currentLogo} alt={t('Logo')} style={{ width: '100%' }} />
                    <button
                      className="images-remove"
                      type="button"
                      onClick={(event) => removeImage(event, 'logo')}
                    >
                      <Icon icon={ICONS.TRASH} size={16} />
                    </button>
                  </>
                ) : (
                  <UploadButton name="logo" />
                )}
              </Upload>
            </ImgCrop>
            <div className="images-text images-text--title">
              {t('Logo')} ({t('required')})
            </div>
          </div>
          <div className="images-block">
            <ImgCrop aspect={1 / 1}>
              <Upload
                name="image1"
                listType="picture-card"
                className="images-cover"
                customRequest={(event) => customRequest(event, 'image1')}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleImageChange(info, 'image1')}
              >
                {currentImage1 ? (
                  <>
                    <img crossOrigin="anonymous" src={currentImage1} alt={t('Image')} style={{ width: '100%' }} />
                    <button
                      className="images-remove"
                      type="button"
                      onClick={(event) => removeImage(event, 'image1')}
                    >
                      <Icon icon={ICONS.TRASH} size={16} />
                    </button>
                  </>
                ) : (
                  <UploadButton name="image1" />
                )}
              </Upload>
            </ImgCrop>
          </div>
          <div className="images-block">
            <ImgCrop aspect={1 / 1}>
              <Upload
                name="image2"
                listType="picture-card"
                className="images-cover"
                customRequest={(event) => customRequest(event, 'image2')}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleImageChange(info, 'image2')}
              >
                {currentImage2 ? (
                  <>
                    <img crossOrigin="anonymous" src={currentImage2} alt={t('Image')} style={{ width: '100%' }} />
                    <button
                      className="images-remove"
                      type="button"
                      onClick={(event) => removeImage(event, 'image2')}
                    >
                      <Icon icon={ICONS.TRASH} size={16} />
                    </button>
                  </>
                ) : (
                  <UploadButton name="image2" />
                )}
              </Upload>
            </ImgCrop>
            <div className="images-text images-text--title">{t('Additional Images')}</div>
          </div>
          <div className="images-block">
            <ImgCrop aspect={1 / 1}>
              <Upload
                name="image3"
                listType="picture-card"
                className="images-cover"
                customRequest={(event) => customRequest(event, 'image3')}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={(info) => handleImageChange(info, 'image3')}
              >
                {currentImage3 ? (
                  <>
                    <img crossOrigin="anonymous" src={currentImage3} alt={t('Image')} style={{ width: '100%' }} />
                    <button
                      className="images-remove"
                      type="button"
                      onClick={(event) => removeImage(event, 'image3')}
                    >
                      <Icon icon={ICONS.TRASH} size={16} />
                    </button>
                  </>
                ) : (
                  <UploadButton name="image3" />
                )}
              </Upload>
            </ImgCrop>
          </div>
        </div>
      )}
      <FieldError error={errors.cover} />
      <FieldError error={errors.miniCover} />
      <FieldError error={errors.logo} />
    </>
  );
};

export default Images;
