import auth from './auth';
import products from './products';
import modal from './modal';
import preferences from './preferences';
import businesses from './businesses';
import profile from './profile';
import users from './users';
import plans from './plans';
import autopilots from './autopilots';
import statistics from './statistics';
import slots from './slots';
import messages from './messages';
import newsletters from './newsletters';
import regulars from './regulars';
import notifications from './notifications';

const rootReducer = {
  auth,
  products,
  modal,
  preferences,
  businesses,
  profile,
  users,
  plans,
  autopilots,
  statistics,
  slots,
  messages,
  newsletters,
  regulars,
  notifications,
};

export default rootReducer;
