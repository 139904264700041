import React from 'react';
import { useTranslation } from 'react-i18next';

import './CategoriesItem.scss';

const CategoriesItem = ({
  name,
  title,
  value,
  icon,
  checked,
  onChange,
  isPreview,
  readOnly = false,
}) => {
  const { t } = useTranslation();

  return (
    <label className="categoriesItem">
      <input
        type="checkbox"
        className="categoriesItem-input"
        name={name}
        value={value}
        checked={isPreview ? true : checked}
        readOnly={readOnly}
        onChange={onChange}
      />
      <div className="categoriesItem-box">
        <img crossOrigin="anonymous" src={icon} alt={title} />
      </div>
      {title && <div className="categoriesItem-text">{t(title)}</div>}
    </label>
  );
};

export default CategoriesItem;
