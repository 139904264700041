import {
  REQUEST_GET_BUSINESSES,
  REQUEST_CREATE_BUSINESSES,
  REQUEST_UPDATE_BUSINESS,
  REQUEST_DELETE_BUSINESS,
  CLEAN_CREATE_BUSINESSES_ERRORS,
  CLEAN_LAST_CREATED_BUSINESS,
  REQUEST_GET_MEMBERS,
  REQUEST_CREATE_MEMBER,
  REQUEST_DELETE_MEMBER,
  REQUEST_RESEND_MEMBER,
  REQUEST_GET_PARTNER_BUSINESSES,
} from '../types';

export function getBusinesses(payload) {
  return {
    type: REQUEST_GET_BUSINESSES,
    payload,
  };
}

export function getPartnerBusinesses(payload) {
  return {
    type: REQUEST_GET_PARTNER_BUSINESSES,
    payload,
  };
}

export function createBusinesses(payload) {
  return {
    type: REQUEST_CREATE_BUSINESSES,
    payload,
  };
}

export function updateBusiness(payload) {
  return {
    type: REQUEST_UPDATE_BUSINESS,
    payload,
  };
}

export function deleteBusiness(payload) {
  return {
    type: REQUEST_DELETE_BUSINESS,
    payload,
  };
}

export function cleanBusinessErrors(payload) {
  return {
    type: CLEAN_CREATE_BUSINESSES_ERRORS,
    payload,
  };
}

export function cleanLastCreatedBusiness(payload) {
  return {
    type: CLEAN_LAST_CREATED_BUSINESS,
    payload,
  };
}

export function getMembers(payload) {
  return {
    type: REQUEST_GET_MEMBERS,
    payload,
  };
}

export function deleteMember(payload) {
  return {
    type: REQUEST_DELETE_MEMBER,
    payload,
  };
}

export function createMember(payload) {
  return {
    type: REQUEST_CREATE_MEMBER,
    payload,
  };
}

export function resendMember(payload) {
  return {
    type: REQUEST_RESEND_MEMBER,
    payload,
  };
}
