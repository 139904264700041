import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './NotFound.scss';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="notFound">
      <div className="notFound-content">
        <div className="notFound-code">
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </div>
        <h1 className="notFound-title">{t('Page not found')}</h1>
        <Link to="/main" className="btn btn--secondary">
          {t('Go Back')}
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
