import moment from 'moment';

export const getFormattedGetSlotData = (data) => {
  const formattedData = {};

  // region
  if (data.region && Object.keys(data.region).length) {
    formattedData.region = data.region;
  }

  // period
  if (data.period && data.period.length) {
    formattedData.period = data.period.map((date) => moment(date).toISOString());
  } else {
    formattedData.period = null;
  }

  // point
  if (data.price) {
    formattedData.price = data.price / 100;
  }

  return formattedData;
};

export const getFormattedPostSlotData = (data) => {
  const formattedData = {};

  // region
  if (data.region && Object.keys(data.region).length) {
    formattedData.region = data.region;
  }

  // period
  if (data.period && data.period.length) {
    formattedData.period = data.period.map((date) => moment(date).toISOString());
  } else {
    formattedData.period = null;
  }

  // point
  if (data.price) {
    formattedData.price = Math.round(data.price * 100);
  }

  return formattedData;
};
