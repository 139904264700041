import React from 'react';

import AdminStatisticsTab from './AdminStatisticsTab';

const AdminStatisticsTabs = ({ tabs, activeTab, changeOptionHandler }) => (
  <div className="adminStatisticTabs">
    {tabs.map(({ title, icon, value }, index) => (
      <AdminStatisticsTab
        key={index}
        title={title}
        icon={icon}
        isActive={value === activeTab}
        onClick={() => changeOptionHandler(value, title)}
      />
    ))}
  </div>
);

export default AdminStatisticsTabs;
