import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BackBtn from '../../../components/BackBtn/BackBtn';
import LanguagePicker from '../../../components/LanguagePicker/LanguagePicker';

import './NewAccountDescription.scss';

const NewAccountDescription = () => {
  const { t } = useTranslation();

  return (
    <div className="newAccountDesc">
      <div className="smallContainer">
        <div className="smallContainer-top">
          <BackBtn />
          <LanguagePicker />
        </div>
        <h2 className="newAccountDesc-title">{t('Business Profile')}</h2>
        <div className="newAccountDesc-description">{t('NewAccount.description')}</div>
        <ul className="newAccountDesc-list">
          <li>{t('NewAccount.item 1')}</li>
          <li>{t('NewAccount.item 2')}</li>
          <li>{t('NewAccount.item 3')}</li>
          <li>{t('NewAccount.item 4')}</li>
          <li>{t('NewAccount.item 5')}</li>
          <li>{t('NewAccount.item 6')}</li>
        </ul>
        <div className="newAccountDesc-banner">
          <p>{t('NewAccount.banner')}</p>
          <Link to="/sign-up" className="btn btn--primary">
            {t('Sign up')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NewAccountDescription;
