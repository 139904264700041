import { REQUEST_GET_NOTIFICATIONS, REQUEST_DELETE_NOTIFICATION } from '../types';

export function getNotifications(payload) {
  return {
    type: REQUEST_GET_NOTIFICATIONS,
    payload,
  };
}

export function deleteNotification(payload) {
  return {
    type: REQUEST_DELETE_NOTIFICATION,
    payload,
  };
}
