import React from 'react';
import PropTypes from 'prop-types';

import Toogle from '../../ui/Toogle';

import './MessagesSettingsItem.scss';

const MessagesSettingsItem = ({ title, description, enabled, changeHandler }) => (
  <div className="messagesSettingsItem">
    <div className="messagesSettingsItem-content">
      <div className="messagesSettingsItem-title">{title}</div>
      <div className="messagesSettingsItem-description">{description}</div>
    </div>
    <Toogle name={title} checked={enabled} changeHandler={changeHandler} />
  </div>
);

MessagesSettingsItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default MessagesSettingsItem;
