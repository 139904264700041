import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';
import Api from '../../../redux/middlewares/Api';
import { getBase64, beforeUpload } from '../../../utils/files';

import './Avatar.scss';

const api = new Api();

const Avatar = ({ defaultCover }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [currentCover, setCurrentCover] = useState(defaultCover);
  const { register, setValue, clearErrors } = useFormContext();

  useEffect(() => {
    register('cover');
    if (defaultCover) {
      const coverId = parseInt(defaultCover.substring(defaultCover.lastIndexOf('/') + 1));
      setValue('cover', { id: coverId });
    }
  }, [defaultCover]);

  const handleImageChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (imageUrl) => {
        setCurrentCover(imageUrl);
        setLoading(false);
        clearErrors('cover');
      });
    }
  };

  const removeImage = (event) => {
    event.stopPropagation();
    setCurrentCover(null);
    setValue('cover', null);
  };

  const customRequest = async ({ file, onSuccess, onError }) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      const imageData = await api.uploadFile(formData);
      setValue('cover', { id: imageData.id });
      onSuccess();
    } catch (e) {
      onError();
    }
  };

  return (
    <div className="avatar profilePreview-img">
      <ImgCrop aspect={112 / 112} shape="round">
        <Upload
          name="cover"
          listType="picture-card"
          className="avatar-cover"
          customRequest={customRequest}
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleImageChange}
        >
          {currentCover ? (
            <>
              <img crossOrigin="anonymous" src={currentCover} alt={t('Main Image')} style={{ width: '100%' }} />
              <button className="avatar-remove" type="button" onClick={removeImage}>
                <Icon icon={ICONS.CLOSE} size={24} />
              </button>
            </>
          ) : (
            <>
              {loading ? (
                <Spin spinning={loading} size="small" />
              ) : (
                <div className="avatar-uploadButton">
                  <Icon className="avatar-uploadIcon" icon={ICONS.IMAGE} size={32} />
                </div>
              )}
            </>
          )}
        </Upload>
      </ImgCrop>
    </div>
  );
};

export default Avatar;
