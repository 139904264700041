import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  GET_PRODUCTS,
  REQUEST_GET_PRODUCTS,
  DELETE_PRODUCT,
  REQUEST_DELETE_PRODUCTS,
  UPDATE_PRODUCT,
  REQUEST_UPDATE_PRODUCTS,
  CREATE_PRODUCT,
  REQUEST_CREATE_PRODUCTS,
} from '../types/index';

const api = new Api();

function* getProductsWatcher() {
  yield takeEvery(REQUEST_GET_PRODUCTS, getProductsWorker);
}

function* deleteProductWatcher() {
  yield takeEvery(REQUEST_DELETE_PRODUCTS, deleteProductWorker);
}

function* updateProductWatcher() {
  yield takeEvery(REQUEST_UPDATE_PRODUCTS, updateProductWorker);
}

function* createProductWatcher() {
  yield takeEvery(REQUEST_CREATE_PRODUCTS, createProductWorker);
}

function* getProductsWorker({
  payload: { arrayOfBusinessesIds, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.getProductsByBusinessesIds(arrayOfBusinessesIds));
    yield put({ type: GET_PRODUCTS, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteProductWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteProduct(id));
    yield put({ type: DELETE_PRODUCT, payload: id });
    onSuccessCallback && onSuccessCallback();
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* updateProductWorker({
  payload: { id, productData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.updateProduct(id, productData));
    yield put({ type: UPDATE_PRODUCT, payload: data });
    onSuccessCallback &&
      onSuccessCallback({
        type: data.type,
        id: data.id,
        trackingID: data.trackingID,
      });
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* createProductWorker({
  payload: { productData, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(() => api.createProduct(productData));
    yield put({ type: CREATE_PRODUCT, payload: data });
    onSuccessCallback &&
      onSuccessCallback({
        type: data.type,
        id: data.id,
        trackingID: data.trackingID,
      });
  } catch (errors) {
    onErrorCallback && onErrorCallback(errors);
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* products() {
  yield all([
    fork(getProductsWatcher),
    fork(deleteProductWatcher),
    fork(updateProductWatcher),
    fork(createProductWatcher),
  ]);
}
