import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import QRCode from 'qrcode.react';
import { showModal } from '../../../redux/actions/modal';

import { MODAL_TYPES, PRODUCT_TYPES } from '../../../constants';

import './QrCodeModal.scss';

const QrCodeModal = ({ productType, productId, productTrackingID, title }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const downloadHandler = () => {
    const canvas = document.getElementById('qrCode');
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'qrCode.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    dispatch(
      showModal({
        type: MODAL_TYPES.NOTIFICATION,
        title: t('The card was updated'),
        okBtnText: t('Go to Cards/Gifts'),
      }),
    );
  };

  const getQrValue = () => {
    const value = {
      type: productType === PRODUCT_TYPES.CARD ? 'LoialCard' : 'LoialGift',
      id: parseInt(productId),
      trackingID: productTrackingID,
    };
    return JSON.stringify(value);
  };

  return (
    <div className="qrCodeModal">
      <h2 className="qrCodeModal-heading">{title || t('QR Code was Refreshed!')}</h2>
      <div className="qrCodeModal-codeWrapper">
        <QRCode id="qrCode" value={getQrValue()} size={88} />
        <svg
          className="qrCodeModal-circle"
          width="106"
          height="106"
          viewBox="0 0 106 106"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106 106C106 47.4578 58.542 0 0 0V6C55.2285 6 100 50.7715 100 106H106Z"
            fill="white"
          />
        </svg>
      </div>
      <button className="btn btn--secondary" onClick={downloadHandler}>
        {t('Save Image')}
      </button>
    </div>
  );
};

export default QrCodeModal;
