import React from 'react';
import { useTranslation } from 'react-i18next';

import './ProductCard.scss';
import { PRODUCT_TYPES } from '../../../constants';

const ProductCard = ({ title, cover, description, conditions, type }) => {
  const { t } = useTranslation();
  return (
    <div className="productCard">
      <div className="productCard-imageWrapper">
        <img crossOrigin="anonymous" className="productCard-image" src={cover} alt={title} />
      </div>
      <div className="productCard-content">
        <h3 className="productCard-title">{title}</h3>
        {description && <div className="productCard-description">{description}</div>}
        <div className="productCard-conditions">{conditions}</div>
      </div>
      <div className="productCard-iconWrapper">
        <div className="productCard-icon" />
        <b>Collect</b> {type === PRODUCT_TYPES.CARD ? t('Reward') : t('Gift')}
      </div>
    </div>
  );
};

export default ProductCard;
