import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import { showModal } from '../../../redux/actions/modal';
import Api from '../../../redux/middlewares/Api';

import { PRODUCT_TYPES, ICONS, MODAL_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';
import { getExpiredDate } from '../../../utils/products';

import Icon from '../../Icon';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import BusinessPreview from '../../Business/BusinessPreview/BusinessPreview';

import './ProductView.scss';

const api = new Api();

const ProductView = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [productData, setProductData] = useState({});
  const [productLoading, setProductLoading] = useState(true);
  const [onGetProductErrors, setOnGetProductErrors] = useState([]);

  useEffect(() => {
    api.getProduct(id).then(
      (product) => {
        setProductData(product);
        setProductLoading(false);
      },
      (errors) => {
        setOnGetProductErrors(getCorrectErrorMessages(errors));
        setProductLoading(false);
      },
    );
  }, [id]);

  const deleteHandler = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.PRODUCT_DELETE,
        id,
      }),
    );
  };

  const editHandler = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.PRODUCT_EDIT,
        id,
        onSuccessCallback: (product) => {
          dispatch(
            showModal({
              type: MODAL_TYPES.QR_CODE,
              productType: product.type,
              productId: product.id,
              productTrackingID: product.trackingID,
              title: t('QR Code was Refreshed!'),
            }),
          );
        },
      }),
    );
  };

  return (
    <div className="messageModal messageModal--view">
      {productLoading ? (
        <Spin size="large" />
      ) : Object.keys(productData).length ? (
        <div className="productView">
          <div className="productView-imgWrapper">
            <img crossOrigin="anonymous" src={productData.cover} alt={productData.title} />
          </div>
          <h3 className="productView-title">{productData.title}</h3>
          {productData.period && productData.period.length ? (
            <div className="productView-item">
              <Icon icon={ICONS.CHECKBOX} size={16} color="#FABB18" />
              <span>Expires on {getExpiredDate(productData.period)}</span>
            </div>
          ) : null}
          {productData.expirationDays && (
            <div className="productView-item">
              <Icon icon={ICONS.CHECKBOX} size={16} color="#FABB18" />
              <span>Expiration days {productData.expirationDays}</span>
            </div>
          )}
          <div className="productView-separator" />
          {productData.type === PRODUCT_TYPES.CARD ? (
            <h4 className="productView-subtitle">
              {productData.point} {productData.point !== 1 ? t('Points') : t('Point')}
            </h4>
          ) : (
            <h4 className="productView-subtitle">{t('Gift')}</h4>
          )}
          <div className="productView-conditions">{productData.conditions}</div>
          {productData.description && (
            <>
              <div className="productView-separator" />
              <div className="productView-description">{productData.description}</div>
            </>
          )}
          {productData.businesses && productData.businesses.length ? (
            <>
              <div className="productView-separator" />
              <h4 className="productView-subtitle">{t('Available at')}</h4>
              {productData.businesses.map((business) => (
                <BusinessPreview
                  key={business.id}
                  title={business.title}
                  cover={business.cover}
                  description={business.description}
                />
              ))}
            </>
          ) : null}
          <div className="modal-actions modal-actions--fullsize">
            <button className="btn btn--small btn--secondary" onClick={deleteHandler}>
              {t('Delete')}
            </button>
            <button className="btn btn--small btn--primary" onClick={editHandler}>
              {t('Edit')}
            </button>
          </div>
        </div>
      ) : (
        <ErrorMessages errorMessages={onGetProductErrors} />
      )}
    </div>
  );
};

export default ProductView;
