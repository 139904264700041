import React from 'react';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from '../../../utils/validation_schemas/newsletterSlot';

import HookedField from '../../HookedField/HookedField';
import Images from '../../Images/Images';

import './NewsletterSlot.scss';
import { Spin } from 'antd';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';

const NewsletterSlotForm = ({ price, submitHandler, loading = false, onSubmitErrors }) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <Spin spinning={loading}>
      <FormProvider {...methods}>
        <form
          className="newsletterSlot"
          autoComplete="off"
          onSubmit={methods.handleSubmit(submitHandler)}
        >
          <HookedField
            name="subject"
            placeholder={t('Main Subject')}
            label={t('Main Subject')}
            defaultValue=""
            fieldType="textField"
            type="text"
          />
          <Images defaultCover={null} />
          <HookedField
            name="description"
            placeholder={t('Message Text')}
            label={t('Message Text')}
            defaultValue=""
            fieldType="textField"
            type="textarea"
          />
          <ErrorMessages errorMessages={onSubmitErrors} />
          <div className="newsletterSlot-footer">
            <h3 className="newsletterSlot-price">
              {t('Price')}: <span>{price}</span>
            </h3>
            <button type="submit" className="btn btn--small btn--primary">
              {t('Save & Buy')}
            </button>
          </div>
          <div className="newsletterSlot-banner">{t('Not available')}</div>
        </form>
      </FormProvider>
    </Spin>
  );
};

const NewsletterSlotPreview = ({ subject, cover, description, price }) => (
  <div className="newsletterView-info">
    <h4 className="newsletterView-info-subject">{subject}</h4>
    <div className="newsletterView-info-imgWrapper">
      <img crossOrigin="anonymous" className="newsletterView-info-img" src={cover} alt={subject} />
    </div>
    <div className="newsletterView-info-description">{description}</div>
    <div className="newsletterSlot-footer">
      <h3 className="newsletterSlot-price">
        Price: <span>{price}</span>
      </h3>
      <button type="button" disabled className="btn btn--small btn--primary">
        Bought
      </button>
    </div>
  </div>
);

const NewsletterSlot = ({
  price,
  owner,
  submitHandler,
  loading = false,
  onSubmitErrors,
  subject,
  cover,
  description,
}) =>
  owner ? (
    <NewsletterSlotPreview
      subject={subject}
      cover={cover}
      description={description}
      price={price}
    />
  ) : (
    <NewsletterSlotForm
      price={price}
      submitHandler={submitHandler}
      loading={loading}
      onSubmitErrors={onSubmitErrors}
    />
  );

export default NewsletterSlot;
