import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { getProducts } from '../../../redux/actions/products';
import { getAutopilots } from '../../../redux/actions/autopilots';
import { showModal } from '../../../redux/actions/modal';

import { MODAL_TYPES } from '../../../constants';

import MessagePreview from '../../../components/Message/MessagePreview/MessagePreview';
import MessagesSettings from '../../../components/MessagesSettings/MessagesSettings';
import NoData from '../../../components/NoData/NoData';

import './Messages.scss';
/* import {getRegularMessages} from "../../../redux/actions/regulars";
import RegularMessagePreview from "../../../components/RegularMessage/RegularMessagePreview/RegularMessagePreview"; */

const Messages = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [autopilotsLoading, setAutopilotsLoading] = useState(false);

  useEffect(() => {
    setAutopilotsLoading(true);
    dispatch(
      getAutopilots({
        onSuccessCallback: () => {
          setAutopilotsLoading(false);
        },
        onErrorCallback: (errors) => {
          if (errors.some((error) => error === 'CHARGEBEE_UPDATE_SUBSCRIPTION_PLAN')) {
            dispatch(
              showModal({
                type: MODAL_TYPES.ACCESS,
                title: t('You don\'t have access to autopilots'),
                description: t('Please change your subscription plan to see your autopilots.'),
              }),
            );
          }
          setAutopilotsLoading(false);
        },
      }),
    );
  }, []);

  const autopilots = useSelector((state) => state.autopilots.items);
  const { businesses } = useSelector((state) => state.profile.data);

  useEffect(() => {
    if (businesses && businesses.length) {
      dispatch(
        getProducts({
          arrayOfBusinessesIds: businesses.map((business) => business.id),
        }),
      );
    }
  }, [businesses]);

  const clickAutopilotHandler = (id) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.MESSAGE_VIEW,
        id,
      }),
    );
  };

  // regular messages

  // const regularMessages = useSelector(state => state.regulars.messages);
  // const [getRegularMessagesLoading, setGetRegularMessagesLoading] = useState(false);
  //
  // useEffect(() => {
  //     setGetRegularMessagesLoading(true);
  //     dispatch(getRegularMessages({
  //         onSuccessCallback: () => {
  //             setGetRegularMessagesLoading(false);
  //         },
  //         onErrorCallback: () => {
  //             setGetRegularMessagesLoading(false);
  //         }
  //     }));
  // }, []);
  //
  // const addRegularMessageHandler = () => {
  //     dispatch(showModal({
  //         type: MODAL_TYPES.REGULAR_MESSAGE_ADD
  //     }))
  // }

  return (
    <div className="messagesPage">
      <h2 className="pageTitle">{t('Messages')}</h2>
      <MessagesSettings autopilotMessages={autopilots} />
      <h3 className="pageSubtitle">{t('Autopilot Messages')}</h3>
      {autopilotsLoading ? (
        <Spin size="large" />
      ) : autopilots.length ? (
        <div className="messages">
          {autopilots.map(({ id, type, subject, description, cover }) => (
            <MessagePreview
              key={id}
              type={type}
              cover={cover}
              subject={subject}
              description={description}
              clickHandler={() => clickAutopilotHandler(id)}
            />
          ))}
        </div>
      ) : (
        <NoData />
      )}
      {/* <div className="regularMessages">
                <h3>{ t("Regular Messages") }</h3>
                <button className="btn btn--primary btn--small" onClick={addRegularMessageHandler}>
                    + {t("Add Regular Message")}
                </button>
            </div>
            {getRegularMessagesLoading ? (
                <Spin size="large"/>
            ) : regularMessages.length ? (
                <div className="messages">
                    {regularMessages.map(
                        ({
                             id,
                             subject,
                             description,
                             cover,
                             createdAt
                         }) => (
                            <RegularMessagePreview
                                key={id}
                                subject={subject}
                                description={description}
                                cover={cover}
                                createdAt={createdAt}
                            />
                        )
                    )}
                </div>
            ) : (
                <NoData/>
            )} */}
    </div>
  );
};

export default Messages;
