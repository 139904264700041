import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import CustomerStat from './CustomerStat/CustomerStat';
import CardStat from './CardStat/CardStat';
import {
  getCardsByCustomer,
  getCollectedPoints,
  getFullCards,
  getCollectedPointsByCards,
} from '../../../utils/statistics';
import { getCustomerStatistics, getDetailsStatistics } from '../../../redux/actions/statistics';
import { showModal } from '../../../redux/actions/modal';
import { MODAL_TYPES } from '../../../constants';
import NoData from '../../NoData/NoData';

const GeneralInfo = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // customer statistic

  const [customerStatisticLoading, setCustomerStatisticLoading] = useState(true);
  const [customerStatistic, setCustomerStatistic] = useState({});

  const [detailStatisticLoading, setDetailStatisticLoading] = useState(false);
  const [detailStatistic, setDetailStatistic] = useState({});

  const { customerStatistics, detailsStatistics } = useSelector((state) => state.statistics);

  useEffect(() => {
    dispatch(
      getCustomerStatistics({
        onSuccessCallback: () => {
          setCustomerStatisticLoading(false);
          setDetailStatisticLoading(true);
          dispatch(
            getDetailsStatistics({
              onSuccessCallback: () => setDetailStatisticLoading(false),
              onErrorCallback: () => setDetailStatisticLoading(false),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setCustomerStatisticLoading(false);
          if (errors.some((error) => error === 'CHARGEBEE_UPDATE_SUBSCRIPTION_PLAN')) {
            dispatch(
              showModal({
                type: MODAL_TYPES.ACCESS,
                title: t('You don\'t have access to statistics'),
                description: t(
                  'Please change your subscription plan to see your business statistics.',
                ),
              }),
            );
          }
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (customerStatistics && Object.keys(customerStatistics).length) {
      setCustomerStatistic(customerStatistics);
    }
  }, [customerStatistics]);

  useEffect(() => {
    if (detailsStatistics && Object.keys(detailsStatistics).length) {
      setDetailStatistic(detailsStatistics);
    }
  }, [detailsStatistics]);

  // open cards

  const [openedCard, setOpenedCard] = useState(null);

  const onCardClick = (id) => {
    if (openedCard === id) {
      setOpenedCard(null);
    } else {
      setOpenedCard(id);
    }
  };

  return (
    <div className="generalInfo">
      <h4>{t('By Customer')}</h4>
      {customerStatisticLoading ? (
        <Spin spinning={customerStatisticLoading} />
      ) : Object.keys(customerStatistic).length ? (
        <div className="customerStats">
          <CustomerStat text={t('Customers')} value={customerStatistic.customers} />
          <CustomerStat
            text={t('Cards per Customer')}
            value={getCardsByCustomer(customerStatistic.cards.total, customerStatistic.customers)}
          />
          <CustomerStat
            text={t('Collected Points')}
            value={getCollectedPoints(
              customerStatistic.points.collected,
              customerStatistic.points.total,
            )}
          />
          <CustomerStat
            text={t('Full cards')}
            value={getFullCards(customerStatistic.cards.collected, customerStatistic.cards.open)}
          />
        </div>
      ) : (
        <NoData />
      )}
      <h4>{t('By Cards')}</h4>
      {detailStatisticLoading ? (
        <Spin spinning={detailStatisticLoading} />
      ) : Object.keys(customerStatistic).length && Object.keys(detailsStatistics).length ? (
        <div className="cardStats">
          <CardStat
            value={customerStatistic.cards.open}
            text={t('Open Cards')}
            isOpen={openedCard === 1}
            cards={detailStatistic.open}
            onCardClick={() => onCardClick(1)}
          />
          <CardStat
            value={getCollectedPointsByCards(detailStatistic.collected)}
            text={t('Collected points')}
            isOpen={openedCard === 2}
            cards={detailStatistic.collected}
            onCardClick={() => onCardClick(2)}
          />
          <CardStat
            value={customerStatistic.cards.full}
            text={t('Full cards')}
            isOpen={openedCard === 3}
            cards={detailStatistic.full}
            onCardClick={() => onCardClick(3)}
          />
          <CardStat
            value={customerStatistic.cards.redeemed}
            text={t('Redeemed Cards')}
            isOpen={openedCard === 4}
            cards={detailStatistic.redeemed}
            onCardClick={() => onCardClick(4)}
          />
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default GeneralInfo;
