import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Spin } from 'antd';
import { getSlots } from '../../redux/actions/slots';

import SlotPreview from '../Slot/SlotPreview/SlotPreview';
import NoData from '../NoData/NoData';
import { showModal } from '../../redux/actions/modal';
import { MODAL_TYPES } from '../../constants';
import { getNewsletters } from '../../redux/actions/newsletter';
import NewsletterPreview from '../Newsletter/NewsletterPreview/NewsletterPreview';

const PartnerAdditionalOffers = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [slotsLoading, setSlotsLoading] = useState(false);
  const slots = useSelector((state) => state.slots.items);

  const getSlotsHandler = () => {
    setSlotsLoading(true);
    dispatch(
      getSlots({
        onSuccessCallback: () => setSlotsLoading(false),
        onErrorCallback: () => setSlotsLoading(false),
      }),
    );
  };

  useEffect(() => {
    getSlotsHandler();
  }, []);

  const buySlotHandler = (slotId) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.SLOT_BUY,
        slotId,
        onSuccessCallback: () => {
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('You successfully bought slot'),
            }),
          );
          getSlotsHandler();
        },
      }),
    );
  };

  const renderSlots = () => {
    if (slotsLoading) {
      return <Spin size="large" />;
    }
    if (slots && slots.length) {
      return slots.map((slot) => (
        <SlotPreview
          key={slot.id}
          owner={slot.owner}
          createdAt={slot.createdAt}
          period={slot.period}
          price={slot.price}
          region={slot.region}
          isForPartner
          buyHandler={() => buySlotHandler(slot.id)}
        />
      ));
    }
    return <NoData />;
  };

  // newsletters

  const [newslettersLoading, setNewslettersLoading] = useState(false);
  const newsletters = useSelector((state) => state.newsletters.items);

  useEffect(() => {
    setNewslettersLoading(true);
    dispatch(
      getNewsletters({
        onSuccessCallback: () => setNewslettersLoading(false),
        onErrorCallback: () => setNewslettersLoading(false),
      }),
    );
  }, []);

  const clickNewsletterHandler = (id) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.NEWSLETTER_VIEW,
        id,
      }),
    );
  };

  const renderNewsletters = () => {
    if (newslettersLoading) {
      return <Spin size="large" />;
    }
    if (newsletters && newsletters.length) {
      return newsletters.map(({ id, subject, description, date }) => (
        <NewsletterPreview
          key={id}
          subject={subject}
          description={description}
          date={date}
          clickHandler={() => clickNewsletterHandler(id)}
        />
      ));
    }
    return <NoData />;
  };

  return (
    <div className="additionalOffers">
      <h2 className="pageTitle">{t('Additional Offers')}</h2>
      <div className="pageRow">
        <h3>{t('Featured Partner Slot')}</h3>
      </div>
      <div className="slots">{renderSlots()}</div>
      <div className="pageRow">
        <h3>{t('Slot in newsletter')}</h3>
      </div>
      <div className="newsletters">{renderNewsletters()}</div>
      {/* <h3 className="pageSubtitle">My newsletters</h3> */}
      {/* <div className="newsletters"> */}
      {/*    {myNewsLetters.map(({id, title, subtitle, description, date}) => ( */}
      {/*        <NewsletterCard */}
      {/*            key={id} */}
      {/*            title={title} */}
      {/*            subtitle={subtitle} */}
      {/*            description={description} */}
      {/*            date={date} */}
      {/*        /> */}
      {/*    ))} */}
      {/* </div> */}
    </div>
  );
};

export default PartnerAdditionalOffers;
