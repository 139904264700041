import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import { Radio, Spin } from 'antd';
import { showModal } from '../../redux/actions/modal';
import { getProducts } from '../../redux/actions/products';

import { PRODUCT_TYPES, MODAL_TYPES, ICONS } from '../../constants';

import { sortAlphabetically, sortByNovelty } from '../../utils/sorters';

import Search from '../../components/Search/Search';
import Icon from '../../components/Icon';
import NoData from '../../components/NoData/NoData';
import ProductPreview from '../../components/Product/ProductPreview/ProductPreview';

const CardsAndGifts = ({ match }) => {
  const { t } = useTranslation();

  const bpId = match.params.businessId;

  const dispatch = useDispatch();

  // get all products
  const [productsLoading, setProductsLoading] = useState(false);
  const products = useSelector((state) => state.products.items);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [sortValue, setSortValue] = useState('alphabetically');

  const getProductsHandler = (bpId) => {
    if (bpId) {
      setProductsLoading(true);
      dispatch(
        getProducts({
          arrayOfBusinessesIds: [bpId],
        }),
      );
    }
  };

  useEffect(() => {
    getProductsHandler(bpId);
  }, [bpId]);

  useEffect(() => {
    setCurrentProducts([...products].sort(sortAlphabetically));
    setProductsLoading(false);
    setSearchValue('');
    setSortValue('alphabetically');
  }, [products]);

  const onSortChange = (e) => {
    setSortValue(e.target.value);
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    let sortedProducts = [...products];

    switch (sortValue) {
      case 'alphabetically':
        sortedProducts = sortedProducts.sort(sortAlphabetically);
        break;
      case 'by novelty':
        sortedProducts = sortedProducts.sort(sortByNovelty);
        break;
      default:
        return;
    }

    // search
    sortedProducts = sortedProducts.filter((card) =>
      card.title.toLowerCase().includes(searchValue.trim().toLowerCase()),
    );

    setCurrentProducts(sortedProducts);
  }, [sortValue, searchValue]);

  // add card modal

  const onAddProductButtonClick = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.PRODUCT_ADD,
        onSuccessCallback: (product) => {
          dispatch(
            showModal({
              type: MODAL_TYPES.QR_CODE,
              productType: product.type,
              productId: product.id,
              productTrackingID: product.trackingID,
              title: t('QR Code was created!'),
            }),
          );
          // getProductsHandler(bpId);
        },
      }),
    );
  };

  // calendar modal

  const showCalendarModal = () => {
    dispatch(
      showModal({
        type: MODAL_TYPES.CALENDAR,
        cards: products.filter((product) => {
          if (product.type === PRODUCT_TYPES.CARD && product.period && product.period.length) {
            return product;
          }
        }),
      }),
    );
  };

  // delete product handler

  const deleteProductHandler = (event, productId) => {
    event.stopPropagation();
    dispatch(
      showModal({
        type: MODAL_TYPES.PRODUCT_DELETE,
        id: productId,
      }),
    );
  };

  // view product

  const clickProductHandler = (productId) => {
    dispatch(
      showModal({
        type: MODAL_TYPES.PRODUCT_VIEW,
        id: productId,
      }),
    );
  };

  // render cards and gifts

  const renderCards = () => {
    const cards = currentProducts.filter((product) => product.type === PRODUCT_TYPES.CARD);
    if (productsLoading) {
      return <Spin size="large" />;
    }
    if (cards.length) {
      return (
        <div className="cards">
          {cards.map((card) => (
            <ProductPreview
              key={card.id}
              title={card.title}
              cover={card.cover}
              miniCover={card.miniCover}
              period={card.period}
              expirationDays={card.expirationDays}
              deleteHandler={(event) => deleteProductHandler(event, card.id)}
              clickHandler={() => clickProductHandler(card.id)}
            />
          ))}
        </div>
      );
    }
    return <NoData />;
  };

  const renderGifts = () => {
    const gifts = currentProducts.filter((product) => product.type === PRODUCT_TYPES.GIFT);
    if (productsLoading) {
      return <Spin size="large" />;
    }
    if (gifts.length) {
      return (
        <div className="cards">
          {gifts.map((gift) => (
            <ProductPreview
              key={gift.id}
              title={gift.title}
              cover={gift.cover}
              miniCover={gift.miniCover}
              period={gift.period}
              expirationDays={gift.expirationDays}
              deleteHandler={(event) => deleteProductHandler(event, gift.id)}
              clickHandler={() => clickProductHandler(gift.id)}
            />
          ))}
        </div>
      );
    }
    return <NoData />;
  };

  return (
    <div className="cardsPage">
      <h2 className="pageTitle">{t('Cards & Gifts')}</h2>
      <div className="bar">
        <Search value={searchValue} onChange={onSearchChange} />
        <div style={{ display: 'flex' }}>
          <button className="calendar-btn" onClick={showCalendarModal}>
            <Icon icon={ICONS.CALENDAR} size={32} />
          </button>
          <button className="btn btn--small btn--primary" onClick={onAddProductButtonClick}>
            + {t('Add Card/Gift')}
          </button>
        </div>
      </div>
      <div className="sorters">
        <Radio.Group onChange={onSortChange} value={sortValue}>
          <Radio name="sort" value="alphabetically">
            {t('Alphabetically')}
          </Radio>
          <Radio name="sort" value="by novelty">
            {t('By novelty')}
          </Radio>
        </Radio.Group>
      </div>
      <h3 className="pageSubtitle">{t('Cards')}</h3>
      {renderCards()}
      <h3 className="pageSubtitle">{t('Gifts')}</h3>
      {renderGifts()}
    </div>
  );
};

export default CardsAndGifts;
