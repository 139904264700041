import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { Input, Checkbox, InputNumber, DatePicker, Select, TimePicker } from 'antd';

import { v4 as uuidv4 } from 'uuid';
import InputMask from 'react-input-mask';

import moment from 'moment';
import FieldError from './FieldError/FieldError';
import Icon from '../Icon';
import { ICONS, DATE_FORMAT } from '../../constants';

const { Password, TextArea } = Input;

const { RangePicker: TimeRangePicker } = TimePicker;

const { RangePicker } = DatePicker;

const HookedField = ({
  className,
  name,
  input,
  defaultValue,
  fieldType,
  label,
  type,
  disabled,
  placeholder,
  text,
  error,
  options,
  disabledDate,
  mode,
  allowClear,
  prefixIcon,
  suffixIcon,
  ...rest
}) => {
  const { t } = useTranslation();

  const id = uuidv4();

  const { control, errors } = useFormContext();

  const classes = ['hooked-field', className, errors[name] ? 'hooked-field--withError' : ''];

  const renderInput = (onChange, value) => {
    switch (type) {
      case 'password':
        return (
          <Password
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            bordered={false}
            {...rest}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            className={className}
            onChange={(e) => onChange(e.target.checked)}
            checked={value}
            disabled={disabled}
            id={id}
            bordered={false}
            {...rest}
          >
            {placeholder}
          </Checkbox>
        );
      case 'toggle':
        return (
          <label className="toogle">
            <input
              className="toogle-input"
              type="checkbox"
              disabled={disabled}
              name={name}
              checked={value}
              defaultValue={defaultValue}
              onChange={(e) => onChange(e.target.checked)}
            />
            <span className="toogle-box" />
          </label>
        );
      case 'textarea':
        return (
          <TextArea
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            bordered={false}
            autoSize={{ minRows: 1, maxRows: 5 }}
            {...rest}
          />
        );
      case 'number':
        return (
          <InputNumber
            className={className}
            type="number"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            {...rest}
          />
        );
      case 'price':
        return (
          <InputNumber
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            formatter={(value) => value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\s?|(,*)/g, '')}
            id={id}
            defaultValue={defaultValue}
            {...rest}
          />
        );
      case 'select':
        return (
          <Select
            mode={mode}
            allowClear={allowClear}
            className={className}
            showArrow
            placeholder={placeholder}
            disabled={disabled}
            defaultValue={defaultValue}
            onChange={onChange}
            suffixIcon={<Icon size={20} icon={ICONS.ARROW_DOWN} color="#FABB18" />}
            id={id}
            options={options}
            {...rest}
          />
        );
      case 'datePicker':
        return (
          <DatePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={defaultValue && moment(defaultValue, moment.ISO_8601)}
            format={DATE_FORMAT}
            onChange={onChange}
            suffixIcon={<Icon size={20} icon={ICONS.CALENDAR} viewBox={22} color="#FABB18" />}
            disabledDate={disabledDate}
          />
        );
      case 'dateRange':
        return (
          <RangePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={
              defaultValue &&
              defaultValue.length && [
                moment(defaultValue[0], moment.ISO_8601),
                moment(defaultValue[1], moment.ISO_8601),
              ]
            }
            format={DATE_FORMAT}
            onChange={onChange}
            placeholder={[t('Start date'), t('End date')]}
            suffixIcon={<Icon size={20} icon={ICONS.CALENDAR} viewBox={22} color="#FABB18" />}
            disabledDate={disabledDate}
          />
        );
      case 'timePicker':
        return (
          <TimePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={defaultValue && moment(defaultValue, moment.ISO_8601)}
            onChange={onChange}
            suffixIcon={<Icon size={20} icon={ICONS.CLOCK} viewBox={22} color="#FABB18" />}
          />
        );
      case 'timeRange':
        return (
          <TimeRangePicker
            clearIcon={<Icon size={20} icon={ICONS.CLOSE} />}
            defaultValue={
              defaultValue &&
              defaultValue.length && [
                moment(defaultValue[0], moment.ISO_8601),
                moment(defaultValue[1], moment.ISO_8601),
              ]
            }
            order={false}
            onChange={onChange}
            placeholder={[t('Start time'), t('End time')]}
            suffixIcon={<Icon size={20} icon={ICONS.CLOCK} viewBox={22} color="#FABB18" />}
          />
        );
      case 'tel':
        return (
          <InputMask
            mask="+99999999999"
            maskChar={null}
            className={className}
            type="tel"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            {...rest}
          />
        );
      default:
        return (
          <Input
            className={className}
            type="text"
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
            id={id}
            bordered={false}
            {...rest}
          />
        );
    }
  };

  const renderController = () => (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ onChange, value }) => renderInput(onChange, value)}
      type={type}
    />
  );

  const renderError = () => <FieldError error={error || errors[name]} />;

  const hookedInput = renderController();
  const hookedError = renderError();

  const renderTextField = () => {
    const classes = [
      'field',
      suffixIcon ? 'field--withSuffixIcon' : '',
      prefixIcon ? 'field--withPrefixIcon' : '',
    ];
    return (
      <div className={classes.join(' ')}>
        {label && <label htmlFor={id}>{label}</label>}
        <div className="field-wrapper">
          {prefixIcon && <div className="field-prefixIcon">{prefixIcon}</div>}
          {hookedInput}
          {suffixIcon && <div className="field-suffixIcon">{suffixIcon}</div>}
          <div className="field-border" />
          {hookedError}
        </div>
      </div>
    );
  };

  const renderNumberField = () => (
    <div className="field">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field-wrapper">
        {hookedInput}
        <div className="field-border" />
        {hookedError}
      </div>
    </div>
  );

  const renderPasswordField = () => (
    <div className="password-field">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field-wrapper">
        {hookedInput}
        <div className="field-border" />
        {hookedError}
      </div>
    </div>
  );

  const renderCheckboxField = () => (
    <>
      <label className="customCheckbox">
        {hookedInput}
        {text}
      </label>
      {hookedError}
    </>
  );

  const renderDateField = () => (
    <div className="picker">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  );

  const renderDateRangeField = () => (
    <div className="picker">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  );

  const renderTimeField = () => (
    <div className="picker">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  );

  const renderTimeRangeField = () => (
    <div className="picker">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field-wrapper">
        {hookedInput}
        {hookedError}
      </div>
    </div>
  );

  const renderSelectField = () => (
    <div className="field field--select">
      {label && <label htmlFor={id}>{label}</label>}
      <div className="field-wrapper">
        {hookedInput}
        <div className="field-border" />
        {hookedError}
      </div>
    </div>
  );

  const renderCustomFieldField = () => (
    <>
      {hookedInput}
      {hookedError}
    </>
  );

  const renderHookedField = () => {
    switch (fieldType) {
      case 'textField':
        return renderTextField();
      case 'passwordField':
        return renderPasswordField();
      case 'checkboxField':
        return renderCheckboxField();
      case 'numberField':
        return renderNumberField();
      case 'customField':
        return renderCustomFieldField();
      case 'dateField':
        return renderDateField();
      case 'dateRange':
        return renderDateRangeField();
      case 'timeField':
        return renderTimeField();
      case 'timeRangeField':
        return renderTimeRangeField();
      case 'selectField':
        return renderSelectField();
      default:
        return renderTextField();
    }
  };

  return <div className={classes.join(' ')}>{renderHookedField()}</div>;
};

export default HookedField;
