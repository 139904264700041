import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Spin } from 'antd';
import HookedField from '../HookedField/HookedField';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import { schema } from '../../utils/validation_schemas/changePassword';

const ConfirmPasswordForm = ({
  confirmPasswordHandler,
  goToResetPasswordForm,
  onConfirmPasswordErrors,
  confirmPasswordLoading,
}) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <FormProvider {...methods}>
      <form className="form form--signIn" onSubmit={methods.handleSubmit(confirmPasswordHandler)}>
        <h2 className="form-heading">{t('Enter your previous password')}</h2>
        <fieldset className="form-fields">
          <HookedField
            name="password"
            defaultValue=""
            fieldType="passwordField"
            type="password"
            placeholder={t('Old password')}
          />
        </fieldset>
        <div className="form-actions">
          <Spin spinning={confirmPasswordLoading}>
            <button disabled={!methods.formState.isValid} className="btn btn--primary">
              {t('Submit')}
            </button>
          </Spin>
        </div>
      </form>
      <ErrorMessages errorMessages={onConfirmPasswordErrors} />
      <button className="forgotBtn" type="button" onClick={goToResetPasswordForm}>
        {t('Forgot password?')}
      </button>
    </FormProvider>
  );
};

export default ConfirmPasswordForm;
