import * as yup from 'yup';
import { PRODUCT_TYPES } from '../../constants';

export const addSchema = yup.object().shape({
  type: yup.string().required('This field is required'),
  title: yup.string().required('This field is required'),
  point: yup.number().when('type', {
    is: PRODUCT_TYPES.CARD,
    then: yup
      .number()
      .min(1, 'minimum 1 point')
      .max(20, 'maximum 20 points')
      .typeError('It has to be a number')
      .required('This field is required'),
  }),
  conditions: yup.string().required('This field is required'),
  withValidityPeriod: yup.bool(),
  period: yup.array().when('withValidityPeriod', {
    is: true,
    then: yup
      .array()
      .min(2, 'Period is required')
      .typeError('Period is required')
      .required('Period is required'),
  }),
  cover: yup.object().typeError('Main image is required').required('Main image is required'),
  expirationDays: yup.number().when('type', {
    is: PRODUCT_TYPES.GIFT,
    then: yup.number().typeError('It has to be a number').required('This field is required'),
  }),
  businesses: yup.array().min(1, 'Pick at least one').required('Pick at least one'),
});

export const editSchema = yup.object().shape({
  type: yup.string().required('This field is required'),
  title: yup.string().required('This field is required'),
  point: yup.number().when('type', {
    is: PRODUCT_TYPES.CARD,
    then: yup
        .number()
        .min(1, 'minimum 1 point')
        .max(20, 'maximum 20 points')
        .typeError('It has to be a number')
        .required('This field is required'),
  }),
  conditions: yup.string().required('This field is required'),
  withValidityPeriod: yup.bool(),
  period: yup.array().when('withValidityPeriod', {
    is: true,
    then: yup
        .array()
        .min(2, 'Period is required')
        .typeError('Period is required')
        .required('Period is required'),
  }),
  cover: yup.object().typeError('Main image is required').required('Main image is required'),
  expirationDays: yup.number().when('type', {
    is: PRODUCT_TYPES.GIFT,
    then: yup.number().typeError('It has to be a number').required('This field is required'),
  }),
});