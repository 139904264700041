import {
  REQUEST_GET_REGULAR_MESSAGES,
  REQUEST_GET_REGULAR_MESSAGE,
  REQUEST_CREATE_REGULAR_MESSAGE,
} from '../types';

export function getRegularMessages(payload) {
  return {
    type: REQUEST_GET_REGULAR_MESSAGES,
    payload,
  };
}

export function getRegularMessage(payload) {
  return {
    type: REQUEST_GET_REGULAR_MESSAGE,
    payload,
  };
}

export function createRegularMessage(payload) {
  return {
    type: REQUEST_CREATE_REGULAR_MESSAGE,
    payload,
  };
}
