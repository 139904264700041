import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import Api from '../middlewares/Api';
import {
  GET_MESSAGES,
  REQUEST_GET_MESSAGES,
  DELETE_MESSAGE,
  REQUEST_DELETE_MESSAGE,
} from '../types/index';

const api = new Api();

function* getMessagesWatcher() {
  yield takeEvery(REQUEST_GET_MESSAGES, getMessagesWorker);
}

function* deleteMessageWatcher() {
  yield takeEvery(REQUEST_DELETE_MESSAGE, deleteMessageWorker);
}

function* getMessagesWorker({
  payload: { onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    const data = yield call(api.getMessages);
    yield put({ type: GET_MESSAGES, payload: data.result });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

function* deleteMessageWorker({
  payload: { id, onSuccessCallback, onErrorCallback, onFinallyCallback },
}) {
  try {
    yield call(() => api.deleteMessage(id));
    yield put({ type: DELETE_MESSAGE, payload: id });
    onSuccessCallback && onSuccessCallback();
  } catch (e) {
    console.log(e);
    onErrorCallback && onErrorCallback();
  } finally {
    onFinallyCallback && onFinallyCallback();
  }
}

export function* messages() {
  yield all([fork(getMessagesWatcher), fork(deleteMessageWatcher)]);
}
