import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { schema } from '../../utils/validation_schemas/password';

import HookedField from '../HookedField/HookedField';
import BackBtn from '../BackBtn/BackBtn';
import ErrorMessages from '../ErrorMessages/ErrorMessages';

const Password = ({
  defaultValues = {},
  onPasswordSubmitErrors,
  passwordSubmitHandler,
  passwordSubmitLoading,
}) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <div className="passwordForm">
      <BackBtn />

      <FormProvider {...methods}>
        <form
          action="/"
          className="form form--signIn"
          onSubmit={methods.handleSubmit(passwordSubmitHandler)}
        >
          <h1 className="form-heading">{t('Enter your password')}</h1>
          <fieldset className="form-fields">
            <HookedField
              name="password"
              defaultValue={defaultValues.password || ''}
              fieldType="passwordField"
              type="password"
              placeholder={t('Password')}
            />
            <HookedField
              name="confirm_password"
              defaultValue={defaultValues.password || ''}
              fieldType="passwordField"
              type="password"
              placeholder={t('Confirm password')}
            />
          </fieldset>
          <ErrorMessages errorMessages={onPasswordSubmitErrors} />
          <div className="form-actions">
            <Spin spinning={passwordSubmitLoading}>
              <button className="btn btn--primary" disabled={!methods.formState.isValid}>
                {t('Submit')}
              </button>
            </Spin>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default Password;
