import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Spin } from 'antd';
import { updateProduct } from '../../../redux/actions/products';
import { hideModal } from '../../../redux/actions/modal';
import Api from '../../../redux/middlewares/Api';

import { editSchema } from '../../../utils/validation_schemas/product';

import { PRODUCT_TYPES } from '../../../constants';
import { getFormattedGetProductData, getFormattedPostProductData } from '../../../utils/products';
import { getCorrectErrorMessages } from '../../../utils/errors';

import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import HookedField from '../../HookedField/HookedField';
import Images from '../../Images/Images';

import './ProductForm.scss';
import Toogle from '../../ui/Toogle';

const api = new Api();

const ProductEditForm = ({ id, onSuccessCallback, skip, skipHandler }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(editSchema),
  });

  // get Product

  const [productData, setProductData] = useState({});
  const [productLoading, setProductLoading] = useState(true);
  const [onGetProductErrors, setOnGetProductErrors] = useState([]);

  useEffect(() => {
    api.getProduct(id).then(
      (product) => {
        const formattedData = getFormattedGetProductData(product);
        setProductData(formattedData);
        setProductLoading(false);
      },
      (errors) => {
        setProductLoading(false);
        setOnGetProductErrors(getCorrectErrorMessages(errors));
      },
    );
  }, [id]);

  // update Product

  const [updateProductLoading, setUpdateProductLoading] = useState(false);
  const [onUpdateProductErrors, setOnUpdateProductErrors] = useState([]);

  const submitHandler = (data) => {
    setUpdateProductLoading(true);
    const formattedData = getFormattedPostProductData(data);
    dispatch(
      updateProduct({
        id,
        productData: { ...formattedData, trackingID: null },
        onSuccessCallback: (product) => {
          setUpdateProductLoading(false);
          onSuccessCallback(product);
        },
        onErrorCallback: (errors) => {
          setUpdateProductLoading(false);
          setOnUpdateProductErrors(getCorrectErrorMessages(errors));
        },
      }),
    );
  };

  const cancelHandler = () => {
    dispatch(hideModal());
  };

  const renderHeading = (productType) => {
    if (productType === PRODUCT_TYPES.GIFT) {
      return t('Editing Gift');
    }
    return t('Editing Card');
  };

  const [withValidityPeriod, setWithValidityPeriod] = useState(false);

  const validityPeriodHandler = (event) => {
    setWithValidityPeriod(event.target.checked);
  };

  useEffect(() => {
    setWithValidityPeriod(!!productData.period);
  }, [productData]);

  return (
    <FormProvider {...methods}>
      <div className="productForm">
        {productLoading ? (
          <Spin size="large" />
        ) : Object.keys(productData).length ? (
          <>
            <h2 className="productForm-heading">{renderHeading(productData.type)}</h2>
            <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
              <HookedField
                name="title"
                placeholder={t('Name of Product')}
                label={t('Name of Product')}
                defaultValue={productData.title || ''}
                fieldType="textField"
                type="text"
              />
              <Images
                defaultCover={productData.cover}
                withMiniCover
                defaultMiniCover={productData.miniCover}
              />
              <HookedField
                name="description"
                placeholder={t('Description')}
                label={t('Description')}
                defaultValue={productData.description || ''}
                fieldType="textField"
                type="textarea"
              />
              {productData.type === PRODUCT_TYPES.CARD && (
                <HookedField
                  name="point"
                  placeholder={t('Quantity of Points')}
                  label={t('Quantity of Points')}
                  defaultValue={productData.point || null}
                  fieldType="numberField"
                  type="number"
                  min={0}
                />
              )}
              {productData.type === PRODUCT_TYPES.GIFT && (
                <HookedField
                  name="expirationDays"
                  placeholder={t('Expiration date in days')}
                  label={t('Expiration date in days')}
                  defaultValue={productData.expirationDays || null}
                  fieldType="numberField"
                  type="number"
                  min={0}
                />
              )}
              <HookedField
                name="conditions"
                placeholder={t('Conditions')}
                label={t('Conditions')}
                defaultValue={productData.conditions || ''}
                fieldType="textField"
                type="textarea"
              />
              {productData.type === PRODUCT_TYPES.CARD && (
                <>
                  <div className="toogleBlock">
                    <div className="toogleBlock-title">{t('Validity Period')}</div>
                    <Toogle
                      name="withPeriod"
                      checked={withValidityPeriod}
                      changeHandler={validityPeriodHandler}
                    />
                  </div>
                  {withValidityPeriod && (
                    <HookedField
                      name="period"
                      label={t('Validity')}
                      defaultValue={productData.period || []}
                      type="dateRange"
                      fieldType="dateRange"
                      disabledDate={(current) => current && current < moment()}
                    />
                  )}
                </>
              )}
              {!skip && (
                <Spin spinning={updateProductLoading}>
                  <button type="submit" className="refreshBtn">
                    {t('Refresh QR Code')}
                  </button>
                </Spin>
              )}
              <input type="hidden" name="type" value={productData.type} ref={methods.register} />
              <ErrorMessages errorMessages={onUpdateProductErrors} />
              {skip ? (
                <Spin spinning={updateProductLoading}>
                  <div>
                    <button type="submit" className="btn btn--primary">
                      {t('Next step')}
                    </button>
                    <button type="button" className="btn btn--skip" onClick={skipHandler}>
                      {t('Skip')}
                    </button>
                  </div>
                </Spin>
              ) : (
                <Spin spinning={updateProductLoading}>
                  <div className="modal-actions modal-actions--fullsize">
                    <button
                      type="button"
                      className="btn btn--small btn--secondary"
                      onClick={cancelHandler}
                    >
                      {t('Cancel')}
                    </button>

                    <button type="submit" className="btn btn--small btn--primary">
                      {t('Save')}
                    </button>
                  </div>
                </Spin>
              )}
            </form>
          </>
        ) : (
          <ErrorMessages errorMessages={onGetProductErrors} />
        )}
      </div>
    </FormProvider>
  );
};

export default ProductEditForm;
