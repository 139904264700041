import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import ReactCodeInput from 'react-verification-code-input';
import { yupResolver } from '@hookform/resolvers/yup';
import { Spin } from 'antd';
import { schema } from '../../utils/validation_schemas/emailVerification';

import InputError from '../HookedField/FieldError/FieldError';
import ErrorMessages from '../ErrorMessages/ErrorMessages';
import BackBtn from '../BackBtn/BackBtn';

const EmailVerificationCode = ({ email, signUpHandler, onSignUpErrors, signUpLoading }) => {
  const { t } = useTranslation();

  const { handleSubmit, errors, control, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  return (
    <div className="emailVerification">
      <BackBtn />
      <form className="form form--signIn" onSubmit={handleSubmit(signUpHandler)}>
        <h1 className="form-heading">{t('Check your email!')}</h1>
        <p className="form-subheading form-subheading--wide">
          {t("We've sent a 6-digit confirmation code to")} {email}.{' '}
          {t('It will expire shortly, so enter it soon.')}
        </p>
        <Controller
          control={control}
          name="code"
          defaultValue=""
          render={({ onChange, value }) => (
            <ReactCodeInput
              className="verificationCode"
              fieldWidth={44}
              fieldHeight={44}
              title={t(
                'Keep this window open while checking for your code. Remember to try your spam folder!',
              )}
              onChange={onChange}
              value={value}
            />
          )}
          type="text"
        />
        <InputError error={errors.code} />
        <ErrorMessages errorMessages={onSignUpErrors} />
        <div className="form-actions">
          <Spin spinning={signUpLoading}>
            <button type="submit" className="btn btn--primary" disabled={!formState.isValid}>
              {t('Submit')}
            </button>
          </Spin>
        </div>
      </form>
    </div>
  );
};

export default EmailVerificationCode;
