import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useDispatch } from 'react-redux';
import { Radio, Spin } from 'antd';
import Api from '../../../redux/middlewares/Api';
import { updateAutopilot } from '../../../redux/actions/autopilots';
import { prevModal, showModal } from '../../../redux/actions/modal';

import { AUTOPILOT_TYPES, MODAL_TYPES, PRODUCT_TYPES } from '../../../constants';
import { getCorrectErrorMessages } from '../../../utils/errors';
import {
  getFormattedGetAutopilotData,
  getFormattedPostAutopilotData,
  renderAutopilotType,
} from '../../../utils/autopilots';

import Toogle from '../../ui/Toogle';
import HookedField from '../../HookedField/HookedField';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';
import ProductChooser from '../../ProductChooser/ProductChooser';
import Images from '../../Images/Images';

import './MessageForm.scss';

const api = new Api();

const MessageEditForm = ({ id }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // get Message

  const [messageData, setMessageData] = useState({});
  const [messageLoading, setMessageLoading] = useState(true);
  const [onGetMessageErrors, setOnGetMessageErrors] = useState([]);

  const schema = yup.object().shape({
    subject: yup.string().required('This field is required'),
    cover: yup.object().typeError('Main image is required').required('Main image is required'),
    description: yup.string().required('This field is required'),
    withAttachments: yup.bool(),
    products: yup.array().when('withAttachments', {
      is: true,
      then: yup.array().min(1, t('Pick at least one')).required('Pick at least one'),
    }),
    points: yup
      .number()
      .min(1, 'Minimum 1 point')
      .max(20, 'Maximum 20 points')
      .typeError('It has to be a number')
      .when('cardType', {
        is: PRODUCT_TYPES.CARD,
        then: yup
          .number()
          .min(1, 'Minimum 1 point')
          .max(20, 'Maximum 20 points')
          .typeError('It has to be a number')
          .required('This field is required'),
      }),
    pointsReceived:
      messageData.type === AUTOPILOT_TYPES.FREQUENT_CUSTOMER
        ? yup
            .number()
            .min(1, 'Minimum 1 point')
            .max(20, 'Maximum 20 points')
            .typeError('It has to be a number')
            .required('This field is required')
        : null,
    mailingTime: yup.object().nullable(),
  });

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    api.getAutopilot(id).then(
      (autopilot) => {
        const formattedData = getFormattedGetAutopilotData(autopilot);
        setMessageData(formattedData);
        setMessageLoading(false);
      },
      (errors) => {
        setOnGetMessageErrors(getCorrectErrorMessages(errors));
        setMessageLoading(false);
      },
    );
  }, [id]);

  // update Message

  const [updateMessageLoading, setUpdateMessageLoading] = useState(false);
  const [onUpdateMessageErrors, setOnUpdateMessageErrors] = useState([]);

  const cancelHandler = () => {
    dispatch(prevModal());
  };

  const submitHandler = (data) => {
    setUpdateMessageLoading(true);
    const formattedData = getFormattedPostAutopilotData(data);
    dispatch(
      updateAutopilot({
        id,
        autopilotData: formattedData,
        onSuccessCallback: () => {
          dispatch(
            showModal({
              type: MODAL_TYPES.NOTIFICATION,
              title: t('Message saved and activated!'),
            }),
          );
        },
        onErrorCallback: (errors) => {
          setOnUpdateMessageErrors(getCorrectErrorMessages(errors));
          setUpdateMessageLoading(false);
        },
      }),
    );
  };

  const [currentCardType, setCurrentCardType] = useState(PRODUCT_TYPES.CARD);
  const [withAttachments, setWithAttachments] = useState(false);

  useEffect(() => {
    if (messageData && messageData.products) {
      const cardType = messageData.products.some((product) => product.type === PRODUCT_TYPES.GIFT)
        ? PRODUCT_TYPES.GIFT
        : PRODUCT_TYPES.CARD;
      setCurrentCardType(cardType);

      setWithAttachments(!!messageData.products);
    }
  }, [messageData]);

  const onChangeCardType = (e) => {
    setCurrentCardType(e.target.value);
    methods.clearErrors();
  };

  const changeAttachmentHandler = (e) => {
    setWithAttachments(e.target.checked);
  };

  useEffect(() => {
    if (!withAttachments) {
      methods.setValue('products', []);
    }
  }, [withAttachments]);

  return (
    <div className="messageModal">
      <FormProvider {...methods}>
        <h2 className="messageModal-title">{t('Editing an Autopilot Message')}</h2>

        {messageLoading ? (
          <Spin size="large" />
        ) : Object.keys(messageData).length ? (
          <>
            <div className="messageModal-type">{renderAutopilotType(messageData.type)}</div>
            <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
              <input type="hidden" name="type" value={messageData.type} ref={methods.register} />
              <input type="hidden" name="title" value={messageData.type} ref={methods.register} />
              <HookedField
                name="subject"
                placeholder={t('Subject')}
                label={`${t('Subject')} (${t('required')})`}
                defaultValue={messageData.subject || ''}
                fieldType="textField"
                type="text"
              />
              <Images defaultCover={messageData.cover} />
              <HookedField
                name="description"
                placeholder={t('Message Text')}
                label={`${t('Message Text')} (${t('required')})`}
                defaultValue={messageData.description || ''}
                fieldType="textField"
                type="textarea"
              />
              <div className="toogleBlock">
                <div className="toogleBlock-title">{t('Attachments')}</div>
                <Toogle
                  name="withAttachments"
                  checked={withAttachments}
                  changeHandler={changeAttachmentHandler}
                />
              </div>
              {withAttachments && (
                <>
                  <div className="cardType-chooser">
                    <Radio.Group onChange={onChangeCardType} value={currentCardType}>
                      <Radio name="cardType" value={PRODUCT_TYPES.CARD}>
                        {t('Give Points')}
                      </Radio>
                      <Radio name="cardType" value={PRODUCT_TYPES.GIFT}>
                        {t('Give Gift')}
                      </Radio>
                    </Radio.Group>
                  </div>
                  <ProductChooser
                    defaultValue={messageData.products || null}
                    productType={currentCardType}
                  />
                  {currentCardType === PRODUCT_TYPES.CARD && (
                    <HookedField
                      name="points"
                      placeholder={t('Quantity of Points')}
                      label={`${t('Quantity of Points')} (${t('required')})`}
                      defaultValue={messageData.points || null}
                      fieldType="numberField"
                      type="number"
                      min={0}
                    />
                  )}
                </>
              )}
              {messageData.type === AUTOPILOT_TYPES.FREQUENT_CUSTOMER ? (
                <HookedField
                  name="pointsReceived"
                  placeholder={t('Points to receive')}
                  label={`${t('Points to receive')} (${t('required')})`}
                  defaultValue={messageData.pointsReceived || null}
                  fieldType="numberField"
                  type="number"
                  min={0}
                />
              ) : null}
              <HookedField
                name="mailingTime"
                placeholder={t('Select Time')}
                label={t('Mailing Time')}
                defaultValue={messageData.mailingTime || null}
                type="timePicker"
                fieldType="timeField"
              />
              <ErrorMessages errorMessages={onUpdateMessageErrors} />
              <Spin spinning={updateMessageLoading}>
                <div className="modal-actions modal-actions--fullsize">
                  <button
                    type="button"
                    className="btn btn--small btn--secondary"
                    onClick={cancelHandler}
                  >
                    {t('Cancel')}
                  </button>
                  <button type="submit" className="btn btn--small btn--primary">
                    {t('Save')}
                  </button>
                </div>
              </Spin>
            </form>
          </>
        ) : (
          <ErrorMessages errorMessages={onGetMessageErrors} />
        )}
      </FormProvider>
    </div>
  );
};

export default MessageEditForm;
