import React from 'react';
import Icon from '../../Icon';
import { ICONS } from '../../../constants';

const AdminStatisticsInfo = ({ title, current, previous, style }) => {
  const classes = ['adminStatisticInfo', `adminStatisticInfo--${style}`];

  const renderProgress = () => {
    if (parseInt(previous) && parseInt(current)) {
      const percent = ((parseInt(current) - parseInt(previous)) / parseInt(previous)) * 100;
      const icon = percent >= 0 ? ICONS.INCREASE : ICONS.DECREASE;
      return (
        <div className="adminStatisticInfo-percent">
          <Icon icon={icon} size={24} color="#ffffff" />
          <span>{percent.toFixed(2)}%</span>
        </div>
      );
    }
    const delta = parseInt(current) - parseInt(previous);
    const icon = delta >= 0 ? ICONS.INCREASE : ICONS.DECREASE;
    return (
      <div className="adminStatisticInfo-percent">
        <Icon icon={icon} size={24} color="#ffffff" />
        <span>
          {delta >= 0 ? '+' : ''}
          {delta}
        </span>
      </div>
    );
  };

  return (
    <div className={classes.join(' ')}>
      {title && <h4 className="adminStatisticInfo-title">{title}</h4>}
      {current && <div className="adminStatisticInfo-value">{current}</div>}
      {renderProgress()}
    </div>
  );
};

export default AdminStatisticsInfo;
