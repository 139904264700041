import React from 'react';
import { Spin } from 'antd';
import Icon from '../../../Icon';
import { ICONS } from '../../../../constants';
import ProductPreview from '../../../Product/ProductPreview/ProductPreview';
import { getCollectedPoints } from '../../../../utils/statistics';

import './CardStat.scss';

const CardStat = ({ value, text, cards, isOpen, onCardClick }) => {
  const classes = ['cardStat', isOpen ? 'cardStat--open' : ''];

  return (
    <div className={classes.join(' ')} onClick={cards.length ? onCardClick : undefined}>
      <div className="cardStat-value">
        {typeof value === 'undefined' ? <Spin size="small" /> : value}
      </div>
      <div className="cardStat-text">{text}</div>
      {cards.length ? <Icon className="cardStat-icon" icon={ICONS.ARROW_RIGHT} /> : null}
      <div className="cardStat-cards">
        {cards.length
          ? cards.map(({ total, product, points }) => (
              <div className="cardStat-card" key={product.id}>
                <div className="cardStat-cardValue">
                  {text === 'Collected points' ? getCollectedPoints(points, total) : total}
                </div>
                <ProductPreview
                  title={product.title}
                  cover={product.cover}
                  miniCover={product.miniCover}
                  period={product.period}
                  expirationDays={product.expirationDays}
                  withDeleteBtn={false}
                  withClickEvent={false}
                />
              </div>
            ))
          : null}
      </div>
    </div>
  );
};

export default CardStat;
