import React from 'react';
import InputError from '../HookedField/FieldError/FieldError';

import './ErrorMessages.scss';

const ErrorMessages = ({ errorMessages }) => (
  <>
    {errorMessages && errorMessages.length ? (
      <div className="errors">
        {errorMessages.map((error, index) => (
          <InputError className="changePassword-error" key={index} error={error} />
        ))}
      </div>
    ) : null}
  </>
);

export default ErrorMessages;
