import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { Spin } from 'antd';
import { ICONS, DAYS } from '../../../constants';

import HookedField from '../../HookedField/HookedField';
import Categories from '../../Categories/Categories';
import LocationPicker from '../../LocationPicker/LocationPicker';
import Images from '../../Images/Images';
import Icon from '../../Icon';
import FieldError from '../../HookedField/FieldError/FieldError';
import ErrorMessages from '../../ErrorMessages/ErrorMessages';

import './BusinessMainInfo.scss';

import { schema } from '../../../utils/validation_schemas/business';

const BusinessMainInfo = ({
  submitBtnText,
  defaultValues = {},
  title = '',
  submitHandler,
  onSubmitErrors,
  onSubmitLoading,
}) => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      phones: defaultValues.phones || [{ value: '' }],
      custom: defaultValues.custom || [{ day: null, work: [], lunch: [] }],
    },
    resolver: yupResolver(schema),
  });

  const customizeByDay = methods.watch('customizeByDay');

  const preferences = useSelector((state) => state.preferences.items);

  const {
    fields: phonesFields,
    append: phonesAppend,
    remove: phonesRemove,
  } = useFieldArray({
    control: methods.control,
    name: 'phones',
  });

  const {
    fields: customFields,
    append: customAppend,
    remove: customRemove,
  } = useFieldArray({
    control: methods.control,
    name: 'custom',
  });

  return (
    <FormProvider {...methods}>
      <div className="mainInfoBlock">
        {title && <h2 className="pageTitle">{title}</h2>}
        <form onSubmit={methods.handleSubmit(submitHandler)} autoComplete="off">
          <HookedField
            name="title"
            placeholder={t('Name of BP Profile')}
            label={`${t('Name of BP Profile')} (${t('required')})`}
            defaultValue={defaultValues.title || ''}
            fieldType="textField"
            type="text"
          />
          <HookedField
            name="additional"
            placeholder={t('Additional Information')}
            label={t('Additional Information')}
            defaultValue={defaultValues.additional || null}
            fieldType="textField"
            type="text"
          />
          <Images
            defaultCover={defaultValues.cover}
            withMiniCover
            defaultMiniCover={defaultValues.miniCover}
            withAdditionalImages
            defaultLogo={defaultValues.logo}
            defaultImage1={defaultValues.image1}
            defaultImage2={defaultValues.image2}
            defaultImage3={defaultValues.image3}
          />
          <HookedField
            name="description"
            placeholder={t('Description')}
            label={t('Description')}
            defaultValue={defaultValues.description || null}
            type="textarea"
            fieldType="textField"
            autoSize="true"
          />
          <div className="mainInfoBlock-subtitle">{t('Schedule')}</div>
          <HookedField
            name="weekDays"
            placeholder={t('Select Days of Week')}
            label={t('Working Days Of Week')}
            defaultValue={defaultValues.weekDays || []}
            options={DAYS.map((day) => ({ ...day, label: t(day.label) }))}
            type="select"
            mode="multiple"
            fieldType="selectField"
          />
          <div className="mainInfoBlock-subtitle">{t('Opening hours')}</div>
          <HookedField
            name="workHours"
            placeholder={t('Select Time')}
            label={t('Weekdays Working Hours')}
            defaultValue={defaultValues.workHours || []}
            type="timeRange"
            fieldType="timeRangeField"
          />
          <HookedField
            name="lunchHours"
            placeholder={t('Select Time')}
            label={t('Weekdays Lunch Hours')}
            defaultValue={defaultValues.lunchHours || []}
            type="timeRange"
            fieldType="timeRangeField"
          />
          <div className="toogleBlock">
            <div className="toogleBlock-title">{t('Customize by Day')}</div>
            <HookedField
              name="customizeByDay"
              type="toggle"
              fieldType="customField"
              defaultValue={!!(defaultValues.custom && defaultValues.custom.length)}
            />
          </div>
          {customizeByDay && (
            <div className="fields-list">
              {customFields.map((item, index) => (
                <div className="fields-item" key={item.id}>
                  <HookedField
                    name={`custom[${index}].day`}
                    placeholder={t('Select Time')}
                    label={t('Working Day of Week')}
                    defaultValue={item.day}
                    options={DAYS.map((day) => ({ ...day, label: t(day.label) }))}
                    type="select"
                    fieldType="selectField"
                    error={
                      methods.errors.custom &&
                      methods.errors.custom[index] &&
                      methods.errors.custom[index].day
                    }
                  />
                  <HookedField
                    name={`custom[${index}].work`}
                    placeholder={t('Select Time')}
                    label={t('Weekdays Working Hours')}
                    defaultValue={item.work}
                    type="timeRange"
                    fieldType="timeRangeField"
                    error={
                      methods.errors.custom &&
                      methods.errors.custom[index] &&
                      methods.errors.custom[index].work
                    }
                  />
                  <HookedField
                    name={`custom[${index}].lunch`}
                    placeholder={t('Select Time')}
                    label={t('Weekdays Lunch Hours')}
                    defaultValue={item.lunch}
                    type="timeRange"
                    fieldType="timeRangeField"
                    error={
                      methods.errors.custom &&
                      methods.errors.custom[index] &&
                      methods.errors.custom[index].lunch
                    }
                  />
                  {index !== 0 && (
                    <button
                      className="fields-removeBtn"
                      type="button"
                      onClick={() => customRemove(index)}
                    >
                      <Icon icon={ICONS.TRASH} size={16} />
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                className="btn btn--secondary addBtn"
                onClick={() =>
                  customAppend({
                    day: null,
                    work: [],
                    lunch: [],
                  })
                }
              >
                + {t('Add a Day')}
              </button>
            </div>
          )}
          <LocationPicker
            label={`${t('Location')} (${t('required')})`}
            name="address"
            defaultValue={defaultValues.address || {}}
            placeholder={t('Search')}
          />
          <div className="fields-list">
            {phonesFields.map((item, index) => (
              <div className="fields-item" key={item.id}>
                <HookedField
                  name={`phones[${index}].value`}
                  placeholder={t('Phone Number')}
                  label={`${t('Phone Number')} (${t('required')})`}
                  defaultValue={item.value}
                  type="tel"
                  fieldType="textField"
                  error={
                    methods.errors.phones &&
                    methods.errors.phones[index] &&
                    methods.errors.phones[index].value
                  }
                />
                {index !== 0 && (
                  <button
                    className="fields-removeBtn"
                    type="button"
                    onClick={() => phonesRemove(index)}
                  >
                    <Icon icon={ICONS.TRASH} size={16} />
                  </button>
                )}
              </div>
            ))}
          </div>
          {phonesFields.length < 3 && (
            <button
              type="button"
              className="btn btn--secondary addBtn"
              onClick={() => phonesAppend({ value: '' })}
            >
              + {t('Add a Phone Number')}
            </button>
          )}
          <HookedField
            name="email"
            placeholder={t('Email')}
            label={t('Email')}
            defaultValue={defaultValues.email || null}
            fieldType="textField"
            type="text"
          />
          <HookedField
            name="link"
            placeholder={t('Link to the Website')}
            label={t('Link to the Website')}
            defaultValue={defaultValues.link || null}
            fieldType="textField"
            type="text"
          />
          <div className="mainInfoBlock-subtitle">{t('Links to Social Networks')}</div>
          <HookedField
            name="facebook"
            label="Facebook"
            prefix="https://facebook.com/"
            placeholder={t('username')}
            defaultValue={defaultValues.facebook || null}
            suffixIcon={<Icon icon={ICONS.FACEBOOK} size={24} color="#FABB18" />}
            fieldType="textField"
            type="text"
          />
          <HookedField
            name="instagram"
            label="Instagram"
            prefix="https://instagram.com/"
            placeholder={t('username')}
            defaultValue={defaultValues.instagram || null}
            suffixIcon={<Icon icon={ICONS.INSTAGRAM} size={24} color="#FABB18" />}
            fieldType="textField"
            type="text"
          />
          <HookedField
            name="youtube"
            label="Youtube"
            prefix="https://youtube.com/user/"
            placeholder={t('username')}
            defaultValue={defaultValues.youtube || null}
            suffixIcon={<Icon icon={ICONS.YOUTUBE} size={24} color="#FABB18" />}
            fieldType="textField"
            type="text"
          />
          <HookedField
            name="twitter"
            label="Twitter"
            prefix="https://twitter.com/"
            placeholder={t('username')}
            defaultValue={defaultValues.twitter || null}
            suffixIcon={<Icon icon={ICONS.TWITTER} size={24} color="#FABB18" />}
            fieldType="textField"
            type="text"
          />
          <div className="mainInfoBlock-subtitle">
            {t('Choose categories of your cafe')} ({t('required')})
          </div>
          <Categories
            defaultValue={defaultValues.preferences}
            name="preferences"
            options={preferences}
          />
          <FieldError error={methods.errors.preferences} />
          <ErrorMessages errorMessages={onSubmitErrors} />
          <Spin spinning={onSubmitLoading}>
            <button type="submit" className="btn btn--primary submitBtn">
              {submitBtnText}
            </button>
          </Spin>
        </form>
      </div>
    </FormProvider>
  );
};

export default BusinessMainInfo;
