import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { signIn, signInGoogle } from '../../redux/actions/auth';
import { getCorrectErrorMessages } from '../../utils/errors';

import Screen from '../../components/Screen/Screen';
import SignInForm from '../../components/SignInForm/SignInForm';

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [onSignInErrors, setOnSignInErrors] = useState([]);
  const [signInLoading, setSignInLoading] = useState(false);

  const handleSignIn = (data) => {
    setSignInLoading(true);
    dispatch(
      signIn({
        data,
        onSuccessCallback: () => history.push('/main'),
        onErrorCallback: (errors) => {
          setOnSignInErrors(getCorrectErrorMessages(errors));
          setSignInLoading(false);
        },
      }),
    );
  };

  const signInWithGoogleHandler = ({ token }) => {
    setSignInLoading(true);
    dispatch(
      signInGoogle({
        data: {
          token,
        },
        onSuccessCallback: () => history.push('/main'),
        onErrorCallback: (errors) => {
          setOnSignInErrors(getCorrectErrorMessages(errors));
          setSignInLoading(false);
        },
      }),
    );
  };

  const { t } = useTranslation();

  return (
    <Screen
      info={
        <>
          <span>{t("Don't have an account yet?")}</span>
          &nbsp;
          <Link className="link" to="/new-account-description">
            {t('Register')}
          </Link>
        </>
      }
    >
      <SignInForm
        handleSignIn={handleSignIn}
        signInLoading={signInLoading}
        onSignInErrors={onSignInErrors}
        signInWithGoogleHandler={signInWithGoogleHandler}
      />
    </Screen>
  );
};

export default SignIn;
